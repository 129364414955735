import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as userAction from "../actions/user";
import { UserFeatureFlag } from "../constants/userFeature";
import { ResourceOwnerSetting } from "../types/resourceOwner";
import { BriefTeam, Permission } from "../types/team";
import { SubscriptionData, User } from "../types/user";
import { UserFeatureFlagAccessor } from "../types/userFeature";
import { WorkerToken } from "../types/workerToken";

export interface ResourceOwnerState {
  readonly planName?: string;
  readonly planIsPaid?: boolean;
  readonly planId?: string | null;
  readonly subscriptionCancelAt?: string;
  readonly resourceOwnerId?: string;
  readonly isTeam: boolean;
  readonly teamName?: string;
  readonly teamContactEmail?: string | null;
  readonly teamLookupId?: string | null;
  readonly workerTokens?: WorkerToken[];
  readonly permissions: Permission;
  readonly isPaymentRequired: boolean;
  readonly isGoogleOcrEngineSet: boolean;
  readonly isAzureOcrEngineSet: boolean;
  readonly enabledFeatures: string[];
  readonly enabledAuditLog: boolean;
  readonly isUsageReachedHardLimit: boolean;

  // These are cached on demand.
  readonly subscriptionData?: SubscriptionData;
  readonly setting?: ResourceOwnerSetting;

  readonly selectedWorkerToken?: string;

  // Computed properties
  isFeatureEnabled(): (feature: string) => boolean;
}

const userPermission = {
  viewSubscription: true,
  editSubscription: true,
  viewMembership: true,
  editMembership: true,
  viewResource: true,
  editResource: true,
  createResource: true,
  removeResource: true,
  viewTeamSetting: true,
  editTeamSetting: true,
  viewAuditLog: true,
};

export const defaultState = {
  isTeam: false,
  permissions: userPermission,
  isPaymentRequired: false,
  subscriptionCancelAt: undefined,
  isGoogleOcrEngineSet: false,
  isAzureOcrEngineSet: false,
  enabledFeatures: [],
  isFeatureEnabled: function (this: ResourceOwnerState) {
    return (feature: string) => {
      return UserFeatureFlagAccessor.hasPermission(
        this.enabledFeatures as UserFeatureFlag[],
        feature as UserFeatureFlag
      );
    };
  },
  isUsageReachedHardLimit: false,
  enabledAuditLog: false,
};

function getFirstAvailableResourceOwnerIdFromUser(
  user: User,
  exclude?: string
): string | undefined {
  const firstTeam = user.teams.filter(t => t.id !== exclude)[0];
  return user.resourceOwner?.id ?? (firstTeam && firstTeam.id) ?? undefined;
}

function getInitialStateForResourceOwner(
  state: ResourceOwnerState,
  resourceOwnerId: string | undefined,
  user: User
): ResourceOwnerState {
  const isTeam =
    user.resourceOwner?.id === undefined
      ? resourceOwnerId !== undefined
      : user.resourceOwner?.id !== resourceOwnerId;

  const briefTeam =
    isTeam && resourceOwnerId
      ? getBriefTeamById(resourceOwnerId, user)
      : undefined;

  return {
    ...state,
    resourceOwnerId: resourceOwnerId,
    isTeam: isTeam,
    teamName: briefTeam?.name,
    teamContactEmail: briefTeam?.contactEmail,
    teamLookupId: briefTeam?.lookupId,
    workerTokens: undefined,
    planName:
      isTeam && resourceOwnerId
        ? briefTeam?.planName
        : user.resourceOwner?.planName,
    planIsPaid:
      isTeam && resourceOwnerId
        ? briefTeam?.planIsPaid
        : user.resourceOwner?.planIsPaid,
    planId:
      isTeam && resourceOwnerId
        ? briefTeam?.planId
        : user.resourceOwner?.planId,
    subscriptionCancelAt:
      (isTeam && resourceOwnerId
        ? briefTeam?.subscriptionCancelAt
        : user.resourceOwner?.subscriptionCancelAt) ?? undefined,
    permissions:
      isTeam && resourceOwnerId
        ? getPermissionByTeamId(resourceOwnerId, user)
        : userPermission,
    isGoogleOcrEngineSet:
      isTeam && resourceOwnerId
        ? briefTeam?.isGoogleOcrEngineSet ?? false
        : user.resourceOwner?.isGoogleOcrEngineSet ?? false,
    isAzureOcrEngineSet:
      isTeam && resourceOwnerId
        ? briefTeam?.isAzureOcrEngineSet ?? false
        : user.resourceOwner?.isAzureOcrEngineSet ?? false,
    enabledFeatures:
      isTeam && resourceOwnerId
        ? briefTeam?.enabledFeatures ?? []
        : user.resourceOwner?.enabledFeatures ?? [],
    enabledAuditLog:
      isTeam && resourceOwnerId
        ? briefTeam?.enabledAuditLog ?? false
        : user.resourceOwner?.enabledAuditLog ?? false,
    isUsageReachedHardLimit:
      isTeam && resourceOwnerId
        ? briefTeam?.isUsageReachedHardLimit ?? false
        : user.resourceOwner?.isUsageReachedHardLimit ?? false,
    subscriptionData: undefined,
    setting: undefined,
  };
}

function getBriefTeamById(teamId: string, user: User): BriefTeam | undefined {
  return user.teams.find(team => team.id === teamId);
}

function getPermissionByTeamId(teamId: string, user: User): Permission {
  return (
    user.permissions.find(permission => permission.teamId === teamId)
      ?.permission ?? userPermission
  );
}

export const resourceOwnerReducer = createReducer<ResourceOwnerState>(
  defaultState,
  builder => {
    builder
      .addCase(userAction.UserLogin, (state, action) => {
        const { resourceOwnerId, user } = action.payload;
        return getInitialStateForResourceOwner(state, resourceOwnerId, user);
      })
      .addCase(userAction.UserLogout, state => ({
        ...state,
        resourceOwnerId: undefined,
        isTeam: false,
        teamName: undefined,
        workerToken: undefined,
        plan: undefined,
        planId: undefined,
        subscriptionCancelAt: undefined,
        subscriptionData: undefined,
        setting: undefined,
        isPaymentRequired: false,
        isGoogleOcrEngineSet: false,
        isAzureOcrEngineSet: false,
        isUsageReachedHardLimit: false,
        enabledFeatures: [],
        enabledAuditLog: false,
      }))
      .addCase(teamAction.TeamDeleted, (state, action) => {
        const { teamId, currentUser } = action.payload;
        const resourceOwnerId = getFirstAvailableResourceOwnerIdFromUser(
          currentUser,
          teamId
        );
        return getInitialStateForResourceOwner(
          state,
          resourceOwnerId,
          currentUser
        );
      })
      .addCase(teamAction.TeamUserRemoved, (state, action) => {
        const { teamId, removedUserId, currentUser } = action.payload;
        const resourceOwnerId = getFirstAvailableResourceOwnerIdFromUser(
          currentUser,
          teamId
        );

        if (removedUserId === currentUser.id) {
          return getInitialStateForResourceOwner(
            state,
            resourceOwnerId,
            currentUser
          );
        } else {
          return state;
        }
      })
      .addCase(teamAction.TeamRenamed, (state, action) => {
        const { teamId, name } = action.payload;
        if (state.resourceOwnerId === teamId) {
          state.teamName = name;
        }
      })
      .addCase(resourceOwnerAction.SelectTeam, (state, action) => {
        const { resourceOwnerId, user } = action.payload;
        return getInitialStateForResourceOwner(state, resourceOwnerId, user);
      })
      .addCase(resourceOwnerAction.GotWorkerTokens, (state, action) => {
        state.workerTokens = action.payload;
        state.selectedWorkerToken = undefined;
      })
      .addCase(resourceOwnerAction.GotSubscriptionData, (state, action) => {
        state.subscriptionData = action.payload;
      })
      .addCase(resourceOwnerAction.GotSetting, (state, action) => {
        state.setting = action.payload;
      })
      .addCase(resourceOwnerAction.SettingUpdated, (state, action) => {
        state.setting = action.payload.setting;
      })
      .addCase(
        resourceOwnerAction.IsPaymentRequiredUpdated,
        (state, action) => {
          state.isPaymentRequired = action.payload;
        }
      )
      .addCase(resourceOwnerAction.PaymentMethodUpdated, (state, action) => {
        state.subscriptionData = {
          ...state.subscriptionData,
          paymentMethod: action.payload.paymentMethod,
        };
      })
      .addCase(resourceOwnerAction.BillingEmailUpdated, (state, action) => {
        state.subscriptionData = {
          ...state.subscriptionData,
          billingEmail: action.payload,
          paymentMethod: state.subscriptionData?.paymentMethod ?? null,
        };
      })
      .addCase(teamAction.UserPermissionUpdated, (state, action) => {
        const { permission, teamId, isCurrentUser } = action.payload;
        if (state.isTeam && state.resourceOwnerId === teamId && isCurrentUser) {
          state.permissions = permission;
        }
      })
      .addCase(resourceOwnerAction.SelectWorkerToken, (state, action) => {
        state.selectedWorkerToken = action.payload;
      })
      .addMatcher(
        isAnyOf(teamAction.CreateTeam, teamAction.TeamInvitationAccepted),
        (
          state,
          action: {
            payload: {
              team: BriefTeam;
              permission: Permission;
            };
          }
        ) => {
          const { team, permission } = action.payload;
          return {
            ...state,
            resourceOwnerId: team.id,
            isTeam: true,
            teamName: team?.name,
            teamLookupId: team?.lookupId,
            workerToken: undefined,
            planName: team.planName,
            planIsPaid: team.planIsPaid,
            planId: team.planId,
            subscriptionCancelAt: team.subscriptionCancelAt ?? undefined,
            permissions: permission,
            subscriptionData: undefined,
            setting: undefined,
            isGoogleOcrEngineSet: team.isGoogleOcrEngineSet,
            isAzureOcrEngineSet: team.isAzureOcrEngineSet,
            isUsageReachedHardLimit: team.isUsageReachedHardLimit,
            enabledFeatures: team.enabledFeatures,
            enabledAuditLog: team.enabledAuditLog,
          };
        }
      );
  }
);
