import React from "react";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import {
  ConnectedApp,
  ConnectedAppsSection,
} from "../components/ConnectedAppsSection";
import { Layout, Main, Top } from "../components/Layout";
import {
  useListenWindowEvent,
  useOnload,
} from "../hooks/asyncguard/asyncguard";
import {
  DEFAULT_CONNECTED_APPS_PAGE_SIZE,
  useOAuthCredentialListResource,
} from "../hooks/oAuthCredentialListResource";
import { useOAuthCredential } from "../hooks/oauth";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { ConfirmModalType } from "../types/confirmation";
import HeaderContainer from "./Header";

type ConnectAppResourceQueryParms = {
  page: number;
};

function getQueryParams(): ConnectAppResourceQueryParms {
  const url = new URL(document.location.href);
  const page = parseInt(url.searchParams.get(URLParamsKey.page) || "1") || 1;
  return { page };
}

function useConnectedAppsContainerState() {
  const oAuthCredentialListResource = useOAuthCredentialListResource();
  const { deleteOAuthCredential } = useOAuthCredential();

  const initialQueryParams = React.useMemo(() => getQueryParams(), []);
  const [currentPage, setCurrentPage] = React.useState(initialQueryParams.page);

  const { setParam } = useSearchParamUtils();

  const connectedApps = React.useMemo(() => {
    return oAuthCredentialListResource.resource.oauthCredentials.map(
      credential => {
        return {
          id: credential.id,
          name: "Google",
          account: credential.email,
          status: "connected",
        } as ConnectedApp;
      }
    );
  }, [oAuthCredentialListResource]);

  const pageCount = oAuthCredentialListResource.resource.pageInfo.totalCount;

  const refresh = React.useCallback(async () => {
    const currentPage = getQueryParams().page;
    await oAuthCredentialListResource.query(currentPage);
  }, [oAuthCredentialListResource]);

  useOnload(() => {
    refresh();
  });

  useListenWindowEvent("popstate", () => {
    refresh();
    const { page } = getQueryParams();
    setParam(URLParamsKey.page, page.toString(), false);
    setCurrentPage(page);
  });

  const navigateToPage = React.useCallback(
    (page: number) => {
      setParam(URLParamsKey.page, page.toString(), false);
      setCurrentPage(page);
      oAuthCredentialListResource.query(page);
    },
    [oAuthCredentialListResource, setParam]
  );

  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const requestToDeleteOAuthCredential = React.useCallback(
    async (id: string) => {
      const confirm = await requestUserConfirmation(
        {
          titleId: "connect_apps_table.remove_dialog.title",
          messageId: "connect_apps_table.remove_dialog.message",
          actionId: "connect_apps_table.remove_dialog.action",
          type: ConfirmModalType.Destory,
        },
        false
      );
      if (confirm) {
        await deleteOAuthCredential(id);
        await refresh();
      }
    },
    [deleteOAuthCredential, refresh, requestUserConfirmation]
  );

  return {
    currentPage,
    connectedApps,
    pageCount,
    navigateToPage,
    requestToDeleteOAuthCredential,
  };
}

const ConnectedAppsContainer = () => {
  const {
    connectedApps,
    pageCount,
    currentPage,
    navigateToPage,
    requestToDeleteOAuthCredential,
  } = useConnectedAppsContainerState();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <ConnectedAppsSection
          pageCount={pageCount}
          currentPage={currentPage}
          pageSize={DEFAULT_CONNECTED_APPS_PAGE_SIZE}
          apps={connectedApps}
          navigateToPage={navigateToPage}
          requestToDeleteOAuthCredential={requestToDeleteOAuthCredential}
        />
      </Main>
    </Layout>
  );
};

export default ConnectedAppsContainer;
