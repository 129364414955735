import cn from "classnames";
import React, { AnchorHTMLAttributes } from "react";

interface TextLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean;
}

const TextLink = ({
  href,
  onClick,
  disabled = false,
  children,
  className = "",
  target = "_blank",
  rel = "noopener noreferrer",
  ...restProps
}: TextLinkProps) => {
  return (
    <a
      {...restProps}
      className={cn(
        "no-underline",
        "focus:outline-none",
        "focus-visible:outline",
        "focus-visible:outline-1",
        "focus-visible:outline-type-800",
        "focus-visible:outline-offset-2",
        "active:outline-none",
        "visited:no-underline",
        {
          "text-type-disabled pointer-events-none": disabled,
          "text-primary-500 hover:text-primary-800 hover:underline": !disabled,
          "visited:text-primary-500": !disabled,
        },
        className
      )}
      href={disabled ? undefined : href}
      onClick={disabled ? (e: React.MouseEvent) => e.preventDefault() : onClick}
      target={!disabled && href ? target : undefined}
      rel={!disabled && href ? rel : undefined}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
    >
      {children}
    </a>
  );
};

export default TextLink;
