import { ApiClientConstructor, _BaseApiClient } from "../base";
import { AuditLogApiClient, withAuditLogApi } from "./auditLog";
import { AuthApiClient, withAuthApi } from "./auth";
import {
  ConfigSnapshotApiClient,
  withConfigSnapshotApi,
} from "./configSnapshot";
import { CustomModelApiClient, withCustomModelApi } from "./customModel";
import {
  CustomModelImageApiClient,
  withCustomModelImageApi,
} from "./customModelImage";
import { DataLogApiClient, withDataLogApi } from "./dataLog";
import { ExtractionApiClient, withExtractionApi } from "./extraction";
import { ExtractorApiClient, withExtractorApi } from "./extractor";
import { FormApiClient, withFormApi } from "./form";
import { FormGroupApiClient, withFormGroupApi } from "./formGroup";
import { type GoogleSheetApiClient, withGoogleSheetApi } from "./googleSheet";
import {
  type WorksapceGoogleSheetExportApiClient,
  withWorksapceGoogleSheetExportApi,
} from "./googleSheetExport";
import { InvitationApiClient, withInvitationApi } from "./invitation";
import {
  type OAuthCredentialApiClient,
  withOAuthCredentialApi,
} from "./oauthCredential";
import { OCRResultApiClient, withOCRResultApi } from "./ocrResult";
import { PlanAPIClient, withPlanApi } from "./plan";
import { ReceiptGroupApiClient, withReceiptGroupApi } from "./receiptGroup";
import { RequestLogApiClient, withRequestLogApi } from "./requestLog";
import { ResourceOwnerApiClient, withResourceOwnerApi } from "./resourceOwner";
import { SurveyApiClient, withSurveyApi } from "./survey";
import { TeamApiClient, withTeamApi } from "./team";
import { TemplateApiClient, withTemplateApi } from "./template";
import { UserApiClient, withUserApi } from "./user";
import { WebhookApiClient, withWebhookApi } from "./webhook";
import { WorkerTokenApiClient, withWorkerTokenApi } from "./workerToken";
import { WorkspaceApiClient, withWorkspaceApi } from "./workspace";
import {
  WorkspaceSharePointSubscriptionApiClient,
  withWorkspaceSharePointSubscriptionApi,
} from "./workspaceSharePointSubscription";
import {
  WorkspaceWebhookApiClient,
  withWorkspaceWebhookApi,
} from "./workspaceWebhook";

export function applyMixins(baseClass: ApiClientConstructor<_BaseApiClient>) {
  const mixins = [
    withAuditLogApi,
    withAuthApi,
    withConfigSnapshotApi,
    withCustomModelApi,
    withCustomModelImageApi,
    withDataLogApi,
    withExtractionApi,
    withExtractorApi,
    withFormApi,
    withFormGroupApi,
    withWorksapceGoogleSheetExportApi,
    withGoogleSheetApi,
    withInvitationApi,
    withOAuthCredentialApi,
    withOCRResultApi,
    withPlanApi,
    withReceiptGroupApi,
    withRequestLogApi,
    withResourceOwnerApi,
    withSurveyApi,
    withTeamApi,
    withTemplateApi,
    withUserApi,
    withWebhookApi,
    withWorkerTokenApi,
    withWorkspaceApi,
    withWorkspaceSharePointSubscriptionApi,
    withWorkspaceWebhookApi,
  ];
  return mixins.reduce((client, mixin) => mixin(client), baseClass) as new (
    ...args: any[]
  ) => _BaseApiClient & ApiClientMixin;
}

export type ApiClientMixin = UserApiClient &
  ResourceOwnerApiClient &
  AuthApiClient &
  OAuthCredentialApiClient &
  OCRResultApiClient &
  TemplateApiClient &
  RequestLogApiClient &
  CustomModelApiClient &
  CustomModelImageApiClient &
  PlanAPIClient &
  FormGroupApiClient &
  FormApiClient &
  GoogleSheetApiClient &
  ReceiptGroupApiClient &
  TeamApiClient &
  WebhookApiClient &
  WorkspaceWebhookApiClient &
  InvitationApiClient &
  AuditLogApiClient &
  ExtractorApiClient &
  WorkerTokenApiClient &
  WorkspaceApiClient &
  ExtractionApiClient &
  WorksapceGoogleSheetExportApiClient &
  WorkspaceSharePointSubscriptionApiClient &
  SurveyApiClient &
  ConfigSnapshotApiClient &
  DataLogApiClient;
