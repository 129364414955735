import React from "react";

import {
  BackpressureMode,
  isAbortError,
  useAbortable,
  useBackpressure,
} from "./asyncguard/asyncguard";
import { useOAuthCredential } from "./oauth";

const INTERVAL_TIME = 300; /// 300ms
export const DEFAULT_CONNECTED_APPS_PAGE_SIZE = 20;

export function useOAuthCredentialListResource() {
  const { listOAuthCredentials, oauthCredentials } = useOAuthCredential();

  const { abort } = useAbortable();

  const abortableQuery = React.useCallback(
    async (page: number) => {
      const { newSignal } = abort();
      const size = DEFAULT_CONNECTED_APPS_PAGE_SIZE;
      const offset = (page - 1) * size;

      return await listOAuthCredentials("google", offset, size, newSignal);
    },
    [abort, listOAuthCredentials]
  );

  const debouncedQuery = useBackpressure(
    abortableQuery,
    BackpressureMode.throttleLatest(INTERVAL_TIME)
  );

  const query = React.useCallback(
    async (page: number) => {
      try {
        return await debouncedQuery(page);
      } catch (e) {
        if (isAbortError(e)) {
          return;
        }
        console.error(e);
        return;
      }
    },
    [debouncedQuery]
  );

  return React.useMemo(
    () => ({
      query,
      resource: oauthCredentials,
    }),
    [query, oauthCredentials]
  );
}
