import * as React from 'react';
var PortalCompatContext = React.createContext(undefined);
var portalCompatContextDefaultValue = function() {
    return function() {
        return undefined;
    };
};
export var PortalCompatContextProvider = PortalCompatContext.Provider;
export function usePortalCompat() {
    var _React_useContext;
    return (_React_useContext = React.useContext(PortalCompatContext)) !== null && _React_useContext !== void 0 ? _React_useContext : portalCompatContextDefaultValue;
}
