import { UserFeatureFlag } from "../constants";
import {
  AlphaFeatureFlags,
  BetaFeatureFlags,
  SpecialFeatureFlags,
} from "../constants/userFeature";

export class UserFeatureFlagAccessor {
  static hasPermission(
    enabledFeatureFlags: UserFeatureFlag[],
    requiredFeatureFlag: UserFeatureFlag
  ): boolean {
    return enabledFeatureFlags.includes(UserFeatureFlag.All) &&
      !SpecialFeatureFlags.includes(requiredFeatureFlag)
      ? true
      : enabledFeatureFlags.includes(UserFeatureFlag.Alpha) &&
        (AlphaFeatureFlags.includes(requiredFeatureFlag) ||
          BetaFeatureFlags.includes(requiredFeatureFlag))
      ? true
      : enabledFeatureFlags.includes(UserFeatureFlag.Beta) &&
        BetaFeatureFlags.includes(requiredFeatureFlag)
      ? true
      : enabledFeatureFlags.includes(requiredFeatureFlag);
  }
}

export enum UserFeatureGroup {
  All = "all",
  Alpha = "alpha",
  Beta = "beta",
  NoGroup = "no_group",
}

export class UserFeatureGroupAccessor {
  static fromUserFeatureFlags(
    featureFlags: UserFeatureFlag[]
  ): UserFeatureGroup {
    return featureFlags.includes(UserFeatureFlag.All)
      ? UserFeatureGroup.All
      : featureFlags.includes(UserFeatureFlag.Beta)
      ? UserFeatureGroup.Beta
      : featureFlags.includes(UserFeatureFlag.Alpha)
      ? UserFeatureGroup.Alpha
      : UserFeatureGroup.NoGroup;
  }

  static toUserFeatureFlags(featureGroup: UserFeatureGroup): UserFeatureFlag[] {
    switch (featureGroup) {
      case UserFeatureGroup.All:
        return Object.values(UserFeatureFlag).filter(
          x => !SpecialFeatureFlags.includes(x)
        );
      case UserFeatureGroup.Alpha:
        return Object.values(AlphaFeatureFlags)
          .concat(Object.values(BetaFeatureFlags))
          .concat([UserFeatureFlag.Alpha]);
      case UserFeatureGroup.Beta:
        return Object.values(BetaFeatureFlags).concat([UserFeatureFlag.Beta]);
      case UserFeatureGroup.NoGroup:
        return [];
    }
  }
}
