import { FormattedMessage } from "@oursky/react-messageformat";
import { FC, useCallback, useState } from "react";
import React from "react";

import { WorkspaceIntegrationOptionType } from "../../types/workspaceIntegration";
import { GroupHeader } from "../GroupHeader";
import { WorkspaceIntegrationOption } from "../WorkspaceIntegrationOption";

interface WorkspaceIntegrationOptionGroupProps {
  optionType: "import" | "export" | "thirdParty";
  items: readonly WorkspaceIntegrationOptionType[];
  selectedOptionSet: Set<WorkspaceIntegrationOptionType>;
  enabledOptions: Set<WorkspaceIntegrationOptionType>;
  onOptionClick: (op: WorkspaceIntegrationOptionType) => void;
  defaultExpanded?: boolean;
}

const optionGroupTitleMap: Record<string, string> = {
  import: "workspace.integrations.option_list.import.title",
  export: "workspace.integrations.option_list.export.title",
  thirdParty: "workspace.integrations.option_list.3rdparty.title",
};

const actionTextMap: Record<string, "add" | "link"> = {
  import: "add",
  export: "add",
  thirdParty: "link",
};

export const WorkspaceIntegrationOptionGroup: FC<
  WorkspaceIntegrationOptionGroupProps
> = ({
  optionType,
  items,
  selectedOptionSet,
  enabledOptions,
  onOptionClick,
  defaultExpanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

  const handleToggle = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  return (
    <div>
      <GroupHeader isExpanded={isExpanded} onToggle={handleToggle}>
        <FormattedMessage
          id={optionGroupTitleMap[optionType]}
          values={{ count: items.length }}
        />
      </GroupHeader>

      {isExpanded &&
        items.map(op => (
          <div key={op}>
            <WorkspaceIntegrationOption
              optionType={op}
              isAdded={selectedOptionSet.has(op)}
              isCommingSoon={!enabledOptions.has(op)}
              onOptionClick={onOptionClick}
              actionText={actionTextMap[optionType]}
            />
          </div>
        ))}
    </div>
  );
};
