import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useAppActionCreator } from "../actions/app";
import FreePlanUgradeMessageBar from "../components/FreePlanUpgradeMessageBar";
import { PLAN_NAMES } from "../constants";
import { useFirstSession } from "../hooks/gtm";
import { RootState } from "../redux/types";

function useUpgradeBar() {
  const { getIsFirstSession } = useFirstSession();
  const location = useLocation();

  const shouldShowUpgradeBar = useSelector((state: RootState) => {
    const { isUpgradeBarDismissed } = state.app;
    if (isUpgradeBarDismissed) {
      return false;
    }
    return state.resourceOwner.planName === PLAN_NAMES.free;
  });
  const { dismissUpgradeBar } = useAppActionCreator();

  // Redirection may happen and break the first session test.
  // So we need to update the session status on location changes
  const isFirstSession = React.useMemo(
    () => getIsFirstSession(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  return {
    dismissUpgradeBar,
    shouldShowUpgradeBar: shouldShowUpgradeBar && !isFirstSession,
  };
}

const FreePlanUpgradeMessageBarContainer = React.memo(() => {
  const { dismissUpgradeBar, shouldShowUpgradeBar } = useUpgradeBar();

  return shouldShowUpgradeBar ? (
    <FreePlanUgradeMessageBar onDismiss={dismissUpgradeBar} />
  ) : null;
});

export default FreePlanUpgradeMessageBarContainer;
