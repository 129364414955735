import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { useAppActionCreator } from "../actions/app";
import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import Payment from "../components/Payment";
import { PLAN_NAMES } from "../constants";
import HeaderContainer from "../containers/Header";
import { FOCRError } from "../errors";
import { useOnload } from "../hooks/asyncguard/asyncguard";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";

const PaymentContainer = React.memo(() => {
  const toast = useToast();

  const { subscribePlan, manageSubscription } = useResourceOwnerActionCreator();

  const { disablePaymentRequiredToast, listPlan: fetchPlans } =
    useAppActionCreator();

  const plans = useSelector((state: RootState) => state.app.plans);

  const isPaymentRequired = useSelector(
    (state: RootState) => state.resourceOwner.isPaymentRequired
  );

  const subscriptionCancelAt = useSelector(
    (state: RootState) => state.resourceOwner.subscriptionCancelAt
  );

  const canEditSubscription = useSelector<RootState, boolean>(
    (state: RootState) => state.resourceOwner.permissions.editSubscription
  );

  const currentPlanName = useSelector<RootState, string>(
    (state: RootState) => state.resourceOwner.planName ?? PLAN_NAMES.free
  );

  const currentPlanIsPaid = useSelector<RootState, boolean>(
    (state: RootState) => state.resourceOwner.planIsPaid ?? false
  );

  const [isLoadingData, setIsLoadingData] = useState(false);
  useOnload(() => {
    disablePaymentRequiredToast();

    setIsLoadingData(true);
    fetchPlans()
      .catch(() => toast.error("error.stripe.load.failure.try.refresh"))
      .finally(() => setIsLoadingData(false));
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleOnPayAsYouGoPlanAction = useCallback(
    (planId: string) => {
      setIsLoading(true);
      subscribePlan(planId, PLAN_NAMES.payAsYouGo).catch(e => {
        if (e instanceof FOCRError) {
          toast.error(e.messageId);
        } else {
          toast.error("error.subscription.unexpected");
        }
        setIsLoading(false);
      });
    },
    [toast, subscribePlan]
  );

  const handleOnStarterPlanAction = useCallback(
    (planId: string) => {
      setIsLoading(true);
      subscribePlan(planId, PLAN_NAMES.starter).catch(e => {
        if (e instanceof FOCRError) {
          toast.error(e.messageId);
        } else {
          toast.error("error.subscription.unexpected");
        }
        setIsLoading(false);
      });
    },
    [toast, subscribePlan]
  );

  const handleOnContactUs = useCallback(() => {
    window.open(
      "https://www.formx.ai/talk-with-us?utm_campaign=portal_payment",
      "_blank"
    );
  }, []);

  const handleOnEditSubscription = useCallback(() => {
    setIsLoading(true);
    manageSubscription("payment_method_update").catch(e => {
      if (e instanceof FOCRError) {
        toast.error(e.messageId);
      } else {
        toast.error("error.subscription.unexpected");
      }
      setIsLoading(false);
    });
  }, [toast, manageSubscription]);

  const handleOnViewPaymentRecords = useCallback(() => {
    setIsLoading(true);
    manageSubscription().catch(e => {
      if (e instanceof FOCRError) {
        toast.error(e.messageId);
      } else {
        toast.error("error.subscription.unexpected");
      }
      setIsLoading(false);
    });
  }, [toast, manageSubscription]);

  const handleOnCancelSubscription = useCallback(() => {
    setIsLoading(true);
    manageSubscription("subscription_cancel").catch(e => {
      if (e instanceof FOCRError) {
        toast.error(e.messageId);
      } else {
        toast.error("error.subscription.unexpected");
      }
      setIsLoading(false);
    });
  }, [toast, manageSubscription]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <>
        <Main hasTop={true}>
          {isLoadingData ? null : (
            <Payment
              isPaymentRequired={isPaymentRequired}
              subscriptionCancelAt={subscriptionCancelAt}
              plans={plans}
              canEditSubscription={canEditSubscription}
              currentPlanName={currentPlanName}
              currentPlanIsPaid={currentPlanIsPaid}
              onPayAsYouGoPlanAction={handleOnPayAsYouGoPlanAction}
              onStarterPlanAction={handleOnStarterPlanAction}
              onContactUs={handleOnContactUs}
              onEditSubscription={handleOnEditSubscription}
              onViewPaymentRecords={handleOnViewPaymentRecords}
              onCancelSubscription={handleOnCancelSubscription}
            />
          )}
        </Main>
      </>
      <LoadingModal
        messageId="common.loading"
        isOpen={isLoadingData || isLoading}
      />
    </Layout>
  );
});

export default PaymentContainer;
