import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import {
  ExtractedContentSchema,
  ExtractedContentSchemaType,
} from "../../types/extractedContentSchema";
import styles from "./styles.module.scss";

interface MainFieldItemProps {
  name: string;
}

function MainFieldItem(props: MainFieldItemProps) {
  const { name } = props;

  return (
    <div className={styles["main-field-item"]}>
      <div className={styles["main-field-item-type"]}>
        <FormattedMessage id="fsl_custom_model.field_schema_list.document_type" />
      </div>
      <div className={styles["main-field-item-name"]}>{name}</div>
    </div>
  );
}

interface FieldItemProps {
  name: string;
  type: string;
  isList: boolean;
}

function FieldItem(props: FieldItemProps) {
  const { name, type, isList } = props;

  return (
    <div className={styles["field-item"]}>
      <div className={styles["field-item-name"]}>{name}</div>
      <div className={styles["field-item-type"]}>
        <FormattedMessage id={`fsl_custom_model.field_type.${type}`} />
        {isList &&
          ![
            ExtractedContentSchemaType.FieldGroup.valueOf(),
            ExtractedContentSchemaType.Checkbox.valueOf(),
          ].includes(type) && (
            <FormattedMessage id={`fsl_custom_model.field_type.is_list`} />
          )}
      </div>
    </div>
  );
}

interface FSLFieldSchemaListProps {
  extractedContentSchema?: ExtractedContentSchema;
  nameVisible?: boolean;
}

export function FSLFieldSchemaList(props: FSLFieldSchemaListProps) {
  const { extractedContentSchema } = props;
  const nameVisible = props.nameVisible ?? true;

  return (
    <>
      {nameVisible && (
        <MainFieldItem name={extractedContentSchema?.name ?? ""} />
      )}
      {extractedContentSchema?.payload.map((field, index: number) => {
        return (
          <FieldItem
            name={field.name}
            type={field.type}
            isList={field.isList}
            key={index}
          />
        );
      })}
    </>
  );
}
