@import "../../../styles/variables.scss";
.buttonSpinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.buttonText {
  font-weight: bold;
}

.buttonLoading {
  position: relative;

  .buttonText {
    opacity: 0;
  }
}

.root,
.root:enabled {
  :global(.ms-Icon) {
    color: inherit;
  }
}
