import React from "react";
import { useSelector } from "react-redux";

import {
  GoogleAuthData,
  useGoogleAuthActionCreator,
} from "../actions/googleAuth";
import type { OAuthError } from "../actions/oauth";
import { RootState } from "../redux/types";

export interface useGoogleAuthReturnValues {
  googleAuthError: OAuthError | null;
  isGoogleAuthorized: boolean;
  isGoogleAuthorizing: boolean;
  googleAuth: (scopes: string[]) => Promise<GoogleAuthData>;
  resetGoogleAuth: () => void;
}

export function useGoogleAuth(): useGoogleAuthReturnValues {
  const { auth, reset } = useGoogleAuthActionCreator();
  const { authError, isAuthorized, isAuthorizing } = useSelector(
    (state: RootState) => state.googleAuth
  );

  return React.useMemo(
    () => ({
      googleAuthError: authError,
      isGoogleAuthorized: isAuthorized,
      isGoogleAuthorizing: isAuthorizing,
      googleAuth: auth,
      resetGoogleAuth: reset,
    }),
    [authError, isAuthorized, isAuthorizing, reset, auth]
  );
}
