import { List } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { useLocale } from "../../contexts/locale";
import { WorkspaceIntegrationConfiguration } from "../../types/workspaceIntegration";
import { ActionButton } from "../WrappedMSComponents/Buttons";

interface WorkspaceWebhookListProps {
  configurations: WorkspaceIntegrationConfiguration[];
  onConfigurationRemoved: (
    configuration: WorkspaceIntegrationConfiguration
  ) => void;
}

export function WorkspaceWebhookListImpl(props: WorkspaceWebhookListProps) {
  const { configurations, onConfigurationRemoved } = props;
  const { localized } = useLocale();

  const _onConfigurationRemoved = useCallback(
    (configuration: WorkspaceIntegrationConfiguration) => {
      return () => {
        onConfigurationRemoved(configuration);
      };
    },
    [onConfigurationRemoved]
  );

  const renderHeader = () => {
    return (
      <div className="grid grid-cols-3 py-3 px-4 font-semibold text-base bg-gray-100 border-t border-l border-r border-solid border-gray-200">
        <div>
          <FormattedMessage id="workspace.integrations.configuration_list.column.connectionId" />
        </div>
        <div>
          <FormattedMessage id="workspace.integrations.configuration_list.column.status" />
        </div>
        <div>
          <FormattedMessage id="workspace.integrations.configuration_list.column.action" />
        </div>
      </div>
    );
  };

  const renderRow = (
    item?: WorkspaceIntegrationConfiguration,
    _index?: number
  ) => {
    if (!item || item.optionType !== "export_zapier") {
      return null;
    }

    return (
      <div className="grid grid-cols-3 py-3 px-4 border-t border-l border-r border-solid border-gray-200 items-center text-sm last:border-b">
        <div>{item.zapId}</div>
        <div className="text-[#0F7713]">
          {localized(
            "workspace.integrations.configuration_list.status.connected"
          )}
        </div>
        <div>
          <ActionButton
            className="p-0"
            textId="workspace.integrations.configuration_list.action.remove"
            isDestroy={true}
            styles={{
              root: { padding: 0 },
              label: { margin: 0 },
            }}
            onClick={_onConfigurationRemoved(item)}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderHeader()}
      <List items={configurations} onRenderCell={renderRow} />
    </div>
  );
}

export const WorkspaceWebhookList = React.memo(WorkspaceWebhookListImpl);
