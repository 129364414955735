import { OCRResult, ocrResultSchema } from "../../types/ocrResult";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface OCRResultApiClient {
  createOCRResult: (
    assetId: string,
    resourceOwnerId: string,
    lookupId: string
  ) => Promise<OCRResult>;

  getOCRResult: (
    resourceOwnerId: string,
    ocrResultID: string
  ) => Promise<OCRResult>;
}

export function withOCRResultApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async createOCRResult(
      assetId: string,
      resourceOwnerId: string,
      lookupId: string
    ): Promise<OCRResult> {
      return this.lambda(
        "ocr_result:create",
        {
          asset_id: assetId,
          resource_owner_id: resourceOwnerId,
          lookup_id: lookupId,
        },
        ocrResultSchema
      );
    }

    async getOCRResult(
      resourceOwnerId: string,
      ocrResultID: string
    ): Promise<OCRResult> {
      return this.lambda(
        "ocr_result:get",
        { resource_owner_id: resourceOwnerId, ocr_result_id: ocrResultID },
        ocrResultSchema
      );
    }
  };
}
