import { useMemo } from "react";

import { AppConfig } from "../config";
import { DetectionRegionFieldEngine } from "../types/detectionRegion";
import {
  Abbyy,
  AzureComputerVision,
  GoogleCloudVision,
  Tesseract,
} from "../types/ocrConfig";
import { useUserSetting } from "./user";

export function useAvailableEngines() {
  const {
    isGoogleServiceAccoutKeySet,
    isAzureSubscriptionKeySet,
    isUsingCustomCredentials,
  } = useUserSetting();

  return useMemo(() => {
    const shouldIgnoreExternalServiceSettings = !isUsingCustomCredentials;
    const userAvailableEngines: DetectionRegionFieldEngine[] = [];

    const disabledOCREngines = AppConfig.disabledOCREngines || [];

    if (
      (shouldIgnoreExternalServiceSettings || isGoogleServiceAccoutKeySet) &&
      !disabledOCREngines.includes(GoogleCloudVision)
    ) {
      userAvailableEngines.push(GoogleCloudVision);
    }

    if (
      (shouldIgnoreExternalServiceSettings || isAzureSubscriptionKeySet) &&
      !disabledOCREngines.includes(AzureComputerVision)
    ) {
      userAvailableEngines.push(AzureComputerVision);
    }

    userAvailableEngines.push(Tesseract);

    if (AppConfig.enableAbbyy) {
      userAvailableEngines.push(Abbyy);
    }

    return userAvailableEngines;
  }, [
    isUsingCustomCredentials,
    isGoogleServiceAccoutKeySet,
    isAzureSubscriptionKeySet,
  ]);
}
