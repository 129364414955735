import * as yup from "yup";

export const ocrResultSchema = yup
  .object({
    id: yup.string().required(),
    lookupId: yup.string().required(),
    filename: yup.string().required(),
    assetUrl: yup.string().required(),
  })
  .camelCase();

export type OCRResult = yup.InferType<typeof ocrResultSchema>;
