export const PLAN_NAMES = {
  free: "free",
  payAsYouGo: "pay-as-you-go",
  starter: "starter",
  enterprise: "enterprise",
  trial: "trial",
};

export const PLAN_NAME_MESSAGE_IDS: Partial<Record<string, string>> = {
  free: "payment.plans.free",
  "pay-as-you-go": "payment.plans.pay_as_you_go",
  starter: "payment.plans.starter",
  enterprise: "payment.plans.enterprise",
  trial: "payment.plans.trial",
};
