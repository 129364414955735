import { FontIcon } from "@fluentui/react";
import cn from "classnames";
import React, { useCallback } from "react";

interface ClickableCardProps {
  className?: string;
  iconName: string;
  title: string;
  description: string;
  onClick?: () => void;
}

export function ClickableCard(props: ClickableCardProps) {
  const { className, iconName, title, description, onClick } = props;

  const handleOnClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <button
      className={cn(
        className,
        "p-3 flex items-center gap-3",
        "appearance-none bg-transparent outline-none -outline-offset-1",
        "rounded border-solid shadow",
        "border border-gray-200",
        "hover:outline-2 hover:outline-primary-500",
        "focus-visible:outline-2 focus-visible:outline-primary-500",
        "active:bg-gray-100"
      )}
      onClick={handleOnClick}
    >
      <span className="p-3">
        <span className="flex w-9 h-9 items-center justify-center">
          <FontIcon className="text-[30px]" iconName={iconName} />
        </span>
      </span>
      <span className="min-w-0 flex-1 block space-y-1 text-left">
        <span className="block truncate text-sm font-semibold text-gray-900">
          {title}
        </span>
        <span className="block text-xs text-gray-700">{description}</span>
      </span>
    </button>
  );
}
