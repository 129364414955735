import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { AppConfig } from "../../config";
import { PLAN_NAMES, UserFeatureFlag } from "../../constants";
import { useGtm } from "../../hooks/gtm";
import { useTeamPermission } from "../../hooks/permission";
import { useReadmeIO } from "../../hooks/readmeIO";
import { useAppSelector } from "../../hooks/redux";
import BetaTag from "../BetaTag";
import { LinkWithTeamRef } from "../LinkWithTeamRef";
import styles from "./styles.module.scss";

interface Props {
  isLeftBarCollapsed: boolean;
  selectedPage?: string;
  toggleLeftBarCollapsed: (value: boolean | undefined) => void;
}

interface ImplProps extends Props {
  shouldShowPayment?: boolean;
  hasPermissionToViewAuditLog: boolean;
  hasPermissionToViewMembership: boolean;
  hasPermissionToViewTeamSetting: boolean;
  hasPermissionToViewSubscription: boolean;
  enabledAuditLog: boolean;
  enabledPII: boolean;
  enabledWebhooksV1: boolean;
  enableCustomCredentials: boolean;
  onRedirectToReadmeIO: () => void;
  onContactUs: () => void;
}

interface AdminProps {
  isLeftBarCollapsed: boolean;
  selectedPage?: string;
  toggleLeftBarCollapsed: (value: boolean | undefined) => void;
}

function LeftBarItemContent(props: {
  className?: string;
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
  withoutTeamRef?: boolean;
}) {
  const { className, to, onClick, children, withoutTeamRef } = props;

  const onClickButton = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      onClick?.();
    },
    [onClick]
  );

  if (onClick != null) {
    return (
      <a className={className} href="#" onClick={onClickButton}>
        {children}
      </a>
    );
  }

  if (to === undefined || to.startsWith("http")) {
    return (
      <a className={className} href={to} target="_blank">
        {children}
      </a>
    );
  }

  if (withoutTeamRef) {
    return (
      <LinkWithTeamRef className={className} to={to}>
        {children}
      </LinkWithTeamRef>
    );
  }

  return (
    <Link className={className} to={to}>
      {children}{" "}
    </Link>
  );
}

function LeftBarItem(props: {
  to?: string;
  selected: boolean;
  titleId: string;
  withoutTeamRef?: boolean;
  isBeta?: boolean;
  onClick?: () => void;
}) {
  const { to, selected, titleId, withoutTeamRef, isBeta, onClick } = props;

  return (
    <div
      className={classnames(styles["left-bar-item"], {
        [styles["selected"]]: selected,
      })}
    >
      <LeftBarItemContent
        className={styles["content"]}
        to={to}
        onClick={onClick}
        withoutTeamRef={withoutTeamRef}
      >
        <p>
          <FormattedMessage id={titleId} />
        </p>
        {isBeta === true ? <BetaTag className={styles["beta-tag"]} /> : null}
      </LeftBarItemContent>
    </div>
  );
}

const useLeftBar = (props: Props): ImplProps => {
  const { isLeftBarCollapsed, selectedPage, toggleLeftBarCollapsed } = props;

  const shouldShowPayment = useAppSelector(
    state => state.resourceOwner.planName !== PLAN_NAMES.enterprise
  );
  const {
    hasPermissionToViewAuditLog,
    hasPermissionToViewMembership,
    hasPermissionToViewTeamSetting,
    hasPermissionToViewSubscription,
  } = useTeamPermission();
  const enabledAuditLog = useAppSelector(
    state => state.resourceOwner.enabledAuditLog
  );
  const enabledPII = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.PII)
  );

  const enabledWebhooksV1 = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.WebhookV1)
  );

  const enableCustomCredentials = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.UseCustomCredentials)
  );

  const { onRedirectToReadmeIO } = useReadmeIO();
  const { pushClickedContactUsEvent } = useGtm();

  const onContactUs = useCallback(() => {
    window.open(
      "https://www.formx.ai/talk-with-us?utm_source=portal&utm_medium=web&utm_campaign=nav-bar",
      "_blank"
    );
    pushClickedContactUsEvent("contact_us_nav_bar");
  }, [pushClickedContactUsEvent]);

  return useMemo(
    () => ({
      isLeftBarCollapsed,
      selectedPage,
      toggleLeftBarCollapsed,
      shouldShowPayment,
      hasPermissionToViewAuditLog,
      hasPermissionToViewMembership,
      hasPermissionToViewTeamSetting,
      hasPermissionToViewSubscription,
      enabledAuditLog,
      enabledPII,
      onRedirectToReadmeIO,
      onContactUs,
      enabledWebhooksV1,
      enableCustomCredentials,
    }),
    [
      enabledAuditLog,
      enabledPII,
      hasPermissionToViewAuditLog,
      hasPermissionToViewMembership,
      hasPermissionToViewSubscription,
      hasPermissionToViewTeamSetting,
      isLeftBarCollapsed,
      onRedirectToReadmeIO,
      shouldShowPayment,
      selectedPage,
      toggleLeftBarCollapsed,
      onContactUs,
      enabledWebhooksV1,
      enableCustomCredentials,
    ]
  );
};

export const LeftBarImpl = (props: ImplProps) => {
  const {
    isLeftBarCollapsed,
    selectedPage,
    toggleLeftBarCollapsed,
    shouldShowPayment,
    hasPermissionToViewAuditLog,
    hasPermissionToViewMembership,
    hasPermissionToViewTeamSetting,
    hasPermissionToViewSubscription,
    enabledAuditLog,
    enabledPII,
    enabledWebhooksV1,
    enableCustomCredentials,
    onRedirectToReadmeIO,
    onContactUs,
  } = props;

  return (
    <div
      className={classnames(styles["container"], {
        [styles["container-collapsed"]]: isLeftBarCollapsed,
      })}
    >
      <div className={styles["content"]}>
        {!isLeftBarCollapsed && (
          <>
            <div className={styles["list"]}>
              <div className={styles["items"]}>
                <div className={styles["section-header"]}>
                  <p>
                    <FormattedMessage id="left_bar.extraction" />
                  </p>
                </div>

                <LeftBarItem
                  selected={["extractor", "form", "form-group"].includes(
                    selectedPage ?? ""
                  )}
                  to={"/extractor"}
                  titleId="left_bar.extractors"
                />

                {AppConfig.leftBar.hideIntegration !== true && (
                  <>
                    <div className={styles["section-header"]}>
                      <p>
                        <FormattedMessage id="left_bar.add_ons" />
                      </p>
                    </div>

                    <LeftBarItem
                      selected={selectedPage === "workspace"}
                      to={"/workspace"}
                      titleId="left_bar.workspaces"
                      isBeta={true}
                    />

                    <LeftBarItem
                      selected={selectedPage === "detect-documents"}
                      to={"/detect-documents/test"}
                      titleId="left_bar.detect_documents"
                    />

                    {enabledPII && (
                      <LeftBarItem
                        selected={selectedPage === "detect-pii"}
                        to={"/detect-pii/test"}
                        titleId="left_bar.detect_pii"
                        isBeta={true}
                      />
                    )}
                    {enabledWebhooksV1 && (
                      <LeftBarItem
                        selected={selectedPage === "webhook"}
                        to={"/webhook"}
                        titleId="left_bar.webhooks"
                      />
                    )}
                  </>
                )}

                <div className={styles["section-header"]}>
                  <p>
                    <FormattedMessage id="left_bar.help_center" />
                  </p>
                </div>

                <LeftBarItem
                  selected={false}
                  titleId="left_bar.api_doc"
                  onClick={() => onRedirectToReadmeIO()}
                />

                <LeftBarItem
                  selected={false}
                  titleId="left_bar.contact_us"
                  onClick={onContactUs}
                />

                <div className={styles["section-header"]}>
                  <p>
                    <FormattedMessage id="left_bar.team" />
                  </p>
                </div>

                {hasPermissionToViewMembership && (
                  <LeftBarItem
                    selected={selectedPage === "team"}
                    to={"/team"}
                    titleId="left_bar.manage_team"
                  />
                )}

                <LeftBarItem
                  selected={selectedPage === "usage"}
                  to={"/usage"}
                  titleId="left_bar.usage"
                />

                {shouldShowPayment && hasPermissionToViewSubscription && (
                  <LeftBarItem
                    selected={selectedPage === "payment"}
                    to={"/payment"}
                    titleId="left_bar.billing"
                  ></LeftBarItem>
                )}

                {enableCustomCredentials && hasPermissionToViewTeamSetting && (
                  <LeftBarItem
                    selected={selectedPage === "setting"}
                    to={"/setting"}
                    titleId="left_bar.settings"
                  ></LeftBarItem>
                )}

                {enabledAuditLog && hasPermissionToViewAuditLog && (
                  <LeftBarItem
                    selected={selectedPage === "audit-log"}
                    to={"/audit-log"}
                    titleId="left_bar.audit_log"
                  ></LeftBarItem>
                )}

                {!AppConfig.shouldTawkDisabled && (
                  <div className={styles["tawk-padding"]} />
                )}
              </div>
            </div>
            {AppConfig.shouldTawkDisabled && (
              <div className={styles["powered-by-formx"]}>
                <FormattedMessage id="left_bar.powered_by_formx" />
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={classnames(styles["collapse-button-bar"], {
          [styles["button-collapsed"]]: isLeftBarCollapsed,
        })}
        onClick={() => toggleLeftBarCollapsed(undefined)}
      />
    </div>
  );
};

export const LeftBar = React.memo((props: Props) => {
  const implProps = useLeftBar(props);
  return <LeftBarImpl {...implProps} />;
});

export const AdminLeftBar = React.memo((props: AdminProps) => {
  const { isLeftBarCollapsed, selectedPage, toggleLeftBarCollapsed } = props;
  const location = useLocation();

  return (
    <div
      className={classnames(styles["container"], {
        [styles["container-collapsed"]]: isLeftBarCollapsed,
      })}
    >
      <div className={styles["content"]}>
        {!isLeftBarCollapsed && (
          <>
            <div className={styles["list"]}>
              <div className={styles["items"]}>
                <LeftBarItem
                  selected={selectedPage === "admin-report"}
                  to={`/admin/report`}
                  titleId="left_bar.report"
                  withoutTeamRef={true}
                />
                <LeftBarItem
                  selected={selectedPage === "team-list"}
                  to={`/admin/team${location.search}`}
                  titleId="left_bar.teams"
                  withoutTeamRef={true}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={classnames(styles["collapse-button-bar"], {
          [styles["button-collapsed"]]: isLeftBarCollapsed,
        })}
        onClick={() => toggleLeftBarCollapsed(undefined)}
      />
    </div>
  );
});

export default LeftBar;
