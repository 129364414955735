import {
  Checkbox,
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  Label,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo, useState } from "react";

import {
  DefaultLLMModel,
  LLMModelNameKey,
  LLMModelsWithVision,
} from "../../../constants/llmModel";
import { useLocale } from "../../../contexts/locale";
import { TargetField } from "../../../types/advancedTokenSetup/table";
import {
  DefaultLLMInputType,
  LLMInputType,
} from "../../../types/llmCompletion";
import { LLMModel } from "../../../types/llmModel";
import { GroupHeader } from "../../GroupHeader";

function AdvancedSettingsLLMInputTypeOptionLabel(props: { text: string }) {
  const { text } = props;
  return (
    <div className="pl-7">
      <div className="text-gray-900">{text}</div>
    </div>
  );
}

export interface AdvancedSettingsProps {
  className?: string;
  target: TargetField;
  canTunePrompts: boolean;

  defaultExpanded?: boolean;

  fieldDescription: string;
  onFieldDescriptionChange: (value: string) => void;

  model: LLMModel;
  availableModels: readonly LLMModel[];
  onModelChange: (value: LLMModel) => void;

  inputType?: LLMInputType;
  onInputTypeChange: (value: LLMInputType) => void;

  preserveHorizontalWhitespace: boolean;
  onPreserveHorizontalWhitespaceChange: (value: boolean) => void;

  preserveVerticalWhitespace: boolean;
  onPreserveVerticalWhitespaceChange: (value: boolean) => void;
}

const fieldDescriptionDescriptionKeys: Record<TargetField, string> = {
  invoice_number:
    "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.description.invoice_number",
  total_amount:
    "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.description.total_amount",
  date: "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.description.date",
};

const fieldDescriptionPlaceholderKeys: Record<TargetField, string> = {
  invoice_number:
    "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.placeholder.invoice_number",
  total_amount:
    "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.placeholder.total_amount",
  date: "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.placeholder.date",
};

export function AdvancedSettings(props: AdvancedSettingsProps) {
  const {
    className,
    target,
    canTunePrompts,

    defaultExpanded = false,

    fieldDescription,
    onFieldDescriptionChange,
    model,
    availableModels,
    onModelChange,
    inputType,
    onInputTypeChange,
    preserveHorizontalWhitespace,
    onPreserveHorizontalWhitespaceChange,
    preserveVerticalWhitespace,
    onPreserveVerticalWhitespaceChange,
  } = props;

  const { localized } = useLocale();

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const handleHeaderOnToggle = useCallback(() => setIsExpanded(x => !x), []);

  const handleOnFieldDescriptionChange = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onFieldDescriptionChange(e.currentTarget.value);
    },
    [onFieldDescriptionChange]
  );

  const modelOptions = useMemo<IDropdownOption[]>(
    () =>
      availableModels.map(m => ({
        key: m,
        text:
          m === DefaultLLMModel
            ? localized("llm_model.default", {
                modelLabel: localized(LLMModelNameKey[m]),
              })
            : localized(LLMModelNameKey[m]),
      })),
    [availableModels, localized]
  );
  const handleModelOnChange = useCallback(
    (_e: React.FormEvent, opt: IDropdownOption | undefined) => {
      if (opt != null) {
        onModelChange(opt.key as LLMModel);
      }
    },
    [onModelChange]
  );

  const shouldShowInputType = useMemo(
    () => LLMModelsWithVision.includes(model),
    [model]
  );

  const inputTypeOptions = useMemo<IChoiceGroupOption[]>(
    () =>
      Object.values(LLMInputType).map(inputType => ({
        key: inputType,
        text: localized(
          `advance_token_setup_field_replacement_setting_panel.panel.llm.input_type.${inputType}.label`
        ),
        onRenderLabel: props =>
          props != null ? (
            <AdvancedSettingsLLMInputTypeOptionLabel text={props.text} />
          ) : null,
      })),
    [localized]
  );

  const handleInputTypeChange = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option != null) {
        onInputTypeChange(option.key as LLMInputType);
      }
    },
    [onInputTypeChange]
  );

  const handlePreserveHorizontalWhitespaceChange = useCallback(
    (_e: React.FormEvent | undefined, value: boolean | undefined) => {
      if (value != null) {
        onPreserveHorizontalWhitespaceChange(value);
      }
    },
    [onPreserveHorizontalWhitespaceChange]
  );
  const handlePreserveVerticalWhitespaceChange = useCallback(
    (_e: React.FormEvent | undefined, value: boolean | undefined) => {
      if (value != null) {
        onPreserveVerticalWhitespaceChange(value);
      }
    },
    [onPreserveVerticalWhitespaceChange]
  );

  return (
    <div className={className}>
      <GroupHeader
        size="s"
        isExpanded={isExpanded}
        onToggle={handleHeaderOnToggle}
      >
        <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.llm.advanced_settings" />
      </GroupHeader>

      {isExpanded ? (
        <div className={"pl-7"}>
          <div className="mb-1.5 flex items-center h-6">
            <p className="text-sm font-semibold text-gray-900">
              <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.title" />
            </p>
          </div>
          <p className="mb-2 text-sm text-gray-700">
            <FormattedMessage id={fieldDescriptionDescriptionKeys[target]} />
          </p>
          <TextField
            inputClassName="min-h-12"
            onChange={handleOnFieldDescriptionChange}
            value={fieldDescription}
            placeholder={localized(fieldDescriptionPlaceholderKeys[target])}
            resizable={false}
            multiline
            autoAdjustHeight
          />

          {canTunePrompts ? (
            <div className="mt-3">
              <Dropdown
                label={localized(
                  "advance_token_setup_field_replacement_setting_panel.panel.llm.llm_model.label"
                )}
                selectedKey={model}
                options={modelOptions}
                onChange={handleModelOnChange}
                placeholder={localized("dropdown.placeholder")}
              />

              {shouldShowInputType ? (
                <ChoiceGroup
                  className="mt-3"
                  options={inputTypeOptions}
                  selectedKey={inputType ?? DefaultLLMInputType}
                  onChange={handleInputTypeChange}
                />
              ) : null}

              <Label className="mt-3">
                <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.llm.whitespace_preservation" />
              </Label>
              <Checkbox
                className="mt-2"
                label={localized(
                  "advance_token_setup_field_replacement_setting_panel.panel.llm.preserve_horizontal_whitespace.label"
                )}
                checked={preserveHorizontalWhitespace}
                onChange={handlePreserveHorizontalWhitespaceChange}
              />
              <Checkbox
                className="mt-2"
                label={localized(
                  "advance_token_setup_field_replacement_setting_panel.panel.llm.preserve_vertical_whitespace.label"
                )}
                checked={preserveVerticalWhitespace}
                onChange={handlePreserveVerticalWhitespaceChange}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
