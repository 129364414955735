export const LABELLER_PADDING = 60;
export const PAYMENT_REQUIRED_TOAST_ID = "payment_required";
export const GOT_TEAM_INVITATION_TOAST_ID = "got_team_invitation";
export const ToolBoxTutorialTargetIds = {
  InfoIcon: "info-icon",
  SelectToolBox: "select-tool-box",
  AnchorToolBox: "anchor-tool-box",
  DetectionRegionToolBox: "detection-region-tool-box",
};
export const DetectionRegionInspectorTargetIds = {
  AddExtractionField: "add-extraction-field",
  LabelExtractionField: "label-extraction-field",
};
export const MAX_FETCH_CUSTOM_MODELS = 50;
export const CUSTOM_MODEL_IMAGE_PAGE_SIZE = 12;

export const DEFAULT_CUSTOM_MODEL_REMARK = "None";
export const CUSTOM_MODEL_SAVE_DEBOUNCE_INTERVAL = 500;
export const CONFIRM_MODAL_FADE_OUT_DELAY = 300;

export const EXTRACTION_POLLING_INTERVAL = 2000;
export const EXTRACTION_MAXIMUM_POLLING_TIME = 300000; // 5 mintues

export const SHOULD_RELOAD_COOKIE_POLLING_INTERVAL = 30000;

export const EXTRACTOR_PAGE_SIZE = 50;

export const WORKSPACE_PAGE_SIZE = 50;

export const EXTRACTION_PAGE_SIZE = 50;

export const ADDITIONAL_IMAGE_PAGE_SIZE = 10;

export const EXPORT_POLLING_INTERVAL = 5000;
export const EXPORT_MAXIMUM_POLLING_TIME_PER_EXTRACTION_RESULT = 120000; // 2 mintues per extraction result

export const CONFIG_SNAPSHOT_PAGE_SIZE = 20;
