import {
  MessageBar as FluentMessageBar,
  IMessageBarProps,
} from "@fluentui/react";
import cn from "classnames";
import React from "react";

export { MessageBarType } from "@fluentui/react";

export function MessageBar(props: IMessageBarProps) {
  const { className, ...rest } = props;

  return (
    <FluentMessageBar
      className={cn("min-h-12 px-4 justify-center", className)}
      {...rest}
    />
  );
}
