import { useSelector } from "react-redux";

import { useOAuthActionCreator } from "../actions/oauth";
import { RootState } from "../redux/types";
import type { OAuthCredentialList } from "../types/oauth";

export interface useOAuthCredentialReturnValues {
  deleteOAuthCredential: (id: string) => Promise<void>;
  listOAuthCredentials: (
    resource_server: string,
    offset: number,
    size: number,
    signal: AbortSignal
  ) => Promise<OAuthCredentialList>;
  listAllOAuthCredentials: (
    resource_server: string
  ) => Promise<OAuthCredentialList>;
  oauthCredentials: OAuthCredentialList;
}

export function useOAuthCredential(): useOAuthCredentialReturnValues {
  const oauthCredentials = useSelector(
    (state: RootState) => state.oauth.oauthCredentials
  );

  const {
    deleteOAuthCredential,
    listOAuthCredentials,
    listAllOAuthCredentials,
  } = useOAuthActionCreator();

  return {
    deleteOAuthCredential,
    listOAuthCredentials,
    oauthCredentials,
    listAllOAuthCredentials,
  };
}
