import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { RootState } from "../../redux/types";
import { TeamListItem } from "../../types/team";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import styles from "./styles.module.scss";

export enum TeamTableRowInfo {
  ContactEmail = "ContactEmail",
  CreatedAt = "CreatedAt",
  TotalUsage = "TotalUsage",
  Plan = "Plan",
}

interface Props {
  team: TeamListItem;
  key: number;
  regionKey: string;
  displayInfos: TeamTableRowInfo[];
}

export function _TeamTableRow(props: Props) {
  const { team, regionKey, displayInfos } = props;
  const navigate = useNavigate();
  const plans = useSelector((state: RootState) => state.app.plans);

  const onRowClick = React.useCallback(() => {
    navigate(`/admin/team/${regionKey}/${team.id}`);
  }, [navigate, regionKey, team.id]);

  const planToDisplay = capitalizeFirstLetter(
    plans.find(x => x.id === team.planId)?.name || team.planName || ""
  );

  const renderDisplayField = React.useCallback(
    (info: TeamTableRowInfo) => {
      switch (info) {
        case TeamTableRowInfo.CreatedAt:
          return (
            <td key={info}>
              <div className={styles["detail-info"]}>
                {team.createdAt.toLocaleDateString()}{" "}
                {team.createdAt.toLocaleTimeString()}
              </div>
            </td>
          );
        case TeamTableRowInfo.Plan:
          return (
            <td key={info}>
              <div className={styles["detail-info-capitalize"]}>
                {planToDisplay}
              </div>
            </td>
          );
        case TeamTableRowInfo.ContactEmail:
          return (
            <td key={info}>
              <div className={styles["detail-info"]}>{team.contactEmail}</div>
            </td>
          );
        case TeamTableRowInfo.TotalUsage:
          return (
            <td key={info}>
              <div className={styles["detail-info"]}>
                {team.totalUsage ?? 0}
              </div>
            </td>
          );
      }
    },
    [team, planToDisplay]
  );

  return (
    <tr onClick={onRowClick} className={styles["team-table-row"]}>
      <td>
        <div className={styles["basic-info"]}>
          <div className={styles["name"]}>{team.name}</div>
          <div className={styles["lookup-id"]}>{team.lookupId}</div>
        </div>
      </td>
      {displayInfos.map(renderDisplayField)}
    </tr>
  );
}

export const TeamTableRow = React.memo(_TeamTableRow);
export default TeamTableRow;
