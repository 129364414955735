import * as React from "react";
import { useSelector } from "react-redux";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { WorkspaceIntegrationSection } from "../components/WorkspaceIntegration";
import { WorkspaceTab } from "../components/WorkspaceNavBar";
import { AppConfig } from "../config";
import { useOnload } from "../hooks/asyncguard/asyncguard";
import { useOAuthCredential } from "../hooks/oauth";
import { useUnsafeParams } from "../hooks/params";
import { useCommonWorkspaceContainerState } from "../hooks/workspace";
import { useWorkspaceWebhookResource } from "../hooks/workspaceWebhook";
import {
  useGoogleSheetIntegration,
  useSharePointIntegration,
} from "../hooks/workspace_integration";
import { PathParam } from "../models";
import { RootState } from "../redux/types";
import HeaderContainer from "./Header";
import { WorkspaceNavBarLayoutContainer as WorkspaceNavBarLayout } from "./WorkspaceNavBarLayout";

function useWorkspaceIntegrationContainer() {
  const googleSheetIntegrationProps = useGoogleSheetIntegration();
  const oauthProps = useOAuthCredential();

  const { listAllOAuthCredentials } = oauthProps;

  const { workspaceId } = useUnsafeParams<PathParam>();
  const { workspace } = useCommonWorkspaceContainerState(workspaceId);
  const { workspaceWebhooks } = useSelector((state: RootState) => {
    return {
      workspaceWebhooks: state.workspaceWebhook.workspaceWebhooks,
    };
  });

  const { queryWorkspaceWebhook } = useWorkspaceWebhookResource();

  useOnload(() => {
    queryWorkspaceWebhook(workspaceId);
    // TODO - List OAuth credential only if Export to Google Sheet is available.
    // Will handle it in #3367
    listAllOAuthCredentials("google");
  });

  const googleAuth = React.useCallback(async () => {
    if (AppConfig.googleAuth === undefined) {
      throw new Error("AppConfig.googleAuth not configured");
    }

    // No need to set authorizing state. That was handled by useGoogleAuth
    const googleAuthData = await googleSheetIntegrationProps.googleAuth(
      AppConfig.googleAuth.sheetsExportScopes
    );

    const { oauthCredentials } = await listAllOAuthCredentials("google");
    const item = oauthCredentials.find(
      item => item.email === googleAuthData.email
    );

    return item;
  }, [googleSheetIntegrationProps, listAllOAuthCredentials]);

  return React.useMemo(
    () => ({
      isLoading: workspace.state === "loading",
      workspace,
      workspaceWebhooks,
      googleAuth,
      googleSheetIntegrationProps,
      oauthProps,
    }),
    [
      workspace,
      workspaceWebhooks,
      googleSheetIntegrationProps,
      oauthProps,
      googleAuth,
    ]
  );
}

export function WorkspaceIntegrationContainer() {
  const sharePointIntegrationProps = useSharePointIntegration();

  const {
    isLoading,
    workspace,
    workspaceWebhooks,
    googleSheetIntegrationProps,
    oauthProps,
    googleAuth,
  } = useWorkspaceIntegrationContainer();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={isLoading} />
      <Main hasTop={true}>
        {workspace.state === "error" && (
          <ErrorPlaceholder messageId="common.fail_to_fetch_workspace" />
        )}
        {workspace.state === "success" && (
          <WorkspaceNavBarLayout selectedTab={WorkspaceTab.Integrations}>
            <WorkspaceIntegrationSection
              googleAuth={googleAuth}
              googleSheetIntegrationProps={googleSheetIntegrationProps}
              oauthProps={oauthProps}
              sharePointIntegrationProps={sharePointIntegrationProps}
              workspace={workspace.data}
              workspaceWebhooks={workspaceWebhooks}
            />
          </WorkspaceNavBarLayout>
        )}
      </Main>
    </Layout>
  );
}

export default React.memo(WorkspaceIntegrationContainer);
