import { Dropdown, FontIcon, IDropdownOption } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo, useState } from "react";

import { useConfirmModalActionCreator } from "../../../actions/confirmModal";
import { apiClient } from "../../../apiClient";
import { UserFeatureFlag } from "../../../constants";
import {
  DATE_INPUT_FORMAT_OPTIONS,
  MAX_FSL_FIELD_REPLACEMENT_SAMPLES,
} from "../../../constants/formConfig";
import {
  DefaultLLMModel,
  PubliclyAvailableLLMModels,
  RestrictedLLMModels,
} from "../../../constants/llmModel";
import { useAdvanceTokenSetupEditor } from "../../../contexts/advanceTokenSetupEditor";
import { useLocale } from "../../../contexts/locale";
import { FOCRError } from "../../../errors";
import { useOnload } from "../../../hooks/asyncguard/asyncguard";
import { useAppSelector } from "../../../hooks/redux";
import {
  LLMSamplesSettings,
  LLMSettings,
} from "../../../types/advancedPatternMatching";
import {
  LLMConfig,
  TargetField,
} from "../../../types/advancedTokenSetup/table";
import { ConfirmModalType } from "../../../types/confirmation";
import { DateInputFormatType } from "../../../types/formConfig";
import { LLMInputType, LLMParameters } from "../../../types/llmCompletion";
import { LLMModel, isLLMModel } from "../../../types/llmModel";
import { defaultLLMSettings } from "../../../types/mappers/advancedPatternMatching/merchant";
import { OCRResult } from "../../../types/ocrResult";
import { ImageFile, ImageThumbnailList } from "../../ImageThumbnailList";
import LoadingModal from "../../LoadingModal";
import { AdvancedSettings } from "./AdvancedSettings";
import { EditSampleModal, useEditSampleModalHandle } from "./EditSampleModal";

type BaseProps = ReturnType<
  typeof useLLMFieldReplacementViewHandle
>["triggerProps"];

type Props = BaseProps;

interface SampleItem {
  id: string;
  imageURL: string;
  imageFilename: string;
  fieldValues: Record<TargetField, string>;
}

function makeSampleItem(
  ocrResult: OCRResult,
  fieldValues: Partial<Record<TargetField, string>>
) {
  return {
    id: ocrResult.id,
    imageURL: ocrResult.assetUrl,
    imageFilename: ocrResult.filename,
    fieldValues: {
      invoice_number: fieldValues["invoice_number"] ?? "",
      total_amount: fieldValues["total_amount"] ?? "",
      date: fieldValues["date"] ?? "",
    },
  };
}

const defaultLLMConfig: LLMConfig = {
  llm_settings_id: "default",
};

export function useLLMFieldReplacementViewHandle(
  targetTag: string,
  targetField: TargetField,
  initialLLMConfig?: LLMConfig,
  initialLLMSettings?: LLMSettings
) {
  const [llmConfig, setLLMConfig] = useState<LLMConfig>(
    initialLLMConfig ?? defaultLLMConfig
  );
  const [llmSettings, setLLMSettings] = useState<LLMSettings>(
    initialLLMSettings ?? defaultLLMSettings
  );
  const llmParameters = (llmSettings.llm_parameters ?? {}) as LLMParameters;

  const canUseRestrictedLLMModels = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.MoreLLMModels)
  );
  const availableModels = useMemo(
    () => [
      ...PubliclyAvailableLLMModels,
      ...(canUseRestrictedLLMModels ? RestrictedLLMModels : []),
    ],
    [canUseRestrictedLLMModels]
  );

  const isAdvancedPromptTuningEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.AdvancedPromptTuning)
  );
  const canTunePrompts =
    isAdvancedPromptTuningEnabled && availableModels.length > 0;

  const dateInputFormat =
    llmConfig.date_input_format ?? DATE_INPUT_FORMAT_OPTIONS[0];
  const onDateInputFormatChange = useCallback((value: DateInputFormatType) => {
    setLLMConfig(prev => ({ ...prev, date_input_format: value }));
  }, []);

  const resourceOwnerId =
    useAppSelector(s => s.resourceOwner.resourceOwnerId) ?? "";
  const formId = useAdvanceTokenSetupEditor().formId ?? "";

  const [samples, setSamples] = useState<SampleItem[]>([]);
  const [isLoadingSamples, setIsLoadingSamples] = useState(false);
  useOnload(() => {
    async function loadSample(
      resourceOwnerId: string,
      sample: LLMSamplesSettings
    ): Promise<SampleItem> {
      const fieldValues = sample.field_values as Partial<
        Record<TargetField, string>
      >;
      const ocrResult = await apiClient.getOCRResult(
        resourceOwnerId,
        sample.ocr_result_id
      );

      return makeSampleItem(ocrResult, fieldValues);
    }

    async function loadSamples(resourceOwnerId: string) {
      setIsLoadingSamples(true);
      try {
        const ocrResults = await Promise.all(
          (initialLLMSettings?.samples ?? []).map(s =>
            loadSample(resourceOwnerId, s)
          )
        );
        setSamples(ocrResults);
      } finally {
        setIsLoadingSamples(false);
      }
    }

    loadSamples(resourceOwnerId).catch(console.error);
  });

  const onDeleteSample = useCallback((item: SampleItem) => {
    setSamples(s => s.filter(x => x.id !== item.id));
  }, []);

  const { open, props } = useEditSampleModalHandle();

  const onEditSample = useCallback(
    (item: SampleItem) => {
      open("edit", {
        targetTag: targetTag,
        sampleImageURL: item.imageURL,
        sampleFilename: item.imageFilename,
        defaultFieldValues: item.fieldValues,
      }).then(values => {
        setSamples(s =>
          s.map(x => (x.id === item.id ? { ...x, fieldValues: values } : x))
        );
      });
    },
    [open, targetTag]
  );

  const [isUploadingSample, setIsUploadingSample] = useState(false);
  const onAddSample = useCallback(
    async (file: File) => {
      setIsUploadingSample(true);
      let ocrResult: OCRResult;
      try {
        const { name: assetId } = await apiClient.createAsset(file);
        ocrResult = await apiClient.createOCRResult(
          assetId,
          resourceOwnerId,
          `${formId}:fsl-field-replacement`
        );
      } finally {
        setIsUploadingSample(false);
      }

      const values = await open("add", {
        targetTag: targetTag,
        sampleImageURL: ocrResult.assetUrl,
        sampleFilename: ocrResult.filename,
        defaultFieldValues: {
          invoice_number: "",
          total_amount: "",
          date: "",
        },
      });
      setSamples(s => [...s, makeSampleItem(ocrResult, values)]);
    },
    [formId, open, resourceOwnerId, targetTag]
  );

  const fieldDescription = llmConfig.field_description ?? "";
  const onFieldDescriptionChange = useCallback((value: string) => {
    setLLMConfig(prev => ({ ...prev, field_description: value }));
  }, []);

  const model = isLLMModel(llmParameters.model)
    ? llmParameters.model
    : DefaultLLMModel;
  const onModelChange = useCallback((value: LLMModel) => {
    setLLMSettings(s => ({
      ...s,
      llm_parameters: { ...s.llm_parameters, model: value },
    }));
  }, []);

  const inputType = llmParameters.input_type ?? LLMInputType.OCROnly;
  const onInputTypeChange = useCallback((value: LLMInputType) => {
    setLLMSettings(s => ({
      ...s,
      llm_parameters: {
        ...s.llm_parameters,
        input_type: value,
      },
    }));
  }, []);

  const preserveHorizontalWhitespace =
    llmParameters.should_preserve_horizontal_whitespace ?? false;
  const onPreserveHorizontalWhitespaceChange = useCallback((value: boolean) => {
    setLLMSettings(s => ({
      ...s,
      llm_parameters: {
        ...s.llm_parameters,
        should_preserve_horizontal_whitespace: value,
      },
    }));
  }, []);

  const preserveVerticalWhitespace =
    llmParameters.should_preserve_vertial_whitespace ?? false;
  const onPreserveVerticalWhitespaceChange = useCallback((value: boolean) => {
    setLLMSettings(s => ({
      ...s,
      llm_parameters: {
        ...s.llm_parameters,
        should_preserve_vertial_whitespace: value,
      },
    }));
  }, []);

  const submit = useCallback(
    () => ({
      llmConfig,
      llmSettings: {
        ...llmSettings,
        samples: samples.map(s => ({
          ocr_result_id: s.id,
          field_values: s.fieldValues,
        })),
      },
    }),
    [llmConfig, llmSettings, samples]
  );

  const shouldShowInputDateFormat = targetField === "date";

  return useMemo(
    () => ({
      triggerProps: {
        targetField,

        isLoadingSamples,
        isUploadingSample,
        modalProps: props,
        samples,
        onDeleteSample,
        onEditSample,
        onAddSample,

        shouldShowInputDateFormat,
        dateInputFormat,
        onDateInputFormatChange,
        fieldDescription,
        onFieldDescriptionChange,

        canTunePrompts,
        availableModels,
        model,
        onModelChange,
        inputType,
        onInputTypeChange,
        preserveHorizontalWhitespace,
        onPreserveHorizontalWhitespaceChange,
        preserveVerticalWhitespace,
        onPreserveVerticalWhitespaceChange,
      },
      submit,
    }),
    [
      targetField,
      isLoadingSamples,
      isUploadingSample,
      props,
      samples,
      onDeleteSample,
      onEditSample,
      onAddSample,
      shouldShowInputDateFormat,
      dateInputFormat,
      onDateInputFormatChange,
      fieldDescription,
      onFieldDescriptionChange,
      canTunePrompts,
      availableModels,
      model,
      onModelChange,
      inputType,
      onInputTypeChange,
      preserveHorizontalWhitespace,
      onPreserveHorizontalWhitespaceChange,
      preserveVerticalWhitespace,
      onPreserveVerticalWhitespaceChange,
      submit,
    ]
  );
}

const LLMFieldReplacementView = React.memo<Props>(props => {
  const {
    targetField,
    isLoadingSamples,
    isUploadingSample,
    modalProps,
    samples,
    onDeleteSample,
    onEditSample,
    onAddSample,
    shouldShowInputDateFormat,
    dateInputFormat,
    onDateInputFormatChange,
    fieldDescription,
    onFieldDescriptionChange,
    canTunePrompts,
    availableModels,
    model,
    onModelChange,
    inputType,
    onInputTypeChange,
    preserveHorizontalWhitespace,
    onPreserveHorizontalWhitespaceChange,
    preserveVerticalWhitespace,
    onPreserveVerticalWhitespaceChange,
  } = props;

  const { localized } = useLocale();

  const dateInputFormatOptions: IDropdownOption[] = useMemo(() => {
    return DATE_INPUT_FORMAT_OPTIONS.map(f => ({
      id: f,
      key: f,
      text: localized(`date_input_format.${f}`),
    }));
  }, [localized]);

  const handleDateInputFormatOnChange = useCallback(
    (_e: React.FormEvent, option?: IDropdownOption) => {
      if (option != null && option.id != null) {
        onDateInputFormatChange(option.id as DateInputFormatType);
      }
    },
    [onDateInputFormatChange]
  );

  const samplesSubtitleValues = useMemo(
    () => ({ max: MAX_FSL_FIELD_REPLACEMENT_SAMPLES, num: samples.length }),
    [samples]
  );

  const sampleThumbnailItems = useMemo<ImageFile[]>(
    () =>
      samples.map(s => ({
        url: s.imageURL,
        filename: s.imageFilename,
      })),
    [samples]
  );

  const handleRenderInfoPanel = useCallback(
    (_: ImageFile, index: number) => {
      const value = samples[index].fieldValues[targetField];
      return (
        <div className="px-1.5 py-1 flex flex-col gap-y-0.5 text-xs">
          <p className="text-gray-700 truncate">{targetField}</p>
          <p className="text-gray-800 truncate">{value}</p>
        </div>
      );
    },
    [samples, targetField]
  );

  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const handleOnDelete = useCallback(
    (image: ImageFile, index: number) => {
      const sample = samples[index];

      requestUserConfirmation({
        titleId:
          "advance_token_setup_field_replacement_setting_panel.panel.llm.samples.delete_confirm.title",
        titleValues: { filename: image.filename },
        messageId:
          "advance_token_setup_field_replacement_setting_panel.panel.llm.samples.delete_confirm.message",
        actionId: "common.delete",
        type: ConfirmModalType.Destory,
      }).then(() => {
        onDeleteSample(sample);
      });
    },
    [requestUserConfirmation, samples, onDeleteSample]
  );

  const handleOnPress = useCallback(
    (_: ImageFile, index: number) => {
      onEditSample(samples[index]);
    },
    [onEditSample, samples]
  );

  const handleOnUpload = useCallback(
    (file: File) => {
      onAddSample(file).catch(e => {
        let messageId = "";
        if (e instanceof FOCRError) {
          messageId = "error.failed_to_upload_sample_image";
        }

        requestUserConfirmation({
          titleId: "error.failed_to_upload_sample_image",
          titleValues: { filename: file.name },
          messageId,
          messageValues: { filename: file.name },
          type: ConfirmModalType.Normal,
          actionId: "common.ok",
          hideCancel: true,
        });
      });
    },
    [onAddSample, requestUserConfirmation]
  );

  return (
    <div className="flex flex-col gap-5 py-4">
      <LoadingModal isOpen={isLoadingSamples || isUploadingSample} />

      {shouldShowInputDateFormat && (
        <Dropdown
          label={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.llm.date_input_format.label"
          )}
          selectedKey={dateInputFormat}
          onChange={handleDateInputFormatOnChange}
          options={dateInputFormatOptions}
        />
      )}

      <div>
        <div className="flex items-center h-6 mb-1">
          <p className="text-sm font-semibold text-gray-900">
            <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.llm.samples.title" />
          </p>
          <p className="ml-2 text-sm text-gray-700">
            <FormattedMessage
              id="advance_token_setup_field_replacement_setting_panel.panel.llm.samples.subtitle"
              values={samplesSubtitleValues}
            />
          </p>
        </div>
        <p className="text-sm text-gray-700">
          <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.llm.samples.description" />
        </p>
      </div>

      <div className="h-[14.125rem]">
        {isLoadingSamples ? null : (
          <ImageThumbnailList
            className="h-full"
            items={sampleThumbnailItems}
            itemPerRow={MAX_FSL_FIELD_REPLACEMENT_SAMPLES}
            canUpload={samples.length < MAX_FSL_FIELD_REPLACEMENT_SAMPLES}
            isUploading={false}
            renderInfoPanel={handleRenderInfoPanel}
            onDelete={handleOnDelete}
            onPress={handleOnPress}
            onUpload={handleOnUpload}
          />
        )}
      </div>

      <div className="flex gap-x-1.5 items-center text-sm text-gray-700">
        <FontIcon iconName="Info" />
        <p>
          <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.llm.samples.hint" />
        </p>
      </div>

      <AdvancedSettings
        target={targetField}
        canTunePrompts={canTunePrompts}
        fieldDescription={fieldDescription}
        onFieldDescriptionChange={onFieldDescriptionChange}
        model={model}
        availableModels={availableModels}
        onModelChange={onModelChange}
        inputType={inputType}
        onInputTypeChange={onInputTypeChange}
        preserveHorizontalWhitespace={preserveHorizontalWhitespace}
        onPreserveHorizontalWhitespaceChange={
          onPreserveHorizontalWhitespaceChange
        }
        preserveVerticalWhitespace={preserveVerticalWhitespace}
        onPreserveVerticalWhitespaceChange={onPreserveVerticalWhitespaceChange}
      />

      {modalProps != null ? <EditSampleModal {...modalProps} /> : null}
    </div>
  );
});

export default LLMFieldReplacementView;
