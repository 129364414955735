import { createReducer } from "@reduxjs/toolkit";

import * as oauthAction from "../actions/oauth";
import { OAuthCredentialList } from "../types/oauth";

export interface OAuthState {
  readonly oauthCredentials: OAuthCredentialList;
}

const defaultState: OAuthState = {
  oauthCredentials: {
    pageInfo: {
      offset: 0,
      totalCount: 0,
    },
    oauthCredentials: [],
  },
};

export const oauthReducer = createReducer<OAuthState>(defaultState, builder => {
  builder
    .addCase(oauthAction.OAuthCredentialDeleted, (state, action) => {
      const { id: oauthCredentialId } = action.payload;
      state.oauthCredentials.oauthCredentials =
        state.oauthCredentials.oauthCredentials.filter(
          ({ id }) => id !== oauthCredentialId
        );
      state.oauthCredentials.pageInfo.totalCount =
        state.oauthCredentials.oauthCredentials.length;
    })
    .addCase(oauthAction.OAuthGotCredentialList, (state, action) => {
      state.oauthCredentials = action.payload;
    });
});
