import produce from "immer";
import { v4 as uuidv4 } from "uuid";

import { getTimestamp } from "../../utils/time";
import {
  CustomTokenType,
  FieldReplacement,
  InfoField,
  isBuiltinFieldType,
} from "../advancedPatternMatching";
import { DateInputFormatType } from "../formConfig";
import { KeyValueResp } from "../keyValue";
import { HeaderIconType } from "./headerIcon";

export enum MenuItem {
  TagMall = "TagMall",
  TagMerchant = "TagMerchant",
  ExactMatchRule = "ExactMatchRule",
  ClosestMatchRuleMall = "ClosestMatchRuleMall",
  ClosestMatchRuleMerchant = "ClosestMatchRuleMerchant",
  FieldReplacementMall = "FieldReplacementMall",
  FieldReplacementMerchant = "FieldReplacementMerchant",
}

export type TagKey = "merchant_id" | "mall_id";

export type TargetField = "invoice_number" | "total_amount" | "date";

export interface LocalizedHeader extends Omit<Header, "label"> {
  labelId: string;
  fieldType?: CustomTokenType;
  [propertyName: string]: any;
}

export interface TextToken {
  type?: CustomTokenType;
  pattern: string;
  isExactMatch?: boolean;
  isClearSpace?: boolean;
  isRegex?: boolean;
}
export enum HeaderGroupKey {
  RowOrder = "row_order",
  TagGroup = "tag_groups",
  ReturningTags = "returning_tags",
  MatchingTokens = "matching_tokens",
  Field = "field",
}

export enum CellDataType {
  TokenCellData = "token-cell-data",
  RegexTokenCellData = "regex-token-cell-data",
  SettingCellData = "setting-cell-data",
  TagAutoCompleteCellData = "tag-auto-complete-cell-data",
  TagCellData = "tag-cell-data",
}

export enum InternalCellDataType {
  RowOrderCellData = "row-order-cell-data",
  DeleteRowCellData = "delete-row-cell-data",
}

export interface Header {
  label: string;
  type: CellDataType;
  icon?: HeaderIconType;
  group?: HeaderGroupKey;
}

interface ErrorCellData {
  error?: string;
}

export interface TokenCellData extends ErrorCellData {
  type: CellDataType.TokenCellData;
  token: TextToken;
}

export interface KeyValueConfig {
  type: "key_value";
  config: KeyValueResp;
}

export interface CustomModelTargetConfig {
  custom_model_id: string;
  field_name: string;
  date_input_format?: DateInputFormatType;
}

export interface ChangeDateInputFormatConfig {
  date_input_format: DateInputFormatType;
}

export interface ChangeDateInputConfig {
  type: "change_date_input";
  config: ChangeDateInputFormatConfig;
}

export interface CustomModelConfig {
  type: "custom_model";
  config: CustomModelTargetConfig;
}

export interface LLMConfig {
  llm_settings_id: string;
  field_description?: string;
  date_input_format?: DateInputFormatType;
}

export interface LLMFieldReplacementConfig {
  type: "llm";
  config: LLMConfig;
}

export type SettingConfig =
  | KeyValueConfig
  | CustomModelConfig
  | ChangeDateInputConfig
  | LLMFieldReplacementConfig;

export interface SettingCellData extends ErrorCellData {
  type: CellDataType.SettingCellData;
  config?: SettingConfig;
  target: TargetField;
}

export interface TagCellData extends ErrorCellData {
  type: CellDataType.TagCellData;
  tag: string;
  tagKey: TagKey;
}

export interface TagAutoCompleteCellData extends ErrorCellData {
  type: CellDataType.TagAutoCompleteCellData;
  tag: string;
  tagKey: TagKey;
  options: string[];
}

interface RowOrderCellData {
  type: InternalCellDataType.RowOrderCellData;
  order: number;
}

interface DeleteRowCellData {
  type: InternalCellDataType.DeleteRowCellData;
}

export type CellData =
  | TokenCellData
  | SettingCellData
  | TagCellData
  | TagAutoCompleteCellData;

type InternalCellData = RowOrderCellData | DeleteRowCellData;

export type TableCellData = CellData | InternalCellData;

interface _RowData<T> {
  order: number;
  modifiedAt: number;
  data: T[];
}

export type RowData = _RowData<CellData>;

export type TableRowData = _RowData<TableCellData>;

export class TableAccessor {
  data: RowData[];

  constructor(table: RowData[]) {
    this.data = table;
  }

  renameTagAtRow(tagKey: TagKey, row: number, newTag: string) {
    this.data = produce(this.data, draft => {
      draft[row].data.forEach(d => {
        if (
          (d.type === CellDataType.TagAutoCompleteCellData ||
            d.type === CellDataType.TagCellData) &&
          d.tagKey === tagKey
        ) {
          d.tag = newTag;
        }
      });
      draft[row].modifiedAt = getTimestamp();
    });

    return this.data;
  }

  renameTag(tagKey: TagKey, oldTag: string, newTag: string) {
    this.data = this.data.map(row => {
      return {
        ...row,
        data: row.data.map(d => {
          if (
            (d.type === CellDataType.TagAutoCompleteCellData ||
              d.type === CellDataType.TagCellData) &&
            d.tagKey === tagKey &&
            d.tag === oldTag
          ) {
            return {
              ...d,
              tag: newTag,
            };
          }
          return d;
        }),
      };
    });

    return this.data;
  }
}

export function removeRowDataAt(data: RowData[], row: number) {
  const removedRow = data.splice(row, 1)[0];
  const { order } = removedRow;
  let newData = data;
  if (order) {
    newData = data.map(p => {
      if (p.order && p.order > order) {
        return {
          ...p,
          order: p.order - 1,
        };
      }
      return p;
    });
  }
  return { newData, removedRow };
}

export function getRowDataTagCellAt(data: RowData[], row: number) {
  try {
    const rowData = data[row];
    return rowData.data.find(
      p => p.type === CellDataType.TagCellData
    ) as TagCellData;
  } catch {}
  return undefined;
}

export function isCellData(data: TableCellData): data is CellData {
  return (
    data.type !== InternalCellDataType.RowOrderCellData &&
    data.type !== InternalCellDataType.DeleteRowCellData
  );
}

export const isEmpty = (cellData: CellData) => {
  switch (cellData.type) {
    case CellDataType.SettingCellData:
      return false;
    case CellDataType.TagCellData:
    case CellDataType.TagAutoCompleteCellData:
      return cellData.tag.length === 0;
    case CellDataType.TokenCellData:
      return cellData.token.pattern.length === 0;
  }
};

export const makeTagCell = (tag: string, tagKey: TagKey): TagCellData => {
  return {
    type: CellDataType.TagCellData,
    tag,
    tagKey,
  };
};

export const makeTagAutoCompleteCell = (
  tag: string,
  tagKey: TagKey
): TagAutoCompleteCellData => {
  return {
    type: CellDataType.TagAutoCompleteCellData,
    tag,
    tagKey,
    options: [],
  };
};

export const TokenCellDefaultValue = {
  brand_name: {
    isExactMatch: false,
    isClearSpace: false,
  },
  phone_number: {
    isExactMatch: false,
    isClearSpace: false,
  },
  fax_number: {
    isExactMatch: false,
    isClearSpace: false,
  },
  url: {
    isExactMatch: false,
    isClearSpace: false,
  },
  email: {
    isExactMatch: false,
    isClearSpace: false,
  },
  token: {
    isExactMatch: false,
    isClearSpace: false,
  },
  negative_token: {
    isExactMatch: false,
    isClearSpace: false,
    isRegex: false,
  },
  positive_token: {
    isExactMatch: false,
    isClearSpace: false,
    isRegex: false,
  },
};

export const makeTokenCell = (
  type?: CustomTokenType,
  infoField?: InfoField,
  defaults?: {
    isRegex?: boolean;
    isExactMatch?: boolean;
    isClearSpace?: boolean;
  }
): TokenCellData => {
  const defaultValues = {
    ...defaults,
  };

  return {
    type: CellDataType.TokenCellData,
    token: infoField
      ? {
          type,
          pattern: infoField.pattern ?? "",
          isRegex: infoField.is_regex ?? defaultValues.isRegex,
          isClearSpace:
            infoField.is_ignore_white_space ?? defaultValues.isClearSpace,
          isExactMatch:
            infoField.is_exact_match_only ?? defaultValues.isExactMatch,
        }
      : type && isBuiltinFieldType(type)
      ? {
          type,
          pattern: "",
          isClearSpace: false,
          isExactMatch: false,
        }
      : {
          type,
          pattern: "",
          isRegex: false,
          isClearSpace: false,
          isExactMatch: false,
        },
  };
};

export const makeSettingCell = (
  fallbackTarget: TargetField,
  fieldReplacement?: FieldReplacement
): SettingCellData => {
  return fieldReplacement
    ? {
        type: CellDataType.SettingCellData,
        target: fieldReplacement.target,
        config: {
          type: fieldReplacement.type,
          config: fieldReplacement.config as any,
        },
      }
    : {
        type: CellDataType.SettingCellData,
        target: fallbackTarget,
      };
};

export type SheetData = Record<MenuItem, RowData[]>;

export class SheetDataAccessor {
  data: SheetData;

  constructor(sheetData: SheetData) {
    this.data = sheetData;
  }

  createTag(tagKey: TagKey, tag: string): SheetData {
    const tagMenuItem =
      tagKey === "mall_id" ? MenuItem.TagMall : MenuItem.TagMerchant;

    this.data = produce(this.data, draft => {
      const table = draft[tagMenuItem];
      table.push({
        order: table.length + 1,
        modifiedAt: getTimestamp(),
        data: [
          {
            type: CellDataType.TagCellData,
            tag,
            tagKey,
          },
        ],
      });
    });
    return this.data;
  }

  renameTagAtRow(tagKey: TagKey, row: number, newTag: string): SheetData {
    const tagMenuItem =
      tagKey === "mall_id" ? MenuItem.TagMall : MenuItem.TagMerchant;

    let oldTag: string | undefined = undefined;

    this.data = produce(this.data, draft => {
      const rowData = draft[tagMenuItem][row];
      const tagCell = rowData.data.find(
        p => p.type === CellDataType.TagCellData
      ) as TagCellData;
      oldTag = tagCell.tag;
      tagCell.tag = newTag;
      rowData.modifiedAt = getTimestamp();
    });

    if (oldTag !== undefined && oldTag !== "") {
      [
        MenuItem.ExactMatchRule,
        MenuItem.ClosestMatchRuleMall,
        MenuItem.ClosestMatchRuleMerchant,
        MenuItem.FieldReplacementMall,
        MenuItem.FieldReplacementMerchant,
      ].forEach(menuItem => {
        this.data[menuItem] = new TableAccessor(this.data[menuItem]).renameTag(
          tagKey,
          oldTag ?? "",
          newTag
        );
      });
    }
    return this.data;
  }
}

export function getDefaultCellValue(
  header: LocalizedHeader,
  cellType: CellDataType
): CellData {
  switch (cellType) {
    case CellDataType.TagCellData:
      return {
        type: CellDataType.TagCellData,
        tag: "",
        tagKey:
          header.labelId === "advance_token_setup.nav_menu.tag.mall_id"
            ? "mall_id"
            : "merchant_id",
      };
    case CellDataType.TagAutoCompleteCellData:
      return {
        type: CellDataType.TagAutoCompleteCellData,
        tag: "",
        tagKey:
          header.labelId === "advance_token_setup.nav_menu.tag.mall_id"
            ? "mall_id"
            : "merchant_id",
        options: [],
      };
    case CellDataType.RegexTokenCellData:
      return {
        type: CellDataType.TokenCellData,
        token: {
          type: header.fieldType,
          pattern: "",
          isRegex: false,
          isClearSpace: false,
          isExactMatch: false,
        },
      };
    case CellDataType.TokenCellData:
      return {
        type: CellDataType.TokenCellData,
        token: {
          type: header.fieldType,
          pattern: "",
          isClearSpace: false,
          isExactMatch: false,
        },
      };
    case CellDataType.SettingCellData:
      return {
        type: CellDataType.SettingCellData,
        target: header.target,
      };
  }
}

export function hasSheetTag(sheetData: SheetData, tagKey: string, tag: string) {
  const ignoredTables = [MenuItem.TagMall, MenuItem.TagMerchant];

  return Object.entries(sheetData).some(([_key, value]) => {
    const tableData = value;
    if (ignoredTables.includes(_key as MenuItem)) {
      return false;
    }
    return tableData.some(row => {
      return row.data.some(cell => {
        if (cell.type === CellDataType.TagAutoCompleteCellData) {
          return cell.tagKey === tagKey && cell.tag === tag;
        }
        return false;
      });
    });
  });
}

function generateLLMSettingsId() {
  return uuidv4();
}

export class FieldReplacementRowAccessor {
  data: RowData;

  constructor(data: RowData) {
    this.data = data;
  }

  setupLLMSettingsId(newLLMSettingsId?: string) {
    let llmSettingsId: string | undefined;
    this.data = produce(this.data, data => {
      for (const cell of data.data) {
        if (
          cell.type !== CellDataType.SettingCellData ||
          cell.config?.type !== "llm"
        ) {
          continue;
        }
        const cellLLMSettingsId = cell.config.config.llm_settings_id;
        if (llmSettingsId == null) {
          if ((cellLLMSettingsId ?? "default") !== "default") {
            llmSettingsId = cellLLMSettingsId;
          } else {
            llmSettingsId = newLLMSettingsId ?? generateLLMSettingsId();
          }
        }
        cell.config.config.llm_settings_id = llmSettingsId;
      }

      data.modifiedAt = new Date().getTime();
    });

    return llmSettingsId;
  }

  setConfig(field: TargetField, config: SettingConfig | undefined) {
    this.data = produce(this.data, data => {
      for (const cell of data.data) {
        if (
          cell.type === CellDataType.SettingCellData &&
          cell.target === field
        ) {
          cell.config = config;
        }
      }

      data.modifiedAt = new Date().getTime();
    });

    return this;
  }
}
