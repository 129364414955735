import {
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  IDialogContentProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import React from "react";

import { useLocale } from "../../../contexts/locale";
import { TargetField } from "../../../types/advancedTokenSetup/table";
import { ImageViewer } from "../../ImageViewer";

interface ModalArgsEditSampleModalArgs {
  targetTag: string;
  sampleImageURL: string;
  sampleFilename: string;
  defaultFieldValues: Record<TargetField, string>;
}

export function useEditSampleModalHandle() {
  const [props, setProps] = useState<EditSampleModalProps | null>(null);

  const open = useCallback(
    async (mode: "add" | "edit", args: ModalArgsEditSampleModalArgs) => {
      return new Promise<Record<TargetField, string>>(resolve =>
        setProps({
          isOpened: true,
          mode,
          onDismiss: result => {
            if (result != null) {
              resolve(result);
            }
            setProps(x => (x ? { ...x, isOpened: false } : null));
          },
          ...args,
        })
      );
    },
    []
  );

  return useMemo(() => ({ open, props }), [open, props]);
}

export interface EditSampleModalProps extends ModalArgsEditSampleModalArgs {
  isOpened: boolean;
  mode: "add" | "edit";
  onDismiss: (newFieldValues: Record<TargetField, string> | null) => void;
}

export function EditSampleModal(props: EditSampleModalProps) {
  const {
    isOpened,
    mode,
    onDismiss,
    targetTag,
    sampleImageURL,
    sampleFilename,
    defaultFieldValues,
  } = props;
  const { localized } = useLocale();

  const handleOnDismiss = useCallback(() => onDismiss(null), [onDismiss]);

  const dialogContentProps = useMemo<IDialogContentProps>(
    () => ({
      title: localized(
        "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.title",
        { tag: targetTag }
      ),
      showCloseButton: true,
    }),
    [localized, targetTag]
  );

  return (
    <Dialog
      hidden={!isOpened}
      onDismiss={handleOnDismiss}
      dialogContentProps={dialogContentProps}
      maxWidth="100vw"
    >
      <EditSampleModalContent
        mode={mode}
        onDismiss={onDismiss}
        sampleImageURL={sampleImageURL}
        sampleFilename={sampleFilename}
        defaultFieldValues={defaultFieldValues}
      />
    </Dialog>
  );
}

interface EditSampleModalContentProps {
  mode: "add" | "edit";
  onDismiss: (newFieldValues: Record<TargetField, string> | null) => void;
  sampleImageURL: string;
  sampleFilename: string;
  defaultFieldValues: Record<TargetField, string>;
}

function EditSampleModalContent(props: EditSampleModalContentProps) {
  const {
    mode,
    onDismiss,
    sampleImageURL,
    sampleFilename,
    defaultFieldValues,
  } = props;
  const { localized } = useLocale();

  const [stem, suffix] = useMemo(() => {
    const dotIndex = sampleFilename.lastIndexOf(".");
    return [sampleFilename.slice(0, dotIndex), sampleFilename.slice(dotIndex)];
  }, [sampleFilename]);

  const [fieldValues, setFieldValues] = useState(defaultFieldValues);
  const canSubmit = useMemo(
    () =>
      fieldValues.invoice_number !== "" &&
      fieldValues.total_amount !== "" &&
      fieldValues.date !== "",
    [fieldValues]
  );

  const handleInvoiceNumberOnChange = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.currentTarget.value;
      setFieldValues(f => ({ ...f, invoice_number: value }));
    },
    []
  );

  const handleTotalAmountOnChange = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.currentTarget.value;
      setFieldValues(f => ({ ...f, total_amount: value }));
    },
    []
  );

  const dateValue = useMemo(() => {
    if (fieldValues.date === "") {
      return undefined;
    }
    return DateTime.fromISO(fieldValues.date).toJSDate();
  }, [fieldValues.date]);
  const handleDateOnSelectDate = useCallback(
    (date: Date | null | undefined) => {
      setFieldValues(f => ({
        ...f,
        date: date == null ? "" : DateTime.fromJSDate(date).toISODate(),
      }));
    },
    []
  );
  const handleFormatDate = useCallback((date: Date | undefined) => {
    if (date == null) {
      return "";
    }
    return DateTime.fromJSDate(date).toISODate();
  }, []);

  const handleOnCancel = useCallback(() => onDismiss(null), [onDismiss]);
  const handleOnSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onDismiss(fieldValues);
    },
    [onDismiss, fieldValues]
  );

  return (
    <form
      className="flex gap-x-5 w-[calc(100vw-96px)] h-[calc(100vh-158px)] mt-0.5"
      onSubmit={handleOnSubmit}
    >
      <div
        className={cn(
          "flex-1 min-w-0 flex flex-col",
          "bg-gray-100 border-solid border border-gray-200"
        )}
      >
        <p
          className={cn(
            "flex flex-row items-center px-5 py-3",
            "bg-white text-gray-800 text-base font-semibold"
          )}
        >
          <span className="truncate">{stem}</span>
          <span>{suffix}</span>
        </p>
        <ImageViewer src={sampleImageURL} zoomControlEnabled={true} />
      </div>
      <div className="flex-1 min-w-0 flex flex-col gap-y-3">
        <p className="mb-2 text-base text-gray-700">
          <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.invoice_number.instruction" />
        </p>
        <TextField
          label={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.invoice_number.label"
          )}
          placeholder={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.invoice_number.placeholder"
          )}
          value={fieldValues.invoice_number}
          onChange={handleInvoiceNumberOnChange}
        />
        <TextField
          label={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.total_amount.label"
          )}
          placeholder={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.total_amount.placeholder"
          )}
          value={fieldValues.total_amount}
          onChange={handleTotalAmountOnChange}
        />
        <DatePicker
          label={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.date.label"
          )}
          placeholder={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.date.placeholder"
          )}
          onSelectDate={handleDateOnSelectDate}
          value={dateValue}
          formatDate={handleFormatDate}
        />

        <DialogFooter className="mt-auto">
          <DefaultButton
            onClick={handleOnCancel}
            text={localized(
              mode === "add" ? "common.discard" : "common.cancel"
            )}
          />
          <PrimaryButton
            type="submit"
            text={localized(
              mode === "add"
                ? "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.add"
                : "common.update"
            )}
            disabled={!canSubmit}
          />
        </DialogFooter>
      </div>
    </form>
  );
}
