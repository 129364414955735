import { IIconProps, IconButton } from "@fluentui/react";
import cn from "classnames";
import React, { useCallback, useMemo } from "react";

interface ImageThumbnailCardProps {
  className?: string;
  imageURL: string;
  filename: string;

  renderInfoPanel?: () => React.ReactNode;
  onDelete?: () => void;
  onPress?: () => void;
}

const iconPropsDelete: IIconProps = {
  iconName: "Delete",
  className: "w-3 h-3 text-xs leading-none",
};

export function ImageThumbnailCard(props: ImageThumbnailCardProps) {
  const { className, imageURL, filename, renderInfoPanel, onDelete, onPress } =
    props;

  const handleButtonOnClick = useCallback(() => {
    onPress?.();
  }, [onPress]);

  const handleDeleteOnClick = useCallback(() => {
    onDelete?.();
  }, [onDelete]);

  const [stem, suffix] = useMemo(() => {
    const dotIndex = filename.lastIndexOf(".");
    return [filename.slice(0, dotIndex), filename.slice(dotIndex)];
  }, [filename]);

  return (
    <div
      className={cn(
        className,
        "grid grid-rows-[auto_minmax(0,_1fr)_auto] grid-cols-1",
        "shadow-elevation4 overflow-hidden",
        "rounded-sm",
        "[&_.meta]:opacity-0 [&_.meta]:hover:opacity-90 [&_.meta]:has-[:focus-visible]:opacity-90",
        "outline outline-0 has-[.image-button:focus-visible]:outline-primary-500 has-[.image-button:focus-visible]:outline-2"
      )}
    >
      {onPress != null ? (
        <button
          className={cn(
            "image-button",
            "col-start-1 row-start-1 row-span-2 w-full h-full",
            "border-none outline-none cursor-pointer"
          )}
          onClick={handleButtonOnClick}
        >
          <img className="w-full h-full object-contain" src={imageURL} />
        </button>
      ) : (
        <img
          className="col-start-1 row-start-1 row-span-2 w-full h-full object-contain"
          src={imageURL}
        />
      )}
      <div
        className={cn(
          "meta",
          "col-start-1 row-start-1",
          "flex items-center pl-1.5 py-0.5 min-h-7",
          "bg-white text-gray-800 text-xxs leading-normal"
        )}
      >
        <span className="truncate">{stem}</span>
        <span className="mr-1.5">{suffix}</span>
        {onDelete != null ? (
          <IconButton
            className="ml-auto w-6 h-6 text-red-700 hover:text-red-700"
            iconProps={iconPropsDelete}
            onClick={handleDeleteOnClick}
          />
        ) : null}
      </div>
      {renderInfoPanel ? (
        <div className="col-start-1 row-start-3 bg-white">
          {renderInfoPanel()}
        </div>
      ) : null}
    </div>
  );
}
