import React from "react";
import { useSelector } from "react-redux";

import { UserFeatureFlag } from "../constants";
import { RootState } from "../redux/types";
import { useAppSelector } from "./redux";

export function useUserSetting() {
  const isUsingCustomCredentials = useSelector((state: RootState) =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.UseCustomCredentials)
  );
  const isGoogleServiceAccoutKeySet = useSelector(
    (state: RootState) => state.resourceOwner.isGoogleOcrEngineSet
  );
  const isAzureSubscriptionKeySet = useSelector(
    (state: RootState) => state.resourceOwner.isAzureOcrEngineSet
  );

  return {
    isUsingCustomCredentials,
    isGoogleServiceAccoutKeySet,
    isAzureSubscriptionKeySet,
  };
}

export function useUserInfo() {
  const userInfo = useAppSelector(state => state.user.userInfo);
  const currentUser = useAppSelector(state => state.user.currentUser);

  const email = userInfo?.email ?? currentUser?.email;
  const userId = userInfo?.sub ?? currentUser?.id;

  const displayName = React.useMemo(() => {
    const isEmpty = (str: string | undefined | null) => {
      return str == null || str.trim() === "";
    };
    let surveyFormName = undefined;
    try {
      const surveyForm = JSON.parse(
        userInfo?.customAttributes["survey_form"] as string
      );
      surveyFormName = surveyForm.name;
    } catch {}

    return !isEmpty(userInfo?.name)
      ? userInfo?.name
      : !isEmpty(surveyFormName)
      ? surveyFormName
      : currentUser?.username ?? "";
  }, [currentUser?.username, userInfo?.customAttributes, userInfo?.name]);

  return {
    displayName,
    email,
    userId,
  };
}
