import produce from "immer";

import {
  FallbackTokenGroup,
  FieldReplacement,
  InfoField,
  MatchingRule,
  MerchantPatternMatching,
  MerchantPatternMatchingAuxData,
} from "../../advancedPatternMatching";
import {
  CellDataType,
  HeaderGroupKey,
  LocalizedHeader,
  MenuItem,
  RowData,
  SettingCellData,
  TagAutoCompleteCellData,
  TagCellData,
  TagKey,
  TokenCellData,
  TokenCellDefaultValue,
  isEmpty,
  makeSettingCell,
  makeTagAutoCompleteCell,
  makeTagCell,
  makeTokenCell,
} from "../../advancedTokenSetup/table";
import { AdvancedMerchantPatternMatchingCSVReader } from "./merchantCSVReader";
import { MerchantPatternMatchingCSVWriter } from "./merchantCSVWriter";

const POSITIVE_TOKEN_COUNT = 2;
const NEGATIVE_TOKEN_COUNT = 2;
const FALLBACK_CUSTOM_TOKEN_COUNT = 1;

export const defaultLLMSettings = {
  llm_parameters: {
    should_preserve_horizontal_whitespace: true,
    should_preserve_vertial_whitespace: true,
  },
};

export const baseTableHeadersMap: Record<MenuItem, LocalizedHeader[]> = {
  [MenuItem.TagMall]: [
    {
      type: CellDataType.TagCellData,
      labelId: "advance_token_setup.nav_menu.tag.mall_id",
      icon: "tag",
      group: HeaderGroupKey.TagGroup,
    },
  ],
  [MenuItem.TagMerchant]: [
    {
      type: CellDataType.TagCellData,
      labelId: "advance_token_setup.nav_menu.tag.merchant_id",
      icon: "tag",
      group: HeaderGroupKey.TagGroup,
    },
  ],
  [MenuItem.ExactMatchRule]: [
    {
      type: CellDataType.TagAutoCompleteCellData,
      labelId: "advance_token_setup.nav_menu.tag.merchant_id",
      icon: "tag",
      group: HeaderGroupKey.ReturningTags,
    },
    {
      type: CellDataType.TagAutoCompleteCellData,
      labelId: "advance_token_setup.nav_menu.tag.mall_id",
      icon: "tag",
      group: HeaderGroupKey.ReturningTags,
    },
    {
      type: CellDataType.TokenCellData,
      labelId: "advance_token_setup_table.header.brand_name",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
      fieldType: "brand_name",
    },
    {
      type: CellDataType.TokenCellData,
      labelId: "advance_token_setup_table.header.email",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
      fieldType: "email",
    },
    {
      type: CellDataType.TokenCellData,
      labelId: "advance_token_setup_table.header.phone_number",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
      fieldType: "phone_number",
    },
    {
      type: CellDataType.TokenCellData,
      labelId: "advance_token_setup_table.header.fax_number",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
      fieldType: "fax_number",
    },
    {
      type: CellDataType.TokenCellData,
      labelId: "advance_token_setup_table.header.url",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
      fieldType: "url",
    },
  ],
  [MenuItem.ClosestMatchRuleMall]: [
    {
      type: CellDataType.TagAutoCompleteCellData,
      labelId: "advance_token_setup.nav_menu.tag.mall_id",
      icon: "tag",
      group: HeaderGroupKey.ReturningTags,
    },
  ],
  [MenuItem.ClosestMatchRuleMerchant]: [
    {
      type: CellDataType.TagAutoCompleteCellData,
      labelId: "advance_token_setup.nav_menu.tag.merchant_id",
      icon: "tag",
      group: HeaderGroupKey.ReturningTags,
    },
  ],
  [MenuItem.FieldReplacementMall]: [
    {
      type: CellDataType.TagAutoCompleteCellData,
      labelId: "advance_token_setup.nav_menu.tag.mall_id",
      icon: "tag",
      group: HeaderGroupKey.ReturningTags,
    },
    {
      type: CellDataType.SettingCellData,
      labelId: "advance_token_setup_table.header.invoice_number",
      icon: "hand_writing",
      group: HeaderGroupKey.Field,
    },
    {
      type: CellDataType.SettingCellData,
      labelId: "advance_token_setup_table.header.total_amount",
      icon: "hand_writing",
      group: HeaderGroupKey.Field,
    },
    {
      type: CellDataType.SettingCellData,
      labelId: "advance_token_setup_table.header.date",
      icon: "hand_writing",
      group: HeaderGroupKey.Field,
      target: "date",
    },
  ],
  [MenuItem.FieldReplacementMerchant]: [
    {
      type: CellDataType.TagAutoCompleteCellData,
      labelId: "advance_token_setup.nav_menu.tag.merchant_id",
      icon: "tag",
      group: HeaderGroupKey.ReturningTags,
    },
    {
      type: CellDataType.SettingCellData,
      labelId: "advance_token_setup_table.header.invoice_number",
      icon: "hand_writing",
      group: HeaderGroupKey.Field,
      target: "invoice_number",
    },
    {
      type: CellDataType.SettingCellData,
      labelId: "advance_token_setup_table.header.total_amount",
      icon: "hand_writing",
      group: HeaderGroupKey.Field,
      target: "total_amount",
    },
    {
      type: CellDataType.SettingCellData,
      labelId: "advance_token_setup_table.header.date",
      icon: "hand_writing",
      group: HeaderGroupKey.Field,
      target: "date",
    },
  ],
};

export const defaultTableHeadersMap = {
  ...baseTableHeadersMap,
  [MenuItem.ClosestMatchRuleMerchant]: [
    ...baseTableHeadersMap.ClosestMatchRuleMerchant,
    ...new Array(FALLBACK_CUSTOM_TOKEN_COUNT).fill({
      type: CellDataType.TokenCellData,
      labelId: "advance_token_setup_table.header.custom_tokens",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
    }),
  ],
  [MenuItem.ClosestMatchRuleMall]: [
    ...baseTableHeadersMap.ClosestMatchRuleMall,
    ...new Array(FALLBACK_CUSTOM_TOKEN_COUNT).fill({
      type: CellDataType.TokenCellData,
      labelId: "advance_token_setup_table.header.custom_tokens",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
    }),
  ],
  [MenuItem.ExactMatchRule]: [
    ...baseTableHeadersMap.ExactMatchRule,
    ...new Array(POSITIVE_TOKEN_COUNT).fill({
      type: CellDataType.RegexTokenCellData,
      labelId: "advance_token_setup_table.header.positive_tokens",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
      fieldType: "positive_token",
    }),
    ...new Array(POSITIVE_TOKEN_COUNT).fill({
      type: CellDataType.RegexTokenCellData,
      labelId: "advance_token_setup_table.header.negative_tokens",
      icon: "sip_move",
      group: HeaderGroupKey.MatchingTokens,
      fieldType: "negative_token",
    }),
  ],
};

function makeFieldReplacementRows(
  tagKey: TagKey,
  fieldReplacements: FieldReplacement[],
  modifiedAt: number
): RowData[] {
  const tagConfigs = fieldReplacements.filter(f => f.tags[tagKey]);
  const tags = Array.from(new Set(tagConfigs.map(f => f.tags[tagKey] ?? "")));

  return tags.map((t, i) => {
    return {
      order: i + 1,
      modifiedAt,
      data: [
        makeTagAutoCompleteCell(t, tagKey),
        makeSettingCell(
          "invoice_number",
          tagConfigs
            .filter(c => t === c.tags[tagKey])
            .find(r => r.target === "invoice_number")
        ),
        makeSettingCell(
          "total_amount",
          tagConfigs
            .filter(c => t === c.tags[tagKey])
            .find(r => r.target === "total_amount")
        ),
        makeSettingCell(
          "date",
          tagConfigs
            .filter(c => t === c.tags[tagKey])
            .find(r => r.target === "date")
        ),
      ],
    };
  });
}

function parseTags(data: RowData[]): string[] {
  return data
    .map(t =>
      t.data
        .filter(d => d.type === CellDataType.TagCellData)
        .map(d => (d as TagCellData).tag)
    )
    .flat();
}
function parseFallBackTokenGroups(data: RowData[]): FallbackTokenGroup[] {
  return data
    .map(r => {
      const tagData = r.data.find(
        d => d.type === CellDataType.TagAutoCompleteCellData
      );
      if (tagData && tagData.type === CellDataType.TagAutoCompleteCellData) {
        return {
          tag: tagData.tag,
          tokens: r.data
            .filter(d => d.type === CellDataType.TokenCellData)
            .map(d => {
              const token = (d as TokenCellData).token;
              return {
                value: token.pattern,
                is_exact_match_only: token.isExactMatch ?? false,
                is_ignore_white_space: token.isClearSpace ?? false,
              };
            }),
        };
      }
      return {
        tag: "",
        tokens: [],
      };
    })
    .filter(r => r.tokens.length > 0);
}

function parseMatchingRules(data: RowData[]): MatchingRule[] {
  return data.map(r => {
    const merchantTag = r.data.find(
      d =>
        d.type === CellDataType.TagAutoCompleteCellData &&
        d.tagKey === "merchant_id"
    );
    const mallTag = r.data.find(
      d =>
        d.type === CellDataType.TagAutoCompleteCellData &&
        d.tagKey === "mall_id"
    );

    return {
      match_mode: "all",
      tags: {
        merchant_id: merchantTag
          ? (merchantTag as TagAutoCompleteCellData).tag
          : "",
        mall_id: mallTag ? (mallTag as TagAutoCompleteCellData).tag : "",
      },
      infos: r.data
        .filter(d => d.type === CellDataType.TokenCellData && !isEmpty(d))
        .map(d => {
          const token = (d as TokenCellData).token;
          return {
            type: token.type ?? "",
            pattern: token.pattern,
            is_ignore_white_space: token.isClearSpace,
            is_exact_match_only: token.isExactMatch,
            is_regex: token.isRegex,
            is_negative_match: token.type === "negative_token",
          } as InfoField;
        }),
    };
  });
}

function parseFieldReplacements(data: RowData[]): FieldReplacement[] {
  return data
    .flatMap((r): (FieldReplacement | undefined)[] | undefined => {
      const tagData = r.data.find(
        d => d.type === CellDataType.TagAutoCompleteCellData
      );
      const tag = tagData ? (tagData as TagAutoCompleteCellData) : undefined;

      if (tagData) {
        return r.data
          .filter(d => d.type === CellDataType.SettingCellData)
          .map(d => {
            const setting = d as SettingCellData;
            if (tag && setting.config) {
              return {
                tags: {
                  [tag.tagKey]: tag.tag,
                },
                type: setting.config.type,
                target: setting.target,
                config: setting.config.config,
              };
            }
            return undefined;
          });
      }
      return undefined;
    })
    .filter(t => t !== undefined) as FieldReplacement[];
}

const header = {
  fromStorage: (
    config: MerchantPatternMatching
  ): Record<MenuItem, LocalizedHeader[]> => {
    const positiveTokenCount = (
      config.matching_rules?.map(
        i =>
          i.infos?.filter(
            i => i.type === "positive_token" && i.is_negative_match === false
          ).length
      ) || []
    ).reduce((acc, tokenCount) => {
      return Math.max(tokenCount ?? 0, acc ?? 0);
    }, POSITIVE_TOKEN_COUNT);
    const negativeTokenCount = (
      config.matching_rules?.map(
        i => i.infos?.filter(i => i.is_negative_match === true).length
      ) || []
    ).reduce((acc, tokenCount) => {
      return Math.max(tokenCount ?? 0, acc ?? 0);
    }, NEGATIVE_TOKEN_COUNT);
    const merchantFallbackTokenCount = FALLBACK_CUSTOM_TOKEN_COUNT;
    const mallFallbackTokenCount = FALLBACK_CUSTOM_TOKEN_COUNT;
    return {
      ...baseTableHeadersMap,
      [MenuItem.ClosestMatchRuleMerchant]: [
        ...baseTableHeadersMap.ClosestMatchRuleMerchant,
        ...new Array(merchantFallbackTokenCount).fill({
          type: CellDataType.TokenCellData,
          labelId: "advance_token_setup_table.header.custom_tokens",
          icon: "sip_move",
          group: HeaderGroupKey.MatchingTokens,
        }),
      ],
      [MenuItem.ClosestMatchRuleMall]: [
        ...baseTableHeadersMap.ClosestMatchRuleMall,
        ...new Array(mallFallbackTokenCount).fill({
          type: CellDataType.TokenCellData,
          labelId: "advance_token_setup_table.header.custom_tokens",
          icon: "sip_move",
          group: HeaderGroupKey.MatchingTokens,
        }),
      ],
      [MenuItem.ExactMatchRule]: [
        ...baseTableHeadersMap.ExactMatchRule,
        ...new Array(positiveTokenCount).fill({
          type: CellDataType.RegexTokenCellData,
          labelId: "advance_token_setup_table.header.positive_tokens",
          icon: "sip_move",
          group: HeaderGroupKey.MatchingTokens,
          fieldType: "positive_token",
        }),
        ...new Array(negativeTokenCount).fill({
          type: CellDataType.RegexTokenCellData,
          labelId: "advance_token_setup_table.header.negative_tokens",
          icon: "sip_move",
          group: HeaderGroupKey.MatchingTokens,
          fieldType: "negative_token",
        }),
      ],
    };
  },
  fromCSVHeader(table: MenuItem, header: string[]): LocalizedHeader[] {
    const merchantFallbackTokenCount = FALLBACK_CUSTOM_TOKEN_COUNT;
    const mallFallbackTokenCount = FALLBACK_CUSTOM_TOKEN_COUNT;
    const positiveTokenCount = Math.max(
      header.filter(h => h === "positive_token").length,
      POSITIVE_TOKEN_COUNT
    );
    const negativeTokenCount = Math.max(
      header.filter(h => h === "negative_token").length,
      NEGATIVE_TOKEN_COUNT
    );
    return {
      ...baseTableHeadersMap,
      [MenuItem.ClosestMatchRuleMerchant]: [
        ...baseTableHeadersMap.ClosestMatchRuleMerchant,
        ...new Array(merchantFallbackTokenCount).fill({
          type: CellDataType.TokenCellData,
          labelId: "advance_token_setup_table.header.custom_tokens",
          icon: "sip_move",
          group: HeaderGroupKey.MatchingTokens,
        }),
      ],
      [MenuItem.ClosestMatchRuleMall]: [
        ...baseTableHeadersMap.ClosestMatchRuleMall,
        ...new Array(mallFallbackTokenCount).fill({
          type: CellDataType.TokenCellData,
          labelId: "advance_token_setup_table.header.custom_tokens",
          icon: "sip_move",
          group: HeaderGroupKey.MatchingTokens,
        }),
      ],
      [MenuItem.ExactMatchRule]: [
        ...baseTableHeadersMap.ExactMatchRule,
        ...new Array(positiveTokenCount).fill({
          type: CellDataType.RegexTokenCellData,
          labelId: "advance_token_setup_table.header.positive_tokens",
          icon: "sip_move",
          group: HeaderGroupKey.MatchingTokens,
          fieldType: "positive_token",
        }),
        ...new Array(negativeTokenCount).fill({
          type: CellDataType.RegexTokenCellData,
          labelId: "advance_token_setup_table.header.negative_tokens",
          icon: "sip_move",
          group: HeaderGroupKey.MatchingTokens,
          fieldType: "negative_token",
        }),
      ],
    }[table];
  },
};

export const MerchantPatternMatchingMapper = {
  fromStorage(
    config: MerchantPatternMatching,
    modifiedAt: number = new Date().getTime()
  ): [
    headers: Record<MenuItem, LocalizedHeader[]>,
    tables: Record<MenuItem, RowData[]>,
    auxData: MerchantPatternMatchingAuxData
  ] {
    const positiveTokenCount =
      (
        config.matching_rules?.map(
          i =>
            i.infos?.filter(
              i => i.type === "positive_token" && i.is_negative_match === false
            ).length
        ) || []
      ).reduce((acc, tokenCount) => {
        return Math.max(tokenCount ?? 0, acc ?? 0);
      }, POSITIVE_TOKEN_COUNT) ?? POSITIVE_TOKEN_COUNT;
    const negativeTokenCount =
      (
        config.matching_rules?.map(
          i => i.infos?.filter(i => i.is_negative_match === true).length
        ) || []
      ).reduce((acc, tokenCount) => {
        return Math.max(tokenCount ?? 0, acc ?? 0);
      }, NEGATIVE_TOKEN_COUNT) ?? NEGATIVE_TOKEN_COUNT;

    return [
      header.fromStorage(config),
      {
        [MenuItem.TagMall]:
          config.tags?.mall?.map((t, i) => ({
            modifiedAt,
            data: [makeTagCell(t, "mall_id")],
            order: i + 1,
          })) ?? [],
        [MenuItem.TagMerchant]:
          config.tags?.merchant?.map((t, i) => ({
            modifiedAt,
            data: [makeTagCell(t, "merchant_id")],
            order: i + 1,
          })) ?? [],
        [MenuItem.ClosestMatchRuleMerchant]: (
          config.fallback_token_groups?.merchant_id ?? []
        ).map((tokenGroup, i) => ({
          modifiedAt,
          data: [
            makeTagAutoCompleteCell(tokenGroup.tag, "merchant_id"),
            ...tokenGroup.tokens.slice(0, 1).map(
              token =>
                ({
                  type: CellDataType.TokenCellData,
                  token: {
                    pattern: token.value,
                    isExactMatch: token.is_exact_match_only,
                    isClearSpace: token.is_ignore_white_space,
                  },
                } as TokenCellData)
            ),
          ],
          order: i + 1,
        })),
        [MenuItem.ClosestMatchRuleMall]: (
          config.fallback_token_groups?.mall_id ?? []
        ).map((tokenGroup, i) => ({
          modifiedAt,
          data: [
            makeTagAutoCompleteCell(tokenGroup.tag, "mall_id"),
            ...tokenGroup.tokens.slice(0, 1).map(
              token =>
                ({
                  type: CellDataType.TokenCellData,
                  token: {
                    pattern: token.value,
                    isExactMatch: token.is_exact_match_only,
                    isClearSpace: token.is_ignore_white_space,
                  },
                } as TokenCellData)
            ),
          ],
          order: i + 1,
        })),
        [MenuItem.FieldReplacementMerchant]: makeFieldReplacementRows(
          "merchant_id",
          config.field_replacements ?? [],
          modifiedAt
        ),
        [MenuItem.FieldReplacementMall]: makeFieldReplacementRows(
          "mall_id",
          config.field_replacements ?? [],
          modifiedAt
        ),
        [MenuItem.ExactMatchRule]: (config.matching_rules ?? []).map(
          (matchingRule, i) => {
            let postiveTokens = (matchingRule.infos ?? []).filter(
              i => i.type === "positive_token" && i.is_negative_match === false
            );
            postiveTokens = postiveTokens.concat(
              new Array(positiveTokenCount - postiveTokens.length).fill(
                undefined
              )
            );
            let negativeTokens = (matchingRule.infos ?? []).filter(
              i => i.type === "negative_token" && i.is_negative_match === true
            );
            negativeTokens = negativeTokens.concat(
              new Array(negativeTokenCount - negativeTokens.length).fill(
                undefined
              )
            );

            return {
              modifiedAt,
              data: [
                makeTagAutoCompleteCell(
                  matchingRule.tags.merchant_id ?? "",
                  "merchant_id"
                ),
                makeTagAutoCompleteCell(
                  matchingRule.tags.mall_id ?? "",
                  "mall_id"
                ),
                makeTokenCell(
                  "brand_name",
                  matchingRule.infos?.find(i => i.type === "brand_name"),
                  TokenCellDefaultValue["brand_name"]
                ),
                makeTokenCell(
                  "email",
                  matchingRule.infos?.find(i => i.type === "email"),
                  TokenCellDefaultValue["email"]
                ),
                makeTokenCell(
                  "phone_number",
                  matchingRule.infos?.find(i => i.type === "phone_number"),
                  TokenCellDefaultValue["phone_number"]
                ),
                makeTokenCell(
                  "fax_number",
                  matchingRule.infos?.find(i => i.type === "fax_number"),
                  TokenCellDefaultValue["fax_number"]
                ),
                makeTokenCell(
                  "url",
                  matchingRule.infos?.find(i => i.type === "url"),
                  TokenCellDefaultValue["url"]
                ),
                ...postiveTokens.map(m =>
                  makeTokenCell(
                    "positive_token",
                    m,
                    TokenCellDefaultValue["positive_token"]
                  )
                ),
                ...negativeTokens.map(m =>
                  makeTokenCell(
                    "negative_token",
                    m,
                    TokenCellDefaultValue["negative_token"]
                  )
                ),
              ],
              order: i + 1,
            };
          }
        ),
      },
      {
        llm_settings: produce(config.llm_settings || {}, draftLLMSettings => {
          if (draftLLMSettings["default"] === undefined) {
            draftLLMSettings["default"] = { ...defaultLLMSettings };
          }
        }),
        field_replacement_selector_axi_source:
          config.field_replacement_selector_axi_source,
      },
    ];
  },

  toStorage(
    data: Record<MenuItem, RowData[]>,
    auxData: MerchantPatternMatchingAuxData
  ): MerchantPatternMatching {
    const result = {
      tags: {
        mall: parseTags(data.TagMall),
        merchant: parseTags(data.TagMerchant),
      },
      fallback_token_groups: {
        mall_id: parseFallBackTokenGroups(data.ClosestMatchRuleMall),
        merchant_id: parseFallBackTokenGroups(data.ClosestMatchRuleMerchant),
      },
      matching_rules: parseMatchingRules(data.ExactMatchRule),
      field_replacements: parseFieldReplacements(
        data.FieldReplacementMall
      ).concat(parseFieldReplacements(data.FieldReplacementMerchant)),
      ...auxData,
    };

    // Remove unused LLM settings
    if (result.llm_settings != null) {
      const llmSettingsIds = new Set();
      for (const config of result.field_replacements) {
        if (config.type === "llm") {
          llmSettingsIds.add((config.config as any).llm_settings_id);
        }
      }
      for (const key of Object.keys(result.llm_settings)) {
        if (!llmSettingsIds.has(key)) {
          delete result.llm_settings[key];
        }
      }
    }

    return result;
  },
  fromCSV: (
    tableType: MenuItem,
    data: string[][],
    modifiedAt: number = new Date().getTime()
  ): [headers: LocalizedHeader[], tables: RowData[]] => {
    const headers = data[0];
    const reader = new AdvancedMerchantPatternMatchingCSVReader();

    const rowData = reader.process(tableType, data, modifiedAt);

    return [header.fromCSVHeader(tableType, headers), rowData];
  },

  toCSV: (
    menuItem: MenuItem,
    rowData: RowData[],
    options?: { distinctHeader: boolean }
  ): string[][] => {
    const writer = new MerchantPatternMatchingCSVWriter(options);
    return writer.processRows(menuItem, rowData);
  },
};
