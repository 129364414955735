import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";

import { apiClient } from "../apiClient";
import { useAppDispatch } from "../hooks/redux";
import { type OAuthCredentialList } from "../types/oauth";

export const OAuthCredentialDeleted = createAction(
  "oauth/credentialDeleted",
  (id: string) => ({ payload: { id } })
);

export const OAuthGotCredentialList = createAction<OAuthCredentialList>(
  "oauth/gotCredentialList"
);

export type UserAction =
  | ReturnType<typeof OAuthCredentialDeleted>
  | ReturnType<typeof OAuthGotCredentialList>;

export interface OAuthError {
  error: string;
  errorDescription: string | null;
}

export interface OAuthCallback {
  error: string | null;
  errorDescription: string | null;
  nonce: string | null;
  email: string | null;
}

const MAX_OAUTH_CREDENTIAL_PAGE_SIZE = 100;

export function useOAuthActionCreator() {
  const dispatch = useAppDispatch();

  const deleteOAuthCredential = useCallback(
    async (id: string) => {
      await apiClient.deleteOAuthCredential(id);
      dispatch(OAuthCredentialDeleted(id));
    },
    [dispatch]
  );

  const listOAuthCredentials = useCallback(
    async (
      resourceServer: string,
      offset: number,
      size: number,
      signal: AbortSignal
    ) => {
      const oauthCredentials = await apiClient.listOAuthCredentials(
        resourceServer,
        offset,
        size,
        signal
      );
      dispatch(OAuthGotCredentialList(oauthCredentials));
      return oauthCredentials;
    },
    [dispatch]
  );

  const listAllOAuthCredentials = useCallback(
    async (resourceServer: string) => {
      const allCredentials: OAuthCredentialList = {
        oauthCredentials: [],
        pageInfo: { offset: 0, totalCount: 0 },
      };

      while (true) {
        const credentials = await apiClient.listOAuthCredentials(
          resourceServer,
          allCredentials.pageInfo.offset,
          MAX_OAUTH_CREDENTIAL_PAGE_SIZE
        );
        allCredentials.oauthCredentials = [
          ...allCredentials.oauthCredentials,
          ...credentials.oauthCredentials,
        ];
        allCredentials.pageInfo.totalCount += credentials.pageInfo.totalCount;
        allCredentials.pageInfo.offset = credentials.pageInfo.offset;

        if (
          allCredentials.oauthCredentials.length >=
          credentials.pageInfo.totalCount
        ) {
          break;
        }
      }

      dispatch(OAuthGotCredentialList(allCredentials));
      return allCredentials;
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      listOAuthCredentials,
      deleteOAuthCredential,
      listAllOAuthCredentials,
    }),
    [deleteOAuthCredential, listOAuthCredentials, listAllOAuthCredentials]
  );
}
