import {
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
} from "@fluentui/react";
import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router";

import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { useTeamActionCreator } from "../actions/team";
import ConfirmModal from "../components/ConfirmModal";
import CreateRenamWorkerTokenModal from "../components/CreateRenameWorkerTokenModal";
import CreateRenameTeamModal from "../components/CreateTeamModal";
import InviteTeamUserModal from "../components/InviteTeamUserModal";
import { Layout, Main, Top } from "../components/Layout";
import { useUrlWithTeamRef } from "../components/LinkWithTeamRef";
import LoadingModal from "../components/LoadingModal";
import {
  TabType,
  TeamLayout,
  TeamMemberTab,
  WorkerTokenTab,
} from "../components/Team";
import { PrimaryButton } from "../components/WrappedMSComponents/Buttons";
import { AppConfig } from "../config";
import { PLAN_NAMES } from "../constants";
import HeaderContainer from "../containers/Header";
import { useLocale } from "../contexts/locale";
import errors, { FOCRError } from "../errors";
import { useRemoteData } from "../hooks/remoteData";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { Permission, getTeamRoleMessageId } from "../types/team";

function useDeleteTeamModal() {
  const teamPlan = useSelector(
    (state: RootState) => state.resourceOwner.planName
  );
  const { deleteTeam } = useTeamActionCreator();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const toast = useToast();
  const { localized } = useLocale();
  const navigate = useNavigate();
  const [isDeleteTeamModalOpened, setIsDeleteTeamModalOpened] =
    React.useState(false);

  const [isTeamNotFreeModalOpened, setIsTeamNotFreeModalOpened] =
    React.useState(false);

  const openDeleteTeamModal = React.useCallback(() => {
    if (teamPlan !== PLAN_NAMES.free) {
      setIsTeamNotFreeModalOpened(true);
      return;
    }

    setIsDeleteTeamModalOpened(true);
  }, [teamPlan]);

  const closeDeleteTeamModal = React.useCallback(() => {
    setIsTeamNotFreeModalOpened(false);
    setIsDeleteTeamModalOpened(false);
  }, []);

  const onDeleteTeam = React.useCallback(async () => {
    closeDeleteTeamModal();
    setIsDeleting(true);
    try {
      await deleteTeam();
      navigate("/form");
    } catch (e) {
      if (e instanceof FOCRError) {
        toast.error(e.messageId);
      } else {
        toast.error("team.delete.fail_message");
      }
    } finally {
      setIsDeleting(false);
    }
  }, [closeDeleteTeamModal, deleteTeam, navigate, toast]);

  const teamPlanIsNotFreeDialogContentProps: IDialogContentProps =
    React.useMemo(
      () => ({
        type: DialogType.normal,
        title: localized("team.delete.plan_is_not_free.title"),
        subText: localized("team.delete.plan_is_not_free.message"),
        onClose: closeDeleteTeamModal,
        showCloseButton: false,
      }),
      [closeDeleteTeamModal, localized]
    );

  return React.useMemo(
    () => ({
      isDeleteTeamModalOpened,
      openDeleteTeamModal,
      closeDeleteTeamModal,
      isDeleting,
      onDeleteTeam,
      isTeamNotFreeModalOpened,
      teamPlanIsNotFreeDialogContentProps,
    }),
    [
      isDeleteTeamModalOpened,
      isDeleting,
      onDeleteTeam,
      openDeleteTeamModal,
      closeDeleteTeamModal,
      isTeamNotFreeModalOpened,
      teamPlanIsNotFreeDialogContentProps,
    ]
  );
}

function useInviteTeamUserModal() {
  const { inviteTeamUser } = useTeamActionCreator();

  const [isInviteTeamUserModalOpened, setIsInviteTeamUserModalOpened] =
    React.useState(false);
  const openInviteTeamUserModal = React.useCallback(() => {
    setIsInviteTeamUserModalOpened(true);
  }, []);
  const closeInviteTeamUserModal = React.useCallback(() => {
    setIsInviteTeamUserModalOpened(false);
  }, []);
  const onInviteTeamUser = React.useCallback(
    async (email: string, permission: Permission) => {
      return inviteTeamUser(email, permission);
    },
    [inviteTeamUser]
  );

  return React.useMemo(
    () => ({
      isInviteTeamUserModalOpened,
      openInviteTeamUserModal,
      closeInviteTeamUserModal,
      onInviteTeamUser,
    }),
    [
      isInviteTeamUserModalOpened,
      openInviteTeamUserModal,
      closeInviteTeamUserModal,
      onInviteTeamUser,
    ]
  );
}

function useCreateWorkerTokenModal() {
  const { createWorkerToken } = useResourceOwnerActionCreator();

  const resourceOwnerId = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.resourceOwnerId
  );

  const [isCreateWorkerTokenModalOpened, setIsCreateWorkerTokenModalOpened] =
    React.useState(false);

  const openCreateWorkerTokenModal = React.useCallback(() => {
    setIsCreateWorkerTokenModalOpened(true);
  }, []);
  const closeCreateWorkerTokenModal = React.useCallback(() => {
    setIsCreateWorkerTokenModalOpened(false);
  }, []);

  const onCreateWorkerToken = React.useCallback(
    async (name: string) => {
      return createWorkerToken(name, resourceOwnerId);
    },
    [createWorkerToken, resourceOwnerId]
  );

  return React.useMemo(
    () => ({
      isCreateWorkerTokenModalOpened,
      openCreateWorkerTokenModal,
      closeCreateWorkerTokenModal,
      onCreateWorkerToken,
    }),
    [
      isCreateWorkerTokenModalOpened,
      openCreateWorkerTokenModal,
      closeCreateWorkerTokenModal,
      onCreateWorkerToken,
    ]
  );
}

function useRenameWorkerTokenModal() {
  const { updateWorkerToken } = useResourceOwnerActionCreator();

  const [tokenId, setTokenId] = React.useState<string | undefined>();

  const openRenameWorkerTokenModal = React.useCallback(
    (tokenId: string) => setTokenId(tokenId),
    []
  );

  const closeRenameWorkerTokenModal = React.useCallback(() => {
    setTokenId(undefined);
  }, []);

  const onRenameWorkerToken = React.useCallback(
    async (name: string) => {
      if (tokenId) {
        return updateWorkerToken(name, tokenId);
      }
      return;
    },
    [tokenId, updateWorkerToken]
  );

  const isRenameWorkerTokenModalOpened = React.useMemo(
    () => tokenId !== undefined,
    [tokenId]
  );

  return React.useMemo(
    () => ({
      isRenameWorkerTokenModalOpened,
      openRenameWorkerTokenModal,
      closeRenameWorkerTokenModal,
      onRenameWorkerToken,
    }),
    [
      isRenameWorkerTokenModalOpened,
      openRenameWorkerTokenModal,
      closeRenameWorkerTokenModal,
      onRenameWorkerToken,
    ]
  );
}

function useRevokeWorkerTokenModal() {
  const { revokeWorkerToken } = useResourceOwnerActionCreator();

  const [tokenId, setTokenId] = React.useState<string | undefined>();

  const openRevokeWorkerTokenModal = React.useCallback(
    (tokenId: string) => setTokenId(tokenId),
    []
  );

  const closeRevokeWorkerTokenModal = React.useCallback(() => {
    setTokenId(undefined);
  }, []);

  const onRevokeWorkerToken = React.useCallback(async () => {
    if (tokenId) {
      return revokeWorkerToken(tokenId);
    }
    return;
  }, [tokenId, revokeWorkerToken]);

  const isRevokeWorkerTokenModalOpened = React.useMemo(
    () => tokenId !== undefined,
    [tokenId]
  );

  return React.useMemo(
    () => ({
      isRevokeWorkerTokenModalOpened,
      openRevokeWorkerTokenModal,
      closeRevokeWorkerTokenModal,
      onRevokeWorkerToken,
    }),
    [
      isRevokeWorkerTokenModalOpened,
      openRevokeWorkerTokenModal,
      closeRevokeWorkerTokenModal,
      onRevokeWorkerToken,
    ]
  );
}

function useRenameTeamModal() {
  const { renameTeam } = useTeamActionCreator();
  const [isRenameTeamModalOpened, setIsRenameTeamModalOpened] =
    React.useState(false);
  const [isRenaming, setIsRenaming] = React.useState(false);
  const openRenameTeamModal = React.useCallback(() => {
    setIsRenameTeamModalOpened(true);
  }, []);
  const closeRenameTeamModal = React.useCallback(() => {
    setIsRenameTeamModalOpened(false);
  }, []);
  const toast = useToast();
  const onRenameTeam = React.useCallback(
    async (name: string, retrievedAt: string): Promise<string> => {
      closeRenameTeamModal();
      setIsRenaming(true);
      try {
        const { updatedAt } = await renameTeam(name, retrievedAt);
        toast.success("team.rename.success_message", undefined, { team: name });
        return updatedAt;
      } catch (e) {
        if (e instanceof FOCRError) {
          if (e !== errors.ConflictFound) {
            toast.error(e.messageId);
          }
        } else {
          toast.error("team.rename.fail_message");
        }
        return Promise.reject();
      } finally {
        setIsRenaming(false);
      }
    },
    [closeRenameTeamModal, renameTeam, toast]
  );

  return React.useMemo(
    () => ({
      isRenameTeamModalOpened,
      openRenameTeamModal,
      closeRenameTeamModal,
      onRenameTeam,
      isRenaming,
    }),
    [
      isRenameTeamModalOpened,
      openRenameTeamModal,
      closeRenameTeamModal,
      onRenameTeam,
      isRenaming,
    ]
  );
}

function _TeamContainer() {
  const { listTeamMembers } = useTeamActionCreator();

  const resourceOwnerId = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.resourceOwnerId
  );

  const fetchTeamMembersFunction = useCallback(() => {
    return listTeamMembers(resourceOwnerId);
  }, [listTeamMembers, resourceOwnerId]);

  const {
    remoteData: teamMembersData,
    setSuccessfulRemoteDataValue: setTeamMembersData,
    reloadRemoteData: reloadTeamMembersData,
  } = useRemoteData(fetchTeamMembersFunction);

  const listTeamMembersRef = useRef(listTeamMembers);
  listTeamMembersRef.current = listTeamMembers;

  const { setTeamUserPermission, removeTeamUser, removeTeamInvitation } =
    useTeamActionCreator();

  const toast = useToast();
  const { localized } = useLocale();

  const updateUserPermission = useCallback(
    async (userId: string, permission: Permission) => {
      if (teamMembersData.state !== "success") {
        return;
      }

      try {
        const { updatedAt } = await setTeamUserPermission(
          userId,
          permission,
          teamMembersData.value.updatedAt
        );

        setTeamMembersData({
          ...teamMembersData.value,
          updatedAt,
          members: teamMembersData.value.members.map(user => {
            if (user.userId === userId) {
              return {
                ...user,
                permission,
              };
            }
            return user;
          }),
        });

        const userEmail = teamMembersData.value.members.find(
          member => member.userId === userId
        )?.email;
        if (userEmail)
          toast.success("team.role.updated", undefined, {
            email: userEmail,
            role: localized(getTeamRoleMessageId(permission)),
          });
      } catch (e) {
        if (e !== errors.ConflictFound && e !== errors.ConfirmationRejected) {
          toast.error("team.role.failed_to_update");
        }
      }
    },
    [
      localized,
      teamMembersData,
      setTeamMembersData,
      setTeamUserPermission,
      toast,
    ]
  );

  const removeUser = useCallback(
    async (userId: string) => {
      try {
        await removeTeamUser(userId);
      } catch {
        toast.error("team.remove_user.unexpected_error");
        return;
      }

      if (teamMembersData.state !== "success") {
        return;
      }

      toast.success("team.remove_user.success_message");

      setTeamMembersData({
        ...teamMembersData.value,
        members: teamMembersData.value.members.filter(
          user => user.userId !== userId
        ),
      });
    },
    [teamMembersData, removeTeamUser, setTeamMembersData, toast]
  );

  const removeInvitation = useCallback(
    async (invitationId: string) => {
      if (teamMembersData.state !== "success") {
        return;
      }

      try {
        await removeTeamInvitation(invitationId, AppConfig.region);
      } catch (e) {
        if (e instanceof FOCRError) {
          if (e === errors.TeamInvitationNotFound) {
            reloadTeamMembersData();
          }
          toast.error(e.messageId);
        } else {
          toast.error("team.invitation.remove.unexpected_error");
        }
        return;
      }

      toast.success("team.invitation.remove.success_message");

      setTeamMembersData({
        ...teamMembersData.value,
        invitations: teamMembersData.value.invitations.filter(
          invitation => invitation.id !== invitationId
        ),
      });
    },
    [
      reloadTeamMembersData,
      teamMembersData,
      removeTeamInvitation,
      setTeamMembersData,
      toast,
    ]
  );

  const {
    isInviteTeamUserModalOpened,
    openInviteTeamUserModal,
    closeInviteTeamUserModal,
    onInviteTeamUser,
  } = useInviteTeamUserModal();

  const [isInviting, setIsInviting] = React.useState(false);

  const onInvite = useCallback(
    async (email: string, permission: Permission) => {
      closeInviteTeamUserModal();
      setIsInviting(true);

      try {
        const invitationId = await onInviteTeamUser(email, permission);

        toast.success("invite.new.member.success_message", undefined, {
          email,
        });

        if (teamMembersData.state !== "success") {
          return;
        }

        setTeamMembersData({
          ...teamMembersData.value,
          invitations: teamMembersData.value.invitations.concat({
            email,
            permission,
            id: invitationId,
          }),
        });
      } catch (e) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, { email });
        } else {
          toast.error("invite.new.member.fail_message", undefined, { email });
        }
      } finally {
        setIsInviting(false);
      }
    },
    [
      closeInviteTeamUserModal,
      onInviteTeamUser,
      teamMembersData,
      setTeamMembersData,
      toast,
    ]
  );

  const {
    isRenameTeamModalOpened,
    openRenameTeamModal,
    closeRenameTeamModal,
    onRenameTeam,
    isRenaming,
  } = useRenameTeamModal();

  const _onRenameTeam = useCallback(
    async (name: string) => {
      if (teamMembersData.state !== "success") {
        return;
      }
      try {
        const updatedAt = await onRenameTeam(
          name,
          teamMembersData.value.updatedAt
        );

        setTeamMembersData({
          ...teamMembersData.value,
          updatedAt,
        });
      } catch (error) {
        throw error;
      }
    },
    [onRenameTeam, teamMembersData, setTeamMembersData]
  );

  const {
    isDeleteTeamModalOpened,
    openDeleteTeamModal,
    closeDeleteTeamModal,
    onDeleteTeam,
    isTeamNotFreeModalOpened,
    teamPlanIsNotFreeDialogContentProps,
    isDeleting,
  } = useDeleteTeamModal();

  const navigate = useNavigate();

  const teamName = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.teamName
  );

  const { pathname } = useLocation();

  const selectedTab: TabType = React.useMemo(() => {
    if (pathname === "/team/tokens") {
      return "tokens";
    }
    return "members";
  }, [pathname]);

  const membersUrl = useUrlWithTeamRef("/team");
  const tokensUrl = useUrlWithTeamRef("/team/tokens");

  const setSelectedTab = useCallback(
    (tab: TabType) => {
      if (tab === "tokens") {
        navigate(tokensUrl);
        return;
      }
      navigate(membersUrl);
    },
    [navigate, tokensUrl, membersUrl]
  );

  const {
    isCreateWorkerTokenModalOpened,
    openCreateWorkerTokenModal,
    closeCreateWorkerTokenModal,
    onCreateWorkerToken,
  } = useCreateWorkerTokenModal();

  const _onCreateWorkerToken = useCallback(
    async (name: string) => {
      closeCreateWorkerTokenModal();

      try {
        await onCreateWorkerToken(name);

        toast.success("team.tokens.create.success");
      } catch (e) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined);
        } else {
          toast.error("team.tokens.create.unexpected_error");
        }
        throw e;
      }
    },
    [onCreateWorkerToken, closeCreateWorkerTokenModal, toast]
  );

  const {
    isRenameWorkerTokenModalOpened,
    openRenameWorkerTokenModal,
    closeRenameWorkerTokenModal,
    onRenameWorkerToken,
  } = useRenameWorkerTokenModal();

  const _onRenameWorkerToken = useCallback(
    async (name: string) => {
      closeRenameWorkerTokenModal();
      try {
        const token = await onRenameWorkerToken(name);
        if (token) {
          toast.success("team.tokens.rename.success", undefined, {
            name: token.name,
          });
        }
      } catch (e) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined);
        } else {
          toast.error("team.tokens.rename.unexpected_error");
        }
        throw e;
      }
    },
    [onRenameWorkerToken, closeRenameWorkerTokenModal, toast]
  );

  const {
    isRevokeWorkerTokenModalOpened,
    openRevokeWorkerTokenModal,
    closeRevokeWorkerTokenModal,
    onRevokeWorkerToken,
  } = useRevokeWorkerTokenModal();

  const _onRevokeWorkerToken = useCallback(async () => {
    closeRevokeWorkerTokenModal();
    try {
      const token = await onRevokeWorkerToken();
      if (token) {
        toast.success("team.tokens.revoke.success");
      }
    } catch (e) {
      if (e instanceof FOCRError) {
        toast.error(e.messageId, undefined);
      } else {
        toast.error("team.tokens.revoke.unexpected_error");
      }
      throw e;
    }
  }, [onRevokeWorkerToken, closeRevokeWorkerTokenModal, toast]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <>
        <Main hasTop={true}>
          <TeamLayout
            onRenameTeam={openRenameTeamModal}
            onRemoveTeam={openDeleteTeamModal}
            selectedTab={selectedTab}
            onSelectTab={setSelectedTab}
          >
            <Routes>
              <Route
                path=""
                element={
                  <TeamMemberTab
                    onInviteMember={openInviteTeamUserModal}
                    teamMembersData={teamMembersData}
                    setTeamUserPermission={updateUserPermission}
                    removeTeamUser={removeUser}
                    removeInvitation={removeInvitation}
                  />
                }
              />
              <Route
                path="tokens"
                element={
                  <WorkerTokenTab
                    onCreateToken={openCreateWorkerTokenModal}
                    onRenameToken={openRenameWorkerTokenModal}
                    onRevokeToken={openRevokeWorkerTokenModal}
                  />
                }
              />
            </Routes>
          </TeamLayout>

          <InviteTeamUserModal
            isOpen={isInviteTeamUserModalOpened}
            onCancel={closeInviteTeamUserModal}
            onInvite={onInvite}
          />
          <CreateRenamWorkerTokenModal
            type="create"
            isOpen={isCreateWorkerTokenModalOpened}
            onCancel={closeCreateWorkerTokenModal}
            onSubmit={_onCreateWorkerToken}
          />
          <CreateRenamWorkerTokenModal
            type="rename"
            isOpen={isRenameWorkerTokenModalOpened}
            onCancel={closeRenameWorkerTokenModal}
            onSubmit={_onRenameWorkerToken}
          />
          <CreateRenameTeamModal
            defaultName={teamName}
            isRename={true}
            isOpen={isRenameTeamModalOpened}
            onCancel={closeRenameTeamModal}
            onConfirm={_onRenameTeam}
          />
          <LoadingModal
            isOpen={
              teamMembersData.state === "loading" ||
              isInviting ||
              isRenaming ||
              isDeleting
            }
            messageId={
              isInviting
                ? "invite.new.member.inviting"
                : isRenaming
                ? "team.renaming"
                : isDeleting
                ? "team.deleting"
                : undefined
            }
          />
          <ConfirmModal
            isOpen={isDeleteTeamModalOpened}
            modalType={ConfirmModalType.Destory}
            onCancel={closeDeleteTeamModal}
            onConfirm={onDeleteTeam}
            titleId="team.delete"
            actionId="common.delete"
            messageId="team.delete.confirm"
            challenge={teamName}
          />
          <ConfirmModal
            isOpen={isRevokeWorkerTokenModalOpened}
            modalType={ConfirmModalType.Destory}
            onCancel={closeRevokeWorkerTokenModal}
            onConfirm={_onRevokeWorkerToken}
            titleId="team.tokens.revoke.title"
            actionId="team.tokens.button.revoke"
            messageId="team.tokens.revoke.confirm"
          />
          <Dialog
            hidden={!isTeamNotFreeModalOpened}
            dialogContentProps={teamPlanIsNotFreeDialogContentProps}
          >
            <DialogFooter>
              <PrimaryButton
                onClick={closeDeleteTeamModal}
                textId={"common.close"}
              />
            </DialogFooter>
          </Dialog>
        </Main>
      </>
    </Layout>
  );
}

export const TeamContainer = React.memo(_TeamContainer);
export default TeamContainer;
