import * as React from "react";
import { useStore } from "react-redux";

import { AppConfig } from "../config";
import { useAppSelector } from "../hooks/redux";
import { PathParam } from "../models";
import { RootState } from "../redux/types";
import {
  ExitTooltip,
  ExtractorType,
  GtmClickedContactUs,
  GtmEvent,
  GtmEventType,
  GtmExtractorDocumentType,
  GtmOnboardingFtgTooltipViewed,
  GtmServiceInstance,
  TestedExtractionStatus,
} from "../services/gtmService";
import { OnboardingAvailblePrebuiltExtractorType } from "../types/onboarding";
import { SurveyForm } from "../types/survey";
import { useUnsafeParams } from "./params";
import { URLParamsKey } from "./searchParamUtils";

export function useFirstSession() {
  const getIsFirstSession = React.useCallback(() => {
    try {
      const url = new URL(window.location.href);
      const guidance = url.searchParams.get(URLParamsKey.guidance);
      return guidance != null;
    } catch {
      return false;
    }
  }, []);

  const getGuidanceMode = React.useCallback(() => {
    const url = new URL(window.location.href);
    return url.searchParams.get(URLParamsKey.guidance);
  }, []);

  return {
    getIsFirstSession,
    getGuidanceMode,
  };
}

export function useGtm() {
  const { teamId, plan } = useAppSelector(state => {
    const { resourceOwnerId, teamLookupId } = state.resourceOwner;

    const teamId = teamLookupId ?? resourceOwnerId ?? "";

    return {
      teamId: [AppConfig.region, teamId].join("."),
      plan: state.resourceOwner.planName ?? "",
    };
  });
  const { getState } = useStore<RootState>();

  const { formId, formGroupId, customModelId } = useUnsafeParams<PathParam>();

  const { getIsFirstSession } = useFirstSession();

  const detectCurrentExtractorDocumentType =
    React.useCallback((): GtmExtractorDocumentType => {
      const state = getState();

      if (formId != null && state.form?.currentForm?.id === formId) {
        const documentType =
          state.form?.currentForm?.config.prebuilt_extractor ?? "fixed_layout";
        return documentType as GtmExtractorDocumentType;
      } else if (
        formGroupId != null &&
        state.formGroup?.currentFormGroup?.id === formGroupId
      ) {
        return "combine";
      } else if (
        customModelId != null &&
        state.customModel?.currentCustomModel?.id === customModelId
      ) {
        return "custom_model";
      }
      return "others";
    }, [getState, formId, formGroupId, customModelId]);

  const pushIdentifiedEvent = React.useCallback(() => {
    const currentUser = getState().user.currentUser;
    const userInfo = getState().user.userInfo;

    const userId = userInfo?.sub ?? currentUser?.id;
    if (!userId) {
      return;
    }

    // For Backward Compatibility, it should still need to read mix_panel_distinct_id
    const distinctId =
      (userInfo?.customAttributes["mix_panel_distinct_id"] as string) ??
      (userInfo?.customAttributes["distinct_id"] as string) ??
      userId;

    GtmServiceInstance.push({
      event: GtmEventType.Identified,
      event_data: {
        user_id: userId,
        distinct_id: distinctId,
        user_email: userInfo?.email ?? currentUser?.email ?? "",
      },
    });
  }, [getState]);

  const pushPageViewEvent = React.useCallback(() => {
    const event: GtmEvent = {
      event: GtmEventType.PageView,
      event_data: {
        team_id: teamId,
        plan,
      },
    };
    pushEvent(event);
  }, [teamId, plan]);

  const pushClickedCreateNewExtractorEvent = React.useCallback(() => {
    const event: GtmEvent = {
      event: GtmEventType.ClickedCreateNewExtractor,
      event_data: {
        team_id: teamId,
        plan,
      },
    };
    pushEvent(event);
  }, [teamId, plan]);

  const pushClickedExtractorEvent = React.useCallback(
    (extractorId: string) => {
      const event: GtmEvent = {
        event: GtmEventType.ClickedExtractor,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushClickedExtractorTabEvent = React.useCallback(
    (extractorId: string, tabName: string) => {
      const event: GtmEvent = {
        event: GtmEventType.ClickedExtractorTab,
        event_data: {
          team_id: teamId,
          plan,
          tab_name: tabName,
          extractor_id: extractorId,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushClickedSaveExtractorEvent = React.useCallback(
    (extractorId: string) => {
      const event: GtmEvent = {
        event: GtmEventType.ClickedSaveExtractor,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCustomClickedMslStartLabellingEvent = React.useCallback(
    (extractorId: string) => {
      const event: GtmEvent = {
        event: GtmEventType.CustomClickedMslStartLabelling,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCreatedExtractorEvent = React.useCallback(
    (
      extractorId: string,
      extractorName: string,
      type: ExtractorType,
      doc_type?: GtmExtractorDocumentType
    ) => {
      const event: GtmEvent = {
        event: GtmEventType.CreatedExtractor,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
          extractor_name: extractorName,
          type,
          doc_type,
        },
      };
      pushEvent(event);
    },
    [plan, teamId]
  );

  const pushInvitedMemberEvent = React.useCallback(
    (invited_email: string, invited_role: string) => {
      const event: GtmEvent = {
        event: GtmEventType.InvitedMember,
        event_data: {
          team_id: teamId,
          plan,
          invited_email,
          invited_role,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushSubscribedPlanEvent = React.useCallback(
    (planId: string, planName: string) => {
      try {
        const event: GtmEvent = {
          event: GtmEventType.SubscribedPlan,
          event_data: {
            team_id: teamId,
            plan: plan,
            new_plan_id: planId,
            new_plan_name: planName,
          },
        };
        pushEvent(event);
      } catch (e) {
        console.error(e);
      }
    },
    [teamId, plan]
  );

  const pushSwitchedTeamEvent = React.useCallback(
    (region: string, toTeamId: string) => {
      const event: GtmEvent = {
        event: GtmEventType.SwitchedTeam,
        event_data: {
          team_id: teamId,
          to_team_id: [region, toTeamId].join("."),
          plan,
        },
      };
      pushEvent(event);
    },
    [plan, teamId]
  );

  const pushTestedExtractionEvent = React.useCallback(
    (extractorId: string, status: TestedExtractionStatus) => {
      const event: GtmEvent = {
        event: GtmEventType.TestedExtraction,
        event_data: {
          team_id: teamId,
          plan,
          status,
          extractor_id: extractorId,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushViewedErrorMessageEvent = React.useCallback(
    (errorMessage?: string) => {
      const event: GtmEvent = {
        event: GtmEventType.ViewedErrorMessage,
        event_data: {
          team_id: teamId,
          plan,
          error_message: errorMessage,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCustomSavedSchemaEvent = React.useCallback(
    (extractorId: string, documentType: string, customSchema?: string) => {
      const event: GtmEvent = {
        event: GtmEventType.CustomSavedSchema,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
          document_type: documentType,
          custom_schema: customSchema,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCustomFslUploadedSampleEvent = React.useCallback(
    (extractorId: string, sampleCount?: number) => {
      const event: GtmEvent = {
        event: GtmEventType.CustomFslUploadedSample,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
          current_number_of_fsl_samples: sampleCount,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCustomMslUploadedSampleEvent = React.useCallback(
    (extractorId: string, sampleCount?: number) => {
      const event: GtmEvent = {
        event: GtmEventType.CustomMslUploadedSample,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
          current_number_of_msl_samples: sampleCount,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCustomFslReviewedSampleEvent = React.useCallback(
    (extractorId: string, correctedData: boolean) => {
      const event: GtmEvent = {
        event: GtmEventType.CustomFslReviewedSample,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
          corrected_data: correctedData,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCustomMslEnabledEvent = React.useCallback(
    (extractorId: string) => {
      const event: GtmEvent = {
        event: GtmEventType.CustomMslEnabled,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCustomMslRequestedTrainingEvent = React.useCallback(
    (extractorId: string) => {
      const event: GtmEvent = {
        event: GtmEventType.CustomMslRequestedTraining,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushCustomMslStartedTrainingEvent = React.useCallback(
    (extractorId: string) => {
      const event: GtmEvent = {
        event: GtmEventType.CustomMslStartedTraining,
        event_data: {
          team_id: teamId,
          plan,
          extractor_id: extractorId,
        },
      };
      pushEvent(event);
    },
    [teamId, plan]
  );

  const pushSurveyClickedBackEvent = React.useCallback(() => {
    const event: GtmEvent = {
      event: GtmEventType.SurveyClickedBack,
      event_data: {},
    };
    pushEvent(event);
  }, []);

  const pushSurveySetName = React.useCallback((name: string) => {
    const event: GtmEvent = {
      event: GtmEventType.SurveySetName,
      event_data: {
        $set: {
          survey_name: name,
        },
      },
    };
    pushEvent(event);
  }, []);

  const pushSurveySetCompany = React.useCallback((company: string) => {
    const event: GtmEvent = {
      event: GtmEventType.SurveySetCompany,
      event_data: {
        $set: {
          survey_company: company,
        },
      },
    };
    pushEvent(event);
  }, []);

  const pushSurveySetUseCases = React.useCallback((useCases: string[]) => {
    const event: GtmEvent = {
      event: GtmEventType.SurveySetUseCases,
      event_data: {
        $set: {
          survey_use_cases: useCases,
        },
      },
    };
    pushEvent(event);
  }, []);

  const pushSurveySetPhone = React.useCallback((phone: string) => {
    const event: GtmEvent = {
      event: GtmEventType.SurveySetPhone,
      event_data: {
        $set: {
          survey_phone: phone,
        },
      },
    };
    pushEvent(event);
  }, []);

  const pushSurveyCompletedSurveyEvent = React.useCallback(
    (form: SurveyForm) => {
      const event: GtmEvent = {
        event: GtmEventType.SurveyCompletedSurvey,
        event_data: {
          $set: {
            survey_name: form.name,
            survey_company: form.companyName,
            survey_use_cases: form.useCases,
            survey_phone: form.phoneNumber,
          },
        },
      };
      pushEvent(event);
    },
    []
  );

  const pushOnboardingAddSampleEvent = React.useCallback(
    (source: "self-upload" | "formx-sample") => {
      const event: GtmEvent = {
        event: GtmEventType.OnboardingAddSample,
        event_data: {
          team_id: teamId,
          source,
        },
      };
      pushEvent(event);
    },
    [teamId]
  );

  const pushOnboardingExtractEvent = React.useCallback(
    (doc_type: OnboardingAvailblePrebuiltExtractorType) => {
      const event: GtmEvent = {
        event: GtmEventType.OnboardingExtract,
        event_data: {
          team_id: teamId,
          doc_type,
        },
      };
      pushEvent(event);
    },
    [teamId]
  );

  const pushOnboardingSkipExtract = React.useCallback(
    (doc_type: OnboardingAvailblePrebuiltExtractorType | null) => {
      const event: GtmEvent = {
        event: GtmEventType.OnboardingSkipExtract,
        event_data: {
          team_id: teamId,
          doc_type,
        },
      };
      pushEvent(event);
    },
    [teamId]
  );

  const pushOnboardingCreateCustomModel = React.useCallback(() => {
    const event: GtmEvent = {
      event: GtmEventType.OnboardingCreateCustomModel,
      event_data: {
        team_id: teamId,
        doc_type: "custom_model",
      },
    };
    pushEvent(event);
  }, [teamId]);

  const pushClickedContactUsEvent = React.useCallback(
    (source: GtmClickedContactUs["event_data"]["source"]) => {
      const currentDocType = detectCurrentExtractorDocumentType();

      const event: GtmEvent = {
        event: GtmEventType.ClickedContactUs,
        event_data: {
          source,
          team_id: teamId,
          current_doc_type: currentDocType,
        },
      };
      pushEvent(event);
    },
    [teamId, detectCurrentExtractorDocumentType]
  );

  const pushClickedImportExtractor = React.useCallback(() => {
    const event: GtmEvent = {
      event: GtmEventType.ClickedImportExtractor,
      event_data: {
        team_id: teamId,
      },
    };
    pushEvent(event);
  }, [teamId]);

  const pushOnboardingFtgExitEvent = React.useCallback(
    (exitTooltip: ExitTooltip) => {
      const event: GtmEvent = {
        event: GtmEventType.OnboardingFtgExit,
        event_data: {
          team_id: teamId,
          exit_tooltip: exitTooltip,
          doc_type: detectCurrentExtractorDocumentType(),
        },
      };
      pushEvent(event);
    },
    [teamId, detectCurrentExtractorDocumentType]
  );

  const pushOnboardingFtgFirstDialogShowMeEvent = React.useCallback(() => {
    const event: GtmEvent = {
      event: GtmEventType.OnboardingFtgFirstDialogShowMe,
      event_data: {
        team_id: teamId,
        doc_type: detectCurrentExtractorDocumentType(),
      },
    };
    pushEvent(event);
  }, [teamId, detectCurrentExtractorDocumentType]);

  const pushOnboardingFtgTooltipViewedEvent = React.useCallback(
    (eventType: GtmOnboardingFtgTooltipViewed["event"]) => {
      const event: GtmEvent = {
        event: eventType,
        event_data: {
          team_id: teamId,
          doc_type: detectCurrentExtractorDocumentType(),
        },
      };
      pushEvent(event);
    },
    [teamId, detectCurrentExtractorDocumentType]
  );

  const pushTestTabReuploadEvent = React.useCallback(() => {
    const event: GtmEvent = {
      event: GtmEventType.TestTabReupload,
      event_data: {
        team_id: teamId,
        doc_type: detectCurrentExtractorDocumentType(),
        is_first_session: getIsFirstSession(),
      },
    };
    pushEvent(event);
  }, [teamId, detectCurrentExtractorDocumentType, getIsFirstSession]);

  const pushClickedManagedRulesEvent = React.useCallback(
    (isManageRulesTooltipPresent: boolean) => {
      const event: GtmEvent = {
        event: GtmEventType.ClickedManagedRules,
        event_data: {
          team_id: teamId,
          is_first_session: getIsFirstSession(),
          is_manage_rules_tooltip_present: isManageRulesTooltipPresent,
          doc_type: detectCurrentExtractorDocumentType(),
        },
      };
      pushEvent(event);
    },
    [teamId, getIsFirstSession, detectCurrentExtractorDocumentType]
  );

  const pushEvent = (event: GtmEvent) => {
    GtmServiceInstance.push(event);
  };

  return {
    pushEvent,
    pushClickedCreateNewExtractorEvent,
    pushClickedExtractorTabEvent,
    pushClickedSaveExtractorEvent,
    pushCustomClickedMslStartLabellingEvent,
    pushCreatedExtractorEvent,
    pushInvitedMemberEvent,
    pushSubscribedPlanEvent,
    pushSwitchedTeamEvent,
    pushTestedExtractionEvent,
    pushViewedErrorMessageEvent,
    pushCustomSavedSchemaEvent,
    pushCustomFslUploadedSampleEvent,
    pushCustomMslUploadedSampleEvent,
    pushCustomFslReviewedSampleEvent,
    pushCustomMslEnabledEvent,
    pushCustomMslStartedTrainingEvent,
    pushCustomMslRequestedTrainingEvent,
    pushIdentifiedEvent,
    pushPageViewEvent,
    pushClickedExtractorEvent,
    pushClickedContactUsEvent,
    pushClickedImportExtractor,
    pushSurveyClickedBackEvent,
    pushSurveySetName,
    pushSurveySetCompany,
    pushSurveySetUseCases,
    pushSurveySetPhone,
    pushSurveyCompletedSurveyEvent,
    pushOnboardingFtgExitEvent,
    pushOnboardingAddSampleEvent,
    pushOnboardingExtractEvent,
    pushOnboardingSkipExtract,
    pushOnboardingCreateCustomModel,
    pushOnboardingFtgFirstDialogShowMeEvent,
    pushOnboardingFtgTooltipViewedEvent,
    teamIdForTracking: teamId,
    detectCurrentExtractorDocumentType,
    pushTestTabReuploadEvent,
    pushClickedManagedRulesEvent,
  };
}
