import { FontIcon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo } from "react";

import { PLAN_NAMES } from "../../constants";
import { Plan } from "../../types/plan";
import { ManageSubscription } from "./ManageSubscription";
import { PaymentRequiredMessageBar } from "./PaymentRequiredMessageBar";
import {
  PlanCardEnterprise,
  PlanCardFree,
  PlanCardPayAsYouGo,
  PlanCardStarter,
} from "./PlanCard";
import { SubscriptionPendingCancelMessageBar } from "./SubscriptionPendingCancelMessageBar";

interface Props {
  plans: Plan[];
  isPaymentRequired: boolean;
  subscriptionCancelAt: string | undefined;
  canEditSubscription: boolean;
  currentPlanName: string;
  currentPlanIsPaid: boolean;

  onPayAsYouGoPlanAction: (planId: string) => void;
  onStarterPlanAction: (planId: string) => void;
  onContactUs: () => void;
  onEditSubscription: () => void;
  onViewPaymentRecords: () => void;
  onCancelSubscription: () => void;
}

function Payment(props: Props) {
  const {
    plans,
    isPaymentRequired,
    subscriptionCancelAt,
    canEditSubscription,
    currentPlanName,
    currentPlanIsPaid,
    onPayAsYouGoPlanAction,
    onStarterPlanAction,
    onContactUs,
    onEditSubscription,
    onViewPaymentRecords,
    onCancelSubscription,
  } = props;

  const canCancelSubscription =
    canEditSubscription && subscriptionCancelAt == null;

  const showFreePlan =
    currentPlanName === PLAN_NAMES.free || currentPlanName === PLAN_NAMES.trial;

  const planPayAsYouGo = useMemo(
    () => plans.find(p => p.name === PLAN_NAMES.payAsYouGo),
    [plans]
  );

  const planStarter = useMemo(
    () => plans.find(p => p.name === PLAN_NAMES.starter),
    [plans]
  );

  const handleOnPayAsYouGoPlanAction = useCallback(() => {
    if (planPayAsYouGo != null) {
      onPayAsYouGoPlanAction(planPayAsYouGo.id);
    }
  }, [onPayAsYouGoPlanAction, planPayAsYouGo]);

  const handleOnStarterPlanAction = useCallback(() => {
    if (planStarter != null) {
      onStarterPlanAction(planStarter.id);
    }
  }, [onStarterPlanAction, planStarter]);

  return (
    <div className="h-full flex flex-col">
      <h3 className="px-10 pt-4 pb-5 text-xl font-semibold border border-solid border-gray-200">
        <FormattedMessage id="payment.plan.header" />
      </h3>

      {isPaymentRequired ? <PaymentRequiredMessageBar /> : null}
      {subscriptionCancelAt != null ? (
        <SubscriptionPendingCancelMessageBar
          subscriptionCancelAt={subscriptionCancelAt}
          planName={currentPlanName}
        />
      ) : null}

      <div className="bg-gray-100 flex-1 flex flex-col">
        <div className="min-w-full overflow-x-auto flex">
          <div className="pt-6 pb-10 px-10 mx-auto inline-flex flex-col">
            <p className="text-right text-sm text-gray-700 mb-4">
              <FontIcon className="align-bottom mr-1" iconName="Info" />
              <FormattedMessage id="payment.plan.currency_note" />
            </p>

            <div className="flex items-stretch gap-6">
              {showFreePlan ? (
                <PlanCardFree
                  className="w-[260px]"
                  currentPlanName={currentPlanName}
                  currentPlanIsPaid={currentPlanIsPaid}
                />
              ) : null}
              {planPayAsYouGo != null ? (
                <PlanCardPayAsYouGo
                  className="w-[260px]"
                  currentPlanName={currentPlanName}
                  currentPlanIsPaid={currentPlanIsPaid}
                  onAction={handleOnPayAsYouGoPlanAction}
                  onContactUs={onContactUs}
                />
              ) : null}
              {planStarter != null ? (
                <PlanCardStarter
                  className="w-[260px]"
                  currentPlanName={currentPlanName}
                  currentPlanIsPaid={currentPlanIsPaid}
                  onAction={handleOnStarterPlanAction}
                  onContactUs={onContactUs}
                />
              ) : null}
              <PlanCardEnterprise
                className="w-[260px]"
                currentPlanName={currentPlanName}
                currentPlanIsPaid={currentPlanIsPaid}
                onContactUs={onContactUs}
              />
            </div>
          </div>
        </div>

        {canEditSubscription && currentPlanIsPaid ? (
          <ManageSubscription
            className="flex-grow bg-white"
            canCancelSubscription={canCancelSubscription}
            onEditSubscription={onEditSubscription}
            onViewPaymentRecords={onViewPaymentRecords}
            onCancelSubscription={onCancelSubscription}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Payment;
