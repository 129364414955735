@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 10px;

  .content {
    width: 80%;
    height: 40vh;
    max-width: 913px;
    min-height: 450px;

    display: flex;
    flex-direction: column;
    gap: 15px;

    .header {
      .title {
        font-size: 22px;
      }
    }

    .dotted-box {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border: 1px dashed #25d0b1;
      box-sizing: border-box;
      border-radius: 2px;

      padding: 10px;
    }

    .instruction {
      display: flex;
      gap: 14px;

      > div {
        border: 1px solid black;
        flex: 1;
        padding: 10px;

        .title {
          font-size: 14px;
        }

        ul {
          list-style: none;
          li {
            position: relative;
            font-weight: 400;
            line-height: 140%;
            color: #605e5c;
            font-size: 14px;
            margin-left: 20px;
            margin-top: 5px;

            > i {
              position: absolute;
              left: -20px;
              font-size: 10px;
            }
          }
        }
      }

      .dos {
        background-color: rgba(37, 208, 177, 0.1);
        border-color: $theme-primary;

        .title {
          color: $theme-dark;
        }

        i {
          @include no-select();
          font-weight: 600;
          color: $theme-primary;
        }
      }
      .donts {
        background-color: rgba(164, 38, 44, 0.1);
        border-color: $input-error-color;
        .title {
          color: $input-error-color;
        }

        i {
          @include no-select();
          color: $input-error-color;
        }
      }
    }
    @media only screen and (max-width: 750px) {
      .instruction {
        flex-direction: column;
      }
    }
  }

  .empty-state {
    @include no-select();
  }
}
.title {
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
}

.container li,
.subtitle {
  font-weight: 400;
  line-height: 24px;
  color: #605e5c;
  font-size: 14px;
}
