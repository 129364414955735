import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { PAYMENT_REQUIRED_TOAST_ID } from "../../constants";
import { useToast } from "../../hooks/toast";
import { MessageBar, MessageBarType } from "../MessageBar";

export const PaymentRequiredMessageBar = React.memo(() => {
  const { removeToast } = useToast();
  React.useEffect(() => {
    removeToast(PAYMENT_REQUIRED_TOAST_ID);
  }, [removeToast]);

  return (
    <MessageBar messageBarType={MessageBarType.warning}>
      <FormattedMessage id="payment.alert.box.reminder" />
    </MessageBar>
  );
});
