import cn from "classnames";
import React, { useCallback, useMemo } from "react";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { chooseFile } from "../../utils/file";
import { ImageThumbnailAddButton } from "./ImageThumbnailAddButton";
import { ImageThumbnailCard } from "./ImageThumbnailCard";

export interface ImageFile {
  url: string;
  filename: string;
}

interface ImageThumbnailListProps {
  className?: string;
  itemClassName?: string;
  items: ImageFile[];
  itemPerRow?: number;
  canUpload?: boolean;
  isUploading?: boolean;
  uploadAccept?: string[];

  renderInfoPanel?: (image: ImageFile, index: number) => React.ReactNode;
  onDelete?: (image: ImageFile, index: number) => void;
  onPress?: (image: ImageFile, index: number) => void;
  onUpload?: (file: File) => void;
}

export function ImageThumbnailList(props: ImageThumbnailListProps) {
  const {
    className,
    itemClassName,
    items,
    itemPerRow = 3,
    canUpload = false,
    isUploading = false,
    uploadAccept = SUPPORTED_IMAGE_MIME,
    renderInfoPanel,
    onDelete,
    onPress,
    onUpload,
  } = props;

  const gridStyle = useMemo<React.CSSProperties>(
    () => ({ gridTemplateColumns: `repeat(${itemPerRow}, minmax(0, 1fr))` }),
    [itemPerRow]
  );

  const handleOnAdd = useCallback(() => {
    chooseFile(uploadAccept.join(","))
      .then(files => {
        if (files != null && files.length > 0) {
          onUpload?.(files[0]);
        }
      })
      .catch(() => {});
  }, [uploadAccept, onUpload]);

  return (
    <div className={cn(className, "grid gap-2")} style={gridStyle}>
      {items.map((image, index) => (
        <ImageThumbnailListItem
          key={index}
          className={itemClassName}
          image={image}
          index={index}
          renderInfoPanel={renderInfoPanel}
          onDelete={onDelete}
          onPress={onPress}
        />
      ))}
      {canUpload ? (
        <ImageThumbnailAddButton
          className={itemClassName}
          isLoading={isUploading}
          onAdd={handleOnAdd}
        />
      ) : null}
    </div>
  );
}

interface ImageThumbnailListItemProps {
  className?: string;
  image: ImageFile;
  index: number;

  renderInfoPanel?: (image: ImageFile, index: number) => React.ReactNode;
  onDelete?: (image: ImageFile, index: number) => void;
  onPress?: (image: ImageFile, index: number) => void;
}

function ImageThumbnailListItem(props: ImageThumbnailListItemProps) {
  const { className, image, index, renderInfoPanel, onDelete, onPress } = props;

  const handleRenderInfoPanel = useMemo(() => {
    if (renderInfoPanel == null) {
      return undefined;
    }
    return () => renderInfoPanel(image, index);
  }, [renderInfoPanel, image, index]);

  const handleOnDelete = useMemo(() => {
    if (onDelete == null) {
      return undefined;
    }
    return () => onDelete(image, index);
  }, [onDelete, image, index]);

  const handleOnPress = useMemo(() => {
    if (onPress == null) {
      return undefined;
    }
    return () => onPress(image, index);
  }, [onPress, image, index]);

  return (
    <ImageThumbnailCard
      className={className}
      imageURL={image.url}
      filename={image.filename}
      renderInfoPanel={handleRenderInfoPanel}
      onDelete={handleOnDelete}
      onPress={handleOnPress}
    />
  );
}
