import { FontIcon, Spinner, SpinnerSize } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, { useCallback } from "react";

interface ImageThumbnailAddButtonProps {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;

  onAdd?: () => void;
}

export function ImageThumbnailAddButton(props: ImageThumbnailAddButtonProps) {
  const { className, disabled, isLoading, onAdd } = props;

  const handleOnClick = useCallback(() => {
    onAdd?.();
  }, [onAdd]);

  return isLoading ? (
    <div
      className={cn(
        className,
        "flex flex-col items-center justify-center gap-y-2 px-1 py-1.5",
        "shadow-elevation4",
        "rounded-sm border border-gray-200",
        "bg-gray-50 text-gray-800 text-xs"
      )}
    >
      <Spinner size={SpinnerSize.large} />
      <span className="text-primary-500">
        <FormattedMessage id="common.loading" />
      </span>
    </div>
  ) : (
    <button
      className={cn(
        className,
        "flex flex-col items-center justify-center gap-y-2 px-1 py-1.5",
        "shadow-elevation4",
        "enabled:cursor-pointer",
        "rounded-sm border border-solid border-gray-200 hover:border-primary-700 disabled:border-gray-200",
        "focus-visible:outline-primary-500 focus-visible:outline-2",
        "bg-white hover:bg-gray-100 active:bg-gray-200 disabled:bg-gray-100",
        "text-gray-800 hover:text-gray-600 active:text-gray-700 disabled:text-gray-600",
        "[&_.icon]:text-primary-500 [&_.icon]:hover:text-primary-700 [&_.icon]:active:text-primary-700 [&_.icon]:disabled:text-gray-600"
      )}
      disabled={disabled}
      onClick={handleOnClick}
    >
      <FontIcon
        className={cn("icon w-6 h-6 text-2xl leading-none")}
        iconName="Add"
      />
      <span className="text-sm">Add Sample</span>
    </button>
  );
}
