import * as yup from "yup";

export const planSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
});

export const planListSchema = yup.array(planSchema).defined();

export type Plan = yup.InferType<typeof planSchema>;
