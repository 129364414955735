import { IIconProps, IconButton } from "@fluentui/react";
import cn from "classnames";
import React, { useCallback, useMemo } from "react";

interface GroupHeaderProps extends React.PropsWithChildren<object> {
  className?: string;
  size?: "s" | "m";
  isExpanded: boolean;
  onToggle: () => void;
}

export function GroupHeader(props: GroupHeaderProps) {
  const { className, size = "m", isExpanded, onToggle, children } = props;

  const iconProps = useMemo<IIconProps>(
    () => ({
      iconName: "ChevronDown",
      className: cn(
        "transition-transform",
        isExpanded ? "" : "-rotate-90",
        size === "m" ? "h-4 text-base leading-none" : "",
        size === "s" ? "h-3 text-xs leading-none" : ""
      ),
    }),
    [isExpanded, size]
  );

  const handleToggleOnClick = useCallback(() => {
    onToggle?.();
  }, [onToggle]);

  return (
    <div
      className={cn(
        className,
        "flex flex-row items-center",
        size === "m" ? "gap-x-2 py-3" : "",
        size === "s" ? "gap-x-1.5 py-1.5" : ""
      )}
    >
      <IconButton
        className={cn(
          "text-gray-800 hover:text-gray-800 active:text-gray-800",
          "transition-transform",
          size === "m" ? "-ml-1 w-8 h-8" : "",
          size === "s" ? "-ml-0.5 w-6 h-6" : ""
        )}
        iconProps={iconProps}
        onClick={handleToggleOnClick}
      />
      <div
        className={cn(
          "flex-1 min-w-0",
          "text-gray-900",
          size === "m" ? "text-base font-semibold" : "",
          size === "s" ? "text-sm font-semibold" : ""
        )}
      >
        {children}
      </div>
    </div>
  );
}
