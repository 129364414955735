{
  "app.name": "FormExtractor",
  "app.toast.payment_required": "All extraction free quotas are used up. Please subscribe to our business plan",
  "app.custom_model_image_service_in_use": "You are either uploading or deleting sample image(s) for custom model(s), if you leave, those pending operation will be cancelled",
  "app.toast.got_team_invitation": "You have {count, plural, one{1 team invite} other{# team invites}}",
  "app.toast.got_team_invitation.view": "View",

  "beta_tag.name": "beta",

  "signup.name": "Sign Up",
  "signup.have_account": "I already have an account.",

  "login.name": "Login",
  "login.no_account_yet": "I don't have an account yet.",

  "login_change_password_form.title": "Change Password",
  "login_change_password_form.submit_button": "Change Password",

  "form_grid.no_form_yet": "You don't have any form yet.",
  "form_grid.team_no_form_yet": "Your team doesn't have any form yet.",
  "form_grid.empty_state_tips": "Create one by clicking the button at the top bar.",
  "form_grid.delete_form": "Delete Extractor",
  "form_grid.are_you_sure_to_delete_form": "Are you sure to delete the selected extractor?",
  "form_grid.delete": "Delete",
  "form_grid.creating_form": "Creating form...",
  "form_grid.importing_form": "Importing form...",

  "form_group_grid.no_form_yet": "You don't have any combined extractor yet.",
  "form_group_grid.team_no_form_yet": "Your team doesn't have any combined extractor yet.",
  "form_group_grid.empty_state_tips": "Create one by clicking the button at the top bar.",
  "form_group_grid.creating_form_group": "Creating combined extractor...",
  "form_group_grid.deleting_form_group": "Deleting combined extractor...",
  "form_group_grid.delete_form": "Delete Combined Extractor",
  "form_group_grid.are_you_sure_to_delete_form_group": "Are you sure to delete the selected combined extractor?",
  "form_group_grid.delete": "Delete",

  "form_group_api.token_copied": "Access token is copied to clipboard",
  "form_group_api.id_copied": "Extractor ID is copied to clipboard",
  "form_group_api.copy_id": "Copy Extractor ID",
  "form_group_api.copy_token": "Copy Access Token",

  "form_group.modifed_prompt.title": "This combined extractor has been modified",
  "form_group.modifed_prompt.desc": "This combined extractor has been modified outside this tab since you open it. Are you sure you want to save this combined extractor?",

  "receipt_group_list.no_receipt_yet": "You don't have any receipt group yet.",
  "receipt_group_list.empty_state_tips": "Create one by clicking the button at the top bar.",
  "receipt_group_list.delete_receipt_group": "delete receipt group",
  "receipt_group_list.are_you_sure_to_delete_receipt_group": "Are you sure to delete selected receipt group?",
  "receipt_group_list.delete": "Delete",
  "receipt_group_list.creating_receipt_group": "Creating receipt group...",
  "receipt_group_list.renaming_receipt_group": "Renaming receipt group...",
  "receipt_group_list.adding_token": "Adding token...",

  "receipt_group_list_item.edit": "Edit",
  "receipt_group_list_item.test": "Test",
  "receipt_group_list_item.api": "API",
  "receipt_group_list_item.id": "ID",

  "receipt_group.modified_prompt.title": "This receipt group has been modified",
  "receipt_group.modified_prompt.desc": "This receipt group has been modified outside this tab since you open it. Are you sure you want to save it?",

  "rename_receipt_group.title": "Rename receipt group",
  "rename_receipt_group.receipt_group_name": "Receipt Group Name",
  "rename_receipt_group.rename": "Rename",

  "rename_form_group.title": "Rename Combined Extractor",
  "rename_form_group.form_group_name": "Combined Extractor Name",
  "rename_form_group.rename": "Rename",

  "left_bar.extraction": "Extraction",
  "left_bar.add_ons": "Add-ons",
  "left_bar.help_center": "Help Center",
  "left_bar.contact_us": "Contact Us",
  "left_bar.team": "Team",
  "left_bar.workspaces": "Workspaces",
  "left_bar.extractors": "Extractors",
  "left_bar.usage": "Usage",
  "left_bar.manage_team": "Manage Team",
  "left_bar.api_doc": "API Doc",
  "left_bar.billing": "Plans & Billing",
  "left_bar.settings": "Settings",
  "left_bar.audit_log": "Audit Log",
  "left_bar.promotion_box.message": "Free plan users can use up to 100 API calls. Upgrade your plan to get unlimited usage.",
  "left_bar.promotion_box.upgrade_now": "Upgrade Now",
  "left_bar.integrations": "Integrations",
  "left_bar.webhooks": "Webhooks",
  "left_bar.powered_by_formx": "Powered by FormX.ai",
  "left_bar.detect_documents": "Detect Documents",
  "left_bar.report": "Report",
  "left_bar.detect_pii": "Detect PII",
  "left_bar.teams": "Teams",

  "labeller.token_copied": "Token is copied to clipboard",

  "form_template.header_prefix": "Template - {name}",
  "form_template.business_registration": "Business Registration",
  "form_template.travel_agency_chinese": "Travel Agency Chinese",
  "form_template.travel_agency_english": "Travel Agency English",
  "form_template.food_license": "Food License",
  "form_template.hawker_license": "Hawker License",
  "form_template.hkid_new": "Hong Kong ID (New)",
  "form_template.hkid_old": "Hong Kong ID (Old)",
  "form_template.hkid": "Hong Kong ID",
  "form_template.twid": "Taiwan ID",
  "form_template.twid_front": "Taiwan ID (Front)",
  "form_template.twid_back": "Taiwan ID (Back)",
  "form_template.moid": "Macau ID",
  "form_template.sgid": "Singapore ID",
  "form_template.passport": "Passport",
  "form_template.intl_id": "International ID/Passport",
  "form_template.intl_id_fallback_form": "International ID/Passport",
  "form_template.intl_id_moid": "International ID (Macau ID)",
  "form_template.intl_id_hkid_old": "International ID (Old Hong Kong ID)",
  "form_template.intl_id_hkid_new": "International ID (Hong Kong ID)",
  "form_template.intl_id_twid_front": "International ID (Taiwan ID Front)",
  "form_template.intl_id_twid_back": "International ID (Taiwan ID Back)",

  "form_template.desc.intl_id": "See the list of supported countries",

  "form_inspector.form_settings": "Extractor Settings",
  "form_inspector.anchor_settings": "Anchor Settings",
  "form_inspector.field_settings": "Field Settings",
  "form_inspector.detection_region_settings": "Detection Region Settings",
  "form_inspector.get_token": "Copy Token",
  "form_inspector.form_name": "Name",
  "form_inspector.form_id": "ID",
  "form_inspector.num_form_feature": "# Extractor Features",
  "form_inspector.num_query_feature": "# Query Features",
  "form_inspector.enable": "Enable",
  "form_inspector.label": "Label",
  "form_inspector.languages": "Languages",
  "form_inspector.tesseract.psm": "Page segmentation modes",
  "form_inspector.tesseract.preset": "Preset",
  "form_inspector.azure.mode": "Mode",
  "form_inspector.google.mode": "Mode",
  "form_inspector.recognizer": "Recognizer",
  "form_inspector.document_type": "Document Type",

  "form_inspector.add_new_field": "Add New Field",
  "form_inspector.add_extraction_field": "Add Extraction Field",
  "form_inspector.choose_extraction_type": "Choose Extraction Type",
  "form_inspector.choose_extraction_type_warning": "Please choose an extraction type",
  "form_inspector.delete": "Delete",
  "form_inspector.delete_region": "Delete Region",
  "form_inspector.delete_anchor": "Delete Anchor",
  "form_inspector.settings": "Settings",
  "form_inspector.view_settings": "View Settings",
  "form_inspector.detection_region_field_advanced_settings": "Advanced Settings",
  "form_inspector.detection_region_type": "Type",
  "form_inspector.detection_region_field_engine": "Engine",
  "form_inspector.detection_region_field_api": "API",
  "form_inspector.detection_region_field_edit_script": "Edit Script",
  "form_inspector.detection_region_field_edit": "Edit",
  "form_inspector.detection_region_field_extract_string_between_words": "Extract string between words",
  "form_inspector.detection_region_field_extract_string_between_words.desc": "You can highlight a large text area (such as a paragraph of text) and extract string between words.\n\nFor example, if your document contains \"The total amount of the bill is $521 dollars\", you can use {between} with \"the bill is\" as {startMark} and \"dollars\" as {endMark}.",
  "form_inspector.detection_region_field.error.field_label_unique": "Field label has to be unique",
  "form_inspector.detection_region_field.error.field_label_empty": "Field label cannot be empty",
  "form_inspector.detection_region_field.error.field_label_invalid": "Field label cannot contain single quote",

  "form_inspector.detection_region_field_text_in_paragraph_type": "Type",
  "form_inspector.detection_region_field_text_in_paragraph_type.before": "Before",
  "form_inspector.detection_region_field_text_in_paragraph_type.after": "After",
  "form_inspector.detection_region_field_text_in_paragraph_mark.start": "Start Mark",
  "form_inspector.detection_region_field_text_in_paragraph_mark.end": "End Mark",
  "form_inspector.detection_region_field_text_in_paragraph_type.between": "Between",
  "form_inspector.detection_region_field_text_in_paragraph_fuzzy_match": "Fuzzy Search",
  "form_inspector.detection_region_field_text_in_paragraph_ignore_whitespace": "Ignore Whitespaces",

  "form_inspector.detection_region_field_script_editor.js_io_tab_title": "JavaScript I/O",
  "form_inspector.detection_region_field_script_editor.function_tab_title": "Available Functions",
  "form_inspector.detection_region_field_script_editor.hide_tab_title": "Hide",

  "form_inspector.detection_region_field_text_setting_editor.title": "Text Settings",
  "form_inspector.detection_region_field_text_setting_editor.desc": "Please select a text type.",
  "form_inspector.detection_region_field_text_setting_editor.remove_watermark": "Remove watermark in the background",

  "form_inspector.detection_region_field_date_time_setting_editor.title": "Date/Time Settings",
  "form_inspector.detection_region_field_date_time_setting_editor.desc": "Detect a certain date/time exists in the document anywhere.",
  "form_inspector.detection_region_field_date_time_setting_editor.date_option": "Date",
  "form_inspector.detection_region_field_date_time_setting_editor.date_fornat_option": "Format",
  "form_inspector.detection_region_field_date_time_setting_editor.time_option": "Time",
  "form_inspector.detection_region_field_date_time_setting_editor.option_title": "Select to detect",

  "form_inspector.text": "Text",
  "form_inspector.date_time": "Date/Time",

  "form_inspector.single_line_eng": "Single Line English",
  "form_inspector.single_line_chi": "Single Line Chinese",
  "form_inspector.single_line_eng_chi": "Single Line English and Chinese",
  "form_inspector.block_eng": "Block of English",
  "form_inspector.block_chi": "Block of Chinese",
  "form_inspector.block_eng_chi": "Block of English and Chinese",
  "form_inspector.handwritten_eng": "Handwritten English",
  "form_inspector.handwritten_chi": "Handwritten Chinese",
  "form_inspector.handwritten_eng_chi": "Handwritten English and Chinese",
  "form_inspector.barcode": "Barcode / QR code",
  "form_inspector.checkbox": "Checkbox",
  "form_inspector.date": "Date",
  "form_inspector.time": "Time",
  "form_inspector.number": "Number",
  "form_inspector.script": "Script",
  "form_inspector.name": "Name",
  "form_inspector.address": "Hong Kong Address",
  "form_inspector.total_amount": "Total Amount",
  "form_inspector.text_in_paragraph": "Text in paragraph",
  "form_inspector.table": "Table",
  "form_inspector.llm_completion": "LLM Completion",

  "form_inspector.document_field.type": "Type",
  "form_inspector.document_field.field_name": "Field Name",
  "form_inspector.document_field.key_value_type": "Key-Value",
  "form_inspector.document_field.text_token_group_type": "Text Tokens",
  "form_inspector.document_field.image_token_group_type": "Image Tokens",
  "form_inspector.document_field.error.field_name_unique": "Field name has to be unique",
  "form_inspector.document_field.error.field_name_empty": "Field name cannot be empty",
  "form_inspector.document_field.error.field_name_invalid": "Field name cannot contain single quote",

  "field_list.label": "Label",
  "field_list.recognizer": "Recognizer",
  "field_list.setting": "Setting",
  "field_list.languages": "Languages",
  "field_list.config": "Config",
  "field_list.preset": "Preset",
  "field_list.none": "None",
  "field_list.azure.mode": "Mode",
  "field_list.google.mode": "Mode",
  "field_list.empty": "You have not define any field for this form yet.",

  "form_group_editor.name": "Name",
  "form_group_editor.export": "Export as zip",
  "form_group_editor.edit_post_process_script": "Edit Post Processing Script",
  "form_group_editor.extractors": "Extractors",
  "form_group_editor.rules": "Rules",
  "form_group_editor.in_use": "{name} (In Use)",
  "form_group_editor.combined_extractor": "{name} (Combined Extractor)",
  "form_group_editor.no_self_reference": "{name} (No Self Reference)",

  "form_group_anchor_editor.desc": "FormX determines which extractor to use automatically by visual similarity of the anchor regions",
  "form_group_anchor_editor.form": "Extractor",
  "form_group_anchor_editor.action": "Action",
  "form_group_anchor_editor.add_an_extractor": "Add an extractor",
  "form_group_anchor_modal.title.add": "Add Extractor",
  "form_group_anchor_modal.title.edit": "Edit Extractor",
  "form_group_anchor_modal.extractor_to_be_matched": "Extractor to be matched",
  "form_group_anchor_modal.description": "Only extractors with anchors are selectable",
  "form_group_anchor_modal.select_an_extractor": "Select an extractor",
  "form_group_anchor_modal.no_anchor": "{name} (No Anchor)",
  "form_group_token_group_modal.title.add": "Add Extractor",
  "form_group_token_group_modal.title.edit": "Edit Extractor",
  "form_group_token_group_modal.extractor_to_be_matched": "Extractor to be matched",
  "form_group_token_group_modal.select_an_extractor": "Select an extractor",
  "form_group_token_group_modal.match_mode": "Match mode",
  "form_group_token_group_modal.match_mode.all": "Match ALL tokens\n\nFormX choose this extractor if and only if all the tokens could be found on the uploaded document",
  "form_group_token_group_modal.match_mode.any": "Match any of the tokens\n\nFormX choose this extractor if any of the token found on the uploaded document. If multiple of extractors share the same token, the one with higher order will be selected",
  "form_group_token_editor.desc": "FormX determines which extractor to use automatically by keywords or image tokens from the documents",
  "form_group_token_group_editor.form": "Extractor",
  "form_group_token_group_editor.match_mode": "Match Mode",
  "form_group_token_group_editor.match_mode.all": "All",
  "form_group_token_group_editor.match_mode.any": "Any",
  "form_group_token_group_editor.action": "Action",
  "form_group_token_group_editor.add_an_extractor": "Add an extractor",

  "form_group_fallback_form_editor.title": "If no extractor from the above is matched, extract with:",
  "form_group_fallback_form_editor.select_editor": "Select extractor",
  "form_group_fallback_form_editor.empty": "No extractor is selected.",

  "form_group_fallback_form_modal.title": "Select Extractor",
  "form_group_fallback_form_modal.extract_with": "If no extractor is matched, extract with",
  "form_group_fallback_form_modal.select_an_extractor": "Select an extractor",

  "form_group_document_detection_editor.desc": "FormX determines which extractor to use automatically by Pre-trained Document Detection Model",
  "form_group_document_detection_editor.add_a_rule": "Add a rule",
  "form_group_document_detection_editor.extractor": "Extractor",
  "form_group_document_detection_editor.document_type": "Type",
  "form_group_document_detection_editor.action": "Action",

  "form_group_document_detection_modal.title.add": "Add a rule",
  "form_group_document_detection_modal.title.edit": "Edit rule",
  "form_group_document_detection_modal.select_an_extractor": "Select an extractor",
  "form_group_document_detection_modal.if_document_type_is": "If document type is...",
  "form_group_document_detection_modal.use_this_extractor": "Use this extractor",
  "form_group_document_detection_modal.please_select_an_extractor": "Please select an extractor",
  "form_group_document_detection_modal.please_select_a_document_type": "Please select a document type",
  "form_group_document_detection_modal.button.add": "Add",
  "form_group_document_detection_modal.button.save": "Save",

  "receipt_group_editor.name": "Name",
  "receipt_group_editor.tokens": "Tokens",
  "receipt_group_editor.token_groups": "Token Groups",
  "receipt_group_editor.custom_fields": "Custom Fields",
  "receipt_group_editor.key_values": "Key Values",
  "receipt_group_editor.rename": "Rename",
  "receipt_group_editor.add_token": "Add token",
  "receipt_group_editor.add_custom_field": "Add custom field",
  "receipt_group_editor.tokens_description_1": "Token is short text pattern that you want to detect from a receipt.",
  "receipt_group_editor.tokens_description_2": "By defining appropriate tokens, you could tag receipt with the shop name, address, payment method, etc.",
  "receipt_group_editor.custom_fields_description_1": "Custom field is a pattern of value near (above, below, on the left or right of) a token.",
  "receipt_group_editor.custom_fields_description_2": "By defining appropriate custom fields, you could extract extra information like account number from the receipt.",
  "receipt_group_editor.no_tokens": "You have not defined any token yet.",
  "receipt_group_editor.no_custom_field": "You have not defined any custom field yet.",
  "receipt_group_editor.no_token": "You have not defined any token yet.",

  "ocr_test.choose": "Choose Image",
  "ocr_test.choose_another_file": "Choose another file",
  "ocr_test.no_image.title": "Drag a file here or click to upload",
  "ocr_test.image_selector.desc": "or test with one of these dummy examples",
  "ocr_test.select_a_file": "Upload a file",
  "ocr_test.extracting": "Extracting...",
  "ocr_test.warped_image": "Warped Image",
  "ocr_test.field_id": "ID",
  "ocr_test.field_content": "Content",
  "ocr_test.field_error": "Error",
  "ocr_test.text_tokens": "Text Tokens",
  "ocr_test.image_tokens": "Image Tokens",
  "ocr_test.jump_to_json": "Jump to JSON",
  "ocr_test.json": "JSON Output",
  "ocr_test.extract_error": "There is an error extracting data from this page",
  "ocr_test.extract_error.reason": "Reason: {message}",
  "ocr_test.multi_document.indicator": "Uploading files with multiple documents.",
  "ocr_test.try_sample": "Test with examples",
  "ocr_test.badge.field": "FIELD",
  "ocr_test.badge.kv": "K/V",
  "ocr_test.badge.token": "TOKEN",
  "ocr_test.badge.auto": "AUTO",
  "ocr_test.extraction_mode.change": "Change",
  "ocr_test.extraction_mode.choose_a_file_type": "Choose a file type",
  "ocr_test.download_json_as_file": "Download JSON as file",

  "ocr_test_table.header.type": "Type",
  "ocr_test_table.header.name": "Name",
  "ocr_test_table.header.value": "Value",
  "ocr_test_table.header.confidence": "Confidence",
  "ocr_test_table.header.image": "Image",
  "ocr_test_table.type.field": "Field",
  "ocr_test_table.type.key_value": "Key Value",
  "ocr_test_table.type.auto": "Auto",
  "ocr_test_table.type.token": "Token",
  "ocr_test_table.type.custom_model": "Custom Model",
  "ocr_test_table.type.formatter": "Formatter",
  "ocr_test.access_token_using": "The access token you are using",
  "ocr_test.no_access_token": "There is no access token available. {url}",
  "ocr_test.create_access_token": "Create one here.",
  "ocr_test.extraction_mode": "Extraction mode",
  "ocr_test.extraction_mode.single_page": "PDF/Image containing one document",
  "ocr_test.extraction_mode.single_page_multi_document": "A single image containing multiple separate document",
  "ocr_test.extraction_mode.multi_page_pdf": "Consider each page as a separate document",
  "ocr_test.extraction_mode.combine_multi_page_pdf": "Combine all pages into a single document (Experimental)",
  "ocr_test.extraction_mode.multi_page_pdf_multi_document": "Each page contains more than one document",

  "detect_documents_test.choose": "Choose Image",
  "detect_documents_test.upload_another_file": "Upload another file",
  "detect_documents_test.detecting": "Detecting...",
  "detect_documents_test.json": "JSON Output",
  "detect_documents_test.warped_image": "Warped Image",
  "detect_documents_test.no_image": "Upload Image for document detection",

  "detect_documents_test_table.header.type": "Type",
  "detect_documents_test_table.header.bbox": "Bounding Box",
  "detect_documents_test_table.header.type_score": "Confidence (Type)",
  "detect_documents_test_table.header.bbox_score": "Confidence (BBox)",

  "detect_pii_test_table.header.type": "Type",
  "detect_pii_test_table.header.value": "Value",
  "detect_pii_test_table.header.confidence": "Confidence",
  "detect_pii_test_table.header.bboxes": "Bounding Boxes",
  "detect_pii.preview_image.toggle_button.label": "Redact PII",

  "batch.batch_extraction_using_desktop_app": "Batch extraction using Desktop App",
  "batch.introduction": "Form Extractor has a companion desktop app for you to do batch extraction with just a few clicks. To do batch extraction, please following steps below.",
  "batch.step_download": "Download the desktop app according to your platform below, install and open it.",
  "batch.step_open": "Click the \"Open in Desktop App\" button in the top right corner of this page. Desktop App should open with form ID and access token being filled, click \"Submit\".",
  "batch.first_time_open": "Your browser may ask you whether you want to open the desktop app or not, check \"Always allow\" and click \"Open\".",
  "batch.manual_fill_in": "If for any reason, the form ID and access token are not filled in the desktop app, you can fill them in manually. You could copy them by clicking the corresponding buttons on the top right corner of this page.",
  "batch.step_extract": "Select or simply drop those images file you want to do extraction, click \"Extract\".",
  "batch.step_result": "Wait a while for the extraction to complete, then the desktop app will ask you where to save the result.",
  "batch.download": "Download Desktop App",
  "batch.macos_app": "Download for macOS",
  "batch.window_app": "Download for Windows",

  "receipt_test.no_image": "Upload image for OCR",
  "receipt_test.recognizing": "Recognizing...",
  "receipt_test.amount": "Amount",
  "receipt_test.date": "Date",
  "receipt_test.time": "Time",
  "receipt_test.tokens": "Tokens",
  "receipt_test.custom_fields": "Custom Fields",
  "receipt_test.try_sample": "You may want to try following receipts as well.",

  "create_form.title": "Create a New Extractor",
  "create_form.form_name": "Extractor Name",
  "create_form.create": "Create",
  "create_form.template_image": "Master Image",

  "create_document_extractor.title": "Create a New Document Extractor",
  "create_document_extractor.session_fixed_format.title": "My Documents have a fixed format",
  "create_document_extractor.session_fixed_format.description": "For document with a fixed format, such as form or government licenses, you will need to upload a master image, which should be cleared and from a scanner. You will create different region / anchor to extract information accurately. {readMore}",

  "create_document_extractor.session_non_fixed_format.title": "My Documents don’t have a fixed format",
  "create_document_extractor.session_non_fixed_format.description": "For document without a fixed format, such as letter, or some simple tables, key/value pair. You can extract information by {keyValueLink}, detect text/ logos, or extract entity such as Name, Address, etc. You don’t need a master image.",

  "create_document_extractor.session_diff_layout_format.title": "My Document have different layout",
  "create_document_extractor.session_diff_layout_format.description": "If the type of document you need to extract information on have different layouts, such as receipts, academic transcript, which various by where the document came from. You may explirer our templates. If it is not available yet, we can train a custom AI models with around 15-50 samples provided from you.",

  "create_document_extractor.link.key_value": "Key/value",
  "create_document_extractor.link.read_more": "Read more",

  "create_ai_form.title": "Create an AI Model for my document",
  "create_ai_form.description": "For now Custom AI model is available for customers with Professional Plan only, please let us know the following information and we will contact you for details as soon as possible.",

  "create_ai_form.question1.title": "1. What type of the document",
  "create_ai_form.question1.placeholder": "Input document type here",
  "create_ai_form.question1.error.empty": "Type of document cannot be empty",
  "create_ai_form.question1.error.reach_limit": "Number of characters reached limit",

  "create_ai_form.question2.title": "2. How many sample image you have",
  "create_ai_form.question2.placeholder": "Input numbers here",
  "create_ai_form.question2.error.empty": "Numbers cannot be empty",

  "create_ai_form.question3.title": "3. What information you will need",
  "create_ai_form.question3.placeholder": "Input the inforamtion here",
  "create_ai_form.question3.error.empty": "Information cannot be empty",

  "create_ai_form.question4.title": "4. Your contact email / number",
  "create_ai_form.question4.placeholder": "Input email address or contact number",
  "create_ai_form.question4.error.empty": "Contact email / number of document cannot be empty",
  "create_ai_form.question4.error.invalid_format": "Invalid contact email / number",

  "create_ai_form.submitting": "Submitting...",
  "create_ai_form.error.fail_to_submit_form": "Failed to submit form",
  "create_ai_form.info.submitted_form": "Thanks for interested in using our Custom AI model and we will contact you for details as soon as possible.",

  "create_form_group.title": "Create a New Combined Extractor",
  "create_form_group.form_group_name": "Combined Extractor Name",
  "create_form_group.form_group_mode": "Mode",
  "create_form_group.form_group_mode.desc": "FormX determines which extractor to use automatically",
  "create_form_group.form_group_mode.anchor.title": "By Anchor",
  "create_form_group.form_group_mode.anchor.desc": "Determined by visual similarity of the anchor regions",
  "create_form_group.form_group_mode.token.title": "By Text/Image Tokens",
  "create_form_group.form_group_mode.token.desc": "Search for keywords or image tokens from the documents",
  "create_form_group.form_group_mode.document_detection.title": "By Pre-trained Document Detection Model",
  "create_form_group.form_group_mode.document_detection.desc": "Use ML model to classify Passports, ID cards, Receipts, and others",
  "create_form_group.create": "Create",

  "create_custom_model_dialog.title": "Create a New Custom Model",
  "create_custom_model_dialog.textfield.label": "Custom Model Name",
  "create_custom_model_dialog.my_custom_extractor": "My Custom Extractors",
  "custom_model_editor.upload_image.desc.title": "Upload Sample Files",
  "custom_model_editor.upload_image.desc.subtitle": "Show us some samples and we will extract the information for you",
  "custom_model_editor.upload_image.center.title": "Start with 10 images. The more images you add, the better the results.",
  "custom_model_editor.upload_image.center.subtitle": "Drag and drop or {link} to add files",
  "custom_model_editor.upload_image.center.subtitle.link": "click here",
  "custom_model_editor.upload_image.empty": "No sample image has uploaded yet.",
  "custom_model_editor.upload_image.dos.title": "Do’s",
  "custom_model_editor.upload_image.donts.title": "Don’ts",
  "custom_model_editor.upload_image.dos.capture_in_well_lit_env": "Capture the document in well lit environment",
  "custom_model_editor.upload_image.dos.hold_steadily": "Hold the camera steadily",
  "custom_model_editor.upload_image.dos.clear_text": "The text on the document should be clear and legible",
  "custom_model_editor.upload_image.donts.image_from_google": "Images from Google as samples",
  "custom_model_editor.upload_image.donts.fake_image": "Fake specimens, which will have bad effect on the model",
  "custom_model_editor.upload_image.donts.blur_image": "Blurry images",

  "custom_model_editor.image_grid.title": "Sample Files",
  "custom_model_editor.image_grid.subtitle": "Show us some samples and we will extract the information for you",
  "custom_model_editor.image_grid.item.upload_at": "Uploaded At {date}",
  "custom_model_editor.image_grid.item.unamed": "Unnamed",
  "custom_model_editor.upload_files_button": "Upload Files",
  "custom_model_editor.upload_files_button_tip": "Upload {count, plural, one{1 more image} other{# more images}} to create a more accurate model",
  "custom_model_editor.lightbox_modal.header.title": "Preview",
  "custom_model_editor.lightbox_modal.header.open_in_labeller_button": "Open in labeller app",
  "custom_model_editor.lightbox_modal.footer.upload_at": "Uploaded At {date}",

  "custom_model_editor.right_bar.title": "Customize Information To Extract",
  "custom_model_editor.right_bar.desc": "Tell us what information do you want to extract. It can be identification numbers, names, address, amounts, etc.",
  "custom_model_editor.right_bar.subtitle": "Fields",
  "custom_model_editor.right_bar.add_new_field_button": "New Field",
  "custom_model_editor.right_bar.request_training_button": "Request Initial Training",
  "custom_model_editor.right_bar.update_training_button": "Update Training Request",
  "custom_model_editor.right_bar.text_field.error": "Please fill in the information",
  "custom_model_editor.right_bar.next_step": "Next Step",
  "custom_model_editor.right_bar.next_step.bubble.desc": "Once created, fields cannot be edited or removed. To change the data to be extracted, create a {span, react, className{{boldTextClassName}} children{new field}} instead.",
  "custom_model_editor.right_bar.next_step.bubble.process_button": "Proceed and don’t show again",
  "custom_model_editor.right_bar.tab.fields": "Fields",
  "custom_model_editor.right_bar.label.desc": "Specify the data to extract from your documents. Then, prepare your model for training by labelling your samples using our labelling tool.",
  "custom_model_editor.right_bar.label.subtitle": "Custom Fields",
  "custom_model_editor.right_bar.label.predefined_fields": "Pre-defined Fields",
  "custom_model_editor.right_bar.label.preset": "based on - {preset}",
  "custom_model_editor.right_bar.label.error.empty": "Please fill in the information",
  "custom_model_editor.right_bar.label.error.invalid_format": "Invalid format, only allow a-z, 0-9 and _",
  "custom_model_editor.right_bar.label.error.duplicated": "Duplicated field",
  "custom_model_editor.right_bar.label.empty": "No custom fields yet",

  "custom_model_editor.right_bar.label.edit_pane.field_name.label": "Field Name",
  "custom_model_editor.right_bar.label.edit_pane.field_name.placeholder": "e.g. invoice_id",
  "custom_model_editor.right_bar.label.edit_pane.type.label": "Data Type",
  "custom_model_editor.right_bar.label.edit_pane.type.text": "Plain Text",
  "custom_model_editor.right_bar.label.edit_pane.type.date": "Date",
  "custom_model_editor.right_bar.label.edit_pane.type.date.US": "MM/DD/YYYY (US)",
  "custom_model_editor.right_bar.label.edit_pane.type.date.UK": "DD/MM/YYYY (UK)",
  "custom_model_editor.right_bar.label.edit_pane.type.date.ISO": "YYYY-MM-DD (ISO)",
  "custom_model_editor.right_bar.label.edit_pane.type.time": "Time",
  "custom_model_editor.right_bar.label.edit_pane.type.time.hhmm": "hh:mm",
  "custom_model_editor.right_bar.label.edit_pane.type.time.hhmmss": "hh:mm:ss",
  "custom_model_editor.right_bar.label.edit_pane.type.time.hmmAMPM": "h:mm AM/PM",
  "custom_model_editor.right_bar.label.edit_pane.type.amount": "Monetary Amount",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.USD": "USD (U.S. dollar)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.EUR": "EUR (Euro)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.JPY": "JPY (Japanese yen)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.GBP": "GBP (Sterling)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.CNY": "CNY (Renminbi)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.AUD": "AUD (Australian dollar)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.CAD": "CAD (Canadian dollar)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.CHF": "CHF (Swiss franc)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.HKD": "HKD (Hong Kong dollar)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.SGD": "SGD (Singapore dollar)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.SEK": "SEK (Swedish krona)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.KRW": "KRW (South Korean won)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.NOK": "NOK (Norwegian krone)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.NZD": "NZD (New Zealand dollar)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.INR": "INR (Indian rupee)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.MXN": "MXN (Mexican peso)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.TWD": "TWD (New Taiwan dollar)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.ZAR": "ZAR (South African rand)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.BRL": "BRL (Brazilian real)",
  "custom_model_editor.right_bar.label.edit_pane.type.amount.DKK": "DKK (Danish krone)",
  "custom_model_editor.right_bar.label.edit_pane.type.numbers": "Numbers",
  "custom_model_editor.right_bar.label.edit_pane.type.region": "",
  "custom_model_editor.right_bar.label.edit_pane.date.default_format_label": "Date Default Format",
  "custom_model_editor.right_bar.label.edit_pane.time.default_format_label": "Time Default Format",
  "custom_model_editor.right_bar.label.edit_pane.amount.default_format_label": "Default Currency",
  "custom_model_editor.right_bar.label.edit_pane.default_formating.label.tooltip": "This serves only as a default format. The actual format can be changed by case later on during labelling.",
  "custom_model_editor.right_bar.label.edit_pane.discard_button": "Discard",

  "custom_model_editor.right_bar.label.frozen_field.type.text": "plain text",
  "custom_model_editor.right_bar.label.frozen_field.type.date": "date",
  "custom_model_editor.right_bar.label.frozen_field.type.date.US": "MM/DD/YYYY (US)",
  "custom_model_editor.right_bar.label.frozen_field.type.date.UK": "DD/MM/YYYY (UK)",
  "custom_model_editor.right_bar.label.frozen_field.type.date.ISO": "YYYY-MM-DD (ISO)",
  "custom_model_editor.right_bar.label.frozen_field.type.time": "time",
  "custom_model_editor.right_bar.label.frozen_field.type.time.hhmm": "hh:mm",
  "custom_model_editor.right_bar.label.frozen_field.type.time.hhmmss": "hh:mm:ss",
  "custom_model_editor.right_bar.label.frozen_field.type.time.hmmAMPM": "h:mm am/pm",
  "custom_model_editor.right_bar.label.frozen_field.type.amount": "monetary amount",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.USD": "USD",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.EUR": "EUR",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.JPY": "JPY",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.GBP": "GBP",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.CNY": "CNY",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.AUD": "AUD",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.CAD": "CAD",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.CHF": "CHF",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.HKD": "HKD",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.SGD": "SGD",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.SEK": "SEK",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.KRW": "KRW",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.NOK": "NOK",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.NZD": "NZD",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.INR": "INR",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.MXN": "MXN",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.TWD": "TWD",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.ZAR": "ZAR",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.BRL": "BRL",
  "custom_model_editor.right_bar.label.frozen_field.type.amount.DKK": "DKK",
  "custom_model_editor.right_bar.label.frozen_field.type.numbers": "numbers",

  "custom_model_editor.right_bar.add_new_label_button": "Add New Label",
  "custom_model_editor.custom_model_is_created": "Custom model is created.",
  "custom_model_editor.custom_model_is_saved": "Custom model is saved.",

  "custom_model_editor.remark_dialog.title": "Training Custom Model for Extraction Is In Limited Preview",
  "custom_model_editor.remark_dialog.desc": "You’re in the waiting list for this feature. Please tell us more about your use cases and we will contact you and prioritise this feature for you.",
  "custom_model_editor.remark_dialog.placeholder": "Enter any remarks about your documents and information to extract",

  "custom_model_editor.rename_dialog.title": "Rename the custom model",
  "custom_model_editor.rename_dialog.label": "Custom Model Name",

  "custom_model_editor.modifed_prompt.title": "This custom model has been modified",
  "custom_model_editor.modifed_prompt.desc": "This custom model has been modified outside this tab since you open it. Are you sure you want to save it?",

  "custom_model_grid.custom_model_label": "Custom Model",
  "custom_model_grid.new_custom_model_button": "Add New Custom Model",
  "custom_model_grid.add_samples_button": "Add Samples",
  "custom_model_grid.delete_files_button": "Delete Files",
  "custom_model_grid.delete_custom_model": "Custom Model",
  "custom_model_grid.custom_model_is_deleted": "Custom Model is deleted",
  "custom_model_grid.are_you_sure_to_delete_form_group": "Are you sure to delete the selected custom model?",
  "custom_model_grid.delete": "Delete",
  "custom_model_grid.total_files": "{totalFiles, plural, one{1 file} other{# files}}",
  "custom_model_grid.selected_files": "Selected {numSelectedFiles} / {totalFiles} {numSelectedFiles, plural, one{file} other{files}}",
  "custom_model_grid.uploading_files": "Uploading {numUploadingFile, plural, one{1 file} other{# files}}…",

  "custom_model_grid.no_model.title": "Create a Custom Model",
  "custom_model_grid.team_no_model.title": "Your team doesn't have any custom model yet.",
  "custom_model_grid.no_model.desc": "A custom model allows you to extract information if your document has various layout",
  "custom_model_grid.no_model.button": "Create a New Custom Model",
  "custom_model_grid.no_images": "No sample images.",
  "custom_model_grid.reminder": "Upload at least {count} samples. The more files you add, the better the results.",

  "custom_model_label.title": "Prepare your model for training",
  "custom_model_label.label.title": "Label all your samples",
  "custom_model_label.label.desc": "Show us where to extract your information in your sample files by labelling all fields on the images. The document area should be labelled as {documentLayout}.",
  "custom_model_label.label.desc2": "Make sure to label all your samples before training the model.",
  "custom_model_label.label.button": "Start labelling",
  "custom_model_label.train.title": "Train your model",
  "custom_model_label.train.desc": "Once you have labelled all your samples, you can train your model and use it to extract information from your documents, or in other extractors you have created in FormX.",
  "custom_model_label.train.button": "Train model",
  "custom_model_label.train.button.requested": "Training requested",
  "custom_model_label.train.button.in_progress": "Train in Progress",
  "custom_model_label.train.error": "Failed to trigger model training, please try again later",
  "custom_model_label.request_is_sent.title": "Training request is sent",
  "custom_model_label.request_is_sent.message": "We will review your samples and get back to you soon. Please make sure you have labelled all your samples.",
  "custom_model_label.request_is_sent.dismiss": "Dismiss",
  "custom_model_label.request_training.error": "Failed to request model training, please try again later",
  "custom_model_label.too_few_sample.title": "Not enough samples",
  "custom_model_label.too_few_sample.message": "Upload and label at least {count} samples to train the model",
  "custom_model_label.too_few_sample.understand": "Understand",
  "custom_model_label.training_failed.title": "Training failed",
  "custom_model_label.training_failed.message": "The model training you initiated at {datetime} has failed. Please contact us at {email} for assistance.",

  "custom_model_model.model_version": "Model Version",
  "custom_model_model.created_at": "Created At",
  "custom_model_model.labels": "Labels",
  "custom_model_model.actions": "Actions",
  "custom_model_model.button.model_training": "Model training",
  "custom_model_model.button.active_model": "Active Model",
  "custom_model_model.button.set_active": "Set as active",
  "custom_model_model.button.deploying": "Deploying",
  "custom_model_model.note1": "You can use this custom model in other extractors you have created to extract information according to the labels.",
  "custom_model_model.note2": "To use the model in other extractors, set the version of the model you want to use as “Active”, and then select this custom model in the Settings tab of the other extractor.",
  "custom_model_model.deploy.error": "Failed to deploy the custom model, please try again later. If the issue presist, please contact us at support@formextractorai.com",

  "custom_model_test.warn.deployment_in_progress": "Model deployment is in progress, the extraction result might not be available.",
  "custom_model_test.warn.no_deployed_model": "No model version is deployed for this custom model, the custom model extraction result will not be available.",

  "fsl_custom_model.error.unable_switch_to_instant_model": "Unable to switch to Instant Model, please try again later. If the issue presist, please contact us at support@formextractorai.com",

  "fsl_custom_model.tabs.manage_fields": "Manage Fields",
  "fsl_custom_model.tabs.instant_model": "Instant Model",
  "fsl_custom_model.tabs.standard_model": "Standard Model",
  "fsl_custom_model.tabs.formatter": "Formatter",
  "fsl_custom_model.tabs.test_extractor": "Test Extractor",
  "fsl_custom_model.tabs.test_extractor_v1": "Test Extractor(V1)",
  "fsl_custom_model.tabs.api": "API",
  "fsl_custom_model.tabs.settings": "Settings",

  "fsl_custom_model.prompt_builder.info_card.sample.title": "Samples",
  "fsl_custom_model.prompt_builder.info_card.sample.desc": "Sample files serve as references to train and improve the extractor performance.",
  "fsl_custom_model.prompt_builder.info_card.sample.button": "Upload the 1st sample",

  "fsl_custom_model.prompt_builder.info_card.ready_to_use.title": "Your extractor is ready to use!",
  "fsl_custom_model.prompt_builder.info_card.ready_to_use.desc": "Head to the EXTRACT tab for integration instructions or to the TEST tab for further testing.",
  "fsl_custom_model.prompt_builder.info_card.ready_to_use.button": "Test the model",
  "fsl_custom_model.prompt_builder.info_card.ready_to_use.result_not_looking_good": "Results not looking good?",

  "fsl_custom_model.prompt_builder.info_card.ready_to_use.sub_title": "Improve performance by uploading sample images",
  "fsl_custom_model.prompt_builder.info_card.ready_to_use.sub_desc": "Sample files serve as references to train and improve the extractor performance. You can increase the sample size to see if it leads to better performance, or you can {link}",
  "fsl_custom_model.prompt_builder.info_card.ready_to_use.sub_desc_link": "get help from FormX team.",
  "fsl_custom_model.prompt_builder.info_card.ready_to_use.sub_button": "Upload a new sample",

  "fsl_custom_model.prompt_builder.info_card.more_samples.title": "Samples",
  "fsl_custom_model.prompt_builder.info_card.more_samples.desc": "You can further boost performance by providing more sample! Corrected samples are all being used to train the model.",
  "fsl_custom_model.prompt_builder.info_card.more_samples.button": "Upload more sample",

  "fsl_custom_model.prompt_builder.info_card.more_samples.corrected": "Corrected",
  "fsl_custom_model.prompt_builder.info_card.more_samples.pending_review": "Pending Review",

  "fsl_custom_model.prompt_builder.info_card.more_samples.uploaded_at": "Uploaded At: ",

  "fsl_custom_model.prompt_builder.info_card.more_samples.warning1": "Review the extracted values of all samples with status - {highlighted}",
  "fsl_custom_model.prompt_builder.info_card.more_samples.warning1.highlighted": "Pending Review",

  "fsl_custom_model.prompt_builder.info_card.more_samples.warning2": "Sample files that have not been reviewed will not be used to train the model, this may result in less optimized performance",

  "fsl_custom_model.prompt_builder.warning_card.too_complex_doc.title": "Your document maybe too complex",
  "fsl_custom_model.prompt_builder.warning_card.too_complex_doc.desc": "Your document seems complex. FormX recommends you to upload at least 10 samples to better train the model. Or you can ",
  "fsl_custom_model.prompt_builder.warning_card.too_complex_doc.link": "get help from FormX team.",
  "fsl_custom_model.prompt_builder.warning_card.too_complex_doc.button": "Upload more samples",

  "fsl_custom_model.prompt_builder.warning_card.token_limit_exceed.title": "Your document maybe too complex",

  "fsl_custom_model.prompt_builder.warning_card.token_limit_exceed.desc": "Your document might need more advance handlings. Get in touch with us to see how we can help.",

  "fsl_custom_model.prompt_builder.warning_card.token_limit_exceed.button": "Get help from FormX team",

  "fsl_custom_model.prompt_builder.remove_confirmation.title": "Delete sample image?",
  "fsl_custom_model.prompt_builder.remove_confirmation.message": "Are you sure to delete the selected sample image?",

  "fsl_custom_model.field_schema_editor.document_type.header": "Document Type",
  "fsl_custom_model.field_schema_editor.document_type.title": "Document Type*",
  "fsl_custom_model.field_schema_editor.document_type.placeholder": "e.g. ‘Business Registration’ instead of ‘BR’",
  "fsl_custom_model.field_schema_editor.document_type.help_text": "Provide a descriptive and meaningful document name in full form. (e.g. use Business Registration' instead of 'BR’)",

  "fsl_custom_model.field_schema_editor.fields.add_new_field": "Add New Field",
  "fsl_custom_model.field_schema_editor.fields.field_name_placeholder": "New Field",

  "fsl_custom_model.field_schema_editor.fields.header": "Fields",

  "fsl_custom_model.field_schema_editor.sample_help_text": "Not sure how to create a document? Check out some examples!",

  "fsl_custom_model.field_schema_editor.templates_panel.help_text": "Or quick start with our templates",
  "fsl_custom_model.field_schema_editor.templates_panel.build_from_scratch_button": "Build from Scratch",
  "fsl_custom_model.field_schema_editor.templates_panel.select_template_button": "Select a Template",
  "fsl_custom_model.field_schema_editor.templates_panel.cont_button": "Continue with {template}",

  "fsl_custom_model.field_schema_editor.templates_panel.title": "Build your own data fields",
  "fsl_custom_model.field_schema_editor.no_field_panel.placeholder": "Start by either selecting a schema template or build your own from scratch",

  "fsl_custom_model.field_schema_editor.fields.extractor.business_registration": "Business Registration",
  "fsl_custom_model.field_schema_editor.fields.extractor.passport": "Passport",
  "fsl_custom_model.field_schema_editor.fields.extractor.air_waybill_llm": "Air Waybill",
  "fsl_custom_model.field_schema_editor.fields.extractor.bank_statement": "Bank Statement",
  "fsl_custom_model.field_schema_editor.fields.extractor.resume_llm": "Resume",
  "fsl_custom_model.field_schema_editor.fields.extractor.invoice": "Invoice",
  "fsl_custom_model.field_schema_editor.fields.extractor.purchase_order_llm": "Purchase Order",
  "fsl_custom_model.field_schema_editor.fields.extractor.quotation_llm": "Quotation",
  "fsl_custom_model.field_schema_editor.fields.extractor.bill_of_lading_instant_model": "Bill Of Lading",

  "fsl_custom_model.field_schema_editor.field_edit_panel.name.label": "Field Name",

  "fsl_custom_model.field_schema_editor.field_edit_panel.type.label": "Field Type",

  "fsl_custom_model.field_schema_editor.field_edit_panel.description.label": "Field Description (Optional)",
  "fsl_custom_model.field_schema_editor.field_edit_panel.description.placeholder": "Describe the format, type, and any expectations you have for the data output.",

  "fsl_custom_model.field_schema_editor.field_edit_panel.title": "Field Settings:",

  "fsl_custom_model.field_schema_editor.field_edit_panel.is_list.label": "This field appears more than once in the document",
  "fsl_custom_model.field_schema_editor.field_edit_panel.input_as_checkbox.label": "Input data can be provided in checkbox format",

  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.title": "Columns",

  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.add_sub_fields": "Add New Column",

  "fsl_custom_model.field_schema_editor.field_edit_panel.what_is_table.title": "What is a table?",
  "fsl_custom_model.field_schema_editor.field_edit_panel.what_is_table.desc": "Table is a group of columns such as product lines in invoices. Each row is a collection of related data. For example:",

  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.name": "Name",
  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.type": "Type",
  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.description": "Field Description (Optional)",
  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.description_placeholder": "Describe the format, type, and any expectations you..",

  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.placeholder_1": "e.g. item name",
  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.placeholder_2": "e.g. quantity",
  "fsl_custom_model.field_schema_editor.field_edit_panel.subfield.placeholder_3": "e.g. subtotal",

  "fsl_custom_model.field_schema_editor.no_field_defined": "There is no field defined in this schema",
  "fsl_custom_model.field_schema_editor.add_new_data_field": "Start Adding Data Fields",
  "fsl_custom_model.field_schema_editor.field_description": "Only allow a-z, 0-9 and _ . e.g. date_of_birth",

  "fsl_custom_model.field_schema_editor.save_schema": "Save Schema",

  "fsl_custom_model.field_schema_editor.title": "Field Schema",
  "fsl_custom_model.field_schema_editor.subtitle": "Tell us the fields you want to extract",

  "fsl_custom_model.field_schema_editor.error.field_required_error": "Field Required",
  "fsl_custom_model.field_schema_editor.error.duplicated_field_name_error": "Duplicated field",
  "fsl_custom_model.field_schema_editor.error.invalid_field_name_error": "Invalid format, only allow a-z, 0-9 and _-",
  "fsl_custom_model.field_schema_editor.error.empty_field_name_error": "You should set the field name",

  "fsl_custom_model.field_schema_editor.error.missing_field_error": "You should add at least one field",

  "fsl_custom_model.field_schema_editor.select_template": "Continue with this schema template",
  "fsl_custom_model.field_schema_editor.deselect_template": "See other schema templates / start from scratch",

  "fsl_custom_model.field_schema_editor.discard_dialog.title": "Discard changes?",
  "fsl_custom_model.field_schema_editor.discard_dialog.message": "Do you want to discard the changes you made?",
  "fsl_custom_model.field_schema_editor.discard_dialog.action": "Discard Changes",

  "fsl_custom_model.field_schema_editor.item_field.no_sub_field": "no column yet",

  "fsl_custom_model.field_schema_viewer.title": "Data Fields to Extract",
  "fsl_custom_model.field_schema_viewer.desc": "Tell us the fields you want to extract",
  "fsl_custom_model.field_schema_viewer.edit_schema_button": "Edit Data Fields",

  "fsl_custom_model.field_schema_editor.page_header.title": "Data Fields to Extract",
  "fsl_custom_model.field_schema_editor.page_header.desc": "Tell us the fields you want to extract",

  "fsl_custom_model.field_schema_viewer.setup_model_button": "Next step, Setup a model",

  "fsl_custom_model.field_schema_list.document_type": "Document Type:",

  "fsl_custom_model.prompt_builder.no_field_in_schema_warning_card.title": "No fields in schema",
  "fsl_custom_model.prompt_builder.no_field_in_schema_warning_card.desc": "Our model works only when you have told us the information you want to extract. Start by using our Field Schema Editor",

  "fsl_custom_model.field_schema_editor.delete_all_fields": "Delete All Fields",

  "fsl_custom_model.extraction_reviewer.header.title": "Review the extracted values",
  "fsl_custom_model.extraction_reviewer.header.desc": "Review the extracted values carefully to identify any incorrect data",

  "fsl_custom_model.extraction_reviewer.reviewed_header.title": "Reviewed Sample",
  "fsl_custom_model.extraction_reviewer.reviewed_header.desc": "FormX will use the testing image and results to enhance the performance",

  "fsl_custom_model.extraction_reviewer.correct_header.title": "Correct the model data",
  "fsl_custom_model.extraction_reviewer.correct_header.desc": "FormX will use the testing image and results to enhance the performance",

  "fsl_custom_model.extraction_reviewer.footer.value_accurate": "Are the extracted values accurate?",

  "fsl_custom_model.extraction_reviewer.footer.correct_data_button": "Correct the data",

  "fsl_custom_model.extraction_reviewer.footer.look_good_button": "Results are good",

  "fsl_custom_model.extraction_reviewer.footer.back_to_sample_buttom": "Back to samples",

  "fsl_custom_model.extraction_reviewer.footer.update_data_buttom": "Update Data",

  "fsl_custom_model.extraction_reviewer.footer.save_training_data": "Once you finish correcting the results, save the sample file as training data.",
  "fsl_custom_model.extraction_reviewer.footer.save_as_sample_button": "Save as a sample",

  "fsl_custom_model.extraction_reviewer.new_list_item": "New list item",

  "fsl_custom_model.extraction_reviewer.mission_bar2.title": "{progress} - Review the extraction results of the uploaded sample",
  "fsl_custom_model.extraction_reviewer.mission_bar2.title_progress": "Instant Model Setup 2/3",

  "fsl_custom_model.extraction_reviewer.accept_teaching_bubble.message1": "It implies that the FormX Instant model is able to extract data accurately without any training data needed.",
  "fsl_custom_model.extraction_reviewer.accept_teaching_bubble.message2": "Therefore, this uploaded image will not be saved as a training sample.",

  "fsl_custom_model.extraction_reviewer.save_as_sample_teaching_bubble.message1": "This image with corrected data will be saved as a training sample to enhance the model’s performance.",

  "fsl_custom_model.extraction_reviewer.teaching_bubble.title": "What does this mean?",
  "fsl_custom_model.extraction_reviewer.teaching_bubble.got_it": "Got it",

  "fsl_custom_model.extraction_reviewer.discard_update.title": "Unsaved change",
  "fsl_custom_model.extraction_reviewer.discard_update.message": "Are you sure to cancel? The extracted data will be reverted.",
  "fsl_custom_model.extraction_reviewer.discard_update.action": "Revert the changes",

  "fsl_custom_model.field_type.single_line_text": "Single-Line text",
  "fsl_custom_model.field_type.multi_line_text": "Multi-Line text",
  "fsl_custom_model.field_type.number": "Number",
  "fsl_custom_model.field_type.percentage": "Percentage",
  "fsl_custom_model.field_type.date": "Date",
  "fsl_custom_model.field_type.time": "Time",
  "fsl_custom_model.field_type.currency": "Currency",
  "fsl_custom_model.field_type.checkbox": "Checkbox (Beta)",
  "fsl_custom_model.field_type.field_group": "Table",
  "fsl_custom_model.field_type.is_list": " (List)",
  "fsl_custom_model.field_type.field_group.desc": "Table is a group of columns such as product lines in invoices",

  "fsl_custom_model.save_schema_confirmation_dialog.title": "Proceed to save?",
  "fsl_custom_model.save_schema_confirmation_dialog.message": "Updating the schema will require you to train the model again. Proceed to save changes?",
  "fsl_custom_model.save_schema_confirmation_dialog.action": "Save Changes",

  "fsl_custom_model.get_help_dialog.title": "Your help request is sent",
  "fsl_custom_model.get_help_dialog.message": "We've received your help request. You will be contacted by our representatives soon.",

  "fsl_custom_model.schema_not_set_warning.title": "🤖 We need to know what data you want",
  "fsl_custom_model.schema_not_set_warning.desc": "To train an extraction model, FormX needs to know what data to extract for you. Simply go to Manage Fields and tell us the data you want to extract.",

  "fsl_custom_model.mission_bar.manage_fields.wizard": "{progress} - Tell us the data you want to extract by adding data fields",
  "fsl_custom_model.mission_bar.manage_fields.progress": "Manage Fields Setup 1/1",
  "fsl_custom_model.mission_bar.manage_fields.updated": "Field schema updated, you might want to review the training samples to keep the extractor model up-to-date",
  "fsl_custom_model.mission_bar.manage_fields.created": "Congrats! Your schema is now set up. Next, upload some samples to set up the Instant Model.",

  "fsl_custom_model.sample_image.corrected": "Corrected",
  "fsl_custom_model.sample_image.pending_review": "Pending Review",
  "fsl_custom_model.sample_image.uploaded_at": "Uploaded at:",
  "fsl_custom_model.sample_image.not_started": "Not Started",
  "fsl_custom_model.sample_image.annotating": "In Progress",
  "fsl_custom_model.sample_image.annotated": "Labelled",
  "fsl_custom_model.sample_image.reviewed": "Labelled",

  "fsl_custom_model.fsl_sample_image.reviewed": "Reviewed",
  "fsl_custom_model.fsl_sample_image.pending_review": "Pending Review",

  "fsl_custom_model.api.schema_not_set.warning": "Setup a field schema first before sending request to the API endpoint",

  "fsl_instant_model_editor.page.title": "Instant Model - Training samples",
  "fsl_instant_model_editor.page.subtitle": "Provide us with 0-3 sample files, this helps with the extraction performance.",
  "fsl_instant_model_editor.delete_samples": "Delete Samples",

  "fsl_instant_model_editor.support_single_image_modal.title": "Instant Model supports only single image files",
  "fsl_instant_model_editor.support_single_image_modal.desc": "The instant model supports only single image file (e.g. jpg, png). Use the Standard model if you wish to extract other file formats",
  "fsl_instant_model_editor.support_single_image_modal.contact": "Contact FormX",
  "fsl_instant_model_editor.support_single_image_modal.use_standard_model": "Use Standard Model",

  "fsl_instant_model_editor.sample_image_grid_0": "Test with 1st Sample",
  "fsl_instant_model_editor.sample_image_grid_1": "Test with 2nd Sample",
  "fsl_instant_model_editor.sample_image_grid_2": "Test with 3rd Sample",
  "fsl_instant_model_editor.sample_image_grid_3": "Test with Final Sample",

  "fsl_instant_model_editor.sample_images.remove_confirmation.title": "Delete sample image?",
  "fsl_instant_model_editor.sample_images.remove_confirmation.message": "Are you sure to delete the selected sample image?",

  "fsl_instant_model_editor.mission_bar.try_standard_model_button": "Try Standard Model",
  "fsl_instant_model_editor.mission_bar.get_help_button": "Get help from FormX team",

  "fsl_instant_model_editor.mission_bar.first_step.title": "{progress} - Setup the Instant Extraction Model by uploading test samples",
  "fsl_instant_model_editor.mission_bar.first_step.title_progress": "Instant Model Setup 1/3",
  "fsl_instant_model_editor.mission_bar.first_step.subtitle": "Start with the ‘Instant Model’ with 0-3 samples to train, before using the more powerful Standard Model which requires at least 10+ samples",

  "fsl_instant_model_editor.mission_bar.last_step.title": "{progress} - Provide more samples to improve the extraction results",
  "fsl_instant_model_editor.mission_bar.last_step.title_progress": "Instant Model Setup 3/3",

  "fsl_instant_model_editor.mission_bar.ready_to_use.title": "The Instant Model is ready!",
  "fsl_instant_model_editor.mission_bar.ready_to_use.subtitle": "Head to ‘API’ for integration instructions or to ‘Test Extractor’ for further testing.",
  "fsl_instant_model_editor.mission_bar.ready_to_use.test_extractor": "Test Extractor",

  "fsl_instant_model_editor.mission_bar.document_too_complex.title": "Your documents is too complicated for Instant Model",
  "fsl_instant_model_editor.mission_bar.document_too_complex.subtitle": "Try using the Standard Model by providing more samples, or get in touch with us for help?",

  "fsl_instant_model_editor.uploading_status": "Uploading {processed} of {total} pages...",

  "fsl_instant_model_editor.sample_uploading_prompt.title": "Uploading mulit-page sample",
  "fsl_instant_model_editor.sample_uploading_prompt.save_warning": "Still uploading the multi-page sample, if you leave now, you will miss those pages which are not uploaded yet. Are you want to leave now?",
  "fsl_instant_model_editor.sample_uploading_prompt.go_back": "Go Back",
  "fsl_instant_model_editor.sample_uploading_prompt.leave_page": "Leave Page",

  "fsl_instant_model_editor.error.failed_to_fetch_grouped_custom_model_image": "Failed to fetch additional page of this sample, please reload and try again",

  "fsl_instant_model.switch_to_standard_model_modal.title": "Explore other extraction models?",
  "fsl_instant_model.switch_to_standard_model_modal.message": "This is the 4th samples you tested with the Intant model. FormX suggests you to explore the Standard Model and see if it yields better results",
  "fsl_instant_model.switch_to_standard_model_modal.action": "Try Standard Model",

  "fsl_standard_model_editor.page.title": "Standard Model - Training Samples",

  "fsl_standard_model_editor.page.subtitle": "Provide us with at least 10 sample files for training the model",

  "fsl_standard_model_editor.not_enabled_warning.title": "👋🏼 Hello There ~ Trouble deciding which model to use?",
  "fsl_standard_model_editor.not_enabled_warning.desc1": "In general, we recommend using the Instant Model first {br} as it requires only 0-3 samples as training data",
  "fsl_standard_model_editor.not_enabled_warning.desc2": "Use the Standard Model only when you want even better extraction accuracy after trying the Instant Model.",
  "fsl_standard_model_editor.not_enabled_warning.button": "Setup the Standard Model",

  "fsl_standard_model_editor.command_bar.upload_samples": "1. Upload Samples",

  "fsl_standard_model_editor.command_bar.label_samples": "2. Label Samples",

  "fsl_standard_model_editor.command_bar.train_model": "{prefix}. Train Model (Beta)",
  "fsl_standard_model_editor.command_bar.request_model_training": "{prefix}. Request model training",
  "fsl_standard_model_editor.command_bar.model_training_in_progress": "Model Training",

  "fsl_standard_model_editor.command_bar.delete_samples": "Delete Samples",
  "fsl_standard_model_editor.command_bar.add_sample": "Add Sample",

  "fsl_standard_model_editor.mission_bar.upload_samples.title1": "Standard Model Setup 1/3",
  "fsl_standard_model_editor.mission_bar.upload_samples.title2": " - Upload at least 10 samples to setup the model ({progress}/10)",

  "fsl_standard_model_editor.mission_bar.labelling.title1": "Standard Model Setup 2/3",
  "fsl_standard_model_editor.mission_bar.labelling.title2": " - Indicate the fields’ location on your samples using the labelling tool",

  "fsl_standard_model_editor.mission_bar.train_model.title1": "Standard Model Setup 3/3",
  "fsl_standard_model_editor.mission_bar.train_model.title2": " - Train the model and get the extractor ready",

  "fsl_standard_model_editor.mission_bar.trained_model.title": "Training Completed, the Standard Model is now ready!",
  "fsl_standard_model_editor.mission_bar.trained_model.subtitle": "Head to ‘API’ for integration instructions or to ‘Test Extractor’ for further testing.",

  "fsl_standard_model_editor.mission_bar.trained_but_expired.title": "Schema is being modified. Update the labelling and re-train the model again to reflect the latest changes in extractions",

  "fsl_standard_model_editor.status.samples": "{count} Samples",
  "fsl_standard_model_editor.status.labelled": "{count} Labeled",
  "fsl_standard_model_editor.status.selected": "{count} Selected",

  "fsl_standard_model_editor.training_banner.title": "Standard Model training in progress",
  "fsl_standard_model_editor.training_banner.desc": "It may take a while. Come back some time later to test the model.",

  "fsl_active_model_picker.title": "Active Model",
  "fsl_active_model_picker.desc": "Select which model you would like to use for testing and extraction.",
  "fsl_active_model_picker.no_model_set": "There is no model available, please train either an Instant model or a Standard model",
  "fsl_active_model_picker.model_version": "Model version",
  "fsl_active_model_picker.button": "Set Model as Active",

  "fsl_active_model_picker.instant_model": "Instant Model",
  "fsl_active_model_picker.standard_model": "Standard Model",

  "merchant_list_settings_modal.title": "Merchant Settings",
  "merchant_list_settings_modal.merchants_count": "{count}/{limit} {count, plural, one{Merchant} other{Merchants}}",
  "merchant_list_settings_modal.desc1": "FormX matches the merchant name extracted with a database. To improve the accuracy of your specific use case, provide a list of merchant names in the text box below. Type in a new line for each merchant name.",
  "merchant_list_settings_modal.textfield.placeholder": "One merchant name per new line.",

  "merchant_settings_modal.title": "Merchant Post-processing Settings",
  "merchant_settings_modal.desc1": "This feature enrich the original Merchant Name extraction. You can add rules with additional information to search for a specific merchant and transform the result into an ID that be mapped into your CRM system.",
  "merchant_settings_modal.desc2": "Tell us the information that is unique to the merchant, such as its name, phone number, URL, etc. When those information is detected, FormX will return the merchant ID matched with those information.",
  "merchant_settings_modal.desc2_highlighted": "unique",
  "merchant_settings_modal.search_placeholder": "Search by merchant info",

  "merchant_settings_modal.sort_by_alphabet": "A to Z",
  "merchant_settings_modal.sort_by_alphabet_reversed": "Z to A",
  "merchant_settings_modal.sort_by_earlier_updated": "Earliest Modified",
  "merchant_settings_modal.sort_by_latest_modified": "Latest Modified",
  "merchant_settings_modal.sort_placeholder": "Sort by...",

  "merchant_settings_modal.list_placeholder1": "Add custom merchant",
  "merchant_settings_modal.list_placeholder2": "OR",
  "merchant_settings_modal.list_placeholder3": "Add custom rules to extract merchant",

  "merchant_settings_modal.list_filter_empty": "We couldn’t find any result for “{filter}”",

  "merchant_settings_modal.list.default_merchant_name": "Merchant Name",
  "merchant_settings_modal.list.more_info": " + {count} More Info",

  "merchant_settings_modal.match_all_info": "Match ALL info",
  "merchant_settings_modal.match_one_info": "Match ONE info only",

  "merchant_settings_modal.form.merchant_id": "Merchant ID",
  "merchant_settings_modal.form.tips": "Make sure you fill in at least one merchant’s information",

  "merchant_settings_modal.form.match_one": "Match ANY of the following info",
  "merchant_settings_modal.form.match_all": "Match ALL filled info",

  "merchant_settings_modal.form.type_header": "Type of info",
  "merchant_settings_modal.form.info_header": "Info to match",
  "merchant_settings_modal.form.exact_match": "Exact match",
  "merchant_settings_modal.form.action": "Action",

  "merchant_settings_modal.field.brand_name": "Brand Name",
  "merchant_settings_modal.field.phone_number": "Phone Number",
  "merchant_settings_modal.field.fax_number": "Fax Number",
  "merchant_settings_modal.field.url": "URL",

  "merchant_settings_modal.field.brand_name_placeholder": "e.g. Tiffany & Co",
  "merchant_settings_modal.field.phone_number_placeholder": "e.g. +852 28765432",
  "merchant_settings_modal.field.fax_number_placeholder": "e.g. (852)28765432",
  "merchant_settings_modal.field.url_placeholder": "e.g. www.apple.com",

  "merchant_settings_modal.add_new_information": "+ Add New Information",

  "merchant_settings_modal.error.merchant_missing": "Merchant Name Required",
  "merchant_settings_modal.error.fill_at_least_one_info": "Need to specify at least 1 info",
  "merchant_settings_modal.error.unable_to_save": "Unable to save settings: some custom merchant names are missing required information.",

  "llm_completion_settings_modal.title": "LLM Completion Settings",
  "llm_completion_settings_modal.taskName": "Task Name",
  "llm_completion_settings_modal.prompt": "Prompt",
  "llm_completion_settings_modal.add_new_prompt": "Add new prompt...",
  "llm_completion_settings_modal.should_add_border_line_to_ocr": "Add border line in OCR result",
  "llm_completion_settings_modal.should_add_border_line_to_ocr.explaination": "Detect horizontal and vertical lines in document and add lines to OCR result",
  "llm_completion_settings_modal.should_output_prompt": "Output prompt",
  "llm_completion_settings_modal.should_split_prompt": "Split prompt to dialogs",
  "llm_completion_settings_modal.should_split_prompt.explaination": "For Few-shot learning. Use \"---INPUT---\" and \"---OUTPUT---\" (On its own line, without any whitespace) to indicate start of an example input and output",
  "llm_completion_settings_modal.should_preserve_horizontal_whitespace": "Preserve horizontal whitespace in OCR result",
  "llm_completion_settings_modal.should_preserve_vertial_whitespace": "Preserve vertical whitespace in OCR result",
  "llm_completion_settings_modal.top_p": "Top-p for Nucleus Sampling",
  "llm_completion_settings_modal.top_p.explaination": "e.g 0.1 means only the tokens comprising the top 10% probability mass are considered",
  "llm_completion_settings_modal.presence_penalty": "Presence penalty",
  "llm_completion_settings_modal.presence_penalty.explaination": "Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.",
  "llm_completion_settings_modal.frequency_penalty": "Frequency penalty",
  "llm_completion_settings_modal.frequency_penalty.explaination": "Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.",
  "llm_completion_settings_modal.model": "Model",
  "llm_completion_settings_modal.input_type.ocr_and_image.label": "Pass both OCR & image to LLM",
  "llm_completion_settings_modal.input_type.ocr_only.label": "Pass OCR only to LLM",
  "llm_completion_settings_modal.input_type.image_only.label": "Pass image only to LLM",

  "error.llm_completion_settings_modal.invalid_task_name": "Task name can only contains A-Z, a-z, 0-9 and _",
  "error.llm_completion_settings_modal.duplicated_task_name": "Task name must be unique",

  "import_form_group.title": "Import Combined Extractor",
  "import_form_group.use_existing_forms": "Use Existing Extractors",
  "import_form_group.use_existing_forms.desc": "If any existing extractors were duplicated with those you are uploading, by checking this checkbox, those existing extractors will be used instead of duplicating a new set for this combined extractor.",

  "create_receipt_group.title": "Create a New Receipt Group",
  "create_receipt_group.receipt_group_name": "Receipt Group Name",
  "create_receipt_group.create": "Create",

  "add_token.title": "Add token",
  "add_token.token_value": "Enter token value here",
  "add_token.add": "add",

  "header.admin": "Admin",
  "header.back_to_client": "Back to client portal",
  "header.editor": "Editor",
  "header.field_list": "Field List",
  "header.ocr_test": "Test",
  "header.ocr_api": "API",
  "header.receipt_edit": "Edit",
  "header.receipt_test": "Test",
  "header.edit": "Edit",
  "header.setup": "Set up",
  "header.test": "Test",
  "header.api": "API",
  "header.extract": "Extract",
  "header.label": "Label",
  "header.model": "Model",
  "header.forms": "Extractors",
  "header.form_groups": "Combined Extractors",
  "header.extractors": "Extractors",
  "header.custom_models": "Custom Models",
  "header.receipts": "Receipts",
  "header.usage": "Usage",
  "header.setting": "Setting",
  "header.change_password": "Change Password",
  "header.logout": "Logout",
  "header.payment": "Payment",
  "header.user_setting": "User Setting",
  "header.personal": "Personal",
  "header.new_team": "New Team",
  "header.manage_team": "Manage Team",
  "header.sign_out": "Sign out",
  "header.detect_documents": "Detect Documents",
  "header.detect_pii": "Detect PII",
  "header.audit": "Audit Log",
  "header.connected_apps": "Connected Apps",

  "header.docs": "Docs",
  "header.book_demo": "Book a demo",
  "change_password.title": "Change Password",
  "change_password.old_password": "Old Password",
  "change_password.new_password": "New Password",
  "change_password.verify_new_password": "Confirm New Password",
  "change_password.submit": "Submit",

  "new_team.title": "Create a New Team Account",
  "new_team.team_name": "Team Name",
  "new_team.team_region": "Where should we process your data?",
  "new_team.team_region.desc": "Please select your preferred region for data processing and storage. Once set, this setting cannot be altered.",

  "confirm.modal.challenge": "Please type ‘{challenge}’ to confirm.",

  "common.email": "Email",
  "common.email.placeholder": "Enter Email here",
  "common.email.empty": "Email cannot be empty",
  "common.username": "Username",
  "common.username.placeholder": "Enter Username here",
  "common.username.empty": "Username cannot be empty",
  "common.password": "Password",
  "common.password.placeholder": "Enter Password here",
  "common.password.empty": "Password cannot be empty",
  "common.retype_password": "Verify Password",
  "common.retype_password.placeholder": "Enter Password here again",
  "common.cancel": "Cancel",
  "common.close": "Close",
  "common.choose": "Choose",
  "common.confirm": "Confirm",
  "common.done": "Done",
  "common.continue": "Continue",
  "common.customize": "Customize",
  "common.rename": "Rename",
  "common.delete": "Delete",
  "common.dismiss": "Dismiss",
  "common.select": "Select",
  "common.discard": "Discard",
  "common.hide": "Hide",
  "common.skip": "Skip",
  "common.extract": "Extract",
  "common.fail_to_fetch_form": "Oops, we can't find your form!",
  "common.fail_to_fetch_form_group": "Oops, we can't find your combined extractor!",
  "common.fail_to_fetch_receipt_group": "Oops, we can't find your receipt group!",
  "common.fail_to_fetch_template": "Templates can't be fetched at the moment.",
  "common.fail_to_fetch_custom_model": "Oops, we can't find your custom model!",
  "common.fail_to_fetch_team_members": "Oops, we can't find your team members!",
  "common.fail_to_fetch_webhook": "Oops, we can't find your webhook!",
  "common.fail_to_fetch_workspace": "Oops, we can't find your workspace!",
  "common.fail_to_fetch_extraction": "Oops, we can't find your document!",
  "common.import_form_success": "The form is successfully imported",
  "common.import_extractor_success": "The extractor is successfully imported",
  "common.error.field_required": "Field required",
  "common.contact_us": "Contact us",

  "common.loading": "Loading...",
  "common.uploading": "Uploading...",
  "common.importing": "Importing...",
  "common.saving": "Saving...",
  "common.save": "Save",
  "common.save_and_overwrite": "Save and Overwrite",
  "common.choose_image": "Choose image",
  "common.choose_file": "Choose file",
  "common.upload_image": "Upload image",
  "common.replace_image": "Replace image",
  "common.upload_image_optional": "Upload Image (Optional)",
  "common.choose_a_file": "Choose a file",
  "common.yes": "Yes",
  "common.no": "No",
  "common.ok": "OK",
  "common.refresh": "Refresh",
  "common.edit": "Edit",
  "common.export": "Export",
  "common.submit": "Submit",
  "common.update": "Update",
  "common.on": "On",
  "common.off": "Off",
  "common.exporting": "Exporting...",
  "common.import": "Import",
  "common.remove": "Remove",
  "common.next": "Next",
  "common.copy": "Copy",
  "common.copied_to_clipboard": "Copied to clipboard",
  "common.create": "Create",
  "common.accept": "Accept",
  "common.accepting": "Accepting...",
  "common.back": "Back",
  "common.proceed": "Proceed",
  "common.read_more": "Read more",
  "common.empty": "",

  "error.config_snapshot_not_found": "Config Snapshot not found",
  "error.config_snapshot_already_bookmarked": "This version has already been bookmarked.",
  "error.unexpected_server_response": "Unexpected server response, please try again later.",
  "error.unknown_error": "Unknown error occured, please try again later.",
  "error.unmapped_error": "{message}",
  "error.network_error": "Network error, please try again.",
  "error.some_fields_are_empty": "Some fields are not filled in.",
  "error.user_not_found": "User does not exist.",
  "error.user_already_exists": "User already exists.",
  "error.invalid_email": "Invalid email format.",
  "error.password_not_match": "Passwords do not match.",
  "error.duplicated_user": "E-mail is already in use.",
  "error.fail_to_create_form": "Fail to create form.",
  "error.fail_to_create_form_group": "Fail to create combined extractor.",
  "error.fail_to_create_receipt_group": "Fail to create receipt group.",
  "error.fail_to_create_webhook": "Fail to create webhook.",
  "error.fail_to_create_extractor": "Fail to create extractor.",
  "error.fail_to_create_workspace": "Fail to create workspace.",
  "error.fail_to_upload_image.exceed_max_size": "Failed to upload master image. The file is exceeding the max file size of {maxSize}.",
  "error.fail_to_rename_receipt_group": "Fail to rename receipt group.",
  "error.number_of_receipt_group_reached_limit": "Number of receipt group reached limit.",
  "error.error_during_remove_form": "Error happened during removing form.",
  "error.error_during_remove_form_group": "Error happened during removing combined extractor.",
  "error.error_during_remove_receipt_group": "Error happened during removing receipt group.",
  "error.empty_language": "Empty language is not allowed.",
  "error.form_not_ready_for_ocr": "Extractor is not ready for OCR. Have you marked any anchor?",
  "error.create_receipt_group.empty_name": "Receipt group name cannot be empty.",
  "error.rename_receipt_group.empty_name": "Receipt group name cannot be empty.",
  "error.rename_form_group.empty_name": "Combined extractor name cannot be empty.",
  "error.add_token.invalid": "Token is invalid.",
  "error.create_form.empty_name": "Extractor name cannot be empty.",
  "error.create_form_group.empty_name": "Combined extractor name cannot be empty.",
  "error.create_form.no_image": "Image is required.",
  "error.google_service_account_key_is_not_set": "Google service account key is not set yet.",
  "error.azure_subscription_key_is_not_set": "Azure subscription key is not set yet.",
  "error.change_password.empty_old_password": "Old password is empty.",
  "error.change_password.empty_new_password": "New password is empty.",
  "error.change_password.inconsistent_new_password": "New passwords are not consistent.",
  "error.incorrect_password": "Incorrect password.",
  "error.incorrect_email_or_password": "Incorrect email or password. Please try again.",
  "error.cannot_get_image_dimension": "Cannot get image dimension. Image file might be corrupted.",
  "error.form_image_size_too_large": "File too large. Please try again with a smaller file (<5MB).",
  "error.invalid_token": "Invalid token.",
  "error.form_id_not_found": "Extractor ID not found.",
  "error.form_compute_feature_no_image": "No image was submitted for feature computation.",
  "error.cannot_load_image": "Cannot load image.",
  "error.no_access_token": "Fail to extract file without access tokens.",
  "error.image_dimension_exceed_limit": "Image dimension cannot exceed {max_image_size_in_pixel}px in either dimension, and total number of pixels cannot larger than {max_image_pixel}.",
  "error.master_image.cannot_load_image": "Cannot load image. Note that only image files are allowed for master image.",
  "error.uploaded_file_too_large": "File too large. Please try again with a smaller file (<5MB).",
  "error.form_not_found": "Extractor not found.",
  "error.form_not_ready": "Extractor not ready, make sure at least one anchor is defined.",
  "error.form_unmatched_query_image": "Query image is not match the specified form",
  "error.error_during_extracting_form_info": "Error during extracting form info",
  "error.form_group_not_found": "Combined extractor not found",
  "error.form_group_is_empty": "Combined extractor is empty",
  "error.custom_model_not_ready": "Fail to extract this file, please check your extractor settings",
  "error.receipt_group_id_not_found": "Receipt group ID not found",
  "error.fail_to_get_receipt_groups": "Fail to get receipt groups.",
  "error.fail_to_load_receipt_details": "Fail to load receipt details.",
  "error.error_during_extract_receipt_info": "Error during extracting receipt info",
  "error.error_during_accessing_google_vision_api": "Error during accessing Google Vision API \n ({detail})",
  "error.azure_computer_vision_setting_is_not_set": "Azure computer vision subscription key and/or endpoint is not set",
  "error.fail_to_save_form": "Failed to save form",
  "error.fail_to_update_form_group": "Failed to update combined extractor",
  "error.fail_to_import_form": "Failed to import form",
  "error.fail_to_import_form_group": "Failed to import combined extractor",
  "error.fail_to_export_form": "Failed to export form",
  "error.fail_to_export_extractor": "Failed to export extractor",
  "error.fail_to_import_extractor": "Failed to import extractor",
  "error.fail_to_save_extractor": "Failed to save extractor",
  "error.failed_to_import_csv": "Failed to import csv",
  "error.failed_to_list_extractions": "Failed to list extractions",

  "error.fail_to_open_script_editor": "Failed to open postprocessing script editor",
  "error.advance_token_setup.fail_to_save": "Fail to save. Please resolve all errors before saving",
  "error.extraction_timeout": "The extraction timed out. Please try with a smaller document. If the problem persists, contact us for help.",
  "error.extraction.not_found": "This document is no longer available",
  "error.extraction.result_not_found": "This document is no longer available",
  "error.export_timeout": "The export timed out. Please try with fewer documents. If the problem persists, contact us for help.",
  "error.webhook.empty_webhook_name": "Webhook Name is empty.",
  "error.webhook.empty_webhook_url": "Webhook URL is empty.",
  "error.webhook.empty_connect_with": "Connect With is empty.",
  "error.webhook.invalid_webhook_url": "Webhook URL is invalid.",
  "error.webhook.custom_headers.duplicated_name": "Name should be unique.",
  "error.webhook.custom_headers.invalid_name": "Invalid format, only allow a-z, 0-9 and _ -",
  "error.webhook.custom_headers.empty_name": "Header name is empty.",
  "error.webhook.custom_headers.x_worker_forbidden": "Header name begin with X-WORKER is forbidden.",
  "error.webhook.custom_headers.invalid_value": "Invalid format, only allow a-z, 0-9 and _ :;.,/\\\"'?!(){bracket}[]@<>=-+*#$&`|~^%",
  "error.webhook.custom_headers.too_large": "The content exceed the size limit.",
  "error.conflict_found": "This form has been modified outside this tab.",
  "error.password_too_simple": "The password must contain at least {passwordMinLength} characters, 1 number, 1 lowercase and uppercase letter, and 1 symbol from `~!@#$%^&*()-_+=",
  "error.password_is_same": "The new password is the same as the current password. Please use another password.",
  "error.password_is_reused": "Password reuse is forbidden. Please use another password.",
  "error.account_locked": "The account is locked. Please contact the administrator to unlock the account.",
  "error.password_expired": "Password expired, please change it to proceed.",
  "error.fail_to_add_token": "Fail to add token",
  "error.fail_to_add_custom_field": "Fail to add custom field",
  "error.fail_to_remove_custom_field": "Fail to remove custom field",
  "error.edit_custom_field.empty_name": "Name cannot be empty",
  "error.edit_custom_field.empty_token": "Token cannot be empty",
  "error.edit_custom_field.empty_pattern": "Pattern cannot be empty",
  "error.edit_custom_field.duplicated_name": "Name should be unique",
  "error.edit_custom_field.invalid_regexp": "Invalid regular expression",
  "error.regex_builder.invalid_pattern_length_range": "Minimum length > Maximum length",
  "error.usage.unknown_error": "Unknown error occurred when retrieving usage record, please reload the page",
  "error.anchor_not_found": "Anchor not found.  Your form might be out-of-sync, consider refresh.",
  "error.field_not_found": "Field not found.  Your form might be out-of-sync, consider refresh.",
  "error.detection_region_not_found": "Detection region not found. Your form might be out-of-sync, consider refresh.",
  "error.delete_fallback_form_failure": "Fallback form of combined extractor cannot be deleted.",
  "error.create_payment_method_failure": "Failed to create payment method.",
  "error.payment.required": "Free quota used up, please upgrade to paid plans.",
  "error.too_many_request": "You have called the extract API too frequent, please try again later.",
  "error.usage_reached_hard_limit": "Your usage has reached the plan quota limit, please try again later.",
  "error.subscription.unexpected": "Unexpected subscription error, please try again later.",
  "error.subscription.stripe.no.card.element": "Can't get Stripe card element.",
  "error.subscription.stripe.element.not.init": "Stripe element not initialised.",
  "error.stripe.key.empty": "Stripe key is empty",
  "error.stripe.load.failure": "Failed to load Stripe",
  "error.stripe.load.failure.try.refresh": "Failed to load Stripe, try refreshing.",
  "error.stripe.error": "Failed to handle payment action. Please try again later.",
  "errors.stripe.card.processing_error": "An error occurred while processing your card. Try again in a little bit.",
  "errors.stripe.card.card_declined": "Your card was declined.",
  "errors.stripe.card.incorrect_cvc": "Your card's security code is incorrect.",
  "errors.stripe.card.expired_card": "Your card has expired.",
  "error.subscription.stripe.invalid.card.number": "Invalid card number",
  "error.subscription.stripe.invalid.expiry.date": "Invalid expiry date",
  "error.subscription.stripe.invalid.cvc": "Invalid expiry date",
  "error.subscription.stripe.invalid.postal.code": "Invalid expiry date",
  "error.subscription.stripe.invalid.default": "Invalid card input",

  "error.image_token_modal.no_image": "Image is required.",
  "error.image_token_modal.empty_name": "Image token name cannot be empty.",
  "error.image_token_modal.duplicated_name": "Duplicated image token name.",
  "error.token_group_modal.empty_name": "Token group name cannot be empty.",
  "error.token_group_modal.duplicated_name": "Duplicated token group name.",
  "error.text_token_modal.empty_value": "Text token value cannot be empty.",
  "error.text_token_modal.duplicated_value": "Text Token has to be unique.",
  "error.text_token_modal.invalid_regexp": "Text Token value is invalid regular expression.",

  "error.custom_model.fail_to_create_custom_model": "Fail to create custom model",
  "error.custom_model.fail_to_get_custom_models": "Fail to get custom models",
  "error.custom_model.fail_to_get_custom_model": "Fail to get custom model",
  "error.custom_model.fail_to_save_custom_model": "Fail to save custom model",
  "error.custom_model.fail_to_delete_custom_model": "Fail to delete custom model",
  "error.custom_model.image_format_not_supported": "Uploaded image format not supported. Please try another image",
  "error.custom_model.some_image_not_supported": "Uploaded images contain unsupported and they are ignored",
  "error.custom_model.not_found": "Custom model not found.",

  "error.create_team.team_name.validation.empty_team_name": "Team name cannot be empty.",
  "error.create_team.team_name.validation.too_short": "Team name is too short. (Min: 3 characters)",
  "error.create_team.team_name.validation.too_long": "Team name is too long. (Max: 100 characters)",
  "error.create_team.team_name.validation.no_leading_or_trailing_whitespace": "Team name cannot start or end with whitespace.",
  "error.create_team.team_name.validation.no_multiple_spaces": "Team name cannot contain multiple spaces.",

  "error.create_team.lookup_id.validation.alphanumeric_or_hyphens_only": "Team ID can only contain alphanumeric characters or hyphens.",
  "error.create_team.lookup_id.validation.no_upper_case": "Team ID must not contain uppercase letters.",
  "error.create_team.lookup_id.validation.no_space": "Team ID must not contain spaces.",
  "error.create_team.lookup_id.validation.too_short": "Team ID is too short. (Min: 4 characters)",
  "error.create_team.lookup_id.validation.too_long": "Team ID is too long. (Max: 100 characters)",

  "error.create_team.failed": "Failed to create team.",
  "error.fail_to_load_member_list": "Fail to load member list.",

  "error.invite.empty_email": "Email cannot be empty.",
  "error.invite.invalid_email": "Invalid email.",
  "error.invite.team_user_already_exists": "Fail to send invitation to {email}. A team user with that email already exists.",
  "error.invite.invitation_already_exists": "Fail to send invitation to {email}. An invitation with that email already exists.",
  "error.invite.invalid_invitation": "Fail to accept the invitation. The invitation is expired or invalid.",
  "error.invite.invitation_email_not_match": "Fail to accept the invitation. Your email is not match with the invitation.",
  "error.invite.fail_to_accept": "Fail to accept the invitation.",
  "error.invite.not_found": "Invitation not found.",
  "error.invite.pending_limit_exceeded": "You have reached the limit of pending invitations. Please wait for the pending invitations to be accepted or rejected before sending more invitations.",

  "error.team.lookup_id_already_exists": "Team ID already exists.",
  "error.team.not_found": "Team not found.",

  "error.team.tokens.empty_name": "Name cannot be empty.",

  "error.audit_log.not_found": "There is no logs for the selected month",
  "error.audit_log.failed_to_load_audit_logs": "Failed to load audit logs. Please try again.",
  "error.audit_log.failed_to_generate_csv_file": "Failed to generate CSV file.",

  "error.give_trial.empty_quota": "Quota cannot be empty.",
  "error.give_trial.cannot_set_trial": "Failed to set trial.",
  "error.give_trial.cannot_clear_trial": "Failed to clear trial.",

  "error.upgrade_to_enterprise.empty_quota": "Quota cannot be empty.",
  "error.upgrade_to_enterprise.cannot_upgrade_to_enterprise": "Failed to upgrade to enterprise.",
  "error.give_trial.cannot_downgrade_enterprise": "Failed to downgrade from enterprise.",

  "error.merchant_list_settings.too_many_merchants": "Number of merchants exceeds the limit. ({count}/{limit})",
  "error.extractor.failed_to_fetch": "Failed to load extractor. Please try again later.",

  "error.sample_image_is_corrupted": "{filename} is corrupted.",
  "error.failed_to_upload_sample_image": "Failed to upload {filename}, please try again later.",
  "error.failed_to_delete_sample_image": "Failed to delete sample image, please try again later.",
  "error.cannot_render_image": "Cannot render image",

  "error.permission_denied": "Permission Denied.",
  "error.not_authenticated": "Not Authenticated.",
  "error.llm_error": "Error during extraction, please try again later (LLM)",
  "error.llm_token_limit_exceeded": "LLM token limit exceeded",
  "error.custom_model.get_help_not_sent": "Unexpected error: Your help request was not sent",

  "error.workspace.fail_to_create_workspace": "Fail to create workspace.",
  "error.workspace.fail_to_create_custom_model": "Fail to create custom model extractor.",
  "error.workspace.fail_to_create_pre_trained": "Fail to create pre-trained extractor.",
  "error.workspace.fail_to_update_workspace": "Fail to update workspace.",
  "error.workspace.fail_to_delete_workspace": "Fail to delete workspace.",
  "error.workspace.fail_to_export_extractions": "Fail to export extractions.",
  "error.workspace.fail_to_download_extraction": "Fail to download extraction.",
  "error.workspace.extraction_not_found": "This document is no longer available",
  "error.workspace.extraction_not_found.modal.title": "This document is no longer available",
  "error.workspace.extraction_not_found.modal.message": "This document is no longer available on FormX’s storage",
  "error.workspace.extraction_not_found.modal.action": "Go Back",
  "error.workspace.extraction_not_found.info": "This document is no longer available.\nShould you need any help, please {url}",
  "error.workspace.document_upload_unsupported": "Unsupported file type. Only support JPEG, PNG, PDF, BMP, TIFF, WEBP, HEIF or HEIC files.",

  "error.create_workspace_sharepoint_subscription.failed": "Failed to create SharePoint subscription.",

  "error.cancelled_by_user": "Cancelled by user",

  "error.cannot_use_workspace_without_async": "Workspace cannot be used without async operation",
  "error.workspace_not_found": "Workspace not found",
  "error.workspace_not_linked_to_extractor": "Workspace is not linked to the specified extractor / combined extractor",
  "error.image_quality_check_failed": "Poor image quality, reason: {reason}",
  "error.sharepoint_subscription_failed_to_create": "Failed to create SharePoint subscription",
  "error.sharepoint_subscription_folder_not_found": "Target SharePoint folder not found",
  "error.sharepoint_subscription_already_exists": "SharePoint subscription already exists",
  "error.import_archive_type_mismatched": "Import Error: Incompatible extractor type",

  "error.failed_to_enable_standard_model": "Failed to enable standard model",
  "error.failed_to_save": "Failed to save",
  "error.upload_document_type_unsupported": "Unsupported file type. Only support JPEG, PNG, PDF, BMP, TIFF, WEBP, HEIF or HEIC files.",

  "error.google_auth.access_denied": "You've denied access to your Google account. Please try again if you'd like to connect.",
  "error.google_auth.already_exists": "This Google account is already connected to another user.",
  "error.google_auth.invalid_access_token": "There was an issue with your Google sign-in. Please try again.",
  "error.google_auth.invalid_grant": "There was an issue with your Google sign-in. Please sign in again.",
  "error.google_auth.invalid_request": "There was an issue with your Google sign-in. Please try again.",
  "error.google_auth.invalid_scope": "We couldn't access the required Google account information. Please try again.",
  "error.google_auth.invalid_state": "There was an issue with your Google sign-in. Please start over.",
  "error.google_auth.missing_code": "We didn't receive the necessary information from Google. Please try signing in again.",
  "error.google_auth.missing_state_key": "We didn't receive the necessary information from Google. Please try again.",
  "error.google_auth.nonce_mismatch": "Your sign-in attempt couldn't be verified. Please try again.",
  "error.google_auth.server_error": "Google's servers encountered an error. Please try again later.",
  "error.google_auth.temporarily_unavailable": "Google sign-in is temporarily unavailable. Please try again later.",
  "error.google_auth.unauthorized_client": "This application is not authorized to use Google sign-in. Please contact support.",
  "error.google_auth.unexpected_state_key": "There was an unexpected error during sign-in. Please try again.",
  "error.google_auth.unknown": "An unknown error occurred. Please try again or contact support if the issue persists.",
  "error.google_auth.unsupported_response_type": "There's an issue with our Google sign-in setup. Please contact support.",
  "error.google_auth.user_cancelled": "You've cancelled the Google sign-in process. Feel free to try again when you're ready.",

  "language.english": "English",
  "language.traditional_chinese": "Traditional Chinese",

  "tesseract.psm.1": "Automatic page segmentation with OSD",
  "tesseract.psm.3": "Automatic page segmentation but no OSD",
  "tesseract.psm.4": "Single column of text of variable sizes",
  "tesseract.psm.5": "Single uniform block of vertically aligned text",
  "tesseract.psm.6": "Single uniform block of text",
  "tesseract.psm.7": "Single text line",
  "tesseract.psm.8": "Single word",
  "tesseract.psm.9": "Single word in a circle",
  "tesseract.psm.10": "Single character",
  "tesseract.psm.11": "Sparse text, no order",
  "tesseract.psm.12": "Sparse text with OSD",
  "tesseract.psm.13": "Raw line",
  "tesseract.preset.english_only": "English Only",
  "tesseract.preset.chinese_only": "Chinese Only",
  "tesseract.preset.mixed": "Mixed English and Chinese",
  "tesseract.preset.number": "Number",

  "azure.mode.printed": "Printed",
  "azure.mode.handwritten": "Hand-written",

  "google.mode.printed": "Printed",
  "google.mode.handwritten": "Hand-written",

  "symbol_detector.preset.checkbox": "Checkbox",

  "recognizer.tesseract": "Tesseract (OCR)",
  "recognizer.zbar": "ZBar (Barcode/QR Code)",
  "recognizer.google": "Google Cloud Vision",
  "recognizer.azure": "Azure Computer Vision",
  "recognizer.symbol_detector": "Checkbox",
  "recognizer.abbyy": "ABBYY (OCR)",

  "report.title": "Report",
  "report.ranking.title": "Top {size} Teams in the last {days} days",
  "report.new_teams.title": "New Teams in {days} days",

  "setting.title": "Setting",
  "setting.google_cloud_vision": "Google Cloud Vision",
  "setting.google_service_account_key": "Service Account Key ID",
  "setting.choose_service_account_key": "Choose service account key",
  "setting.uploaded_invalid_file": "File uploaded invalid",

  "setting.azure_computer_vision": "Azure Computer Vision",
  "setting.azure_subscription_key": "Subscription Key",
  "setting.azure_endpoint": "Endpoint",
  "setting.pricing": "Pricing",
  "setting.contact_us_about_getting_the_key": "Contact us about getting the key",

  "setting.google_cloud_vision_description": "This is required for:\n  - OCR with Google Cloud Vision engine\n  - Receipt information extraction",
  "setting.how_to_get_google_service_account_key": "How to get a Google service account key",
  "setting.how_to_enable_google_cloud_vision_api": "How to enable Google Could Vision API",

  "setting.azure_computer_vision_description": "This is required for:\n  - OCR with Azure Computer Vision engine",
  "setting.how_to_get_azure_subscription_key": "How to get Azure subscription key",

  "setting.update_fail": "Failed to update settings.",
  "setting.update_success": "Successfully updated settings.",
  "setting.modified_prompt.title": "Settings have been modified",
  "setting.modified_prompt.desc": "Settings have been modified outside this tab since you open it. Are you sure you want to save them?",

  "apinote.endpoint": "HTTP Endpoint",
  "apinote.header_parameters": "Parameters in HTTP request header",
  "apinote.form_parameters": "Parameters in form data",
  "apinote.param_name": "Name",
  "apinote.param_description": "Description",
  "apinote.default": "Default value:",
  "apinote.optional": "optional",
  "apinote.example": "cURL Example",
  "apinote.field_name": "Name",
  "apinote.field_type": "Type",
  "apinote.field_description": "Description",
  "apinote.response": "Fields in HTTP response",

  "apinote.toggle.multi_document": "Multi Document",
  "apinote.toggle.async_mode": "Asynchronous Mode",
  "apinote.toggle.on": "ON",
  "apinote.toggle.off": "OFF",

  "apinote.async.structure_same_as": "structure same as the /extract API",
  "apinote.async.using_async_mode": "Using the Asynchronous mode",
  "apinote.async.using_async_mode.desc1": "If the request takes too long to complete, you can use the asynchronous mode to avoid timeout.",
  "apinote.async.using_async_mode.desc2": "This can be enabled by the {tag} parameter either in request header or form data.",
  "apinote.async.job_id": "Job ID",
  "apinote.async.job_id.desc": "If an async job is successfully created, a {resp} response will be returned with {status} and {jobId}.",
  "apinote.async.getting_result": "Getting the extraction result",
  "apinote.async.getting_result.desc1": "The extraction result can be queried by polling the {endpoint} endpoint. Replace {jobIdParam} with the {jobId} you get in the response of {extractEndpoint} call.",
  "apinote.async.getting_result.desc2": "Send a {method} request to the endpoint with the access token in the header until the result is returned.",
  "apinote.async.getting_result.desc3": "The extraction result will be deleted 24 hours after the job is completed, no matter it has been queried or not.",
  "apinote.async.pending": "Pending",
  "apinote.async.completed": "Completed",

  "ocrapi.token_copied": "Access token is copied to clipboard",
  "ocrapi.copy_token": "Copy Access Token",
  "ocrapi.id_copied": "Extractor ID is copied to clipboard",
  "ocrapi.copy_id": "Copy Extractor ID",
  "ocrapi.open_in_desktop_app": "Open in Desktop App",
  "extract.description": "FormX will isolate extraction fields from the uploaded image then perform OCR on these fields.\n\nExtractor ID can be submitted as multipart/form-data or through a header. Image can be uploaded as is or through an image_url.\nFor uploading image directly, it can be uploaded in the request body or via multipart/form-data.\nOn the other hand, if you want to specify an image_url, it can be submitted via a header or multipart/form-data.",
  "extract.Content-Type.desc": "`image/jpeg` or `image/png` or `application/pdf`, required if sent image content in the request body",
  "extract.X-WORKER-TOKEN.desc": "Access token",
  "extract.X-WORKER-FORM-ID.desc": "Extractor ID",
  "extract.X-WORKER-IMAGE-URL.desc": "URL of image to extract fields in jpg or png format, or URL of a pdf file. This is required if request body is empty",
  "extract.X-WORKER-ENCODING.desc": "Encoding of the request body, allowed 'raw' or 'base64'",
  "extract.X-WORKER-PDF-DPI.desc": "DPI of the uploaded pdf file",
  "extract.X-WORKER-SHOW-CONFIDENCE.desc": "Flag for showing confidence score in response",
  "extract.X-WORKER-AUTO-ADJUST-IMAGE-SIZE.desc": "Flag for auto adjusting image size for better extraction result, it will take a longer for extraction if enabled",
  "extract.X-WORKER-MULTI-PAGE-PDF.desc": "Flag for treating different pages of the uploaded PDF file as different documents and extract all of them. If false, only the first page will be extracted",
  "extract.X-WORKER-DETECT-MULTI-DOCUMENT.desc": "Flag for detecting multiple documents from the first page. If false, only one document will be extracted per page",
  "extract.X-WORKER-RETURN-REQUEST-ID.desc": "Flag for showing request id in the response",
  "extract.X-WORKER-ASYNC.desc": "Flag for using the {asyncMode}",
  "extract.X-WORKER-ASYNC.action.asyncMode": "asynchronous mode",
  "extract.token.desc": "Access Token",
  "extract.form_id.desc": "Extractor ID",
  "extract.image.desc": "Image to extract fields in jpg or png format, or a pdf file. Either specific this or image_url",
  "extract.image_url.desc": "URL of image to extract fields in jpg or png format, or URL to a pdf file. Either specify this or image",
  "extract.pdf_dpi.desc": "DPI of the uploaded pdf file",
  "extract.show_confidence.desc": "Flag for showing confidence score in response",
  "extract.auto_adjust_image_size.desc": "Flag for auto adjusting image size for better extraction result, it will take a longer for extraction if enabled",
  "extract.multi_page_pdf.desc": "Flag for treating different pages of the uploaded PDF file as different documents and extract all of them. If false, only the first page will be extracted",
  "extract.return_request_id.desc": "Flag for showing request id in the response",
  "extract.detect_multi_document.desc": "Flag for detecting multiple documents from the first page. If false, only one document will be extracted per page",
  "extract.async.desc": "Flag for using the {asyncMode}",
  "extract.async.action.asyncMode": "asynchronous mode",
  "extract.field.status.desc": "\"ok\" if success, \"failed\" if failed",
  "extract.field.form_id.desc": "Extractor ID",
  "extract.field.fields.desc": "List of extracted fields and fields in detection regions",
  "extract.field.auto_extraction_items.desc": "List of detected auto extraction items",
  "extract.field.key_values.desc": "List of detected key-value pair",
  "extract.field.token_groups.desc": "List of detected token groups",
  "extract.field.error.desc": "Only exists if failed, its shape depends on the failure, but it will always has \"code\" and \"message\" fields",
  "extract.field.documents.desc": "List of extracted documents",

  "extract.field.Document.form_id.desc": "Extractor ID",
  "extract.field.Document.fields.desc": "List of extracted fields and fields in detection regions",
  "extract.field.Document.auto_extraction_items.desc": "List of detected auto extraction items",
  "extract.field.Document.key_values.desc": "List of detected key-value pair",
  "extract.field.Document.token_groups.desc": "List of detected token groups",
  "extract.field.Document.error.desc": "Only exists if failed, its shape depends on the failure, but it will always has \"code\" and \"message\" fields",

  "extract.field.Field.region_id.desc": "Detection region ID",
  "extract.field.Field.name.desc": "Field label",
  "extract.field.Field.type.desc": "Field type",
  "extract.field.Field.value.desc": "Extracted content",
  "extract.field.Field.error.desc": "Message of the error if exists",
  "extract.field.Field.confidence.desc": "Confidence score. It only exists if confidence score is enabled in request. For field with type in name and adress, it will be shown inside value field.",

  "extract.field.AutoExtractionItem.name.desc": "Item name",
  "extract.field.AutoExtractionItem.value.desc": "Item value",
  "extract.field.AutoExtractionItem.confidence.desc": "Confidence score. It only exists if confidence score is enabled in request. For name, address job_title item, it will be shown inside value field.",

  "extract.field.KeyValue.name.desc": "Item name",
  "extract.field.KeyValue.value.desc": "Item value",
  "extract.field.KeyValue.confidence.desc": "Confidence score. It only exists if confidence score is enabled in request.",

  "extract.field.TokenGroup.name.desc": "Token group name",
  "extract.field.TokenGroup.texts.desc": "List of detected text tokens in this group.",
  "extract.field.TokenGroup.images.desc": "List of detected image tokens in this group.",

  "extract.field.Token.id.desc": "Token id",
  "extract.field.Token.value.desc": "Token value",
  "extract.field.Token.confidence.desc": "Confidence score. It only exists if confidence score is enabled in request.",

  "extract.field.CustomModel.id.desc": "Custom model id",
  "extract.field.CustomModel.model_version.desc": "Custom model verison",
  "extract.field.CustomModel.name.desc": "Custom model name",
  "extract.field.CustomModel.result.desc": "Extraction result of the custom model, its structure vary among different custom model.",

  "extract.section.api": "Extraction by API Integration",
  "extract.section.batch": "Extraction via Desktop App",
  "extract.method": "Method {method}: ",
  "extract.api.title": "Integrating with API",
  "extract.api.http_endpoint": "HTTP Endpoint",
  "extract.api.extractor_id": "Extractor ID",
  "extract.api.workspace_id": "Workspace ID",
  "extract.api.access_token": "Access Token",

  "extract.api.overview": "Overview",
  "extract.api.desc.1": "Send the image with a POST request to the Extract API endpoint and FormX will recognize the information from the document.",
  "extract.api.desc.2": "FormX will use the extractor of your choice to extract and return the data in a JSON format. The extractor can be specified by the extractor_id parameter. An Access Token should also be included. They can be obtained from the web portal dashboard.",
  "extract.api.info.title": "Want to know more?",
  "extract.api.info.desc": "Visit {url} for detailed API Reference",
  "extract.api.info.desc.reference": "FormX Interactive Doc",
  "extract.api.code_samples": "Code Samples",
  "extract.api.code_samples.curl": "cURL",
  "extract.api.code_samples.nodejs": "Node.js",
  "extract.api.code_samples.go": "Go",
  "extract.api.code_samples.php": "PHP",
  "extract.contact_us": "Contact us",
  "extract.contact_us.desc": "If you need any help from our FormX AI experts, you can leave your questions at {url}",
  "extract.batch.no_token": "There is no token available. {url}",
  "extract.batch.no_token.create_here": "Create one here",
  "extract.workspace.title": "Extracting with Workspace",
  "extract.workspace.description": "When APIs aren't your go-to, turn to Workspace. Workspace is your control centre for the entire extraction journey: import, extract, review, and export.\n\n{b, react, children{Import:}} Upload documents manually to FormX or import from Share Point folders.\n\n{b, react, children{Review:}} Coming soon, you'll have the power to review each extraction result. Export only confirmed outcomes, fine-tuning the precision of your extracted data.\n\n{b, react, children{Export:}} Choose your path – manual export to Excel or leverage Webhooks for an automated results delivery.\n\nIn a nutshell, FormX Workspace is your destination for:\n1. {b, react, children{Excel Exports}}: Upload documents in bulk and export extraction results to an excel spreadsheet.\n2. {b, react, children{Automated Extraction}}: Set up a streamlined process – import from Sharepoint folders, export with Webhooks\n3. {b, react, children{Result Review}} (coming soon!): Manage and review extraction outcomes for maximum accuracy.",
  "extract.workspace.list_header": "Workspaces of {name} extractor",
  "extract.workspace.go_to_workspace": "Go to Workspace",
  "extract.workspace.create_workspace": "Create a workspace",
  "extract.workspace.no_connected_workspace": "No connected workspaces",

  "date_format.settings.modal.title": "Date Settings",
  "date_format.settings.modal.date_input_format.title": "Default Input Date Format",
  "date_format.settings.modal.date_input_format.desc": "FormX will return the date in the YYYY-MM-DD format. It intelligently detects the date format based on the context and converts it into the YYYY-MM-DD format. In cases of ambiguity, the input date order mentioned above will be utilized.",

  "receipt_dropdown.placeholder": "Select a Receipt Group",
  "receipt.empty": "No Receipt Group Found",

  "receipt_edit.delete": "Delete Receipt group",

  "receipt_api.token_copied": "Access token is copied to clipboard",
  "receipt_api.copy_token": "Copy Access Token",
  "receipt_api.id_copied": "Receipt group ID is copied to clipboard",
  "receipt_api.copy_id": "Copy Receipt group ID",

  "extract_receipt_info.Content-Type.desc": "`image/jpeg` or `image/png` or `application/pdf`, required if sent image content in the request body",
  "extract_receipt_info.X-WORKER-TOKEN.desc": "Access Token",
  "extract_receipt_info.X-WORKER-RECEIPT-GROUP-ID.desc": "Receipt group ID",
  "extract_receipt_info.X-WORKER-IMAGE-URL.desc": "URL of image to extract fields in jpg or png format, or URL of a pdf file. This is required if request body is empty",
  "extract_receipt_info.X-WORKER-ENCODING.desc": "Encoding of the request body, allowed 'raw' or 'base64'",
  "extract_receipt_info.X-WORKER-PDF-DPI.desc": "DPI of the uploaded pdf file",
  "extract_receipt_info.receipt_group_id.desc": "Receipt group ID",
  "extract_receipt_info.image.desc": "Image to extract fields in jpg or png format, or a pdf file. Either specific this or image_url",
  "extract_receipt_info.image_url.desc": "URL of image to extract fields in jpg or png format, or URL to a pdf file. Either specify this or image",
  "extract_receipt_info.field.status.desc": "\"ok\" if success",
  "extract_receipt_info.field.receipt_group_id.desc": "Receipt group ID",
  "extract_receipt_info.field.amount.desc": "Extracted total amount",
  "extract_receipt_info.field.date.desc": "Extracted date",
  "extract_receipt_info.field.time.desc": "Extracted time",
  "extract_receipt_info.field.tokens.desc": "List of detected pre-defined tokens",
  "extract_receipt_info.field.custom_fields.desc": "List of detected custom fields",
  "extract_receipt_info.field.error.desc": "Only exists if failed, its shape depends on the failure, but it will always has \"code\" and \"message\" fields",

  "extract_receipt_info.field.CustomField.name.desc": "Name of the detected custom field",
  "extract_receipt_info.field.CustomField.value.desc": "Value of the detected custom field",

  "extract_receipt_info.description": "Extract info from from the uploaded receipt image include total amount, date and time,\nalso check the existence of predefined token.\n\nUser could upload the image and specified the form id as multipart/form-data,\nor it could specify form id in header and put image content in the request body.\n\nUser could also specify image url in header or multipart/form-data.",

  "detect_documents.Content-Type.desc": "`image/jpeg` or `image/png` or `application/pdf`, required if sent image content in the request body",
  "detect_documents.X-WORKER-TOKEN.desc": "Access token",
  "detect_documents.X-WORKER-IMAGE-URL.desc": "URL of image to detect documents in jpg or png format, or URL of a pdf file. This is required if request body is empty",
  "detect_documents.X-WORKER-ENCODING.desc": "Encoding of the request body, allowed 'raw' or 'base64'",
  "detect_documents.X-WORKER-PDF-DPI.desc": "DPI of the uploaded pdf file",
  "detect_documents.image.desc": "Image to detect documents in jpg or png format, or a pdf file. Either specific this or image_url",
  "detect_documents.image_url.desc": "URL of image to detect documents in jpg or png format, or URL to a pdf file. Either specify this or image",
  "detect_documents.pdf_dpi.desc": "DPI of the uploaded pdf file",
  "detect_documents.field.status.desc": "\"ok\" if success",
  "detect_documents.field.documents.desc": "List of detected documents",
  "detect_documents.field.error.desc": "Only exists if failed, its shape depends on the failure, but it will always has \"code\" and \"message\" fields",

  "detect_documents.field.Document.bbox.desc": "Bounding box of the detected document",
  "detect_documents.field.Document.bbox_score.desc": "Confidence score of the detected bounding box",
  "detect_documents.field.Document.type.desc": "Type of the detected document",
  "detect_documents.field.Document.type_score.desc": "Confidence score of the detected type",

  "detect_documents.description": "FormX will detect documents such as ID card and receipt from the uploaded image.\nIt will return the bounding box and type of each detected document.\n\nImage can be uploaded as is or through an image_url.\nFor uploading image directly, it can be uploaded in the request body or via multipart/form-data.\nOn the other hand, if you want to specify an image_url, it can be submitted via a header or multipart/form-data.",
  "detect_documents.desc.1": "FormX will detect documents such as ID card and receipt from the uploaded image. It will return the bounding box and type of each detected document.",
  "detect_documents.desc.2": "Image can be uploaded as is or through an image_url. For uploading image directly, it can be uploaded in the request body or via multipart/form-data. On the other hand, if you want to specify an image_url, it can be submitted via a header or multipart/form-data.",

  "detect_pii.preview_title": "PII Entities",
  "detect_pii.desc.1": "FormX will detect personally identifiable information (PII) such as name, address, email, identity number from the uploaded image. It will return the bounding boxes and type of each detected text block.",
  "detect_pii.desc.2": "Image can be uploaded as is or through an image_url. For uploading image directly, it can be uploaded in the request body or via multipart/form-data. On the other hand, if you want to specify an image_url, it can be submitted via a header or multipart/form-data.",

  "key_value_modal.title.create": "Create Key Value",
  "key_value_modal.title.edit": "Edit Key Value",

  "edit_custom_field.title.create": "Create custom field",
  "edit_custom_field.title.edit": "Edit custom field",
  "edit_custom_field.submit.create": "Create",
  "edit_custom_field.submit.edit": "OK",
  "edit_custom_field.pattern": "Pattern (RegExp) to match",
  "edit_custom_field.position": "Position",
  "edit_custom_field.use_fuzzy_search": "Use Fuzzy Search",
  "edit_custom_field.position.left": "Left",
  "edit_custom_field.position.right": "Right",
  "edit_custom_field.position.below": "Below",
  "edit_custom_field.position.above": "Above",
  "edit_custom_field.regex_builder_button": "Builder",
  "edit_custom_field.name": "Name",
  "edit_custom_field.token": "Token",

  "regex_builder.title": "Regex Builder",
  "regex_builder.pattern_choice": "Preset Pattern",
  "regex_builder.pattern_choice.lowercase_alphabet": "Lowercase Alphabet",
  "regex_builder.pattern_choice.uppercase_alphabet": "Uppercase Alphabet",
  "regex_builder.pattern_choice.chinese_char": "Chinese Character",
  "regex_builder.pattern_choice.number": "Number",
  "regex_builder.pattern_choice.dot": "Dot",
  "regex_builder.pattern_length": "Length",
  "regex_builder.pattern_range_min": "Min",
  "regex_builder.pattern_range_max": "Max",
  "regex_builder.apply": "Apply",
  "regex_builder.instruction.zero_for_unspecified_length": "* Enter 0 for unspecified Min/Max length",
  "regex_builder.instruction.same_value_for_exact_length": "* Set Min = Max for exact length",

  "usage.api.extract": "Extract API",
  "usage.api.extract_fields": "Extract Fields API (Obsolete)",
  "usage.api.extract_receipt_info": "Extract Receipt Info API",
  "usage.api.detect_documents": "Detect Documents API",
  "usage.api.detect_pii": "Detect PII API",
  "usage.usage_view.title": "Usage History",

  "usage.table.name": "Name",
  "usage.table.entity_id": "Entity ID",
  "usage.table.unclassified_count": "Unclassified",
  "usage.table.sync_count": "Sync",
  "usage.table.async_count": "Async",
  "usage.table.total_count": "Total",
  "usage.table.total": "Total: {count}",
  "usage.table.deleted_entity": "(Deleted)",
  "usage.table.revoked_token": "(Revoked)",

  "usage.dropdown.all": "All",

  "usage.date_range_selector.title": "Search History by Date Range",
  "usage.date_range_selector.from": "From",
  "usage.date_range_selector.to": "To",
  "usage.date_range_selector.access_token": "Access Token",
  "usage.date_range_selector.select_access_token": "Select an access token",
  "usage.date_range_selector.search": "Search",

  "alert.google_service_account_key_is_required_for_receipt_info_extract": "You need to set the Google service account key and enable Google Could Vision API before doing receipt information extraction.\nClick Setting button near top right corner to go to setting page and set there.",
  "alert.template_google_setting_is_required": "To use this template, you need to set the Google service account key and enable Google Could Vision API first.",
  "alert.template_azure_setting_is_required": "To use this template, you need to set the Azure Subscription Key and Endpoint first.",
  "alert.set_up": "Set up",

  "scriptdoc.instruction.toggle": "Script Instructions",
  "scriptdoc.parameters": "Parameters:",
  "scriptdoc.return_value": "Return Value:",
  "scriptdoc.optional": "Optional",

  "scriptdoc.fuzzy_search.desc": "Searches through a text with user-defined pattern",
  "scriptdoc.fuzzy_search.pattern.desc": "Will be used to search target text",
  "scriptdoc.fuzzy_search.text.desc": "The target text where search will be conducted upon",
  "scriptdoc.fuzzy_search.options.desc": "Options for configuring the fuzzy search behaviour",
  "scriptdoc.fuzzy_search.options.max_subs.desc": "maximum substitutions between the pattern and return string",
  "scriptdoc.fuzzy_search.options.max_insert.desc": "amount of character that can be skipped in the pattern",
  "scriptdoc.fuzzy_search.options.max_delete.desc": "amount of character that can be skipped in the return string",
  "scriptdoc.fuzzy_search.options.max_l_dist.desc": "maximum Levenshtein distance allowed between the pattern and return string",
  "scriptdoc.fuzzy_search.return_value.matches.desc": "array of matches",
  "scriptdoc.fuzzy_search.return_value.matches.start.desc": "start position of the match",
  "scriptdoc.fuzzy_search.return_value.matches.end.desc": "end position of the match",
  "scriptdoc.fuzzy_search.return_value.matches.dist.desc": "Levenshtein distance between the matched part and the pattern",
  "scriptdoc.fuzzy_search.return_value.matches.matched.desc": "matched part",

  "scriptdoc.edit_distance.desc": "Returns the Levenshtein distance (edit distance) between two strings, which is a string metric for measuring the difference.",
  "scriptdoc.edit_distance.string1.desc": "First string that will be compared with the other",
  "scriptdoc.edit_distance.string2.desc": "Second string that will be compared with the first",
  "scriptdoc.edit_distance.return_value.edit_distance.desc": "Levenshtein distance between string1 and string2",

  "payment.plans.free": "Free Trial",
  "payment.plans.pay_as_you_go": "Pay-as-you-go",
  "payment.plans.starter": "Starter",
  "payment.plans.enterprise": "Enterprise",
  "payment.plans.trial": "Trial",

  "payment.plan.free.title": "Free Trial",
  "payment.plan.free.price": "$0",
  "payment.plan.free.description": "Try out our features!",
  "payment.plan.free.action": "Now on Free Trial",
  "payment.plan.free.details.item1": "100 free pages",

  "payment.plan.pay_as_you_go.title": "Pay-as-you-go",
  "payment.plan.pay_as_you_go.price.amount": "$0.3",
  "payment.plan.pay_as_you_go.price.unit": "/page",
  "payment.plan.pay_as_you_go.description": "Best for limited or one off usage",
  "payment.plan.pay_as_you_go.action.current": "Now on Pay-as-you-go",
  "payment.plan.pay_as_you_go.action.enabled": "Upgrade to Pay-as-you-go",
  "payment.plan.pay_as_you_go.action.contact_us": "Contact Us",
  "payment.plan.pay_as_you_go.details.item1": "$0.3 for each subsequent page extraction",
  "payment.plan.pay_as_you_go.details.item2": "Get support via our community forum at {span, react, className{text-primary-500} children{{a, react, children{FormX Support} href{https://www.formx.ai/talk-with-us} target{_blank}}}} or email",

  "payment.plan.starter.title": "Starter",
  "payment.plan.starter.tag": "Popular",
  "payment.plan.starter.price.amount": "$299",
  "payment.plan.starter.price.unit": "mo/extractor",
  "payment.plan.starter.description": "Best suited for medium-sized and regular usage",
  "payment.plan.starter.action.current": "Now on Starter",
  "payment.plan.starter.action.enabled": "Upgrade to Starter",
  "payment.plan.starter.action.contact_us": "Contact Us",
  "payment.plan.starter.details.item1": "Up to 3,000 page extractions",
  "payment.plan.starter.details.item2": "$0.1 per additional page extraction",
  "payment.plan.starter.details.item3": "Get support via our community forum at {span, react, className{text-primary-500} children{{a, react, children{FormX Support} href{https://www.formx.ai/talk-with-us} target{_blank}}}} or email",

  "payment.plan.enterprise.title": "Enterprise",
  "payment.plan.enterprise.price": "Custom pricing",
  "payment.plan.enterprise.description": "Tailored solutions designed for enterprise-scale needs",
  "payment.plan.enterprise.action.current": "Now on Enterprise",
  "payment.plan.enterprise.action.contact_us": "Contact Us",
  "payment.plan.enterprise.details.item1": "All features of the Starter Plan",
  "payment.plan.enterprise.details.item2": "Customizable SLAs",
  "payment.plan.enterprise.details.item3": "Dedicated Account Manager",
  "payment.plan.enterprise.details.item4": "Comprehensive client onboarding process",
  "payment.plan.enterprise.details.item5": "Private cloud deployment for enhanced security",
  "payment.plan.enterprise.details.item6": "Unlock additional features: eKYC, mobile SDKs, and custom standard model training.",

  "payment.plan.header": "Plans & Billing",
  "payment.plan.currency_note": "All prices are in USD",
  "payment.alert.box.reminder": "You are not subscribed to any plan. Please upgrade to a suitable plan to continue using our service.",
  "payment.subscription_pending_cancel.message": "Your current “{plan}” plan will end at the end of the day on {date}.",

  "payment.manage_subscription.title": "Billing Details",
  "payment.manage_subscription.description": "Payment information, transaction history, and actions related to your plan",
  "payment.manage_subscription.edit.title": "Edit information",
  "payment.manage_subscription.edit.description": "Update your billing information",
  "payment.manage_subscription.view_records.title": "Payment record",
  "payment.manage_subscription.view_records.description": "The transaction history you made",
  "payment.manage_subscription.cancel": "Cancel Subscription",

  "sentry.report": "Report Error",
  "sentry.error_occurred": "Oops... We have just crashed. Please refresh and try again.",

  "form_editor.form_is_saved": "Extractor is saved.",
  "form_editor.form_is_saved_but_recommend_add_anchor": "Extractor is saved. Still you are recommended to have at least 2 Anchors set up.",
  "form_editor.fail_to_save_form": "Failed to save form, please refresh and try again.",
  "form_editor.setting_is_saved": "The new settings are saved.",
  "form.editor.no.image.placeholder.instruction1": "Upload a Master Image",
  "form.editor.no.image.placeholder.instruction2": "What is a Fixed Layout Document?",
  "form.editor.no.image.placeholder.instruction3": "Some examples include: {highlights}",
  "form.editor.no.image.placeholder.instruction3.highlights": "License registration forms, Financial Reports, Identity Documents etc.",
  "form.editor.no.image.placeholder.multi_document.title": "The file contains multiple documents",
  "form.editor.no.image.placeholder.dos": "Do's",
  "form.editor.no.image.placeholder.dos.item1": "High quality scan",
  "form.editor.no.image.placeholder.dos.item2": "Eligible labels and text",
  "form.editor.no.image.placeholder.dos.item3": "Document with an unchanging format",
  "form.editor.no.image.placeholder.donts": "Don'ts",
  "form.editor.no.image.placeholder.donts.item1": "Low resolution image",
  "form.editor.no.image.placeholder.donts.item2": "Unclear labels and text",
  "form.editor.no.image.placeholder.donts.item3": "Wrinkles, odd angles & glares",
  "form.editor.no.image.uploading": "Uploading image...",
  "form.editor.no.image.missing.image": "No image selected",
  "form.editor.no.image.upload.button": "Upload Now",
  "form_editor.form_not_saved_prompt.save_warning": "Your have unsaved changes. Would you like to save them before leaving?",
  "form_editor.form_not_saved_prompt.title": "Save changes?",
  "form_editor.form_not_saved_prompt.save_changes": "Save changes",
  "form_editor.form_not_saved_prompt.dont_save": "Don't save",
  "form_editor.form_not_saved_prompt.saving": "Saving...",
  "form_editor.form_modifed_prompt.title": "This form has been modified",
  "form_editor.form_modifed_prompt.desc": "This form has been modified outside this tab since you open it. Are you sure you want to save this form?",

  "form_editor.formatter_error_prompt.title": "Conflicts with the Formatter setup.",
  "form_editor.formatter_error_prompt.desc": "Some changes are in conflict with the Formatter setup. This will result in temporary disabling of the Formatter until all conflicts are resolved. You can still run form extraction without formatting the extracted data.\nGo to formatter?",
  "form_editor.formatter_error_prompt.go_to_formatter": "Go to formatter",

  "form.extraction_mode.multi_page_pdf": "Multi-page PDF/Image",
  "form.extraction_mode.multi_page_pdf.desc": "Process all pages in a PDF",
  "form.extraction_mode.single_page": "One-Page Document",
  "form.extraction_mode.single_page.desc": "PDF/Image containing one document",
  "form.extraction_mode.single_page_multi_document": "Multiple Documents in a page",
  "form.extraction_mode.single_page_multi_document.desc": "A single image containing multiple separate documents",
  "form.extraction_mode.multi_page_pdf_multi_document": "Extract all documents from a multi-page file",
  "form.extraction_mode.multi_page_pdf_multi_document.desc": "Detect all documents from all pages in a file",

  "form_inspector.tab.header.region": "Region",
  "form_inspector.tab.header.document": "Document",
  "form_inspector.tab.header.settings": "Settings",
  "form_inspector.anchor.area": "Red area is an \"Anchor\".",
  "form_inspector.anchor.description": "Anchor helps FormExtractor to identify and rectify the document.",
  "form_inspector.anchor.hints": "Please try to mark at least 3 - 5 red areas as anchors, which should be the part of document that never change (such as Logo, Header, Footer, etc).",
  "form_inspector.detection_region.area": "Blue area is \"Detection Region\".",
  "form_inspector.detection_region.reminder": "Each detection region should have at least ONE field.",
  "form_inspector.detection_region.hints": "Give a meaningful label name, and select the type of content you expect in the region for best accuracy.",
  "form_inspector.detection_region.tooltip.pointer": "Pointer",
  "form_inspector.detection_region.tooltip.anchor_region": "Anchor Region",
  "form_inspector.detection_region.tooltip.detection_region": "Detection Region",
  "form_inspector.detection_region.tooltip.field_tool": "Field",

  "form_inspector.nothing_detected.desc": "Add a detection region in blue, to extract information at certain regions. For example, if you know there is an \"Address\" at the bottom of a document, you can create a region highlight bottom part of the document, and create a field with type \"Address\" to extract address from it.",
  "form_inspector.document.desc": "Extract information from anywhere in the document. If you know the relative position of the information, you may want to use Region for higher accuracy.",

  "form_inspector.key_value.description": "Detect a field with key & value which is common in form, e.g a field with key \"First Name\" would have \"John\" as value.",
  "form_inspector.key_value.hints": "You can specify the key and the position of the value (is it on the right? left? above? or below the key) to extract the data.",
  "form_inspector.token_group.description": "Detect if a list of tokens (could be text or image) exists in the document anywhere.",
  "form_inspector.token_group.hints": "It would be useful to say, to identify if a receipt is from a certain restaurant with the restaurant name, or if a bank logo exists in the document.",

  "script_editor_modal.title.edit_post_processing_script": "Edit Post-processing script",
  "script_editor_modal.title.edit_transform_response_script": "Edit Transform Response Script",

  "add.token.group.title": "Add Token Group",
  "edit.token.group.title": "Edit Token Group",

  "form.inspector.tab.header.key_value": "Key Value",
  "form.inspector.tab.header.token_group": "Token Group",

  "add.key_value.button": "Add Key Value",

  "add.token_group.button": "Add Token Group",

  "add.text.token.link": "Add new text token",
  "add.image.token.link": "Add new image token",

  "add.text.token.title": "Add Text Token",
  "edit.text.token.title": "Edit Text Token",
  "add.image.token.title": "Add Image Token",
  "edit.image.token.title": "Edit Image Token",
  "placeholder.image.token.name": "Enter image token name here",
  "placeholder.text.token.name": "Enter text token value here",
  "token.modal.confirm.button": "Confirm",

  "document.tab.auto_extraction_item.title": "Auto Extraction Items",
  "document.tab.auto_extraction_item.title_tooltip": "Use pre-trained models to extract the data from the document",

  "document.tab.custom_extraction_item.title": "Custom Extraction Items",
  "document.tab.custom_extraction_item.title_tooltip": "Define keywords or key-values to extract data from the document",

  "document.tab.custom_extraction_item.choose_extraction_type": "Choose Extraction Type",
  "document.tab.custom_extraction_item.choose_extraction_type_warning": "Please choose an extraction type",
  "document.tab.add_new_item": "Add New Extraction Item",
  "extractor_setting.import_or_export": "Import an Extractor / Export This Extractor",
  "extractor_setting.export_this_extractor": "Export This Extractor",
  "extractor_setting.export_custom_model_desc1": "Only field schema, instant model, formatter and scripts will be exported.",
  "extractor_setting.export_custom_model_desc2": "Standard model will NOT be exported.",
  "extractor_setting.import_desc": "The imported extractor will completely replace the current extractor setup.",
  "extractor_setting.import_custom_model_desc1": "The imported extractor will completely replace the current extractor setup.",
  "extractor_setting.import_custom_model_desc2": "Import is disabled if the current extractor has the standard model enabled.",
  "extractor_setting.import_this_extractor": "Import An Extractor",
  "extractor_setting.replace_master_image": "Replace Master Image",
  "extractor_setting.choose_a_file": "Choose a file",
  "extractor_setting.replace_thumbnail": "Replace thumbnail",
  "extractor_setting.export_form": "Export this form:",
  "extractor_setting.download_as_zip": "Download as ZIP",
  "extractor_setting.edit_post_process_script": "Edit Post Processing Script",
  "extractor_setting.edit_transform_response_script": "Edit Transform Response Script",
  "extractor_setting.import_form": "Import form:",
  "extractor_setting.import_from_zip": "Import from ZIP",
  "extractor_setting.replace_current_form": "Replace the current form",
  "extractor_setting.import_as_new_form": "Import as a new form",
  "extractor_setting.confirm_replace_current_form": "Do you want to import and replace this extractor configuration? This cannot be undone.",
  "extractor_setting.confirm_replace_dialog.title": "Import Extractor Configuration",
  "extractor_setting.import": "Import",
  "extractor_setting.file": "File",
  "extractor_setting.advanced_form_settings": "Advanced Extractor Settings",
  "extractor_setting.custom_models": "Custom Models",
  "extractor_setting.custom_model.dropdown.placeholder": "Choose Custom Model",
  "extractor_setting.custom_model.button.add": "Add Custom Model",
  "extractor_setting.custom_model.error.empty": "Please choose a custom model",
  "extractor_setting.thumbnail_image": "Thumbnail Image",
  "extractor_setting.extractor_type": "Extractor Type",
  "extractor_setting.form_not_saved_prompt.save_warning": "There are unsaved changes, confirm leaving this page without saving?",
  "extractor_setting.form_not_saved_prompt.title": "Unsaved changes",
  "extractor_setting.form_not_saved_prompt.go_back": "Go Back",
  "extractor_setting.form_not_saved_prompt.leave_page": "Leave Page",
  "extractor_setting.specify_ocr_engine.title": "Specify OCR Engine",
  "extractor_setting.specify_ocr_engine.unspecified": "Unspecified: use FormX default",

  "extractor_setting.document_processing_option.option.per-page.label": "Process each page as a separated document",
  "extractor_setting.document_processing_option.option.per-page.description": "e.g. a pdf with multiple ID cards, one card per page",
  "extractor_setting.document_processing_option.option.per-file.label": "Process all pages in a PDF as one document",
  "extractor_setting.document_processing_option.option.per-file.description": "e.g. a multi-page bank statement PDF",
  "extractor_setting.document_processing_option.option.multiple-documents-per-page.label": "Detect multiple documents from each page",
  "extractor_setting.document_processing_option.option.multiple-documents-per-page.description": "e.g. an ID card and a passport scanned on the same page",
  "extractor_setting.document_processing_option.not_supported": "This mode is not supported by this extractor.",

  "llm_model.default": "{modelLabel} (default)",

  "llm_model.gpt-3.5-turbo": "GPT-3.5",
  "llm_model.gpt-4": "GPT-4",
  "llm_model.claude-3-haiku": "Claude 3 Haiku",
  "llm_model.claude-3-sonnet": "Claude 3 Sonnet",
  "llm_model.claude-3-5-sonnet": "Claude 3.5 Sonnet",
  "llm_model.gemini-1.5-flash": "Gemini 1.5 Flash",
  "llm_model.gemini-1.5-pro": "Gemini 1.5 Pro",
  "llm_model.gpt-3.5-turbo-test": "GPT-3.5 (For Testing)",
  "llm_model.gpt-4o": "GPT-4o",
  "llm_model.gpt-4o-test": "GPT-4o (For Testing)",
  "llm_model.gpt-4o-mini": "GPT-4o mini",
  "llm_model.gpt-4o-mini-test": "GPT-4o mini (For Testing)",
  "llm_model.formx-self-host-llm": "FormX Self-Host LLM (For Testing)",

  "document_type.general": "General",
  "document_type.bank_statement": "Bank Statement",
  "document_type.receipt": "Receipt",
  "document_type.address_proof": "Address Proof",
  "document_type.invoice": "Invoice",
  "document_type.international_id": "International ID",

  "auto_extraction_item.total_amount": "Total Amount",
  "auto_extraction_item.date": "Date",

  "auto_extraction_item.address_code": "Address Code",
  "auto_extraction_item.address_full": "Address",
  "auto_extraction_item.code_document": "Document Code",
  "auto_extraction_item.code_identity": "Identity Code",
  "auto_extraction_item.date_birth": "Date of Birth",
  "auto_extraction_item.date_expiry": "Date of Expiry",
  "auto_extraction_item.date_issue": "Issue Date",
  "auto_extraction_item.gender": "Gender",
  "auto_extraction_item.issuing_authority": "Issuing Authority",
  "auto_extraction_item.name_full": "Personal Name",
  "auto_extraction_item.name_full_non_latin": "Personal Name (Non-latin)",
  "auto_extraction_item.nationality": "Nationality",
  "auto_extraction_item.place_of_birth": "Place of birth",
  "auto_extraction_item.barcode": "Barcode / QR code",

  "auto_extraction_item.short_date": "Short Date",
  "auto_extraction_item.time": "Time",
  "auto_extraction_item.name": "Personal Name",
  "auto_extraction_item.address": "Hong Kong Address",
  "auto_extraction_item.invoice_number": "Invoice Number",
  "auto_extraction_item.telephone": "Telephone",
  "auto_extraction_item.merchant_name": "Merchant Name",
  "auto_extraction_item.merchant_fax": "Fax",
  "auto_extraction_item.merchant_website": "Website",
  "auto_extraction_item.store_number": "Store Number",
  "auto_extraction_item.subtotal": "Subtotal",
  "auto_extraction_item.rounding": "Rounding",
  "auto_extraction_item.total_discount": "Total Discount",
  "auto_extraction_item.enter_time": "Enter Time",
  "auto_extraction_item.leave_time": "Leave Time",
  "auto_extraction_item.num_person": "Number of Person",
  "auto_extraction_item.credit_card_type": "Credit Card Type",
  "auto_extraction_item.credit_card_no": "Credit Card Number",
  "auto_extraction_item.payment_name": "Payment Name",
  "auto_extraction_item.payment_card_no": "Payment Card Number",
  "auto_extraction_item.product_info": "Product-Level Information",
  "auto_extraction_item.payment_info": "Payment Information",
  "auto_extraction_item.company": "Company Name",
  "auto_extraction_item.job_title": "Job Title",
  "auto_extraction_item.merchant": "Merchant Post-processing",
  "auto_extraction_item.detect_manipulation": "Detect Image Manipulation (Deprecated)",
  "auto_extraction_item.detect_document_manipulation": "Detect Document Manipulation",
  "auto_extraction_item.pii": "PII",
  "auto_extraction_item.table_extraction": "Table (Beta)",
  "auto_extraction_item.issue_date": "Issue Date",
  "auto_extraction_item.ner_name": "Personal Name (NER)",
  "auto_extraction_item.ner_address": "Hong Kong Address (NER)",
  "auto_extraction_item.receipt_type": "Receipt Type",
  "auto_extraction_item.merchant_logo": "Merchant Logo (Beta)",
  "auto_extraction_item.mobile_payment_transaction_number": "Mobile Payment Transaction Number",
  "auto_extraction_item.auth_code": "Authorization Code",
  "auto_extraction_item.retrieval_reference_number": "Retrieval Reference Number (RRN)",
  "auto_extraction_item.payment_info_beta": "Payment Information for HK (Beta)",
  "auto_extraction_item.checkbox": "Checkbox (Beta)",

  "auto_extraction_item.account_no": "Account Number",
  "auto_extraction_item.bank_account_no": "Bank Account Number",
  "auto_extraction_item.bank_name": "Bank Name",
  "auto_extraction_item.bill_to_address": "Customer Address",
  "auto_extraction_item.bill_to_email": "Customer Email",
  "auto_extraction_item.bill_to_name": "Customer Name",
  "auto_extraction_item.bill_to_phone": "Customer Phone Number",
  "auto_extraction_item.bill_to_vat_number": "Customer VAT Number",
  "auto_extraction_item.currency": "Currency",
  "auto_extraction_item.delivery_date": "Delivery Date",
  "auto_extraction_item.discount": "Discount",
  "auto_extraction_item.due_date": "Due Date",
  "auto_extraction_item.end_date": "End Date",
  "auto_extraction_item.invoice_date": "Invoice Date",
  "auto_extraction_item.order_date": "Order Date",
  "auto_extraction_item.package_weight": "Package Weight",
  "auto_extraction_item.payment_terms": "Payment Terms",
  "auto_extraction_item.purchase_order_no": "Purchase Order Number",
  "auto_extraction_item.reference_no": "Reference Number",
  "auto_extraction_item.ship_to_address": "Shipping Address",
  "auto_extraction_item.ship_to_name": "Recipient Name",
  "auto_extraction_item.start_date": "Start Date",
  "auto_extraction_item.swift_bic": "SWIFT / BIC",
  "auto_extraction_item.tax": "Tax",
  "auto_extraction_item.total": "Total (Amount)",
  "auto_extraction_item.other_dates": "Other Dates",
  "auto_extraction_item.vendor_address": "Vendor Address",
  "auto_extraction_item.vendor_email": "Vendor Email",
  "auto_extraction_item.vendor_name": "Vendor Name",
  "auto_extraction_item.vendor_phone": "Vendor Phone Number",
  "auto_extraction_item.vendor_fax": "Vendor Fax Number",
  "auto_extraction_item.vendor_vat_number": "Vendor VAT Number",
  "auto_extraction_item.vendor_website": "Vendor Website",

  "auto_extraction_item.detect_signature": "Signature",
  "auto_extraction_item.detect_portrait": "Portrait",
  "auto_extraction_item.passport_mrz": "Passport MRZ",
  "auto_extraction_item.llm_completion": "LLM Completion (ChatGPT)",
  "auto_extraction_item.id_liveness": "ID Liveness (Beta)",

  "auto_extraction_item.vat": "VAT",
  "auto_extraction_item.bank_info": "Bank Info",
  "auto_extraction_item.vendor_name": "Vendor Name",
  "auto_extraction_item.vendor_address": "Vendor Address",
  "auto_extraction_item.vendor_email": "Vendor Email",
  "auto_extraction_item.vendor_telephone": "Vendor Telephone",
  "auto_extraction_item.vendor_vat_number": "Vendor VAT Number",
  "auto_extraction_item.vendor_url": "Vendor URL",
  "auto_extraction_item.vendor_fax": "Vendor Fax",
  "auto_extraction_item.customer_name": "Customer Name",
  "auto_extraction_item.customer_address": "Customer Address",
  "auto_extraction_item.customer_email": "Customer Email",
  "auto_extraction_item.customer_telephone": "Customer Telephone",
  "auto_extraction_item.customer_vat_number": "Customer VAT Number",
  "auto_extraction_item.shipment_name": "Shipment Name",
  "auto_extraction_item.shipment_address": "Shipment Address",
  "auto_extraction_item.shipment_email": "Shipment Email",
  "auto_extraction_item.shipment_telephone": "Shipment Telephone",
  "auto_extraction_item.shipment_vat_number": "Shipment VAT Number",
  "auto_extraction_item.line_items": "Line items",

  "auto_extraction_item.account_type": "Account Type",
  "auto_extraction_item.account_info": "Account Info",
  "auto_extraction_item.open_balance": "Open Balance",
  "auto_extraction_item.close_balance": "Close Balance",
  "auto_extraction_item.transactions": "Transactions",

  "auto_extraction_item.ship_date": "Ship Date",
  "auto_extraction_item.bill_of_lading_no": "Bill of Lading Number",
  "auto_extraction_item.shipper_name": "Shipper Name",
  "auto_extraction_item.shipper_address": "Shipper Address",
  "auto_extraction_item.shipper_city": "Shipper City",
  "auto_extraction_item.shipper_state": "Shipper State",
  "auto_extraction_item.shipper_zip": "Shipper ZIP",
  "auto_extraction_item.shipper_phone": "Shipper Phone",
  "auto_extraction_item.shipper_email": "Shipper Email",
  "auto_extraction_item.shipper_reference": "Shipper Reference",
  "auto_extraction_item.carrier_name": "Carrier Name",
  "auto_extraction_item.carrier_address": "Carrier Address",
  "auto_extraction_item.carrier_reference": "Carrier Reference",
  "auto_extraction_item.carrier_fedex_express_air_waybill_no": "Carrier Fedex Express Air Waybill Number",
  "auto_extraction_item.consignee_name": "Consignee Name",
  "auto_extraction_item.consignee_address": "Consignee Address",
  "auto_extraction_item.consignee_city": "Consignee City",
  "auto_extraction_item.consignee_state": "Consignee State",
  "auto_extraction_item.consignee_zip": "Consignee ZIP",
  "auto_extraction_item.consignee_phone": "Consignee Phone",
  "auto_extraction_item.consignee_email": "Consignee Email",
  "auto_extraction_item.port_of_loading": "Port of Loading",
  "auto_extraction_item.port_of_unloading": "Port of Unloading",
  "auto_extraction_item.num_of_packages": "Num of Packages",
  "auto_extraction_item.freight_weight": "Freight Weight",
  "auto_extraction_item.freight_length": "Freight Length",
  "auto_extraction_item.freight_width": "Freight Width",
  "auto_extraction_item.freight_height": "Freight Height",
  "auto_extraction_item.freight_condition": "Freight Condition",
  "auto_extraction_item.freight_description": "Freight Description",
  "auto_extraction_item.freight_identification_marks": "Freight Identification Marks",
  "auto_extraction_item.vessel_name": "Vessel Name",
  "auto_extraction_item.vessel_id": "Vessel ID",
  "auto_extraction_item.terms_of_contract": "Terms of Contract",
  "auto_extraction_item.address.bank_statement_llm": "Address",

  "add.new.form": "Add New Extractor",
  "add.new.form_group": "Add New Combined Extractor",
  "add.new.extractor": "Add New Extractor",
  "add.new.receipt": "Add New Receipt",
  "add.new.webhook": "Add new webhook",
  "import.new.form": "Import Extractor",
  "import.new.form_group": "Import Group",

  "label.token_group_name": "Token Group Name",
  "label.token_value": "Token Value",
  "label.text_token": "Text Token",
  "label.text_token.label": "Label",
  "label.image_token": "Image Token",
  "label.match_mode": "Match Mode",
  "label.match_one_only": "Find the CLOSEST matched token",
  "label.match_all": "Find ALL matched tokens",
  "label.exact_match": "Exact Match",
  "label.token_matching_mode.exact_match": "Exact Match",
  "label.token_matching_mode.fuzzy": "Fuzzy Match",
  "label.token_matching_mode.regex": "Regular Expression",
  "label.token_matching_mode": "Matching Mode",
  "label.token_type": "Token Type",
  "label.images": "Images",
  "label.texts": "Texts",
  "label.remove_whitespaces": "Remove Whitespaces",
  "dropdown.placeholder": "Select...",

  "key_value.modal.title": "Key-Value Settings",
  "key_value.modal.label": "Label",
  "key_value.modal.key": "Key",
  "key_value.modal.value": "Value",
  "key_value.modal.key.placeholder": "Token to aid locating the field",
  "key_value.modal.enable_fuzzy_search": "Enable Fuzzy Search",
  "key_value.modal.pattern": "Pattern (Regex to match, optional)",
  "key_value.modal.pattern_builder": "Builder",
  "key_value.modal.regex_builder": "Regex Builder",
  "key_value.modal.desc": "Detect a field with key & value using a \"Key Value\" processor. For Example",
  "key_value.modal.advance_usage": "Tips: If the regex pattern contains capturing groups, only the first's value will be returned. This is useful when you only want to extract part of the matched pattern.",

  "key_value.example.key": "Key",
  "key_value.example.value": "Value",
  "key_value.example.key.example": "First Name:",
  "key_value.example.value.example": "John",
  "key_value.example.desc": "Value Position = Right",

  "key_value.modal.label.duplicated_error": "Label has to be unique",
  "key_value.modal.label.empty_error": "Label cannot be empty",
  "key_value.modal.key.empty_error": "Key cannot be empty",
  "key_value.modal.key.invalid_regex_error": "Invalid regular expression",

  "key_value.modal.pattern_or_key_is_required_error": "At least one key or pattern is required",
  "key_value.modal.pattern.empty_error": "Pattern cannot be empty",
  "key_value.modal.pattern.invalid_regex_error": "Invalid regular expression",

  "key_value.modal.keys.title": "Keys",
  "key_value.modal.keys.empty": "Start by Adding New Key",
  "key_value.modal.keys.add": "+ Add New Key",
  "key_value.modal.keys.columns.key": "Key",
  "key_value.modal.keys.columns.fuzzy": "Fuzzy Search",
  "key_value.modal.keys.key_input.placeholder": "Enter Key",

  "text_token.model.title": "Text Tokens Settings",
  "text_token.modal.description_1": "Define one or multiple text tokens, and return the field value when it's detected.",
  "text_token.modal.description_2": "For example, if you define \"{McDonald}\" and \"{MacDonald}\" as Text Token, and {Mc} as Field Value, FormX will return {fieldNameExample} when those Text Tokens exists in the document.",
  "text_token.modal.add_tokens": "Add Tokens",
  "text_token.modal.add_token_button": "Add Token",
  "text_token.modal.header_1": "Text Token",
  "text_token.modal.header_2": "Field Value",
  "text_token.modal.header_3": "Matching Mode",
  "text_token.modal.header_4": "Remove White Space",
  "text_token.modal.header_5": "Action",
  "text_token.modal.save_changes_button": "Save Changes",

  "image_token.model.title": "Image Tokens Settings",
  "image_token.modal.description": "Detect an image exists in the document anywhere.",
  "image_token.modal.header_1": "Image Uploaded",
  "image_token.modal.header_2": "Image Token Name",
  "image_token.modal.header_3": "Action",

  "minimal_field_modal.title": "{field_type} Settings",

  "key_value.card.pattern.any": "Any",

  "unsupport_browser_dialog.title": "Browser not supported",
  "unsupport_browser_dialog.message": "Some of the portal features are not optimized for this browser. Please use an up-to-date desktop browser.",
  "unsupport_browser_dialog.dismiss": "Dismiss",

  "command_bar_items.copy_form_id": "Copy Extractor ID",
  "command_bar_items.copy_access_token": "Copy Access Token",
  "command_bar_items.open_in_desktop_app": "Open in Desktop App",
  "command_bar_items.export_as_zip": "Export as ZIP",

  "teaching_bubble.title": "Before you start",

  "teaching_bubble.description": "It is recommended to learn how FormExtractor can be used under various scenarios and understand the basics. Head over to our help page for more information.",
  "teaching_bubble.lets_go": "Let's go",
  "teaching_bubble.next": "Next",
  "teaching_bubble.maybe_later": "Maybe later",
  "teaching_bubble.step": "{numerator} of {denominator}",

  "tutorial.labeller_tool.info.header": "Tutorial on Tool Bar of Labeller",
  "tutorial.labeller_tool.info.content": "The bar below provides three tools for you to draw on your master form. By clicking the go button, you will be briefed with each's functionality and usage.",
  "tutorial.labeller_tool.select.header": "Select Tool",
  "tutorial.labeller_tool.select.content": "This tool allows you to select marked areas, either an Anchor (red) or Detection Region (blue). Once selected, you can move one around, press the delete key to get rid of it, or resize by pulling the corners.",
  "tutorial.labeller_tool.anchor.header": "Anchor Tool",
  "tutorial.labeller_tool.anchor.content": "An Anchor is a position keeper primary used in FormX's warping process. To yield favorable extraction results, you need at least 2 of them drawn. With this tool selected, each click on the master form creates a dot. Connecting the final dot with the first completes an Anchor.",
  "tutorial.labeller_tool.detection_region.header": "Detection Region Tool",
  "tutorial.labeller_tool.detection_region.content": "To extract information from an area, mark it with a Detection Region. With this tool selected, you can pull to draw a rectangle box where extraction(s) will be carried out upon this box.",

  "tutorial.fixed_layout_form.anchor.title1": "What are Anchors?",
  "tutorial.fixed_layout_form.anchor.description1": "Anchors are used to indicate the structure and layout of your document. If your master image is an Application Extractor, then you would use the form fields or labels as your anchors.",
  "tutorial.fixed_layout_form.anchor.title2": "Create your first Anchor Region",
  "tutorial.fixed_layout_form.anchor.description2": "Click on the red anchor tool and form red dots around a desired region until you can connect them and create a rectangle.",
  "tutorial.fixed_layout_form.anchor.description2.with_hightlight": "The region will be {hightlight}.",
  "tutorial.fixed_layout_form.anchor.description2.hightlight": "highlighted in red",
  "tutorial.fixed_layout_form.skip_all": "Skip all tutorials",
  "tutorial.fixed_layout_form.got_it": "Ok, I’ve got it",
  "tutorial.fixed_layout_form.anchor_reminder.title": "Objective",
  "tutorial.fixed_layout_form.anchor_reminder.description": "Make at least {anchor} regions for more effective extraction.",
  "tutorial.fixed_layout_form.anchor_reminder.description.hightlight": "3 anchor",
  "tutorial.fixed_layout_form.anchor_reminder.remind": "Remind me how it’s done",
  "tutorial.fixed_layout_form.detection_region.title1": "What are Detection Regions?",
  "tutorial.fixed_layout_form.detection_region.description1": "Detection regions are used to detect the content or inputted data that corresponds to an anchor.",
  "tutorial.fixed_layout_form.detection_region.description1.second_line": "If the data field is called “Name” and “Jason” is what is filled in, you would set a detetction around “Jason”.",
  "tutorial.fixed_layout_form.detection_region.title2": "Create your first Detection Region",
  "tutorial.fixed_layout_form.detection_region.description2.with_highlight": "1. Click on the Detection Tool and forming a {highlight} around a desired region.",
  "tutorial.fixed_layout_form.detection_region.description2.highlight": "purple rectangle",
  "tutorial.fixed_layout_form.detection_region.description2": "2. Afterwards, click on the detection region you have created",
  "tutorial.fixed_layout_form.configure_detection_region.title": "Configure your Detection Regions",
  "tutorial.fixed_layout_form.configure_detection_region.description": "On the right side menu, under the Region tab, add an extraction field by selectiong one of the options.",
  "tutorial.fixed_layout_form.label_extraction_field.title": "Label your Extraction Field",
  "tutorial.fixed_layout_form.label_extraction_field.description1": "You can label your extraction fields by naming it based on your anchors.",
  "tutorial.fixed_layout_form.label_extraction_field.description2": "For example, if the data field is “Name”, you can use that as the label for the matching extraction field.",
  "tutorial.fixed_layout_form.all_set.title": "You’re all set!",
  "tutorial.fixed_layout_form.all_set.description": "If you need to review each of the steps click the “i” icon. You can navigate to any step you need refreshing below.",
  "tutorial.fixed_layout_form.all_set.review_all_steps": "Review all steps",
  "tutorial.fixed_layout_form.all_set.anchors": "Anchors",
  "tutorial.fixed_layout_form.all_set.detection_region": "Detection Region",
  "tutorial.fixed_layout_form.all_set.configure_your_fields": "Configuring your fields",
  "tutorial.fixed_layout_form.all_set.do_not_show_tutorials": "Do not show tutorials upon creation",

  "webhook.title": "Webhooks",
  "webhook.no_webhook_yet": "You don’t have a webhook integration yet.",
  "webhook.add": "Add a webhook",
  "webhook.new.title": "New Webhook",
  "webhook.name": "Webhook Name",
  "webhook.name.placeholder": "e.g. Zapier",
  "webhook.url": "Webhook URL",
  "webhook.connect_with": "Connect with",
  "webhook.connect_with.form": "Extractor",
  "webhook.connect_with.form_group": "Combined Extractor",
  "webhook.creating_webhook": "Creating webhook...",
  "webhook.created": "New webhook integration is created. Connect to a form to get started.",
  "webhook.list.edit": "Edit",
  "webhook.list.webhooks": "Webhooks",
  "webhook.list.connected_with": "Connected with",
  "webhook.list.action": "Action",
  "webhook.edit.title": "Edit Webhook",
  "webhook.edit.fetching": "Fetching Webhook...",
  "webhook.edit.updating": "Updating Webhook...",
  "webhook.edit.success": "Webhook is updated.",
  "webhook.edit.fail": "Fail to update webhook.",
  "webhook.edit.remove.title": "Delete Webhook",
  "webhook.edit.remove.message": "Are you sure to delete this webhook?",
  "webhook.edit.remove.action": "Delete",
  "webhook.edit.remove.success": "Webhook is deleted.",
  "webhook.edit.remove.fail": "Fail to delete webhook.",
  "webhook.edit.fetch_forms_form_groups_fail": "Fail to fetch extractors.",
  "webhook.modified_prompt.title": "This webhook has been modified",
  "webhook.modified_prompt.desc": "This webhook has been modified outside this tab since you open it. Are you sure you want to save it?",
  "webhook.custom_headers.title": "Custom Headers",
  "webhook.custom_headers.add_header": "+ Add new header",
  "webhook.custom_headers.header_name": "Header Name",
  "webhook.custom_headers.header_value": "Header Value",
  "webhook.custom_headers.header_name_placeholder": "e.g. X-API-KEY",
  "webhook.custom_headers.header_value_placeholder": "Example-value",
  "invite.new.member": "Invite",
  "invite.new.member.title": "Invite a team member",
  "invite.new.member.access": "Access",
  "invite.new.member.success_message": "An invitation has been sent to {email}.",
  "invite.new.member.fail_message": "Fail to send invite to {email}.",
  "invite.new.member.inviting": "Inviting...",
  "team.members.header": "Manage Team",
  "team.members.invite": "Invite Members",
  "team.tokens.name": "Name",
  "team.tokens.header": "Access token",
  "team.tokens.access_token": "Access Token",
  "team.tokens.revoke": "Revoke",
  "team.tokens.create": "Create token",
  "team.tokens.rename": "Rename token",
  "team.tokens.button.create": "Create",
  "team.tokens.button.rename": "Rename",
  "team.tokens.button.revoke": "Revoke",
  "team.tokens.fail_to_fetch": "Oops, we can't find your access tokens!",
  "team.tokens.no_token": "There is no token, click {text} to generate one",
  "team.tokens.create_token": "Create token",
  "team.tokens.create.success": "Access token created",
  "team.tokens.create.unexpected_error": "Unexpected error occurred while creating access token.",
  "team.tokens.rename.success": "Access token renamed to {name}",
  "team.tokens.rename.unexpected_error": "Unexpected error occurred while renaming access token.",
  "team.tokens.revoke.success": "Access token revoked",
  "team.tokens.revoke.unexpected_error": "Unexpected error occurred while revoking access token.",
  "team.tokens.revoke.title": "Revoke Access Token",
  "team.tokens.revoke.confirm": "Are you sure to revoke this access token? This action cannot be undone.",
  "team.team_members": "Team members",
  "team.region": "Server Location: {region}",
  "team.creating_team": "Creating team...",
  "team.detail.title": "Team Details",
  "team.detail.region": "Region: {region}",
  "team.detail.lookup_id": "Team ID: {lookupId}",
  "team.detail.created_at": "Created at: {createdAt}",
  "team.detail.total_usage": "Total usage: {totalUsage}",
  "team.detail.last_usage_at": "Last usage at: {lastUsageAt}",
  "team.detail.members": "Members",
  "team.detail.usage": "Usage",
  "team.detail.toggle.full_log": "Full Log",
  "team.detail.toggle.on": "On",
  "team.detail.toggle.off": "Off",
  "team.detail.resources": "Resources",
  "team.detail.plan": "Plan",
  "team.detail.forms": "Extractors",
  "team.detail.form_groups": "Combined Extractors",
  "team.detail.custom_models": "Custom Models",
  "team.detail.features": "Features",
  "team.detail.resources.name": "Name",
  "team.detail.resources.form_id": "Extractor ID",
  "team.detail.resources.form_group_id": "Combined Extractor ID",
  "team.detail.resources.mode": "Mode",
  "team.detail.resources.form_group_type.anchor": "Anchors",
  "team.detail.resources.form_group_type.token": "Tokens",
  "team.detail.resources.no_of_picture_uploaded": "# of pictures uploaded",
  "team.detail.resources.model_id": "Model ID",
  "team.detail.resources.status": "Status",
  "team.detail.resources.forms.empty": "No extractors yet.",
  "team.detail.resources.form_groups.empty": "No combined extractors yet.",
  "team.detail.resources.custom_models.empty": "No custom models yet.",
  "team.detail.resources.custom_model.status.not_synced": "Not synced",
  "team.detail.resources.custom_model.status.labelling": "Labelling",
  "team.detail.resources.custom_model.status.training": "Training",
  "team.detail.resources.custom_model.status.trained": "Trained",
  "team.detail.resources.custom_model.all": "All custom models",
  "team.detail.resources.created_at": "Created At",
  "team.detail.current_plan": "Current Plan",
  "team.detail.quota": "Quota",
  "team.detail.end_at": "End at (UTC)",
  "team.detail.plan.quota.unlimited": "Unlimited",
  "team.detail.plan.quota.annually": "{quota} (Annually)",
  "team.detail.plan.quota.monthly": "{quota} (Monthly)",
  "team.detail.plan.quota.daily": "{quota} (Daily)",
  "team.detail.plan.remove_trial": "Remove Trial",
  "team.detail.plan.give_trial": "Give Trial",
  "team.detail.plan.save": "Save",
  "team.detail.plan.trial_model": "Trial Mode",
  "team.detail.plan.no_of_pages": "Number of pages",
  "team.detail.plan.trial_indefinitely": "Trial indefinitely",
  "team.detail.plan.set_trial.success": "The team plan has been set to trial.",
  "team.detail.plan.clear_trial": "Clear Trial",
  "team.detail.plan.are_you_sure_to_clear_trial": "Are you sure to clear trial?",
  "team.detail.plan.clear": "Clear",
  "team.detail.plan.set_to_free.success": "The team plan has been set to free.",
  "team.detail.plan.upgrade_to_enterprise": "Upgrade to Enterprise",
  "team.detail.plan.upgrade_to_enterprise_indefinitely": "Upgrade to Enterprise indefinitely",
  "team.detail.plan.upgrade_to_enterprise.success": "The team plan has been upgraded to enterprise.",
  "team.detail.plan.enterprise_model": "Enterprise Mode",
  "team.detail.plan.downgrade_enterprise": "Downgrade from Enterprise",
  "team.detail.plan.are_you_sure_to_downgrade": "Are you sure to downgrade from enterprise?",
  "team.detail.plan.downgrade": "Downgrade",

  "team.detial.assigned_features.title": "Assigned Feature Group",
  "team.detial.assigned_features.all": "All Features",
  "team.detial.assigned_features.alpha": "Alpha",
  "team.detial.assigned_features.beta": "Beta",
  "team.detial.assigned_features.no_group": "No Group",

  "team.detail.features_group_title.alpha": "Alpha Features",
  "team.detail.features_group_title.beta": "Beta Features",
  "team.detail.features_group_title.other": "Other Features",
  "team.detail.features_group_title.special": "Special Features (Read-only)",

  "team.detail.features.frontend_portal_features": "Frontend Portal Features",
  "team.detail.features.system_features": "System Features",
  "team.detail.features.edit_features": "Edit Features",
  "team.detail.features.all": "All Frontend Portal Features",
  "team.detail.features.alpha": "Alpha",
  "team.detail.features.beta": "Beta",

  "team.detail.features.ner.company": "Auto Extraction Item Company",
  "team.detail.features.ner.address": "Auto Extraction Item NER Address",
  "team.detail.features.ner.name": "Auto Extraction Item NER Name",
  "team.detail.features.date_in_address_proof": "Auto Extraction Item Date In Address Proof",
  "team.detail.features.custom_model_training": "Custom Model Training",
  "team.detail.features.custom_model_labelling": "Custom Model Labelling",
  "team.detail.features.table_extraction": "Table Extraction",
  "team.detail.features.invoice": "Invoice",
  "team.detail.features.international_id": "International ID",
  "team.detail.features.signature": "Signature",
  "team.detail.features.post_process_script": "Post Process Script",
  "team.detail.features.passport_mrz": "Passport MRZ",
  "team.detail.features.set_features.success": "Enabled features of the team has been updated.",
  "team.detail.features.set_features.fail": "Unable to update enabled features.",
  "team.detail.features.clearall": "Clear All",
  "team.detail.features.audit_log": "Audit Log",
  "team.detail.features.pii": "PII",
  "team.detail.features.llm_completion": "LLM Completion (ChatGPT)",
  "team.detail.features.preview_extractor": "Preview Extractor",
  "team.detail.features.advance_token_setup": "Advance token setup",
  "team.detail.features.formatter": "Formatter",
  "team.detail.features.workspace": "Workspace",
  "team.detail.features.share_point_integration": "SharePoint integration",
  "team.detail.features.extractor_setting": "Extractor settings",
  "team.detail.features.google_sheets_export_integration": "Workspace Google Sheets Export Integration",
  "team.detail.features.webhook_export_integration": "Workspace Webhook Integration",
  "team.detail.features.v1_test_tab": "V1 Test Tab",
  "team.detail.features.retry_extraction": "Retry Extraction",
  "team.detail.features.id_liveness": "ID Liveness",
  "team.detail.features.merchant_logo": "Merchant Logo",
  "team.detail.features.transform_response_script": "Transform Response Script",
  "team.detail.features.detect_manipulation": "Detect Image Manipulation (Deprecated)",
  "team.detail.features.detect_document_manipulation": "Detect Document Manipulation",
  "team.detail.features.advanced_prompt_tuning": "Advanced Prompt Tuning",
  "team.detail.features.webhook_v1": "Webhook(V1)",
  "team.detail.features.use_custom_credentials": "Use Custom Credentials",
  "team.detail.features.skip_quota_check": "Skip Quota Check",
  "team.detail.features.form_version_snapshots": "Form Verions Snapshots",
  "team.detail.features.image_quality": "Image Quality",
  "team.detail.features.checkbox": "Checkbox",
  "team.detail.features.data_log": "Enable/Disable Data Log",
  "team.detail.features.connected_apps": "Connected Apps",

  "team.detail.features.more_llm_models": "More LLM Models",
  "team.detail.features.inspect_prompt": "Inspect Prompt",
  "team.lookup_id": "Team ID",
  "team.lookup_id.smol": "Team ID: {id}",
  "team.lookup_id.desc": "Team ID is used to uniquely identify your team. It cannot be changed once set.",
  "team.rename": "Rename",
  "team.rename_team": "Rename Team",
  "team.renaming": "Renaming...",
  "team.rename.success_message": "Team renamed to {team}.",
  "team.rename.fail_message": "Failed to rename team.",
  "team.delete": "Delete Team",
  "team.deleting": "Deleting team...",
  "team.delete.fail_message": "Failed to delete team",
  "team.delete.confirm": "Are you sure to delete this team? All resources including all extractors will be deleted. This action cannot be undone.",
  "team.delete.plan_is_not_free.title": "Current Subscription",
  "team.delete.plan_is_not_free.message": "You are currently using a paid subscription. Please cancel your subscription before deleting your team.",
  "team.change.permission.title": "Losing admin permission",
  "team.change.admin_permission.message": "Are you sure to change your own permission? You will lose all administrative privileges of this team, including adding or removing members.",
  "team.list.no_team_yet": "No team yet.",
  "team.list.title": "Teams",
  "team.list.team": "Team",
  "team.list.region": "Region",
  "team.list.contact_email": "Contact Email",
  "team.list.plan": "Plan",
  "team.list.created_at": "Created At",
  "team.list.search_team_name": "Team Name",
  "team.list.search_team_email": "Team Email",
  "team.list.search_team_id": "Team ID",
  "team.list.all": "All",
  "team.list.search": "Search",
  "team.list.search_by": "Search By",
  "team.list.total_usage": "Total Usage",
  "team.remove": "Remove",
  "team.remove.fail_message": "Fail to remove team.",
  "team.remove_user": "Remove team member",
  "team.remove_user.confirm": "Are you sure you want to remove {email} from {team}?",
  "team.remove_user.success_message": "Team member removed.",
  "team.remove_user.unexpected_error": "Unexpected error occurred while removing user.",
  "team.column.status": "Status",
  "team.column.role": "Role",
  "team.role.admin": "Admin",
  "team.role.editor": "Editor",
  "team.role.read_only": "Read only",
  "team.role.custom": "Custom",
  "team.role.admin.with_article": "an Admin",
  "team.role.editor.with_article": "an Editor",
  "team.role.read_only.with_article": "a Read-only Member",
  "team.role.custom.with_article": "a Custom Member",
  "team.role.failed_to_update": "Failed to update team role.",
  "team.role.updated": "{email} is changed to {role}",
  "team.modified_prompt.title": "Team has been modified",
  "team.modified_prompt.desc": "Your team's configuration has been modified outside this tab since you open it. Are you sure you want to save it?",
  "team.invitation.invalid": "Invite Invalid",
  "team.invitation.invalid.message": "This invite link is invalid or expired. Please contact the admin to resend an invite.",
  "team.invitation.continue_to_formx": "Continue to FormX",
  "team.invitation.logged_out": "You're invited to join {team} on FormX",
  "team.invitation.logged_out.message": "You will need to create a new FormX account or login to your existing account",
  "team.invitation.account_not_match": "The invitee email does not match your logged-in email",
  "team.invitation.account_not_match.message": "Try login with a different user?",
  "team.invitation.account_not_match.login": "Login with another user",
  "team.invitation.received": "You're invited to join {team}",
  "team.invitation.received.message": "Click the button below to join {team} as {role}",
  "team.invitation.accept_invitation": "Accept Invitation",
  "team.invitation.remove.success_message": "Invitation removed.",
  "team.invitation.remove": "Remove invitation",
  "team.invitation.remove.unexpected_error": "An unexpected error occurred while removing invitation.",
  "team.invitation.remove.confirm": "Are you sure you want to remove the invitation to {email} from {team}?",
  "team.invitation.pending": "Pending",
  "team.invitation.accepted": "Accepted",
  "team.invitation.email.oneself": "{email} (You)",
  "team.invitations.team_invite_count": "{count, plural, one{1 team invite} other{# team invites}}",
  "team.invitation.list.title": "Team Invitations",
  "team.invitation.list.header": "You are invited to join the following teams:",
  "team.invitation.list.no_invitation_yet": "No invitation yet.",
  "team.invitation.list.failed_to_get_invitations": "Failed to get invitations, please try refresh.",
  "team.invitation.list.view_team": "View this Team",
  "team.invitation.list.deleted": "Deleted",
  "team.invitation.list.region": "{region} Region",
  "team.invitation.list.skip_and_go_to_extractor": "Skip and go to extractor list",
  "team.ranking.list.no_usage_yet": "No usage yet.",
  "team.ranking.list.team_name": "Team Name",
  "team.ranking.list.contact_email": "Contact Email",
  "team.ranking.list.created_at": "Created At",
  "team.ranking.list.usage_in_range": "Usage in the last {days} days",

  "multi.region.tw-gcp": "Taiwan",
  "multi.region.sg-gcp": "Singapore",
  "multi.region.default": "Default",

  "onboarding.welcome_message": "Let's start by creating a team.",
  "onboarding.team_exists_in_other_region.title": "You are currently in {currentRegion} region",
  "onboarding.team_exists_in_other_region.desc": "Trying to access these teams?",
  "onboarding.team_exists_in_other_region.action": "Create a new team in {currentRegion} instead",
  "onboarding.ignore_invitations.action": "Skip and create new extractor",
  "onboarding.default_team_name": "My Team",

  "request_logs.table.name": "Extractor Name",
  "request_logs.table.entity_id": "Entity ID",
  "request_logs.table.timestamp": "Timestamp",
  "request_logs.table.type": "Type",
  "request_logs.table.access_token": "Access Token",
  "request_logs.table.empty": "No record yet.",

  "auto_refresh_updated_at.label": "Updated {ago}",
  "auto_refresh_update_at.less_than_one_minute": "< 1 minute ago",

  "audit.header.title": "Audit Log",
  "audit.header.description": "Download the audit log as a CSV file",
  "audit.datepicker.year.label": "Year",
  "audit.datepicker.month.label": "Month",
  "audit.datepicker.month.option.m1": "01 - January",
  "audit.datepicker.month.option.m2": "02 - February",
  "audit.datepicker.month.option.m3": "03 - March",
  "audit.datepicker.month.option.m4": "04 - April",
  "audit.datepicker.month.option.m5": "05 - May",
  "audit.datepicker.month.option.m6": "06 - June",
  "audit.datepicker.month.option.m7": "07 - July",
  "audit.datepicker.month.option.m8": "08 - August",
  "audit.datepicker.month.option.m9": "09 - September",
  "audit.datepicker.month.option.m10": "10 - October",
  "audit.datepicker.month.option.m11": "11 - November",
  "audit.datepicker.month.option.m12": "12 - December",
  "audit.download": "Download",
  "audit.download.preparing": "Preparing",
  "audit.download.update": "Update file",
  "audit.download.generate": "Generate file",
  "audit.download.generating": "Generating...",
  "audit.download.generating.file": "Generating file",
  "audit.download.generating.message": "The file is being generated. Please come back in a few minutes.",
  "audit.download.error": "There is no logs for the selected month",
  "audit.table.header": "Date",
  "audit.table.incomplete": " (Incomplete)",

  "user.logout.confirm.title": "Log out with pending tasks",
  "user.logout.confirm_cancel_custom_model_image_tasks": "You are either uploading or deleting sample image(s) for custom model(s), if you log out, those pending operation will be cancelled.",

  "extractor.navbar.rename": "Rename",
  "extractor.breadcrumb.extractors": "Extractors",
  "extractor.breadcrumb.create": "Create new extractor",
  "extractor.tab.manage_rules": "Manage Rules",
  "extractor.tab.manage_fields": "Manage Fields",
  "extractor.tab.formatter": "Formatter",
  "extractor.tab.test": "Test Extractor",
  "extractor.tab.test_v1": "Test Extractor(V1)",
  "extractor.tab.extract": "API",
  "extractor.tab.setting": "Settings",
  "extractor.create.title": "Create new extractor",
  "extractor.creating": "Creating extractor...",
  "extractor.importing": "Importing extractor...",
  "extractor.create_modal.title": "Create a new Extractor",
  "extractor.create_modal.type": "Extractor Type",
  "extractor.create_modal.improve_prebuilt.label": "Improve a pre-built model",
  "extractor.create_modal.improve_prebuilt.placeholder": "Select a model",
  "extractor.create_modal.improve_prebuilt.desc": "You can improve an existing model with pre-defined labels",

  "extractor.rename.title": "Rename Extractor",
  "extractor.rename.label": "Extractor Name",
  "extractor.rename.button": "Rename",
  "extractor.rename.error.empty_name": "Extractor name cannot be empty",
  "extractor.rename.modifed_prompt.title": "This extractor has been modified",
  "extractor.rename.modifed_prompt.message": "This extractor has been modified outside this page when you open it. Are you sure you want to rename it?",
  "extractor.rename.error": "Failed to rename the extractor, please try again later",

  "extractor.form_group": "Combined Extractor",
  "extractor.custom_model": "Custom Model",
  "extractor.fixed_layout_extractor": "Fixed Layout Extractor",
  "extractor.fixed_layout_extractor.desc.1": "Upload a master image and label the area of information",
  "extractor.fixed_layout_extractor.desc.2": "Suitable for documents with a fixed template e.g. Application forms, licenses, ID cards",
  "extractor.custom_model_extractor": "Custom Model Extractor",
  "extractor.custom_model_extractor.desc.1": "Train an extractor based on machine learning models by multiple images samples",
  "extractor.custom_model_extractor.desc.2": "Examples: Application forms, Licenses, Reports",
  "extractor.combine_extractors": "Combine Extractors",
  "extractor.combine_extractors.desc": "Combine multiple extractors into one extractor, so that you can extract information from multiple type of documents in one API",
  "extractor.import_extractors": "Import Extractors",
  "extractor.import_extractors.desc": "Import extractor exported from FormX.ai",

  "extractor.prebuilt.title": "Get started with one of our built-in extractor",
  "extractor.custom.title": "Can't find what you're looking for?",
  "extractor.others.title": "Other actions",

  "extractor.custom": "Custom",
  "extractor.custom.fixed_layout_extractor": "Fixed Layout Extractor",
  "extractor.custom.trained_with_sample": "Custom Model Extractor",
  "extractor.custom.combine": "Combined Extractor",

  "extractor.combine": "Combined",
  "extractor.prebuilt.gov_id_passport": "Government ID / Passport",
  "extractor.prebuilt.invoice": "Invoice (Deprecated)",
  "extractor.prebuilt.receipt": "Receipts",
  "extractor.prebuilt.food_license": "Food License",
  "extractor.prebuilt.address_proof": "Address Proof",
  "extractor.prebuilt.bill_of_lading": "Bill of Lading",
  "extractor.prebuilt.business_registration": "Business Registration",
  "extractor.prebuilt.invoice_llm": "Invoice",
  "extractor.prebuilt.bank_statement_llm": "Bank Statement",
  "extractor.prebuilt.bill_of_lading_llm": "Bill of Lading (Deprecated)",
  "extractor.prebuilt.air_waybill_llm": "Airway Bill",
  "extractor.prebuilt.purchase_order_llm": "Purchase Order",
  "extractor.prebuilt.quotation_llm": "Quotation",
  "extractor.prebuilt.resume_llm": "Resume",
  "extractor.prebuilt.bill_of_lading_instant_model": "Bill of Lading",

  "extractor.typeOption.combine": "Combine",
  "extractor.typeOption.custom_form": "Fixed layout",
  "extractor.typeOption.custom_model": "Custom model extractor",

  "prebuilt.gov_id_passport.help": "See the list of supported countries",

  "extractor.list.filter.select_all": "Select All",
  "extractor.list.filter.all": "All",
  "extractor.list.filter.types": "Types",
  "extractor.list.filter.label": "Show by type",
  "extractor.list.no_extractor_found": "No Extractor found.",
  "extractor.list.list.create_new_extractor": "Create New Extractor",
  "extractor.list.last_edited": "Last edited {date}",
  "extractor.list.confirm_delete.title": "Delete Extractor",
  "extractor.list.confirm_delete.message": "Are you sure to delete the selected extractor?",
  "extractor.list.confirm_delete.action": "Delete",
  "extractor.list.deleting": "Deleting extractor...",
  "extractor.list.error.fail_to_pin_extractor": "Failed to pin extractor, please try again later.",
  "extractor.list.error.fail_to_remove_extractor": "Failed to remove extractor, please try again later.",
  "extractor.list.modal.cannot_delete_extractor_connected_to_workspace.title": "Cannot delete this extractor",
  "extractor.list.modal.cannot_delete_extractor_connected_to_workspace.description": "An extractor with any connected workspace cannot be deleted. Remove those workspaces before deleting this extractor.",
  "extractor.list.modal.cannot_delete_extractor_connected_to_workspace.button.got_it": "Got it",
  "extractor.list.modal.cannot_delete_extractor_connected_to_workspace.button.go_to_workspaces": "Go to Workspaces",
  "extractor.list.search": "Search",

  "extractor.request_preview_extractor.title": "Join the waitlist to use the preview feature",
  "extractor.request_preview_extractor.desc": "Join the waitlist to try out this extractor. We'll let you know when your spot is ready.",
  "extractor.request_preview_extractor.success.title": "We will contact you shortly",
  "extractor.request_preview_extractor.success.desc": "Thank you for joining the waitlist. You'll receive an email from our team shortly.",
  "extractor.request_preview_extractor.join": "Join the waitlist",
  "extractor.request_preview_extractor.dismiss": "Dismiss",
  "extractor.request_preview_extractor.error": "Error during sending join request.",

  "extractor.setting.block.workspace_setups.title": "Workspace Settings",
  "extractor.setting.block.workspace_setups.description": "A workspace allows you to establish an automated workflow from file import and storage, to data export to the destinations of your choice.",
  "extractor.setting.block.workspace_setups.connected_workspace.title": "Connected Workspace",
  "extractor.setting.block.workspace_setups.connected_workspace.label.go_to_workspace": "Go to Workspace",
  "extractor.setting.block.workspace_setups.connected_workspace.label.create_workspace_for_extractor": "Create a workspace for this extractor",
  "extractor.setting.block.workspace_setups.connected_workspace.label.create_workspace_link_to_extractor": "Create a workspace link to this extractor",
  "extractor.setting.block.general_settings.title": "General Settings",
  "extractor.setting.block.general_settings.field.extractor_name.label": "Extractor Name",
  "extractor.setting.block.general_settings.field.extractor_id.label": "Extractor ID",
  "extractor.setting.block.general_settings.field.extractor_type.label": "Extractor Type",
  "extractor.setting.block.general_settings.field.document_processing_option.label": "Document Processing Option",
  "extractor.setting.block.contact_us.title": "Contact Us",
  "extractor.setting.block.contact_us.description": "If you need any help from our FormX AI experts, you can leave your questions at {url}",
  "extractor.setting.block.advanced_settings.title": "Advanced Settings",
  "extractor.setting.block.advanced_settings.field.post_processing_script.label": "Post Processing Script",
  "extractor.setting.block.advanced_settings.field.transform_response_script.label": "Transform Response Script",
  "extractor.setting.block.advanced_settings.field.llm_model.label": "LLM in Extractor",
  "extractor.setting.block.image_quality_check.title": "Image Quality Check",
  "extractor.setting.block.image_quality_check.description": "Once enabled, FormX will check the quality of the image before data extraction. If the image quality is poor, FormX will not proceed to data extraction and return an error.",
  "extractor.setting.block.data_log_settings.title": "Data Log",
  "extractor.setting.block.data_log_settings.description": "Once data logging is enabled, FormX will record all documents processed by this extractor. This data will help us enhance accuracy and provide better support for you.",
  "extractor.setting.block.data_log_settings.instruction": "You can also retrieve the stored image and extraction results via an endpoint. ",
  "extractor.setting.block.data_log_settings.learn_more": "Learn How",
  "extractor.setting.block.data_log_settings.request_access": "Request Access",
  "extractor.setting.block.data_log_settings.request_access_message": "Sent successfully! We've received your request. ",
  "extractor.setting.block.data_log_settings.sign_up": "Sign up for early access to data logging.",
  "extractor.setting.block.data_log_settings.new_tag": "New",
  "extractor.setting.block.data_log_settings.retention_period.title": "Data Retention Period",
  "extractor.setting.block.data_log_settings.retention_period.description": "Stored data will be removed after",
  "extractor.setting.block.data_log_settings.retention_period.instruction": "Your data is still stored here and will be removed after the set number of days. It may take a few days for us to remove the data depending on the data size. You will receive an email notification once the process is complete.",
  "extractor.setting.block.data_log_settings.retention_period.days": "Days",
  "extractor.setting.block.data_log_settings.retention_period.delete_data": "Delete Data",
  "extractor.setting.block.data_log_settings.retention_period.error": "Invalid input. Please enter a value between 30 and 1200 days.",
  "data_log_removal_request_modal.title": "Delete Data",
  "data_log_removal_request_modal.message1": "Are you sure you want to delete all data? This action cannot be undone.",
  "data_log_removal_request_modal.message2": "It may take a few days for us to remove the data depending on the data size. You will receive an email notification once the process is complete.",
  "data_log_removal_request_modal.action": "Delete All",
  "data_log_removal_request_modal.toast": "Your data deletion request is being processed.",
  "data_log_disable_warning.title": "Disabling Data Log",
  "data_log_disable_warning.message": "Data logging is now disabled and will no longer log new data. If you want to delete all existing data, click \"Delete Data\" in settings.",
  "extractor.setting.block.prompt_tuning_settings.title": "Prompt Tuning Settings",
  "extractor.setting.block.prompt_tuning_settings.field.add_border_line.label": "Prompt OCR Result Augmentation",
  "extractor.setting.block.prompt_tuning_settings.field.add_border_line.option": "Add border line to OCR result",
  "extractor.setting.block.prompt_tuning_settings.field.llm_model.label": "LLM Model",
  "extractor.setting.block.prompt_tuning_settings.field.input_type.ocr_and_image.label": "Pass both OCR & image to LLM",
  "extractor.setting.block.prompt_tuning_settings.field.input_type.ocr_only.label": "Pass OCR only to LLM",
  "extractor.setting.block.prompt_tuning_settings.field.input_type.image_only.label": "Pass image only to LLM",
  "extractor.setting.block.prompt_tuning_settings.field.white_spec_preservation.label": "White Space Preservation",
  "extractor.setting.block.prompt_tuning_settings.field.white_spec_preservation.options.horizontal": "Preserve horizontal white space in OCR result",
  "extractor.setting.block.prompt_tuning_settings.field.white_spec_preservation.options.vertical": "Preserve vertical white space in OCR result",
  "extractor.setting.block.prompt_tuning_settings.field.prompt_splitting.label": "Prompt splitting",
  "extractor.setting.block.prompt_tuning_settings.field.prompt_splitting.option": "Split prompt for line items",
  "extractor.setting.modal.create_workspace.title": "Create a new workspace",
  "extractor.setting.modal.create_workspace.field.workspace_name.label": "Workspace name",

  "extractor.test.view_in_json": "JSON",
  "extractor.test.view_in_key_value": "Key-Value",
  "extractor.test.download_json": "JSON",
  "extractor.test.view_llm_prompt": "View LLM Prompt",
  "extractor.test.ocr": "OCR",
  "extractor.test.image_quality": "Image Quality",
  "extractor.test.unable_to_load_image": "Failed to display the image of this page",
  "extractor.test.pagination": "Image {current} of {total}",
  "extractor.test.result_error": "Failed to extract result for this page",
  "extractor.test.reupload": "Re-Upload",
  "extractor.test.extracted_by": "Extracted by: {form_id}",

  "document_detection_type.receipt": "Receipt",
  "document_detection_type.passport_id": "Passport ID",
  "document_detection_type.id_card": "ID Card",
  "document_detection_type.payment_card": "Payment Card",
  "document_detection_type.medical_card": "Medical Card",
  "document_detection_type.other": "Other",

  "prebuilt_custom_model.intl_id": "ID/Driving License",
  "prebuilt_custom_model.passport": "Passport",
  "prebuilt_custom_model.receipt": "Receipts",
  "prebuilt_custom_model.invoice": "Invoices",

  "formatter.editor.title": "Formatter Setup",
  "formatter.editor.desc": "Formatter allows you to modify the extracted data according to your custom needs.",
  "formatter.editor.error": "Resolve all errors before saving.",

  "formatter.editor.placeholder": "Start by adding a step by clicking the button below",
  "formatter.editor.add_step": "Add a step",

  "formatter.actions.remove_characters.title": "Remove Characters",
  "formatter.actions.remove_characters.card.desc": "Remove any unwanted character or phrase in the extracted data.",
  "formatter.actions.remove_characters.action_setup.desc": "Select the types of input you want to remove.",

  "formatter.actions.remove_characters.field_selection.desc": "The action ‘Remove Characters’ will apply to the selected fields below.",
  "formatter.actions.remove_characters.filter.label": "Find Removing Value by",
  "formatter.actions.remove_characters.filter.pre_defined_type": "Pre-defined Types",
  "formatter.actions.remove_characters.filter.custom_value": "Custom Value",
  "formatter.actions.remove_characters.filter.placeholder": "Start a new line for each individual value",

  "formatter.actions.remove_characters.filter.regex": "Regex",

  "formatter.actions.remove_characters.custom.label": "Value to be removed",

  "formatter.actions.remove_characters.regex.label": "Remove if matches Regex Pattern",

  "formatter.actions.remove_characters.regex.placeholder": "e.g. [0-9]",

  "formatter.actions.remove_characters.special_char_tooltip": "Special characters include: ! “ # $ % & ‘ ( ) * + , - . / : ;  < = > ? @ [ \\ ] ^- ` {curly_begin} | {curly_end} ~",

  "formatter.actions.remove_characters.preset.numeric": "Numbers",
  "formatter.actions.remove_characters.preset.alphabet": "English Alphabets",
  "formatter.actions.remove_characters.preset.special_character": "Special Characters",
  "formatter.actions.remove_characters.preset.space": "Space",
  "formatter.actions.remove_characters.preset.new_line": "New Line",
  "formatter.actions.remove_characters.preset.latin_alphabet": "Extended Latin Alphabets",
  "formatter.actions.remove_characters.preset.chinese": "Chinese",

  "formatter.actions.swap_month_day.title": "Declare Date Format",
  "formatter.actions.swap_month_day.card.desc": "Declare a desired date format if a date value is extracted in a wrong format (mixing up the day and the month).",
  "formatter.actions.swap_month_day.action_setup.desc": "FormX detects date with the UK format (day-first) by default. Applying this action will instruct FormX to detect date with the US format (month-first) instead.",
  "formatter.actions.swap_month_day.field_selection.desc": "The action ‘Declare Date Format’ will apply to the selected fields below.",

  "formatter.actions.swap_month_day.option": "Use US Format (month-first) instead",

  "formatter.actions.keep_only_one_language.title": "Keep Only One Language",
  "formatter.actions.keep_only_one_language.card.desc": "Keep only a desired language in the extracted data.",
  "formatter.actions.keep_only_one_language.action_setup.desc": "Select the language you want to keep",
  "formatter.actions.keep_only_one_language.field_selection.desc": "The action ‘Keep Only One Language’ will apply to the selected fields below.",

  "formatter.actions.keep_only_one_language.en": "English",
  "formatter.actions.keep_only_one_language.chi": "Chinese",

  "formatter.actions.keep_only_one_language.hint": "This action will keep also the language specific punctuations.",

  "formatter.step_selector.title": "Select an Action",
  "formatter.step_item.more_label": "+{count} more",

  "formatter.step_editor.section.field_section.title": "Field selection",
  "formatter.step_editor.section.field_section.desc": "The action ‘Declare Date Format’ will apply to the selected fields below.",
  "formatter.step_editor.section.field_section.dropdown.title": "Field",
  "formatter.step_editor.section.field_section.dropdown.placeholder": "Select",
  "formatter.step_editor.section.field_section.dropdown.loading": "Fetching Data Fields...",
  "formatter.step_editor.section.field_section.dropdown.error": "There is an error displaying the data fields. {try_again}",
  "formatter.step_editor.section.field_section.dropdown.try_again": "Try Again",
  "formatter.step_editor.section.field_section.dropdown.empty": "There is no data fields in this extractor yet",
  "formatter.step_editor.section.field_section.dropdown.unavailable_option": "{text} (Unavailable)",
  "formatter.step_editor.section.field_section.error": "The highlighted fields are no longer available.",

  "formatter.step_editor.section.action_setup.title": "Action Setup",

  "formatter.step_editor.section.condition_matcher.title": "Condition Matching",
  "formatter.step_editor.section.condition_matcher.desc": "You can specify the condition to instruct FormX when to execute this action. {learn_more}",
  "formatter.step_editor.section.condition_matcher.desc.learn_more": "Learn more.",

  "formatter.step_editor.section.condition_matcher.enable_checkbox": "Enable Condition Matching",
  "formatter.step_editor.section.condition_matcher.desc2": "This action will be executed when the value detected in `Field` is contained in the list below",
  "formatter.step_editor.section.condition_matcher.field_selection.label": "Field",
  "formatter.step_editor.section.condition_matcher.field_selection.placeholder": "Select",
  "formatter.step_editor.section.condition_matcher.field_missing_error": "The field is no longer available",

  "formatter.step_editor.section.condition_matcher.operator.label": "Operator",
  "formatter.step_editor.section.condition_matcher.operator.placeholder": "Exact match",

  "formatter.step_editor.section.condition_matcher.condition_value.label": "Condition Value",
  "formatter.step_editor.section.condition_matcher.condition_value.list_label": "Condition List Value",
  "formatter.step_editor.section.condition_matcher.condition_value.placeholder": "Enter a value to match",

  "formatter.step_editor.section.condition_matcher.operators.exact_match": "Exact match",
  "formatter.step_editor.section.condition_matcher.operators.contains": "Contains",
  "formatter.step_editor.section.condition_matcher.operators.not_contains": "Not contains",
  "formatter.step_editor.section.condition_matcher.operators.in_list": "In list",
  "formatter.step_editor.section.condition_matcher.operators.not_in_list": "Not in list",

  "formatter.step_editor.section.output_settings.title": "Output Settings",
  "formatter.step_editor.section.output_settings.desc": "How would you like to display the final outcome.",
  "formatter.step_editor.section.output_settings.keep_original_field_option": "Keep in Original Field",
  "formatter.step_editor.section.output_settings.new_field_option": "Return in New Field",
  "formatter.step_editor.section.output_settings.desc2": "The output from this action will replace the original detected value. To show any output in a separated field, select ‘Show in New Field’ and specify the new field name below.",
  "formatter.step_editor.section.output_settings.new_field_label": "New Field Name (original: {source})",
  "formatter.step_editor.section.output_settings.duplicated_field_error": "Field name cannot be duplicated",
  "formatter.step_editor.section.output_settings.invalid_field_error": "Invalid field name",

  "formatter.confirm_delete_step.title": "Delete this step?",
  "formatter.confirm_delete_step.message": "Once deleted, you will lose all the configurations in this step. Confirm to delete?",

  "formatter.fields.header.auto_extraction_items": "Auto Extraction Items",
  "formatter.fields.header.fields": "Fields",
  "formatter.fields.header.key_values": "Key Values",
  "formatter.fields.header.token_groups": "Text Tokens",
  "formatter.fields.header.formatter_output": "Formatter Output",

  "workspace.uploading_exit_warning": "{count} files upload in progress. Leaving this page will discard the ongoing upload progress. Are you sure you want to leave this page?",
  "workspace.exporting_exit_warning": "{count} document exports in progress. Leaving this page will discard the ongoing export progress. Are you sure you want to leave this page?",
  "workspace.integrations.modal.export_zapier.access_token": "Access Token",
  "workspace.integrations.modal.export_zapier.description": "Go to Zapier to set up the integration. Supply the following access token to Zapier to make an integration.",
  "workspace.integrations.modal.export_zapier.get_started": "Get Started",
  "workspace.integrations.modal.export_zapier.tutorial_link": "Tutorial",
  "workspace.integrations.modal.export_zapier.title": "Add Export to Zapier",
  "workspace.integrations.modal.export_zapier.workspace_id": "Workspace ID",
  "workspace.integrations.modal.export_zapier.extractor_id": "Extrator ID",
  "workspace.integrations.option.import_sharepoint.title": "Import from Sharepoint",
  "workspace.integrations.option.import_sharepoint.description": "Automatically import any new files uploaded to your Sharepoint folder",
  "workspace.integrations.option.import_zapier.title": "Integrate with Zapier",
  "workspace.integrations.option.import_zapier.description": "Connect to other applications in your workflow through Zapier",
  "workspace.integrations.option.import_email.title": "Import from Email",
  "workspace.integrations.option.import_email.description": "Automatically import any files sent to your email address as attachment",
  "workspace.integrations.option.import_googleDrive.title": "Import from Google Drive",
  "workspace.integrations.option.import_googleDrive.description": "Automatically import any new files uploaded to your Google Drive folder",
  "workspace.integrations.option.import_dropbox.title": "Import from Dropbox ",
  "workspace.integrations.option.import_dropbox.description": "Automatically import any new files uploaded to your Dropbox folder",
  "workspace.integrations.option.export_googleSheets.title": "Export to Google Sheets",
  "workspace.integrations.option.export_googleSheets.description": "Automatically export extracted values to Google Sheets",
  "workspace.integrations.option.export_webhook.title": "Export to Webhooks",
  "workspace.integrations.option.export_webhook.description": "Receive real-time updates at your Webhooks endpoints when events are triggered",
  "workspace.integrations.option.export_zapier.description": "Connect to other applications in your workflow through Zapier",
  "workspace.integrations.option.export_zapier.title": "Export to Zapier",
  "workspace.integrations.option.comming_soon": "Coming Soon",
  "workspace.integrations.option.add": "Add",
  "workspace.integrations.option.added": "Added",
  "workspace.integrations.3rd_party.add": "Link",

  "workspace.integrations.option_list.import.title": "Import Options ({count})",
  "workspace.integrations.option_list.export.title": "Export Options ({count})",
  "workspace.integrations.option_list.3rdparty.title": "3rd party automation tool ({count})",

  "workspace.integrations.option_setting.import.title": "Import",
  "workspace.integrations.option_setting.import.empty_state": "Pick the import integrations you prefer from the right panel",

  "workspace.integrations.configuration.sharepoint.title": "Import from Sharepoint",
  "workspace.integrations.configuration.sharepoint.instruction.label": "Connecting to your Sharepoint account",
  "workspace.integrations.configuration.sharepoint.instruction.desc": "First authenticate to your Sharepoint account and granting admin permissions to FormX, then select the folder you want to listen to. Anything file added to that folder will be imported to FormX.",
  "workspace.integrations.configuration.sharepoint.site_url.label": "Sharepoint Subdomain*",
  "workspace.integrations.configuration.sharepoint.site_url.desc": "Please provide you SharePoint subdomain (e.g. myorganization.sharepoint.com)",
  "workspace.integrations.configuration.sharepoint.folder_picker.label": "Select a Folder to listen to",
  "workspace.integrations.configuration.sharepoint.folder_picker.desc": "Any files added to this selected folder will be imported to FormX",
  "workspace.integrations.configuration.sharepoint.folder_picker.button": "Select a Folder",
  "workspace.integrations.configuration.sharepoint.folder_picker.deselect": "Disconnect Folder",
  "workspace.integrations.configuration.sharepoint.login_button": "Sign-in with Sharepoint",
  "workspace.integrations.configuration.sharepoint.login_button.loading": "Pending permission from Sharepoint...",
  "workspace.integrations.configuration.sharepoint.disconnect_button": "Disconnect with Sharepoint",
  "workspace.integrations.configuration.sharepoint.save": "Save",
  "workspace.integrations.configuration.sharepoint.creating_subscription": "Creating subscription...",

  "workspace.integrations.configuration.export_googleSheets.connectedAccounts.description": "Sign in to connect your Google Sheet account.",
  "workspace.integrations.configuration.export_googleSheets.connectedAccounts.new": "Connect to new google account",
  "workspace.integrations.configuration.export_googleSheets.connectedAccounts.title": "1. Connect to Google Sheet account",
  "workspace.integrations.configuration.export_googleSheets.createNewSpreadsheet": "Create new spreadsheet",
  "workspace.integrations.configuration.export_googleSheets.dropdown.clear": "No selection",
  "workspace.integrations.configuration.export_googleSheets.exportAction.description": "Select how the data should be added in Google Sheet.",
  "workspace.integrations.configuration.export_googleSheets.exportAction.option.insert.title": "Create a new record",
  "workspace.integrations.configuration.export_googleSheets.exportAction.option.upsert.title": "Update existing record (create a new one if none exists)",
  "workspace.integrations.configuration.export_googleSheets.exportAction.title": "3. Export Action",
  "workspace.integrations.configuration.export_googleSheets.fieldMappings.description": "Select the spreadsheet and worksheet to which the file will be exported.",
  "workspace.integrations.configuration.export_googleSheets.fieldMappings.fields": "Sheet columns",
  "workspace.integrations.configuration.export_googleSheets.fieldMappings.formx_field": "FormX fields",
  "workspace.integrations.configuration.export_googleSheets.fieldMappings.sheet_column": "Sheet Column",
  "workspace.integrations.configuration.export_googleSheets.fieldMappings.title": "2. Google Sheets - Formx mapping",
  "workspace.integrations.configuration.export_googleSheets.login_button.loading": "Pending permission from Google ...",
  "workspace.integrations.configuration.export_googleSheets.login_button": "Sign in with Google",
  "workspace.integrations.configuration.export_googleSheets.save": "Save",
  "workspace.integrations.configuration.export_googleSheets.last_refreshed": "Last refreshed: {date}",
  "workspace.integrations.configuration.export_googleSheets.selectSheet": "Select Worksheet",
  "workspace.integrations.configuration.export_googleSheets.selectSpreadsheet": "Select Spreadsheet",
  "workspace.integrations.configuration.export_googleSheets.title": "Export to Google Sheets",
  "workspace.integrations.configuration.export_googleSheets.mapping": "Mapping...",

  "workspace.integrations.configuration.export_webhook.title": "Import from Sharepoint",

  "workspace.integrations.configuration_list.action.remove": "Unlink",
  "workspace.integrations.configuration_list.column.action": "Action",
  "workspace.integrations.configuration_list.column.connectionId": "Connection ID",
  "workspace.integrations.configuration_list.column.status": "Status",
  "workspace.integrations.configuration_list.section.description.zapier": "Connect to other applications in your workflow through Zapier",
  "workspace.integrations.configuration_list.section.title.zapier": "Zapier",
  "workspace.integrations.configuration_list.status.connected": "Linked",

  "workspace.integrations.confirmRemoveModal.action.remove": "Remove",
  "workspace.integrations.confirmRemoveModal.description": "Are you sure you want to remove this Zapier connection (Subscription ID: {connectionId})? Once removed, your Zap will stop functioning. Please remember to update your Zap on Zapier after disconnecting.",
  "workspace.integrations.confirmRemoveModal.title": "Remove Connection",

  "workspace.integrations.option_setting.export.title": "Export",
  "workspace.integrations.option_setting.export.empty_state": "Pick the export integrations you prefer from the right panel",

  "workspace.integrations.option_setting.3rdparty.title": "3rd party automation tool",
  "workspace.integrations.option_showing.zapier_trigger": "Zapier trigger",

  "workspace.integrations.toast.remove_failed": "Fail to delete webhook",
  "workspace.integrations.toast.remove_success": "Webhook is deleted",

  "workspace.api.desc.1": "Send the image with a POST request to the Workflow API endpoint and FormX will recognize the information from the document. The extracted data can then be viewed and export from the portal.",
  "workspace.api.desc.2": "Use the Workspace ID in the request to specify the workspace, the linked extractor with be used. An access token should also be included in the request for authentication.",

  "advance_token_setup_launcher.title": "Advanced Pattern Matching",
  "advance_token_setup_launcher.description": "Advanced pattern matching allows you to define, detect and extract data in a more controlled and precise manner.",
  "advance_token_setup_launcher.setup_button": "Go to Setup",

  "advance_token_setup.editor.title": "Advanced Pattern Matching",
  "advance_token_setup.editor.action.save_and_test": "Save and Test",
  "advance_token_setup.editor.action.save": "Save",
  "advance_token_setup.editor.action_option.save_and_bookmark": "Save and Bookmark",
  "advance_token_setup.editor.toast.changes_saved": "Changes Saved",
  "advance_token_setup.editor.tooltip.save": "Save: {span, react, className{{highlightTextClassName}} children{{modifierKey}}}+{span, react, className{{highlightTextClassName}} children{S}}",
  "advance_token_setup.editor.tooltip.quick_test": "Quick Test: {span, react, className{{highlightTextClassName}} children{{modifierKey}}}+{span, react, className{{highlightTextClassName}} children{S}}",

  "advance_token_setup.editor.form_not_saved_prompt.save_warning": "There are unsaved changes, confirm leaving this page without saving?",
  "advance_token_setup.editor.form_not_saved_prompt.title": "Unsaved changes",
  "advance_token_setup.editor.form_not_saved_prompt.go_back": "Go Back",
  "advance_token_setup.editor.form_not_saved_prompt.leave_page": "Leave Page",
  "advance_token_setup.editor.uploading": "Uploading...",

  "advance_token_setup.nav_menu.group.tag_groups.title": "Tag Groups",
  "advance_token_setup.nav_menu.tag.merchant_id": "merchant_id",
  "advance_token_setup.nav_menu.tag.mall_id": "mall_id",
  "advance_token_setup.nav_menu.group.exact_match_rule.title": "Exact Match Rules",
  "advance_token_setup.nav_menu.group.closest_match_rule.title": "Closest Match Rules",
  "advance_token_setup.nav_menu.group.field_replacement.title": "Field Replacement",
  "advance_token_setup.searchbar.placeholder": "search this sheet (Ctrl+F)",
  "advance_token_setup.searchbar.placeholder.mac": "search this sheet (⌘+F)",
  "advance_token_setup.searchbar.ordering.priority.asc": "Priority (Ascending)",
  "advance_token_setup.searchbar.ordering.priority.desc": "Priority (Descending)",
  "advance_token_setup.searchbar.ordering.last_modified.asc": "Last Modified (Ascending)",
  "advance_token_setup.searchbar.ordering.last_modified.desc": "Last Modified (Descending)",
  "advance_token_setup.searchbar.importing_csv.replace": "Replace existing entries",
  "advance_token_setup.searchbar.importing_csv.append": "Insert after existing entries",
  "advance_token_setup.searchbar.importing_csv.download_template": "Download csv template",
  "advance_token_setup.searchbar.btn.import_csv": "Import CSV",
  "advance_token_setup.searchbar.btn.export_csv": "Export CSV",
  "advance_token_setup.footer.num_of_rows": "{count, plural, one{1 row} other{# rows}} in this sheet",
  "advance_token_setup.footer.get_help": "Get Help?",
  "advance_token_setup.tag_groups.table.hint.description": "Tags are the values to be returned once the rules you defined in {span, react, className{{highlightTextClassName}} children{Exact Match Rules}} or {span, react, className{{highlightTextClassName}} children{Closest Match Rules}} are fulfilled.{br, react}Tags can be created directly in other pages, and will be shown here in the appropriate tag group.",
  "advance_token_setup.exact_match_rule.table.hint.description": "If {span, react, className{{boldTextClassName}} children{ALL}} the defined tokens on the same row are matched, FormX will return the associated tags. FormX will prioritise detecting rows in ascending numerical order.{br, react}If none of these Exact Match Rules are matched, FormX will fallback to use {span, react, className{{highlightTextClassName}} children{Closest Match Rules}} for detection",
  "advance_token_setup.closest_match_rule.table.hint.description": "FormX will detect by Closest Match Rules only when no matching results is matched using {span, react, className{{highlightTextClassName}} children{Exact Match Rules}}.{br, react}FormX will return that one tag associated to the token with the closest match.",
  "advance_token_setup.field_replacement.table.hint.description": "Once a tag is returned from matching {span, react, className{{highlightTextClassName}} children{Exact Match}} or {span, react, className{{highlightTextClassName}} children{Closest Match Rules}},{br, react}FormX will replace the corresponding fields below accordingly.",
  "advance_token_setup_table.header.brand_name": "Brand Name",
  "advance_token_setup_table.header.email": "Email",
  "advance_token_setup_table.header.phone_number": "Phone Number",
  "advance_token_setup_table.header.fax_number": "Fax Number",
  "advance_token_setup_table.header.url": "URL",
  "advance_token_setup_table.header.positive_tokens": "Positive Tokens",
  "advance_token_setup_table.header.negative_tokens": "Negative Tokens",
  "advance_token_setup_table.header.action": "Action",
  "advance_token_setup_table.header.custom_tokens": "Text Token",
  "advance_token_setup_table.header.invoice_number": "invoice_number",
  "advance_token_setup_table.header.total_amount": "total_amount",
  "advance_token_setup_table.header.date": "date",
  "advance_token_setup_table.header_group.tag_group": "Tag Group",
  "advance_token_setup_table.header_group.returning_tags": "Returning tags",
  "advance_token_setup_table.header_group.matching_tokens": "Matching tokens",
  "advance_token_setup_table.header_group.fields": "Fields",
  "advance_token_setup_table.header.action": "Action",
  "advance_token_setup_table.setting_cell.no_replacement": "No replacement",
  "advance_token_setup_table.setting_cell.key_value_pair": "Key-Value Pair",
  "advance_token_setup_table.setting_cell.custom_model": "custom model - {fieldName}",
  "advance_token_setup_table.setting_cell.change_date_input": "Date Input Format - {format}",
  "advance_token_setup_table.setting_cell.llm": "LLM",
  "advance_token_setup_table.validation.tag.should_not_empty": "at least one tag is required",
  "advance_token_setup_table.validation.rule.should_not_duplicated": "Rule already exists for this tag",
  "advance_token_setup_table.validation.rule.must_set_setting": "At least one replacement field must be set",
  "advance_token_setup_table.validation.token.should_not_empty": "at least one token is required",

  "advance_token_setup_table.validation.tooltip.regex": "Regex: {span, react, className{{highlightTextClassName}} children{{modifierKey}}}+{span, react, className{{highlightTextClassName}} children{3}}",
  "advance_token_setup_table.validation.tooltip.remove_white_space": "Remove white space: {span, react, className{{highlightTextClassName}} children{{modifierKey}}}+{span, react, className{{highlightTextClassName}} children{2}}",
  "advance_token_setup_table.validation.tooltip.forced_remove_white_space": "Forced remove white space: {span, react, className{{highlightTextClassName}} children{{modifierKey}}}+{span, react, className{{highlightTextClassName}} children{2}}",
  "advance_token_setup_table.validation.tooltip.exact_match": "Exact match: {span, react, className{{highlightTextClassName}} children{{modifierKey}}}+{span, react, className{{highlightTextClassName}} children{1}}",
  "advance_token_setup_field_replacement_setting_panel.title": "Field Replacement Settings",
  "advance_token_setup_field_replacement_setting_panel.title_with_target": "Field Replacement Settings - {target}",
  "advance_token_setup_field_replacement_setting_panel.option1.no_replacement": "No replacement",
  "advance_token_setup_field_replacement_setting_panel.option2.replace_with_key_value_pair": "Replace with Key-Value Pair",
  "advance_token_setup_field_replacement_setting_panel.option3.replace_with_custom_model_config": "Replace with Custom Model Config",
  "advance_token_setup_field_replacement_setting_panel.option4.replace_with_llm": "Replace with LLM",
  "advance_token_setup_field_replacement_setting_panel.option5.change_input_date_format": "Change Input Date Format",
  "advance_token_setup_field_replacement_setting_panel.footer.done": "Done",
  "advance_token_setup_field_replacement_setting_panel.footer.cancel": "Cancel",
  "advance_token_setup_field_replacement_setting_panel.panel.key_value.pattern": "Pattern (regex, optional)",
  "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.title": "Keys",
  "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.empty": "Start by Adding New Key",
  "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.add": "+ Add New Key",
  "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.columns.key": "Key (regex)",
  "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.columns.fuzzy": "Fuzzy Search",
  "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.key_input.placeholder": "Enter Key",
  "advance_token_setup_field_replacement_setting_panel.panel.custom_model.custom_model_id": "Custom Model ID",
  "advance_token_setup_field_replacement_setting_panel.panel.custom_model.replace_with_field": "Replace with field",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.advanced_settings": "Advanced settings",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.date_input_format.label": "Date Input Format",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.samples.title": "Samples",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.samples.subtitle": "({num}/{max})",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.samples.description": "Samples set up for this merchant will be shared across all fields in field replacement.",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.samples.hint": "Accept image in JPG, PNG format, and smaller than 10MB",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.samples.delete_confirm.title": "Remove {filename}",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.samples.delete_confirm.message": "The sample is shared across different fields. Do you want to remove this sample from all related fields?",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.title": "Prompts",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.description.invoice_number": "Add additional information to help LLM locate the invoice number.",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.description.total_amount": "Add additional information to help LLM locate the total amount.",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.description.date": "Add additional information to help LLM locate the date.",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.placeholder.invoice_number": "(eg) invoice number is always placed at the bottom",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.placeholder.total_amount": "(eg) total amount is always placed next to the payment method e.g. MAST, VISA, CASH and etc.",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description.placeholder.date": "(eg) date is always placed under the company name",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.llm_model.label": "Model",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.input_type.ocr_only.label": "Pass OCR only to LLM",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.input_type.image_only.label": "Pass image only to LLM",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.input_type.ocr_and_image.label": "Pass both OCR & image to LLM",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.whitespace_preservation": "Whitespace preservation",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.preserve_horizontal_whitespace.label": "Horizontal whitespace preservation",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.preserve_vertical_whitespace.label": "Vertical whitespace preservation",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description": "Field description (Optional)",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.title": "Sample for ''{tag}''",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.invoice_number.instruction": "Enter the field value based on the provided samples. This information will be used to train the GPT model. Please make sure the value provided is accurate.",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.invoice_number.label": "invoice_number",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.invoice_number.placeholder": "invoice_number",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.total_amount.label": "total_amount",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.total_amount.placeholder": "total_amount",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.date.label": "date",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.date.placeholder": "YYYY-MM-DD",
  "advance_token_setup_field_replacement_setting_panel.panel.llm.edit_sample.add": "Add",
  "advance_token_setup_field_replacement_setting_panel.panel.change_date_input.convert_date_order": "Convert the following date order into YYYY-MM-DD",
  "advance_token_setup_table.empty_result": "No matching results",
  "advance_token_setup.merge_editor.action.cancel_import": "Cancel Import",
  "advance_token_setup.merge_editor.action.confirm_import": "Confirm Import",
  "advance_token_setup.remove_tag_modal.title": "Confirm Removing this Tag?",
  "advance_token_setup.remove_tag_modal.message": "There are rules associated to this tag. Removing this tag will also remove it from those rules. Confirm to proceed?",

  "workspace.breadcrumb.root": "Workspaces",
  "workspace.tab.documents": "Documents",
  "workspace.tab.integrations": "Integrations",
  "workspace.tab.api": "API",
  "workspace.tab.settings": "Settings",
  "workspace.nav_bar.extractor_button.label": "extractor",
  "workspace.list.title": "Workspaces",
  "workspace.list.subtitle": "Import, extract, review, and export documents.",
  "workspace.list.button.create.label": "Create new workspace",
  "workspace.list.create_new_workspace": "Create new workspace",
  "workspace.list.create_new_workspace.no_permission_modal.title": "Contact the admin to create a workspace",
  "workspace.list.create_new_workspace.no_permission_modal.message": "To create a workspace, please kindly reach out to the admin user for assistance.",
  "workspace.list.create_new_workspace.no_permission_modal.action": "Got it",
  "workspace.list.no_workspace_found": "No Workspace found.",
  "workspace.list.empty_view.title": "How does Workspace Work?",
  "workspace.list.empty_view.message": "Workspace is your control centre for the entire extraction journey: import, extract, review (coming soon!), and export.",
  "workspace.list.empty_view.create_button": "Create your first workspace",
  "workspace.list.item.extractor": "extractor",
  "workspace.create.title": "Create new workspace",
  "workspace.create.field.workspace_name.label": "1. Name Your Workspace",
  "workspace.create.field.extractor_connection.label": "2. Select an extractor to use",
  "workspace.create.field.extractor_connection.description": "Select the extractor to use to process your uploaded documents.",
  "workspace.create.field.extractor_connection.option.pre_trained": "Use a new prebuilt extractor instead",
  "workspace.create.field.extractor_connection.option.existing": "Use an existing extractor in your team instead",
  "workspace.create.field.extractor.label.pre_trained": "Pre-Built Extractors",
  "workspace.create.field.extractor.label.existing": "Your Extractors",
  "workspace.list.last_edited": "Last edited {date}",

  "workspace.list.confirm_delete.title": "Confirm Deleting This Workspace?",
  "workspace.list.confirm_delete.message": "Setups, Documents and Data associated to this workspace will all be removed. Confirm to remove?",
  "workspace.list.confirm_delete.action": "Remove",
  "workspace.document.top_bar.search_box.label": "Search",
  "workspace.document.top_bar.out_of_quota": "Out of API Quota",
  "workspace.document.top_bar.upload_button.label": "Upload Documents",
  "workspace.document.top_bar.delete_selected": "Delete {count, plural, =1 {1 selected document} other {# selected documents}}",
  "workspace.document.top_bar.delete_all": "Delete all docuemnts",
  "workspace.document.top_bar.button.filter_result": "Filter Result: {selectedOption}",
  "workspace.document.top_bar.filter_result_option.all": "All",
  "workspace.document.top_bar.filter_result_option.failed": "Failed",
  "workspace.document.top_bar.filter_result_option.failed.button.retry_all": "Retry all",
  "workspace.document.top_bar.filter_result_option.extracted": "Extracted",
  "workspace.document.bottom_bar.pagination.processing_count": "Extracting {count}",
  "workspace.document.bottom_bar.pagination.page_number": "Page {current} of {total}",
  "workspace.document.top_bar.export_button.label": "Export {count} to Excel",
  "workspace.document.top_bar.export_button.label.all": "Export all to Excel",
  "workspace.document.bottom_bar.automation_button.label": "Let’s Go Automation! ✨",
  "workspace.document_detail.loading.result": "Loading Result...",
  "workspace.document_detail.loading.image": "Loading Image...",
  "workspace.document_detail.pagination.error.no_results_on_previous_page": "No more result found on previous pages",
  "workspace.document_detail.pagination.error.no_results_on_next_page": "No more result found on next pages",
  "workspace.document_detail.header.pagination.page_number": "Document {current} of {total}",
  "workspace.document_detail.download_button.label": "Download .xlsx",
  "workspace.document_detail.key_value": "Key-Value",
  "workspace.document_detail.json": "JSON",
  "workspace.document_detail.image_quality": "Image Quality",
  "workspace.document_detail.asset_image.not_found": "Document image not found",
  "workspace.document_detail.unable_to_get_document": "Unable to get document",
  "workspace.document_detail.go_to_extractor_block.tab_name": "Manage Fields",
  "workspace.document_detail.extractionFailed.message": "There is an extraction error for this document",
  "workspace.document_detail.result_asset.not_found.message": "Failed to fetch extraction result, please try refreshing the page",
  "workspace.document_detail.retry_extraction": "Retry Extraction",
  "workspace.document_detail.retry_extraction.success": "Retry Extraction Submitted",
  "workspace.setting.section.workspace_settings.title": "Workspace Settings",
  "workspace.setting.section.workspace_settings.field.workspace_name": "Workspace Name",
  "workspace.setting.section.workspace_settings.field.workspace_id": "Workspace ID",
  "workspace.setting.form_not_saved_prompt.save_warning": "There are unsaved changes, confirm leaving this page without saving?",
  "workspace.setting.form_not_saved_prompt.title": "Unsaved changes",
  "workspace.setting.form_not_saved_prompt.go_back": "Go Back",
  "workspace.setting.form_not_saved_prompt.leave_page": "Leave Page",
  "workspace.setting.section.document_processing_option.title": "Document Processing Option",
  "workspace.setting.section.document_processing_option.per-page": "FormX is now considering each page of an uploaded file a different document. (e.g. a pdf with multiple ID cards, one card per page)",
  "workspace.setting.section.document_processing_option.per-file": "FormX is now considering all pages in an uploaded PDF as one document. (e.g. a multi-page bank statement PDF)",
  "workspace.setting.section.document_processing_option.multiple-documents-per-page": "FormX is now detecting multiple documents from each page (e.g. e.g. an ID card and a passport scanned on the same page)",
  "workspace.setting.section.document_processing_option.change_settings": "You can change this setting at {url}.",
  "workspace.setting.section.document_processing_option.extractor_settings": "Extractor Settings",
  "workspace.setting.section.csv_exporting_option.title": "CSV Exporting Option",
  "workspace.setting.section.csv_exporting_option.field.document_export_flatten_option": "How do you want the line items appear in the CSV export?",
  "workspace.setting.section.csv_exporting_option.field.document_export_flatten_option.option.one_flattened_column_per_line_item": "One flattened column per line item with one of it’s attributes",
  "workspace.setting.section.csv_exporting_option.field.document_export_flatten_option.option.one_row_per_line_item": "One row per line item, in a separated CSV sheet",
  "workspace.setting.section.contact_us.title": "Contact Us",
  "workspace.setting.section.contact_us.paragraph": "If you need any help from our FormX AI experts, you can leave your questions at {url}",
  "workspace.setting.tutorial.label.csv_output_sample": "CSV Output Sample",
  "workspace.setting.tutorial.label.file_input_sample": "File Input Sample",
  "workspace.setting.tutorial.label.sample_image": "Sample Image",
  "workspace.setting.tutorial.multiple_documents.title": "More on multiple documents",
  "workspace.setting.tutorial.multiple_documents.section.multiple_pages.title": "Multiple Pages Extraction",
  "workspace.setting.tutorial.multiple_documents.section.multiple_pages.description": "An extra column – ‘Page’ will appear in the CSV export, indicating where a data field is extracted from.",
  "workspace.setting.tutorial.multiple_documents.section.multiple_documents.title": "Multiple Documents in a Page Extraction",
  "workspace.setting.tutorial.multiple_documents.section.multiple_documents.description": "An extra column – ‘Slice’ will appear in the CSV export, indicating which image on a page a data field is referring to.",
  "workspace.setting.tutorial.line_items.title": "More on line items",
  "workspace.setting.tutorial.line_items.section.what_are_line_items.title": "What are Line Items",
  "workspace.setting.tutorial.line_items.section.what_are_line_items.description": "Line items are a set of fields returned from a table.",
  "workspace.setting.tutorial.line_items.section.csv_export_option_1.option": "CSV Export Option 1",
  "workspace.setting.tutorial.line_items.section.csv_export_option_1.title": "One row per line item, in a separated CSV sheet",
  "workspace.setting.tutorial.line_items.section.csv_export_option_1.description": "Each line items are exported in separate rows with attributes/sub-fields being the columns. They will appear in a separate CSV sheet.",
  "workspace.setting.tutorial.line_items.section.csv_export_option_2.option": "CSV Export Option 2",
  "workspace.setting.tutorial.line_items.section.csv_export_option_2.title": "One flattened column per line item with one of it’s attributes",
  "workspace.setting.tutorial.line_items.section.csv_export_option_2.description": "Each line item with each of it’s attribute/sub-field are flattened into one single column. They will appear in the same CSV sheet as other non-line item fields do.",
  "workspace.setting_is_saved": "The new settings are saved.",
  "workspace.document.table.columns.file_name": "File Name",
  "workspace.document.table.columns.index": "Document #",
  "workspace.document.table.columns.document_type": "Document Type",
  "workspace.document.table.columns.uploaded_at": "Uploaded at",
  "workspace.document.table.columns.status": "Extraction Status",
  "workspace.document.table.columns.status.extracting": "Extracting...",
  "workspace.document.table.columns.status.failed": "Failed",
  "workspace.document.table.columns.status.extracted": "Extracted",
  "workspace.document.table.delete_document.modal.action": "Remove",
  "workspace.document.table.delete_document.modal.title": "Confirm deleting {count, plural, one {1 selected document} other {# selected documents}}?",
  "workspace.document.table.delete_document.modal.title.all": "Confirm deleting all documents under this workspace?",
  "workspace.document.table.delete_document.modal.description": "This action cannot be undone, are you sure you want to remove the selected document?",
  "workspace.document.table.delete_document.modal.description.all": "This action cannot be undone, are you sure you want to remove all documents?",
  "workspace.document.table.delete_document.success.message": "Document Deleted",
  "workspace.document.table.retry": "Retry",

  "workspace.document.empty.title": "Upload Documents and Start Extracting",
  "workspace.document.empty.message": "Drag or upload a document to start extracting documents.\nExport extraction results to an Excel.",
  "workspace.document.empty.button": "Upload Documents",
  "workspace.document.not_found_message": "No matching results",
  "workspace.document.uploadWidget.header": "Uploaded {uploadedCount}/{totalCount, plural, one {1 Document} other {# Documents}}",
  "workspace.document.uploadWidget.header.complete": "Upload Complete",
  "workspace.document.uploadWidget.header.failed": " ({count} Failed)",
  "workspace.document.uploadWidget.inProgress": "{count, plural, one {1 upload} other {# uploads}} in progress",
  "workspace.document.uploadWidget.failed": "{count, plural, one {1 upload} other {# uploads}} failed",
  "workspace.document.uploadWidget.cancelAll": "Cancel all",
  "workspace.document.uploadWidget.retryAll": "Retry all",
  "workspace.document.uploadWidget.uploaded": "Uploaded",
  "workspace.document.uploadWidget.cancel": "Cancel",
  "workspace.document.uploadWidget.retry": "Retry",
  "workspace.document.upload_widget.uploading_for_other_workspace": "The uploading process here will start after the ongoing uploading tasks in other workspaces are completed",
  "workspace.document.drag_drop_message": "Upload Documents",
  "workspace.document.deleting": "Deleting Documents",
  "ocr_config.engine.google": "Google",
  "ocr_config.engine.tesseract": "Tesseract",
  "ocr_config.engine.azure": "Azure",
  "ocr_config.engine.abbyy": "ABBYY",
  "ocr_config.engine.inherit": "Inherit from extractor",
  "extract_data_field.new_list_item": "New list item",
  "extract_data_field.show_full_size_table": "Show Full Width",
  "extract_table_data_field.add_new_row": "Add New Row",
  "extract_api_v2_response.barcode_result": "barcode:\n{value}",
  "extract_api_v2_response.detect_document_manipulation_result": "type:\n{value}",
  "extract_api_v2_response.boundingbox_result": "bounding-box:\n{value}",
  "extract_api_v2_response.detected_region_button": "Detected Region",
  "extract_api_v2_response.empty": "Cannot extract data from this document. Please check your extractor configuration and the uploaded document",

  "dropzone.drag_drop_message": "Drop your file here",

  "date_input_format.DMY": "DMY - Day First (EU/UK)",
  "date_input_format.MDY": "MDY - Month First (US)",
  "date_input_format.YMD": "YMD - Year First (ISO)",

  "onboarding.name_input.title": "👋 Ready to begin? ",
  "onboarding.name_input.message": "What should we call you?",
  "onboarding.name_input.placeholder": "Your Name",

  "onboarding.company_input.title": " 👋 Welcome {name}!",
  "onboarding.company_input.message": "Your fantastic company name?",
  "onboarding.company_input.placeholder": "Your Company",

  "onboarding.reason_input.title": "Great, almost there! ✨",
  "onboarding.reason_input.message1": "What drives you to FormX.ai?",
  "onboarding.reason_input.message2": "Please share your main reasons with us!",

  "onboarding.reason_input.survey.convert_to_excels": "Convert documents into Excels",
  "onboarding.reason_input.survey.add_doc_processing_to_app": "Add document processing feature to my app",
  "onboarding.reason_input.survey.integrate_with_other_tools": "Automate document processing workflow with RPA/CRM/Zapier",
  "onboarding.reason_input.survey.other": "Other",

  "onboarding.mobile_input.title": "💫 Thanks! 💖 ",
  "onboarding.mobile_input.message1": "Thanks for your input! It'll enhance your experience. ",
  "onboarding.mobile_input.message2": "For more info, drop your number, and we'll reach out soon!",
  "onboarding.mobile_input.placeholder": "Mobile Number (Optional)",

  "onboarding.create_extractor.title": "Unlock the power of data with AI! ✨",
  "onboarding.create_extractor.message": "What kind of document do you want to extract?",
  "onboarding.create_extractor.upload_size_hints": "Minimum 1000x750px / 100 DPI (PDF, JPG, PNG, HEIC)",
  "onboarding.create_extractor.drop.title": "Drop a file here or click to upload.",
  "onboarding.create_extractor.drop.message": "You can also try with these samples",

  "onboarding.create_extractor.custom_drop.title1": "Simply upload your document and let us work",
  "onboarding.create_extractor.custom_drop.title2": "our magic to extract the data for you  🪄",
  "onboarding.create_extractor.custom_drop.message": "or use a random sample below",
  "onboarding.create_extractor.column.finance.title": "Finance",
  "onboarding.create_extractor.column.kyc.title": "KYC",
  "onboarding.create_extractor.column.other.title": "Other",
  "onboarding.create_extractor.type.gov_id_passport": "🪪  Government IDs/Passports",
  "onboarding.create_extractor.type.invoice_llm": "🛍️  Invoices",
  "onboarding.create_extractor.type.receipt": "🧾  Receipts",
  "onboarding.create_extractor.type.address_proof": "🏡  Address Proofs",
  "onboarding.create_extractor.type.bank_statement_llm": "🏦  Bank Statements",
  "onboarding.create_extractor.type.bill_of_lading_instant_model": "📦  Bills of Lading",
  "onboarding.create_extractor.type.purchase_order_llm": "📋  Purchase Order",
  "onboarding.create_extractor.type.quotation_llm": "💰  Quotation",
  "onboarding.create_extractor.type.air_waybill_llm": "✈️  Airway Bill",
  "onboarding.create_extractor.type.resume_llm": "🧑‍💼 Resume",
  "onboarding.create_extractor.type.others": "Others",
  "onboarding.create_extractor.others_type_default_name": "Custom Model",
  "onboarding.create_extractor.create_custom_model": "Create custom model",
  "guidance.progress": "{current} of {total}",
  "first_time_guidance.start_guidance_popup.title": "👋 Hi, {name}!",
  "first_time_guidance.start_guidance_popup.message": "Do you need guidance on using the basic features of our extract?",
  "first_time_guidance.start_guidance_popup.no_thanks": "No thanks",
  "first_time_guidance.start_guidance_popup.show_me": "Show me",
  "first_time_guidance.step1": "You have the flexibility to zoom in/out for  image viewing or switch between pages.",
  "first_time_guidance.step2": "Don't see the data you need? Easily update the extraction parameters here.",
  "first_time_guidance.step3": "Feel free to upload new documents or try different ones to verify the results.",
  "first_time_guidance.step4": "Need assistance? Schedule a demo to discuss your automation needs.",
  "first_time_guidance.confirm_detection_valid_popup.title": "Happy with Your Data? 🪄",
  "first_time_guidance.confirm_detection_valid_popup.message": "Want to adjust or add more info? Head to ‘Manage Field'",
  "first_time_guidance.confirm_detection_valid_popup.manage_field": "Manage Field",
  "first_time_guidance.confirm_detection_valid_popup.all_good": "All good",
  "manage_fields_guidance.step1": "You can modify the field name, data type, or provide a description of the data format to enhance accuracy.",
  "manage_fields_guidance.step2": "Add a new field for any missing data.",
  "manage_fields_guidance.step3": "Save your updates and go to ‘Test Extractor’ to test it again!",
  "manage_fields_guidance.support_request_popup.title": "Struggling with the set up? 👀",
  "manage_fields_guidance.support_request_popup.message": "Our support team will reach out and help.",
  "manage_fields_guidance.support_request_popup.toast": "We've received your support request. We'll reach out to you via email soon.",

  "snapshot_version_list.title": "Version",
  "snapshot_version_list.date_group.today": "Today",
  "snapshot_version_list.date_group.yesterday": "Yesterday",
  "snapshot_version_list.item.action.bookmark": "Bookmark",
  "snapshot_version_list.item.action.edit_info": "Edit info",
  "snapshot_version_list.item.action.delete": "Delete",
  "snapshot_version_list.item.description.restore_from": "Restored from {restored_from}",
  "snapshot_version_list.item.action.filter_by_bookmarked": "Bookmarked",
  "snapshot_version_list.item.action.filter_by_all": "All",
  "snapshot_version_list.tooltip.bookmarked_by": "Bookmarked by {bookmarked_by}",
  "snapshot_version_list.action.restore": "Restore",
  "snapshot_version_list.action.loadmore": "Load More",

  "restore_version_modal.title": "Restore Version",
  "restore_version_modal.unsaved_form_warning_description_with_restore_name": "You have unsaved form. Are you sure you want to restore to the version \"{restore_name}\"?",
  "restore_version_modal.description_with_restore_name": "Are you sure you want to restore to the version \"{restore_name}\"?",
  "restore_version_modal.button.restore": "Restore",
  "restore_version_modal.button.discard_and_restore": "Discard & Restore",
  "delete_version_modal.title": "Delete Version",
  "delete_version_modal.description_with_name": "Are you sure you want to delete the version \"{name}\"?",
  "delete_version_modal.button.delete": "Delete",

  "bookmark_modal.add.title": "Add to bookmark",
  "bookmark_modal.title.label": "Name",
  "bookmark_modal.edit.title": "Edit version information",
  "bookmark_modal.note.placeholder": "Describe what changed",
  "bookmark_modal.save.button": "Save",

  "searchable_dropdown.placeholder": "Select",
  "searchable_dropdown.empty": "No results found",
  "searchable_dropdown.search.placeholder": "Search",

  "advance_token_setup_version_history_modal.title": "Version History",
  "advance_token_setup_version_history_modal.tab.version": "Version",
  "advance_token_setup_version_history_modal.tab.changes": "Changes",

  "error.config_snapshot_modifed_prompt.title": "This config snapshot has been modified",
  "error.config_snapshot_modifed_prompt.desc": "This config snapshot has been modified outside this tab since you open it. Are you sure you want to save this config snapshot?",

  "snapshot_version_change_logs.title": "Changes",
  "snapshot_version_change_logs.label.compare": "Compare",
  "snapshot_version_change_logs.label.to": "To",
  "snapshot_version_change_logs.action.download_excel": "Download Excel",

  "snapshot_version_change_logs.table_diff_log.description.added_with_count": "{count} Added",
  "snapshot_version_change_logs.table_diff_log.description.removed_with_count": "{count} Removed",
  "snapshot_version_change_logs.table_diff_log.description.updated_with_count": "{count} Updated",

  "snapshot_version_diff_table.tooltip.added_row": "Added row",
  "snapshot_version_diff_table.tooltip.deleted_row": "Deleted row",
  "snapshot_version_diff_table.tooltip.updated_row": "Updated row",
  "snapshot_version_diff_table.tooltip.reordered_row": "Reordered row",
  "snapshot_version_diff_table.tooltip.added_col": "Added column",
  "snapshot_version_diff_table.tooltip.deleted_col": "Deleted column",
  "snapshot_version_diff_table.tooltip.reordered_col": "Reordered column",
  "snapshot_version_diff_table.label.empty": "No Changes",

  "version_changes_section.diff_log_group_name.tag_group": "Tag Groups",
  "version_changes_section.diff_log_group_name.exact_match_rule": "Exact Match Rules",
  "version_changes_section.diff_log_group_name.closest_match_rule": "Closest Match Rules",
  "version_changes_section.diff_log_group_name.field_replacement": "Field replacements",
  "version_changes_section.diff_log_table_name.merchant_id": "Merchant ID",
  "version_changes_section.diff_log_table_name.mall_id": "Mall ID",

  "connect_apps_table.title": "Connected Apps",
  "connect_apps_table.column.app_name": "App Name",
  "connect_apps_table.column.account": "Account",
  "connect_apps_table.column.status": "Status",
  "connect_apps_table.column.action": "Action",
  "connect_apps_table.reconnect": "Reconnect",
  "connect_apps_table.status.connected": "Connected",
  "connect_apps_table.status.expired": "Expired",

  "connect_apps_table.remove_dialog.title": "Remove Google Connection?",
  "connect_apps_table.remove_dialog.message": "Workspaces connected to this Google Account will no longer be able to import files or export results to it. The action will affect: team/workspace",
  "connect_apps_table.remove_dialog.action": "Remove Connection"
}
