import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { memo, useCallback, useMemo, useState } from "react";

import { useConfirmModalActionCreator } from "../../actions/confirmModal";
import { useWebhookActionCreator } from "../../actions/webhook";
import { UserFeatureFlag } from "../../constants";
import type { useOAuthCredentialReturnValues } from "../../hooks/oauth";
import { useAppSelector } from "../../hooks/redux";
import { useToast } from "../../hooks/toast";
import {
  type useGoogleSheetIntegrationReturnValues,
  useSharePointIntegrationReturnValues,
} from "../../hooks/workspace_integration";
import { ConfirmModalType } from "../../types/confirmation";
import type { GoogleSheetFieldMapping } from "../../types/googleSheet";
import { OAuthCredential } from "../../types/oauth";
import { Workspace } from "../../types/workspace";
import {
  FilePickerSharePointFile,
  WorkspaceIntegrationConfiguration,
  WorkspaceIntegrationOptionType,
  allWorkspaceIntegration3rdPartyOptionType,
  allWorkspaceIntegrationExportOptionType,
  allWorkspaceIntegrationImportOptionType,
  initialWorkspaceIntegrationConfiguration,
} from "../../types/workspaceIntegration";
import { WorkspaceWebhook } from "../../types/workspaceWebhook";
import { GroupHeader } from "../GroupHeader";
import { ExportGoogleSheetsConfigurationCard } from "../WorkspaceIntegrationConfigurationCard/ExportGoogleSheetsConfigurationCard";
import { SharePointConfigurationCard } from "../WorkspaceIntegrationConfigurationCard/SharePointConfigurationCard";
import { WebhookConfigurationCard } from "../WorkspaceIntegrationConfigurationCard/WebhookConfigurationCard";
import { WorkspaceIntegrationOptionGroup } from "../WorkspaceIntegrationOptionGroup";
import { WorkspaceWebhookList } from "../WorkspaceWebhookList/WorkspaceWebhookList";
import { ZapierModal, useZapierModalHandle } from "../ZapierModal";
import styles from "./styles.module.scss";

interface OptionListProps {
  selectedImportOptions: WorkspaceIntegrationOptionType[];
  selectedExportOptions: WorkspaceIntegrationOptionType[];
  onImportOptionClick: (op: WorkspaceIntegrationOptionType) => void;
  onExportOptionClick: (op: WorkspaceIntegrationOptionType) => void;
}

function OptionList(props: OptionListProps) {
  const {
    selectedImportOptions,
    selectedExportOptions,
    onImportOptionClick,
    onExportOptionClick,
  } = props;

  const {
    isGoogleSheetsExportIntegrationEnabled,
    isSharePointIntegrationEnabled,
    isWebhookExportIntegrationEnabled,
  } = useAppSelector(state => ({
    isGoogleSheetsExportIntegrationEnabled:
      state.resourceOwner.isFeatureEnabled()(
        UserFeatureFlag.GoogleSheetsExportIntegration
      ),
    isSharePointIntegrationEnabled: state.resourceOwner.isFeatureEnabled()(
      UserFeatureFlag.SharePointIntegration
    ),
    isWebhookExportIntegrationEnabled: state.resourceOwner.isFeatureEnabled()(
      UserFeatureFlag.WebhookExportIntegration
    ),
  }));

  const selectedImportOptionSet = useMemo(() => {
    return new Set(selectedImportOptions);
  }, [selectedImportOptions]);

  const selectedExportOptionSet = useMemo(() => {
    return new Set(selectedExportOptions);
  }, [selectedExportOptions]);

  const enabledOptions = useMemo(() => {
    const options = new Set<WorkspaceIntegrationOptionType>();

    if (isGoogleSheetsExportIntegrationEnabled) {
      options.add("export_googleSheets");
    }
    options.add("export_zapier");

    if (isSharePointIntegrationEnabled) {
      options.add("import_sharepoint");
    }
    if (isWebhookExportIntegrationEnabled) {
      options.add("export_webhook");
    }

    return options;
  }, [
    isGoogleSheetsExportIntegrationEnabled,
    isSharePointIntegrationEnabled,
    isWebhookExportIntegrationEnabled,
  ]);

  return (
    <div className={styles["right-sidebar"]}>
      <section className={styles["option-list-section"]}>
        <WorkspaceIntegrationOptionGroup
          optionType="import"
          items={allWorkspaceIntegrationImportOptionType}
          selectedOptionSet={selectedImportOptionSet}
          enabledOptions={enabledOptions}
          onOptionClick={onImportOptionClick}
        />
      </section>
      <section className={styles["option-list-section"]}>
        <WorkspaceIntegrationOptionGroup
          optionType="export"
          items={allWorkspaceIntegrationExportOptionType}
          selectedOptionSet={selectedExportOptionSet}
          enabledOptions={enabledOptions}
          onOptionClick={onExportOptionClick}
        />
      </section>
      <section className={styles["option-list-section"]}>
        <WorkspaceIntegrationOptionGroup
          optionType="thirdParty"
          items={allWorkspaceIntegration3rdPartyOptionType}
          selectedOptionSet={selectedExportOptionSet}
          enabledOptions={enabledOptions}
          onOptionClick={onExportOptionClick}
        />
      </section>
    </div>
  );
}

export interface WorkspaceIntegrationSectionProps {
  googleSheetIntegrationProps: useGoogleSheetIntegrationReturnValues;
  oauthProps: useOAuthCredentialReturnValues;
  sharePointIntegrationProps: useSharePointIntegrationReturnValues;
  workspace: Workspace;
  workspaceWebhooks: WorkspaceWebhook[];
  googleAuth: () => Promise<OAuthCredential | undefined>;
}

function WorkspaceIntegrationSectionImpl(
  props: WorkspaceIntegrationSectionProps
) {
  const {
    googleSheetIntegrationProps,
    oauthProps,
    sharePointIntegrationProps,
    workspace,
    workspaceWebhooks,
    googleAuth,
  } = props;

  const {
    googleAuthError,
    isFetchingGoogleSheets,
    isFetchingGoogleSpreadsheets,
    isSavingWorkspaceGoogleSheetExportIntegration,
    isGoogleAuthorized,
    isGoogleAuthorizing,
    onClearGoogleSheetData,
    onCreateWorkspaceGoogleSheetExport,
    onGetExtractorFields,
    onGetGoogleSheetColumns,
    onGetGoogleSheetList,
    onGetGoogleSpreadsheetList,
  } = googleSheetIntegrationProps;

  const {
    onSharePointSubscriptionSave,
    onSharePointSubscriptionDisconnect,
    onSharePointIntegrationRemove,
    onLoginSharePoint,
    isLoggedInToSharePoint,
    isLoggingInToSharePoint,
    integrationConfigs,
  } = sharePointIntegrationProps;

  const { removeWebhook } = useWebhookActionCreator();
  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const toast = useToast();
  const zapierModalHandle = useZapierModalHandle(
    workspace.id,
    workspace.extractor?.associatedExtractorId ?? workspace.extractor?.id ?? ""
  );

  const { oauthCredentials } = oauthProps;

  // TODO: Set initial state by retrieved workspace object
  const [selectedImportOptions, setSelectedImportOptions] = useState<
    WorkspaceIntegrationConfiguration[]
  >([]);
  const [selectedExportOptions, setSelectedExportOptions] = useState<
    WorkspaceIntegrationConfiguration[]
  >([]);

  const existingExportOptions = useMemo<
    WorkspaceIntegrationConfiguration[]
  >(() => {
    return workspaceWebhooks
      .filter(webhook => webhook.type === "zapier")
      .map(webhook => {
        return {
          optionType: "export_zapier",
          zapId: webhook.config.zap_id,
          webhookId: webhook.webhookId,
        };
      });
  }, [workspaceWebhooks]);

  const connectedGoogleOAuthCredentials = useMemo(() => {
    return oauthCredentials.oauthCredentials.filter(
      ({ resourceServer }) => resourceServer === "google"
    );
  }, [oauthCredentials]);

  const selectedImportOptionTypes = useMemo(() => {
    return selectedImportOptions.map(op => op.optionType);
  }, [selectedImportOptions]);
  const selectedExportOptionTypes = useMemo(() => {
    return selectedExportOptions.map(op => op.optionType);
  }, [selectedExportOptions]);

  const onImportOptionClick = useCallback(
    (selectedOptionType: WorkspaceIntegrationOptionType) => {
      if (selectedOptionType !== "export_zapier") {
        setSelectedImportOptions(prev => {
          if (prev.findIndex(op => op.optionType === selectedOptionType) >= 0) {
            return prev;
          }
          const newOption =
            initialWorkspaceIntegrationConfiguration[selectedOptionType];
          return [...prev, newOption];
        });
      }
    },
    []
  );

  const onExportOptionClick = useCallback(
    (selectedOptionType: WorkspaceIntegrationOptionType) => {
      if (selectedOptionType === "export_zapier") {
        zapierModalHandle.methods.open();
      } else {
        setSelectedExportOptions(prev => {
          if (prev.findIndex(op => op.optionType === selectedOptionType) >= 0) {
            return prev;
          }
          const newOption =
            initialWorkspaceIntegrationConfiguration[selectedOptionType];
          return [...prev, newOption];
        });
      }
    },
    [zapierModalHandle]
  );

  const onImportConfigurationRemoved = useCallback(
    (_optionToBeRemoved: WorkspaceIntegrationConfiguration) => {
      onSharePointIntegrationRemove();
      // TODO - remove integration from selectedImportOptions and disconnect from sharepoint
    },
    [onSharePointIntegrationRemove]
  );

  const onExportConfigurationRemoved = useCallback(
    (optionToBeRemoved: WorkspaceIntegrationConfiguration) => {
      setSelectedExportOptions(prev =>
        prev.filter(op => op.optionType !== optionToBeRemoved.optionType)
      );
    },
    []
  );

  const onGoogleSheetExportConfigurationSaved = useCallback(
    (
      oauthCredentialId: string,
      spreadsheetId: string,
      sheetId: string | null,
      mappings: GoogleSheetFieldMapping[],
      isUpsertMode: boolean
    ) => {
      onCreateWorkspaceGoogleSheetExport(
        workspace.id,
        oauthCredentialId,
        spreadsheetId,
        sheetId,
        mappings,
        isUpsertMode
      );
    },
    [onCreateWorkspaceGoogleSheetExport, workspace.id]
  );

  const onRefreshGoogleSheet = useCallback(async () => {
    onClearGoogleSheetData();
  }, [onClearGoogleSheetData]);

  const [sharePointFolder, setSharePointFolder] =
    useState<FilePickerSharePointFile | null>(null);

  const removeZapier = useCallback(
    async (configuration: WorkspaceIntegrationConfiguration) => {
      if (configuration.optionType !== "export_zapier") {
        return;
      }

      try {
        await removeWebhook(configuration.webhookId);
        toast.success("workspace.integrations.toast.remove_success");
      } catch (e) {
        console.error("Failed to remove webhook: ", e);
        toast.error("workspace.integrations.toast.remove_failed");
      }
    },
    [removeWebhook, toast]
  );

  const onConfigurationRemoved = useCallback(
    async (configuration: WorkspaceIntegrationConfiguration) => {
      if (configuration.optionType !== "export_zapier") {
        return;
      }

      try {
        await requestUserConfirmation({
          type: ConfirmModalType.Destory,
          titleId: "workspace.integrations.confirmRemoveModal.title",
          messageId: "workspace.integrations.confirmRemoveModal.description",
          messageValues: {
            connectionId:
              configuration?.optionType === "export_zapier"
                ? configuration.zapId
                : "",
          },
          actionId: "workspace.integrations.confirmRemoveModal.action.remove",
          hideCancel: true,
        });
        removeZapier(configuration);
      } catch (e) {
        console.error("Failed to show request user confirmation modal: ", e);
      }
    },
    [removeZapier, requestUserConfirmation]
  );

  const [thirdPatySectionExpanded, setThirdPatySectionExpanded] =
    useState(true);

  const handleThirdPartySectionToggle = () => {
    setThirdPatySectionExpanded(!thirdPatySectionExpanded);
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <section className={styles["section"]}>
          <h4 className={styles["section-header"]}>
            <FormattedMessage id="workspace.integrations.option_setting.import.title" />
          </h4>
          {selectedImportOptions.length > 0 ? (
            selectedImportOptions.map(op => {
              switch (op.optionType) {
                case "import_sharepoint":
                  return (
                    <SharePointConfigurationCard
                      key={op.optionType}
                      workspaceId={workspace.id}
                      configuration={integrationConfigs}
                      onConfigurationRemoved={onImportConfigurationRemoved}
                      onSave={onSharePointSubscriptionSave}
                      onDisconnect={onSharePointSubscriptionDisconnect}
                      onLogin={onLoginSharePoint}
                      onFolderSelect={setSharePointFolder}
                      selectedFolder={sharePointFolder}
                      isLoggedIn={isLoggedInToSharePoint}
                      isLoggingIn={isLoggingInToSharePoint}
                    />
                  );
                default:
                  return null;
              }
            })
          ) : (
            <div className={styles["section-empty-state-container"]}>
              <FormattedMessage id="workspace.integrations.option_setting.import.empty_state" />
            </div>
          )}
        </section>
        <section className={styles["section"]}>
          <h4 className={styles["section-header"]}>
            <FormattedMessage id="workspace.integrations.option_setting.export.title" />
          </h4>
          {selectedExportOptions.length > 0
            ? selectedExportOptions.map(op => {
                switch (op.optionType) {
                  case "export_googleSheets":
                    return (
                      <ExportGoogleSheetsConfigurationCard
                        key={op.optionType}
                        configuration={op}
                        extractorId={workspace.extractor?.id ?? null}
                        isAuthorized={isGoogleAuthorized}
                        isAuthorizing={isGoogleAuthorizing}
                        isFetchingSheets={isFetchingGoogleSheets}
                        isFetchingSpreadsheets={isFetchingGoogleSpreadsheets}
                        isSaving={isSavingWorkspaceGoogleSheetExportIntegration}
                        workspaceId={workspace.id}
                        oauthCredentials={connectedGoogleOAuthCredentials}
                        oauthError={googleAuthError}
                        googleAuth={googleAuth}
                        onConfigurationRemoved={onExportConfigurationRemoved}
                        onConfigurationSaved={
                          onGoogleSheetExportConfigurationSaved
                        }
                        onGetFields={onGetExtractorFields}
                        onGetSheetColumns={onGetGoogleSheetColumns}
                        onGetSheetList={onGetGoogleSheetList}
                        onGetSpreadsheetList={onGetGoogleSpreadsheetList}
                        onRefresh={onRefreshGoogleSheet}
                      />
                    );

                  case "export_webhook":
                    return (
                      <WebhookConfigurationCard
                        key={op.optionType}
                        workspaceId={workspace.id}
                        configuration={op}
                        onConfigurationRemoved={onExportConfigurationRemoved}
                      />
                    );
                  default:
                    return null;
                }
              })
            : null}
          {selectedExportOptions.length === 0 ? (
            <div className={styles["section-empty-state-container"]}>
              <FormattedMessage id="workspace.integrations.option_setting.export.empty_state" />
            </div>
          ) : null}
        </section>
        <section className={styles["section"]}>
          {existingExportOptions.length > 0 ? (
            <>
              <h4 className={styles["section-header"]}>
                <FormattedMessage id="workspace.integrations.option_setting.3rdparty.title" />
              </h4>
              <div className="bg-white border border-solid border-gray-200">
                <GroupHeader
                  isExpanded={thirdPatySectionExpanded}
                  onToggle={handleThirdPartySectionToggle}
                  className="my-3 px-4"
                >
                  <div className="flex flex-row items-center">
                    <Icon
                      className="mr-2"
                      styles={{
                        root: {
                          width: 40,
                          height: 40,
                        },
                      }}
                      iconName="IconZapier"
                    />
                    <span className="text-[#201F1E] text-base">
                      <FormattedMessage id="workspace.integrations.configuration_list.section.title.zapier" />
                    </span>
                  </div>
                </GroupHeader>

                {thirdPatySectionExpanded && (
                  <div className="px-4 pb-4">
                    <WorkspaceWebhookList
                      configurations={existingExportOptions}
                      onConfigurationRemoved={onConfigurationRemoved}
                    />
                  </div>
                )}
              </div>
            </>
          ) : null}
        </section>
      </div>
      <OptionList
        selectedImportOptions={selectedImportOptionTypes}
        selectedExportOptions={selectedExportOptionTypes}
        onImportOptionClick={onImportOptionClick}
        onExportOptionClick={onExportOptionClick}
      />
      <ZapierModal {...zapierModalHandle.props} />
    </div>
  );
}

export const WorkspaceIntegrationSection = memo(
  WorkspaceIntegrationSectionImpl
);
