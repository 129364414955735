import { FormattedMessage } from "@oursky/react-messageformat";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";

import { PLAN_NAME_MESSAGE_IDS } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { MessageBar, MessageBarType } from "../MessageBar";

interface SubscriptionPendingCancelMessageBarProps {
  subscriptionCancelAt: string;
  planName: string;
}

export function SubscriptionPendingCancelMessageBar(
  props: SubscriptionPendingCancelMessageBarProps
) {
  const { subscriptionCancelAt, planName } = props;
  const { localized } = useLocale();

  const cancelDate = useMemo(
    () =>
      DateTime.fromISO(subscriptionCancelAt).toLocaleString(DateTime.DATE_FULL),
    [subscriptionCancelAt]
  );
  const messageValues = useMemo(
    () => ({
      date: cancelDate,
      plan: localized(PLAN_NAME_MESSAGE_IDS[planName] ?? ""),
    }),
    [cancelDate, planName, localized]
  );

  const [isDismissed, setIsDismissed] = useState(false);
  const handleOnDismiss = useCallback(() => setIsDismissed(true), []);

  return isDismissed ? null : (
    <MessageBar
      messageBarType={MessageBarType.warning}
      onDismiss={handleOnDismiss}
    >
      <FormattedMessage
        id="payment.subscription_pending_cancel.message"
        values={messageValues}
      />
    </MessageBar>
  );
}
