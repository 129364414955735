import { AutoExtractionItem } from "../types/formConfig";

export const DEFAULT_AUTO_EXTRACTION_ITEM_MAP: {
  [key: string]: AutoExtractionItem[];
} = {
  receipt: [
    "total_amount",
    "date",
    "time",
    "invoice_number",
    "telephone",
    "product_info",
  ],
  bank_statement: ["name", "address", "issue_date"],
  address_proof: ["name", "address", "issue_date"],
  invoice: ["total", "invoice_date", "invoice_number", "due_date"],
  international_id: ["name_full", "date_birth"],
};

export const GENERIC_AUTO_EXTRACTION_ITEMS: AutoExtractionItem[] = [
  "llm_completion",
];

export const AUTO_EXTRACTION_ITEMS: {
  [key: string]: AutoExtractionItem[];
} = {
  general: [
    "date",
    "time",
    "name",
    "address",
    "company",
    "job_title",
    "detect_manipulation",
    "detect_document_manipulation",
    "table_extraction",
    "detect_signature",
    "barcode",
    "pii",
    "checkbox",
    ...GENERIC_AUTO_EXTRACTION_ITEMS,
  ],
  receipt: [
    "total_amount",
    "date",
    "short_date",
    "time",
    "address",
    "invoice_number",
    "telephone",
    "merchant_fax",
    "merchant_website",
    "store_number",
    "subtotal",
    "rounding",
    "total_discount",
    "enter_time",
    "leave_time",
    "num_person",
    "credit_card_type",
    "credit_card_no",
    "payment_name",
    "payment_card_no",
    "product_info",
    "payment_info",
    "merchant_name",
    "merchant",
    "merchant_logo",
    "detect_manipulation",
    "detect_document_manipulation",
    "receipt_type",
    "mobile_payment_transaction_number",
    "auth_code",
    "retrieval_reference_number",
    "payment_info_beta",
    "barcode",
    ...GENERIC_AUTO_EXTRACTION_ITEMS,
  ],
  bank_statement: [
    "name",
    "address",
    "issue_date",
    "detect_manipulation",
    "detect_document_manipulation",
    ...GENERIC_AUTO_EXTRACTION_ITEMS,
  ],
  address_proof: [
    "name",
    "address",
    "issue_date",
    "ner_name",
    "ner_address",
    "date",
    "detect_document_manipulation",
    ...GENERIC_AUTO_EXTRACTION_ITEMS,
  ],
  invoice: [
    "account_no",
    "bank_account_no",
    "swift_bic",
    "bank_name",
    "total",
    "payment_terms",
    // "currency",
    // "discount",
    // "subtotal",
    // "tax",
    "invoice_date",
    "order_date",
    "delivery_date",
    "due_date",
    // "end_date",
    // "start_date",
    "invoice_number",
    "purchase_order_no",
    "reference_no",
    // "package_weight",
    "bill_to_address",
    "bill_to_email",
    "bill_to_name",
    "bill_to_phone",
    "bill_to_vat_number",
    "ship_to_address",
    "ship_to_name",
    "vendor_address",
    "vendor_email",
    "vendor_fax",
    "vendor_name",
    "vendor_phone",
    // "vendor_vat_number",
    "vendor_website",

    "table_extraction",
    "product_info",
    "detect_document_manipulation",
    ...GENERIC_AUTO_EXTRACTION_ITEMS,
  ],
  international_id: [
    "address_code",
    "address_full",
    "code_document",
    "code_identity",
    "date_birth",
    "date_expiry",
    "date_issue",
    "gender",
    "issuing_authority",
    "name_full",
    "name_full_non_latin",
    "nationality",
    "place_of_birth",
    "detect_signature",
    "detect_portrait",
    "passport_mrz",
    "id_liveness",
    "detect_document_manipulation",
    ...GENERIC_AUTO_EXTRACTION_ITEMS,
  ],
  invoice_llm: [
    "account_no",
    "invoice_number",
    "reference_no",
    "purchase_order_no",
    "total",
    "subtotal",
    "vat",
    "currency",
    "payment_terms",
    "invoice_date",
    "order_date",
    "due_date",
    "delivery_date",
    "bank_info",
    "vendor_name",
    "vendor_address",
    "vendor_email",
    "vendor_telephone",
    "vendor_vat_number",
    "vendor_url",
    "vendor_fax",
    "customer_name",
    "customer_address",
    "customer_email",
    "customer_telephone",
    "customer_vat_number",
    "shipment_name",
    "shipment_address",
    "shipment_email",
    "shipment_telephone",
    "shipment_vat_number",
    "package_weight",
    "line_items",
    "detect_document_manipulation",
  ],
  bank_statement_llm: [
    "bank_name",
    "name",
    "address",
    "account_type",
    "account_info",
    "issue_date",
    "open_balance",
    "close_balance",
    "currency",
    "transactions",
    "detect_document_manipulation",
  ],
  bill_of_lading_llm: [
    "ship_date",
    "bill_of_lading_no",
    "shipper_name",
    "shipper_address",
    "shipper_city",
    "shipper_state",
    "shipper_zip",
    "shipper_phone",
    "shipper_email",
    "shipper_reference",
    "carrier_name",
    "carrier_address",
    "carrier_reference",
    "carrier_fedex_express_air_waybill_no",
    "consignee_name",
    "consignee_address",
    "consignee_city",
    "consignee_state",
    "consignee_zip",
    "consignee_phone",
    "consignee_email",
    "port_of_loading",
    "port_of_unloading",
    "num_of_packages",
    "freight_weight",
    "freight_length",
    "freight_width",
    "freight_height",
    "freight_condition",
    "freight_description",
    "freight_identification_marks",
    "vessel_name",
    "vessel_id",
    "payment_info",
    "terms_of_contract",
    "detect_document_manipulation",
  ],
};
