import { IDropdownOption } from "@fluentui/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { v4 as uuid } from "uuid";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useExtractorActionCreator } from "../actions/extractor";
import { useFormActionCreator } from "../actions/form";
import { DEFAULT_FIELD_LABEL } from "../constants";
import errors, { FOCRError } from "../errors";
import { useToast } from "../hooks/toast";
import { CanvasStore } from "../labeller/store";
import {
  BaseFieldSettingModalPayload,
  FormEditorTab,
  MinimalFieldModalPayload,
  ScriptEditorModalPayload,
} from "../models";
import { RootState } from "../redux/types";
import {
  ExtractorGetSchemaFields,
  isPrebuiltExtractors,
} from "../types/extractor";
import { DetailedForm } from "../types/form";
import { FormSettings } from "../types/formConfig";
import { KeyValue } from "../types/keyValue";
import { FormatterMapper } from "../types/mappers/formatter";
import { TokenGroup } from "../types/tokenGroup";
import { FormatterValidator } from "../validators/formatterValidator";
import { removeFormatterOutputFromExtractorField } from "./formatterEditor";
import { useLocale } from "./locale";

function getNumFeatures(form: DetailedForm) {
  if (form.config.detector.type === "orb") {
    return {
      numFormFeature: form.config.detector.number_of_source_feature,
      numQueryFeature: form.config.detector.number_of_query_feature,
    };
  }

  return {
    numFormFeature: 0,
    numQueryFeature: 0,
  };
}

export function buildFormSettings(
  form: DetailedForm,
  patch: Partial<FormSettings> = {}
): FormSettings {
  return {
    name: form.name,
    documentType: form.config.document_type || "general",
    autoExtractionItems: form.config.auto_extraction_items || [],
    postProcessScript: form.config.post_process_script,
    transformResponseScript: form.config.transform_response_script,
    dateFormat: form.config.date_format,
    dateInputFormat: form.config.date_input_format,
    merchantNames: form.config.merchant_names,
    formatter: form.config.formatter,
    llmCompletions: form.config.llm_completions,
    ...getNumFeatures(form),
    ...patch,
  };
}

function genUniqueDocumentFieldName(
  form: DetailedForm | undefined,
  defaultName: string
) {
  if (!form) {
    return defaultName;
  }
  const { keyValues, tokenGroups } = form;
  const names = new Set(
    keyValues
      .map(keyValue => keyValue.name.trim())
      .concat(tokenGroups.map(tokenGroup => tokenGroup.name.trim()))
  );

  let fieldName = defaultName;
  let index = 1;
  while (names.has(fieldName)) {
    index++;
    fieldName = `${defaultName} (${index})`;
  }
  return fieldName;
}

function useStatesDeduceFromReduxStore() {
  return useSelector((state: RootState) => {
    const {
      currentForm,
      currentFormImageSize,
      isAnyAnchorChanged,
      isAnyFieldOrDetectionRegionChanged,
      isFormChanged,
    } = state.form;

    const hasPendingAnchorOrFieldChange =
      isAnyAnchorChanged || isAnyFieldOrDetectionRegionChanged;

    const isFormNotSaved = hasPendingAnchorOrFieldChange || isFormChanged;

    return {
      form: currentForm,
      imageSize: currentFormImageSize,
      isFormNotSaved,
      isGoogleServiceAccoutKeySet: state.resourceOwner.isGoogleOcrEngineSet,
      isAzureSubscriptionKeySet: state.resourceOwner.isAzureOcrEngineSet,
    };
  }, shallowEqual);
}

export function useSaveFormThenShowToast() {
  const { saveForm } = useFormActionCreator();
  const { form } = useStatesDeduceFromReduxStore();
  const toast = useToast();

  const { getExtractorFieldSchemaTable } = useExtractorActionCreator();

  const [isConfirmConflictModalOpen, setIsConfirmConflictModalOpen] =
    useState(false);
  const [ignoreConflictFlag, setIgnoreConflictFlag] = useState(false);
  const [ignoreFormatterValidationFlag, setIgnoreFormatterValidationFlag] =
    useState(false);

  const { handleConflict } = useConfirmModalActionCreator();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const onFormatterModalConfirm = useCallback(() => {
    const formatterPath = pathname.replace(/[^/]*$/, "format");
    navigate(formatterPath);
  }, [navigate, pathname]);

  const validateFormFormatter = useCallback(
    async (form: DetailedForm) => {
      const extractorFieldSchemaTable = await getExtractorFieldSchemaTable(
        form.id
      );
      const validator = new FormatterValidator();
      const originalFormatterOutputs =
        FormatterMapper.fromStorage(form.config.formatter)
          ?.steps.filter(step => step.outputAs.enabled)
          .map(step => step.outputAs.fields.map(field => field.name))
          .flat() ?? [];
      const existingInputFields = removeFormatterOutputFromExtractorField(
        extractorFieldSchemaTable ?? {},
        originalFormatterOutputs
      );
      const { hasError } = validator.validateForm(form, existingInputFields);
      if (hasError) {
        throw errors.ConflictFound;
      }
    },
    [getExtractorFieldSchemaTable]
  );

  const isPrebuiltExtractor =
    form?.config.prebuilt_extractor &&
    isPrebuiltExtractors(form.config.prebuilt_extractor);

  const saveFormThenShowToast = useCallback(
    (options?: {
      ignoreConflict?: boolean;
      ignoreFormatterValidation?: boolean;
      extractorFieldSchemaTable?: ExtractorGetSchemaFields;
    }) => {
      if (!form) {
        return Promise.reject();
      }
      if (options?.ignoreFormatterValidation) {
        setIgnoreFormatterValidationFlag(true);
      }

      if (options?.ignoreConflict) {
        setIgnoreConflictFlag(true);
      }

      const ignoreFormatterValidation =
        options?.ignoreFormatterValidation || ignoreFormatterValidationFlag;

      const ignoreConflict = options?.ignoreConflict || ignoreConflictFlag;
      const reset = () => {
        setIgnoreConflictFlag(false);
        setIgnoreFormatterValidationFlag(false);
      };
      setIsConfirmConflictModalOpen(false);

      const savePromise = saveForm(ignoreConflict);
      savePromise
        .then(() => {
          if (
            form &&
            form.image &&
            form.anchors.length < 2 &&
            !isPrebuiltExtractor
          ) {
            toast.warn("form_editor.form_is_saved_but_recommend_add_anchor");
          } else {
            toast.success("form_editor.form_is_saved");
          }
          reset();
        })
        .then(async () => {
          if (!ignoreFormatterValidation) {
            try {
              await handleConflict(
                async () => {
                  await validateFormFormatter(form);
                },
                async () => {
                  onFormatterModalConfirm();
                  return Promise.resolve();
                },
                {
                  titleId: "form_editor.formatter_error_prompt.title",
                  messageId: "form_editor.formatter_error_prompt.desc",
                  actionId:
                    "form_editor.formatter_error_prompt.go_to_formatter",
                }
              );
            } catch (e) {
              // formatter conflict error already handled
            }
          }
          return Promise.resolve();
        })
        .catch(error => {
          if (error instanceof FOCRError) {
            if (error === errors.ConflictFound) {
              setIsConfirmConflictModalOpen(true);
            } else {
              toast.error(error.messageId, undefined, error.detail);
              reset();
            }
          } else {
            toast.error("error.fail_to_save_form");
            reset();
          }
        });
      return savePromise;
    },
    [
      form,
      ignoreFormatterValidationFlag,
      ignoreConflictFlag,
      saveForm,
      isPrebuiltExtractor,
      toast,
      handleConflict,
      validateFormFormatter,
      onFormatterModalConfirm,
    ]
  );

  const cancelSaveForm = useCallback(() => {
    setIgnoreConflictFlag(false);
    setIgnoreFormatterValidationFlag(false);
    setIsConfirmConflictModalOpen(false);
  }, []);

  return {
    isConfirmConflictModalOpen,
    saveFormThenShowToast,
    cancelSaveForm,
  };
}

function hasDuplication(list: string[]) {
  return new Set(list).size !== list.length;
}

function hasEmpty(list: string[]) {
  return list.some(item => item.trim() === "");
}

function hasQuote(list: string[]) {
  return list.some(item => item.includes("'"));
}

function findDetectionRegionIdWithError(form: DetailedForm | undefined) {
  if (!form) {
    return "";
  }

  const { detectionRegions } = form;

  const regions = detectionRegions.filter(detectionRegion => {
    const fieldLabels = detectionRegion.config.fields.map(field => {
      const fieldLabel = field.label.trim();
      return fieldLabel;
    });
    return (
      hasDuplication(fieldLabels) ||
      hasEmpty(fieldLabels) ||
      hasQuote(fieldLabels)
    );
  });

  return regions.length > 0 ? regions[0].id : "";
}

function hasDocumentTabError(form: DetailedForm | undefined) {
  if (!form) {
    return false;
  }
  const { keyValues, tokenGroups } = form;
  const documentFields = keyValues
    .map(keyValue => keyValue.name.trim())
    .concat(tokenGroups.map(tokenGroup => tokenGroup.name.trim()));
  return (
    hasDuplication(documentFields) ||
    hasEmpty(documentFields) ||
    hasQuote(documentFields)
  );
}

function useFormValidation() {
  const states = useStatesDeduceFromReduxStore();
  const { form } = states;
  const [formHasError, setFormHasError] = useState<boolean>(false);

  useEffect(() => {
    const error =
      findDetectionRegionIdWithError(form) !== "" || hasDocumentTabError(form);
    setFormHasError(error);
  }, [form]);

  return {
    formHasError,
  };
}

function useSetUpCanvasStore(
  canvasStoreRef: React.MutableRefObject<CanvasStore>
) {
  const {
    addAnchor,
    deleteAnchor,
    updateAnchor,
    addField,
    deleteField,
    updateField,
    addDetectionRegion,
    updateDetectionRegion,
    deleteDetectionRegion,
  } = useFormActionCreator();

  useEffect(() => {
    canvasStoreRef.current.delegates.push({
      anchorAdded: addAnchor,
      anchorDeleted: deleteAnchor,
      anchorUpdated: updateAnchor,
      fieldAdded: addField,
      fieldDeleted: deleteField,
      fieldUpdated: updateField,
      detectionRegionAdded: addDetectionRegion,
      detectionRegionUpdated: updateDetectionRegion,
      detectionRegionDeleted: deleteDetectionRegion,
    });
  }, [
    addAnchor,
    addDetectionRegion,
    addField,
    deleteAnchor,
    deleteDetectionRegion,
    deleteField,
    updateAnchor,
    updateDetectionRegion,
    updateField,
    canvasStoreRef,
  ]);
}

function useLoadCanvasStore(
  canvasStoreRef: React.MutableRefObject<CanvasStore>
) {
  const { form } = useStatesDeduceFromReduxStore();
  useEffect(() => {
    if (form) {
      canvasStoreRef.current.load(
        form.anchors,
        form.fields,
        form.detectionRegions
      );
    }
  }, [form, canvasStoreRef]);
}

function useCanvasStore() {
  const canvasStoreRef = useRef(new CanvasStore());
  useSetUpCanvasStore(canvasStoreRef);
  useLoadCanvasStore(canvasStoreRef);
  return canvasStoreRef.current;
}

function useScriptEditorModal() {
  const [scriptModalPayload, setScriptModalPayload] = useState<
    ScriptEditorModalPayload | undefined
  >(undefined);

  const onOpenScriptModal = useCallback(
    (payload: ScriptEditorModalPayload) => () => {
      setScriptModalPayload(payload);
    },
    []
  );

  const onCloseDetectionRegionFieldScriptModal = useCallback(() => {
    setScriptModalPayload(undefined);
  }, []);

  return {
    scriptModalPayload,
    onOpenScriptModal,
    onCloseDetectionRegionFieldScriptModal,
  };
}

function useKeywordsEditorModal() {
  const [keywordsModalPayload, setKeywordsModalPayload] = useState<
    BaseFieldSettingModalPayload | undefined
  >(undefined);

  const onOpenDetectionRegionFieldKeywordModal = useCallback(
    (payload: BaseFieldSettingModalPayload) => () => {
      setKeywordsModalPayload(payload);
    },
    []
  );

  const onCloseDetectionRegionFieldKeywordModal = useCallback(() => {
    setKeywordsModalPayload(undefined);
  }, []);

  return {
    keywordsModalPayload,
    onOpenDetectionRegionFieldKeywordModal,
    onCloseDetectionRegionFieldKeywordModal,
  };
}

function useTextSettingModal() {
  const [textFieldModalPayload, setTextFieldModalPayload] = useState<
    BaseFieldSettingModalPayload | undefined
  >(undefined);

  const onOpenTextFieldModal = useCallback(
    (payload: BaseFieldSettingModalPayload) => () => {
      setTextFieldModalPayload(payload);
    },
    []
  );

  const onCloseTextFieldModal = useCallback(() => {
    setTextFieldModalPayload(undefined);
  }, []);

  return {
    textFieldModalPayload,
    onOpenTextFieldModal,
    onCloseTextFieldModal,
  };
}

function useDateTimeFieldModal() {
  const [dateTimeFieldModalPayload, setDateTimeFieldModalPayload] = useState<
    BaseFieldSettingModalPayload | undefined
  >(undefined);

  const onOpenDateTimeFieldModal = useCallback(
    (payload: BaseFieldSettingModalPayload) => () => {
      setDateTimeFieldModalPayload(payload);
    },
    []
  );

  const onCloseDateTimeFieldModal = useCallback(() => {
    setDateTimeFieldModalPayload(undefined);
  }, []);

  return {
    dateTimeFieldModalPayload,
    onOpenDateTimeFieldModal,
    onCloseDateTimeFieldModal,
  };
}

function useMinimalFieldModal() {
  const [minimalFieldModalPayload, setMinimalFieldModalPayload] = useState<
    MinimalFieldModalPayload | undefined
  >(undefined);

  const onOpenMinimalFieldModal = useCallback(
    (payload: MinimalFieldModalPayload) => () => {
      setMinimalFieldModalPayload(payload);
    },
    []
  );

  const onCloseMinimalFieldModal = useCallback(() => {
    setMinimalFieldModalPayload(undefined);
  }, []);

  return {
    minimalFieldModalPayload,
    onOpenMinimalFieldModal,
    onCloseMinimalFieldModal,
  };
}

function useLLMCompletionFieldModal() {
  const [llmCompletionFieldModalPayload, setLLMCompletionFieldModalPayload] =
    useState<BaseFieldSettingModalPayload | undefined>(undefined);

  const onOpenLLMCompletionFieldModal = useCallback(
    (payload: BaseFieldSettingModalPayload) => () => {
      setLLMCompletionFieldModalPayload(payload);
    },
    []
  );

  const onCloseLLMCompletionFieldModal = useCallback(() => {
    setLLMCompletionFieldModalPayload(undefined);
  }, []);

  return {
    llmCompletionFieldModalPayload,
    onOpenLLMCompletionFieldModal,
    onCloseLLMCompletionFieldModal,
  };
}

function useLabellerSelection() {
  const [selectedAnchorId, setSelectedAnchorId] = useState<string | undefined>(
    undefined
  );
  const [selectedFieldId, setSelectedFieldId] = useState<string | undefined>(
    undefined
  );
  const [selectedDetectionRegionId, setSelectedDetectionRegionId] = useState<
    string | undefined
  >(undefined);

  const onSelectAnchor = useCallback((anchorId?: string) => {
    setSelectedAnchorId(anchorId);
    setSelectedFieldId(undefined);
    setSelectedDetectionRegionId(undefined);
  }, []);

  const onSelectField = useCallback((fieldId?: string) => {
    setSelectedFieldId(fieldId);
    setSelectedAnchorId(undefined);
    setSelectedDetectionRegionId(undefined);
  }, []);

  const onSelectDetectionRegion = useCallback((detectionRegionId?: string) => {
    setSelectedDetectionRegionId(detectionRegionId);
    setSelectedAnchorId(undefined);
    setSelectedFieldId(undefined);
  }, []);

  return {
    selectedAnchorId,
    selectedFieldId,
    selectedDetectionRegionId,
    onSelectAnchor,
    onSelectField,
    onSelectDetectionRegion,
  };
}

function useKeyValues() {
  const { updateFormKeyValues } = useFormActionCreator();
  const states = useStatesDeduceFromReduxStore();
  const { form } = states || { form: {} };
  const { keyValues } = (form as DetailedForm) || {};

  const createDefaultKeyValue = useCallback(() => {
    const newKeyValue: KeyValue = {
      name: DEFAULT_FIELD_LABEL,
      tokens: [],
      pattern: "",
      position: "right",
      created_at: Date.now(),
    };
    return newKeyValue;
  }, []);

  const addKeyValue = useCallback(
    (keyValue: KeyValue) => {
      updateFormKeyValues([keyValue, ...keyValues]);
    },
    [keyValues, updateFormKeyValues]
  );

  const addDefaultKeyValue = useCallback(() => {
    const newKeyValue = createDefaultKeyValue();
    newKeyValue.name = genUniqueDocumentFieldName(form, DEFAULT_FIELD_LABEL);
    addKeyValue(newKeyValue);
  }, [addKeyValue, createDefaultKeyValue, form]);

  return useMemo(
    () => ({
      addKeyValue,
      createDefaultKeyValue,
      addDefaultKeyValue: addDefaultKeyValue,
    }),
    [addKeyValue, addDefaultKeyValue, createDefaultKeyValue]
  );
}

function useTokenGroup() {
  const { updateFormTokenGroups } = useFormActionCreator();
  const states = useStatesDeduceFromReduxStore();
  const { form } = states || { form: {} };
  const { tokenGroups } = (form as DetailedForm) || {};

  const createTokenGroup = useCallback(() => {
    const newTokenGroup: TokenGroup = {
      id: uuid(),
      name: DEFAULT_FIELD_LABEL,
      matchMode: "all",
      images: [],
      texts: [],
      tokenType: "texts",
      created_at: Date.now(),
    };
    return newTokenGroup;
  }, []);

  const addTokenGroup = useCallback(
    (tokenGroup: TokenGroup) => {
      updateFormTokenGroups([tokenGroup, ...tokenGroups]);
    },
    [tokenGroups, updateFormTokenGroups]
  );

  const addDefaultTokenGroup = useCallback(
    tokenType => {
      const newTokenGroup = createTokenGroup();
      newTokenGroup.tokenType = tokenType;
      newTokenGroup.name = genUniqueDocumentFieldName(
        form,
        DEFAULT_FIELD_LABEL
      );
      addTokenGroup(newTokenGroup);
    },
    [createTokenGroup, addTokenGroup, form]
  );

  return useMemo(
    () => ({
      createTokenGroup,
      addTokenGroup,
      addDefaultTokenGroup,
    }),
    [addTokenGroup, createTokenGroup, addDefaultTokenGroup]
  );
}

function useDocumentTabState() {
  const { localized } = useLocale();
  const { addDefaultKeyValue } = useKeyValues();
  const { addDefaultTokenGroup } = useTokenGroup();

  const documentCustomExtractionTypesOptions = useMemo(
    () => [
      {
        key: "keyValueType",
        text: localized("form_inspector.document_field.key_value_type"),
      },
      {
        key: "textTokenGroupType",
        text: localized("form_inspector.document_field.text_token_group_type"),
      },
      {
        key: "imageTokenGroupType",
        text: localized("form_inspector.document_field.image_token_group_type"),
      },
    ],
    [localized]
  );

  const [documentCustomExtractionType, setDocumentCustomExtractionType] =
    useState<string>("");

  const [
    documentCustomExtractionErrorMessage,
    setDocumentCustomExtractionErrorMessage,
  ] = useState<string | undefined>();

  const onDocumentCustomExtractionTypeChange = useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option === undefined) {
        return;
      }

      setDocumentCustomExtractionErrorMessage(undefined);
      setDocumentCustomExtractionType(option.key as string);
    },
    []
  );

  const addDefaultDocumentField = useCallback(() => {
    if (documentCustomExtractionType === "") {
      setDocumentCustomExtractionErrorMessage(
        localized(
          "document.tab.custom_extraction_item.choose_extraction_type_warning"
        )
      );
      return;
    }
    switch (documentCustomExtractionType) {
      case "keyValueType":
        addDefaultKeyValue();
        break;
      case "textTokenGroupType":
        addDefaultTokenGroup("texts");
        break;
      case "imageTokenGroupType":
        addDefaultTokenGroup("images");
        break;
    }
  }, [
    documentCustomExtractionType,
    addDefaultKeyValue,
    addDefaultTokenGroup,
    localized,
  ]);

  return {
    documentCustomExtractionType,
    onDocumentCustomExtractionTypeChange,
    documentCustomExtractionTypesOptions,
    documentCustomExtractionErrorMessage,
    addDefaultDocumentField,
  };
}

function useTabSelection() {
  const [selectedTab, setSelectedTab] = useState<FormEditorTab>("region");
  return {
    selectedTab,
    setSelectedTab,
  };
}

function useMakeContext() {
  const boundActionCreators = useFormActionCreator();
  const states = useStatesDeduceFromReduxStore();

  const { isFormNotSaved, form } = states;

  const { selectedTab, setSelectedTab } = useTabSelection();

  const { formHasError } = useFormValidation();

  const {
    scriptModalPayload,
    onOpenScriptModal,
    onCloseDetectionRegionFieldScriptModal,
  } = useScriptEditorModal();

  const {
    keywordsModalPayload,
    onCloseDetectionRegionFieldKeywordModal,
    onOpenDetectionRegionFieldKeywordModal,
  } = useKeywordsEditorModal();

  const { textFieldModalPayload, onCloseTextFieldModal, onOpenTextFieldModal } =
    useTextSettingModal();

  const {
    dateTimeFieldModalPayload,
    onCloseDateTimeFieldModal,
    onOpenDateTimeFieldModal,
  } = useDateTimeFieldModal();

  const {
    minimalFieldModalPayload,
    onCloseMinimalFieldModal,
    onOpenMinimalFieldModal,
  } = useMinimalFieldModal();

  const {
    llmCompletionFieldModalPayload,
    onOpenLLMCompletionFieldModal,
    onCloseLLMCompletionFieldModal,
  } = useLLMCompletionFieldModal();

  const {
    selectedAnchorId,
    selectedFieldId,
    selectedDetectionRegionId,
    onSelectAnchor,
    onSelectField,
    onSelectDetectionRegion,
  } = useLabellerSelection();

  const [isFailedToFetchForm, setIsFailedToFetchForm] =
    useState<boolean>(false);
  const [isSavingForm, setIsSavingForm] = useState(false);

  const canvasStore = useCanvasStore();

  const { saveFormThenShowToast, isConfirmConflictModalOpen, cancelSaveForm } =
    useSaveFormThenShowToast();

  const onSaveButtonClick = useCallback(
    (options?: {
      ignoreConflict?: boolean;
      ignoreFormatterValidation?: boolean;
    }) => {
      if (formHasError) {
        const regionId = findDetectionRegionIdWithError(form);
        if (regionId !== "") {
          setSelectedTab("region");
          onSelectDetectionRegion(regionId);
        } else if (hasDocumentTabError(form)) {
          setSelectedTab("document");
        }
        return;
      }
      setIsSavingForm(true);
      saveFormThenShowToast(options)
        .catch(() => {
          // Error is already handled by saveFormThenShowToast
        })
        .finally(() => {
          setIsSavingForm(false);
        });
    },
    [
      formHasError,
      saveFormThenShowToast,
      form,
      setSelectedTab,
      onSelectDetectionRegion,
    ]
  );

  const hideInV1Form = (
    form: DetailedForm,
    node: React.ReactNode
  ): React.ReactNode | null => {
    return form.version === "v1" ? null : node;
  };

  const { addDefaultKeyValue, addKeyValue, createDefaultKeyValue } =
    useKeyValues();
  const { addTokenGroup, createTokenGroup } = useTokenGroup();

  const {
    documentCustomExtractionType,
    onDocumentCustomExtractionTypeChange,
    documentCustomExtractionTypesOptions,
    addDefaultDocumentField,
    documentCustomExtractionErrorMessage,
  } = useDocumentTabState();

  return useMemo(
    () => ({
      ...states,
      ...boundActionCreators,
      isFailedToFetchForm,
      selectedAnchorId,
      selectedFieldId,
      selectedDetectionRegionId,
      scriptModalPayload,
      keywordsModalPayload,
      textFieldModalPayload,
      setIsFailedToFetchForm,
      onSelectAnchor,
      onSelectField,
      onSelectDetectionRegion,
      onOpenScriptModal,
      onCloseDetectionRegionFieldScriptModal,
      onCloseDetectionRegionFieldKeywordModal,
      onOpenDetectionRegionFieldKeywordModal,
      onOpenTextFieldModal,
      onCloseTextFieldModal,
      onSaveButtonClick,
      canvasStore,
      isFormNotSaved,
      hideInV1Form,

      dateTimeFieldModalPayload,
      onCloseDateTimeFieldModal,
      onOpenDateTimeFieldModal,

      minimalFieldModalPayload,
      onCloseMinimalFieldModal,
      onOpenMinimalFieldModal,

      llmCompletionFieldModalPayload,
      onOpenLLMCompletionFieldModal,
      onCloseLLMCompletionFieldModal,

      addDefaultKeyValue,
      addKeyValue,
      createDefaultKeyValue,

      createTokenGroup,
      addTokenGroup,

      documentCustomExtractionType,
      onDocumentCustomExtractionTypeChange,
      documentCustomExtractionTypesOptions,
      addDefaultDocumentField,
      documentCustomExtractionErrorMessage,

      formHasError,
      setSelectedTab,
      selectedTab,

      isSavingForm,
      saveFormThenShowToast,
      isConfirmConflictModalOpen,
      cancelSaveForm,
    }),
    [
      states,
      boundActionCreators,
      isFailedToFetchForm,
      selectedAnchorId,
      selectedFieldId,
      selectedDetectionRegionId,
      scriptModalPayload,
      keywordsModalPayload,
      setIsFailedToFetchForm,
      onSelectAnchor,
      onSelectField,
      onSelectDetectionRegion,
      onOpenScriptModal,
      onCloseDetectionRegionFieldScriptModal,
      onCloseDetectionRegionFieldKeywordModal,
      onOpenDetectionRegionFieldKeywordModal,
      onSaveButtonClick,
      isFormNotSaved,
      canvasStore,
      textFieldModalPayload,
      onCloseTextFieldModal,
      onOpenTextFieldModal,

      dateTimeFieldModalPayload,
      onCloseDateTimeFieldModal,
      onOpenDateTimeFieldModal,

      minimalFieldModalPayload,
      onCloseMinimalFieldModal,
      onOpenMinimalFieldModal,

      llmCompletionFieldModalPayload,
      onOpenLLMCompletionFieldModal,
      onCloseLLMCompletionFieldModal,

      addDefaultKeyValue,
      addKeyValue,
      createDefaultKeyValue,

      createTokenGroup,
      addTokenGroup,

      documentCustomExtractionType,
      onDocumentCustomExtractionTypeChange,
      documentCustomExtractionTypesOptions,
      addDefaultDocumentField,
      documentCustomExtractionErrorMessage,

      formHasError,
      setSelectedTab,
      selectedTab,

      isSavingForm,
      saveFormThenShowToast,
      isConfirmConflictModalOpen,
      cancelSaveForm,
    ]
  );
}

type FormEditorContextValue = ReturnType<typeof useMakeContext>;
const FormEditorContext = createContext<FormEditorContextValue>(null as any);

interface Props {
  children: React.ReactNode;
}

export const FormEditorProvider = (props: Props) => {
  const value = useMakeContext();
  return <FormEditorContext.Provider {...props} value={value} />;
};

export function useFormEditor() {
  return useContext(FormEditorContext);
}
