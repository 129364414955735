import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { ClickableCard } from "../ClickableCard";
import { ActionButton } from "../WrappedMSComponents/Buttons";

export interface ManageSubscriptionProps {
  className?: string;
  canCancelSubscription?: boolean;
  onEditSubscription?: () => void;
  onViewPaymentRecords?: () => void;
  onCancelSubscription?: () => void;
}

export function ManageSubscription(props: ManageSubscriptionProps) {
  const {
    className,
    canCancelSubscription,
    onEditSubscription,
    onViewPaymentRecords,
    onCancelSubscription,
  } = props;

  const { localized } = useLocale();

  return (
    <div className={cn(className, "flex flex-col p-10")}>
      <div
        className={cn("pb-3 border-0 border-b border-solid border-gray-200")}
      >
        <p className="text-xl font-semibold text-black mb-1">
          <FormattedMessage id="payment.manage_subscription.title" />
        </p>
        <p className="text-sm text-gray-700">
          <FormattedMessage id="payment.manage_subscription.description" />
        </p>
      </div>
      <div className="py-6 flex gap-6">
        <ClickableCard
          className="flex-1"
          iconName="Edit"
          title={localized("payment.manage_subscription.edit.title")}
          description={localized(
            "payment.manage_subscription.edit.description"
          )}
          onClick={onEditSubscription}
        />
        <ClickableCard
          className="flex-1"
          iconName="TextDocument"
          title={localized("payment.manage_subscription.view_records.title")}
          description={localized(
            "payment.manage_subscription.view_records.description"
          )}
          onClick={onViewPaymentRecords}
        />
      </div>
      {canCancelSubscription ? (
        <ActionButton
          isDestroy={true}
          iconName="cancel"
          textId="payment.manage_subscription.cancel"
          onClick={onCancelSubscription}
        />
      ) : null}
    </div>
  );
}
