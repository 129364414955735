import { PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { useLocale } from "../../contexts/locale";
import { RootState } from "../../redux/types";
import { TeamDetail } from "../../types/team";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import { QuotaTable } from "../QuotaTable";
import styles from "./styles.module.scss";

interface Props {
  team: TeamDetail;
  region: string;
  presetGiveTrialModal: () => void;
  presetClearTrialModal: () => void;
  presetUpgradeToEnterpriseModal: () => void;
  presetDowngradeEnterpriseModal: () => void;
}

function _TeamDetailPlan(props: Props) {
  const {
    team,
    presetGiveTrialModal,
    presetClearTrialModal,
    presetUpgradeToEnterpriseModal,
    presetDowngradeEnterpriseModal,
  } = props;
  const { quota, planIsPaid, planName, trialEndAt, enterpriseEndAt } = team;
  const { localized } = useLocale();
  const plans = useSelector((state: RootState) => state.app.plans);

  const onRemoveTrialClicked = React.useCallback(() => {
    presetClearTrialModal();
  }, [presetClearTrialModal]);

  const onGiveTrialClicked = React.useCallback(() => {
    presetGiveTrialModal();
  }, [presetGiveTrialModal]);

  const onUpgradeToEnterpriseClicked = React.useCallback(() => {
    presetUpgradeToEnterpriseModal();
  }, [presetUpgradeToEnterpriseModal]);

  const onDowngradeEnterpriseClicked = React.useCallback(() => {
    presetDowngradeEnterpriseModal();
  }, [presetDowngradeEnterpriseModal]);

  const showQuota = planIsPaid || planName !== "free";

  const planToDisplay = capitalizeFirstLetter(
    plans.find(x => x.id === team.planId)?.name || team.planName || ""
  );

  return (
    <div className={styles["team-plan"]}>
      <div className={styles["team-plan-info"]}>
        <div className={styles["team-plan-info-title"]}>
          <FormattedMessage id="team.detail.current_plan" />
        </div>
        <div className={styles["team-plan-info-content"]}>{planToDisplay}</div>
      </div>
      {showQuota && <QuotaTable quota={quota} />}
      {planName === "trial" && trialEndAt != null && (
        <div className={styles["team-plan-info"]}>
          <div className={styles["team-plan-info-title"]}>
            <FormattedMessage id="team.detail.end_at" />
          </div>
          <div className={styles["team-plan-info-content"]}>
            {trialEndAt.toLocaleDateString().replace(/\//g, "-")}
          </div>
        </div>
      )}
      {planName === "enterprise" && enterpriseEndAt != null && (
        <div className={styles["team-plan-info"]}>
          <div className={styles["team-plan-info-title"]}>
            <FormattedMessage id="team.detail.end_at" />
          </div>
          <div className={styles["team-plan-info-content"]}>
            {enterpriseEndAt.toLocaleDateString().replace(/\//g, "-")}
          </div>
        </div>
      )}
      <div className={styles["team-plan-buttons"]}>
        {planName === "trial" && (
          <PrimaryButton
            className={styles["team-remove-plan-button"]}
            type="button"
            onClick={onRemoveTrialClicked}
            text={localized("team.detail.plan.remove_trial")}
          />
        )}
        {planName === "free" && (
          <PrimaryButton
            className={styles["team-plan-button"]}
            type="button"
            onClick={onGiveTrialClicked}
            text={localized("team.detail.plan.give_trial")}
          />
        )}
        {(planName === "trial" || planName === "free") && (
          <PrimaryButton
            className={classNames(
              styles["team-plan-button"],
              styles["team-plan-enterprise-button"]
            )}
            type="button"
            onClick={onUpgradeToEnterpriseClicked}
            text={localized("team.detail.plan.upgrade_to_enterprise")}
          />
        )}
        {planName === "enterprise" && (
          <PrimaryButton
            className={styles["team-remove-plan-button"]}
            type="button"
            onClick={onDowngradeEnterpriseClicked}
            text={localized("team.detail.plan.downgrade_enterprise")}
          />
        )}
      </div>
    </div>
  );
}

export const TeamDetailPlan = React.memo(_TeamDetailPlan);
export default TeamDetailPlan;
