import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { AppConfig } from "../config";
import { useGtm } from "../hooks/gtm";
import { useAppDispatch } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { RootState } from "../redux/types";
import { ResourceOwnerSetting } from "../types/resourceOwner";
import {
  PaymentMethod,
  SubscriptionData,
  User,
  UserSetting,
} from "../types/user";
import { WorkerToken, WorkerTokenList } from "../types/workerToken";
import { useConfirmModalActionCreator } from "./confirmModal";
import { getCurrentTeamId } from "./team";

export const IsPaymentRequiredUpdated = createAction<boolean>(
  "resourceOwner/isPaymentRequiredUpdated"
);

export const PaymentMethodUpdated = createAction(
  "resourceOwner/paymentMethodUpdated",
  (paymentMethod: PaymentMethod, isTeam?: boolean) => ({
    payload: {
      paymentMethod,
      isTeam,
    },
  })
);

export const BillingEmailUpdated = createAction<string>(
  "resourceOwner/billingEmailUpdated"
);

export const SettingUpdated = createAction(
  "resourceOwner/settingUpdated",
  (setting: UserSetting, isTeam: boolean | undefined) => ({
    payload: { setting, isTeam },
  })
);

export const GotWorkerTokens = createAction<WorkerToken[]>(
  "resourceOwner/gotWorkerToken"
);

export const GotSubscriptionData = createAction<SubscriptionData>(
  "resourceOwner/gotSubscriptionData"
);

export const GotSetting = createAction<ResourceOwnerSetting>(
  "resourceOwner/gotSetting"
);

export const SelectTeam = createAction(
  "resourceOwner/selectTeam",
  (resourceOwnerId: string | undefined, user: User) => ({
    payload: {
      resourceOwnerId,
      user,
    },
  })
);

export const SelectWorkerToken = createAction<string>(
  "resourceOwner/selectWorkerToken"
);

export type ResourceOwnerAction =
  | ReturnType<typeof IsPaymentRequiredUpdated>
  | ReturnType<typeof SettingUpdated>
  | ReturnType<typeof PaymentMethodUpdated>
  | ReturnType<typeof BillingEmailUpdated>
  | ReturnType<typeof SelectTeam>
  | ReturnType<typeof GotWorkerTokens>
  | ReturnType<typeof GotSubscriptionData>
  | ReturnType<typeof GotSetting>;

export function useResourceOwnerActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();
  const { handleConflict } = useConfirmModalActionCreator();
  const { setParam } = useSearchParamUtils();

  const updateSetting = useCallback(
    async (setting: UserSetting) => {
      const { resourceOwnerId, isTeam } = getState().resourceOwner;

      const updatedSetting = await handleConflict(
        () =>
          apiClient.updateResourceOwnerSetting(setting, false, resourceOwnerId),
        () =>
          apiClient.updateResourceOwnerSetting(setting, true, resourceOwnerId),
        {
          titleId: "setting.modified_prompt.title",
          messageId: "setting.modified_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );

      dispatch(SettingUpdated(updatedSetting, isTeam));

      return Promise.resolve();
    },
    [dispatch, getState, handleConflict]
  );

  const { pushSubscribedPlanEvent } = useGtm();

  const subscribePlan = useCallback(
    async (planId: string, planName: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      const checkoutSessionUrl = await apiClient.subscribePlan(
        planId,
        resourceOwnerId
      );

      pushSubscribedPlanEvent(planId, planName);
      window.location.href = checkoutSessionUrl;
    },
    [getState, pushSubscribedPlanEvent]
  );

  const manageSubscription = useCallback(
    async (flow?: "payment_method_update" | "subscription_cancel") => {
      const { resourceOwnerId } = getState().resourceOwner;
      const portalSessionUrl = await apiClient.manageSubscription(
        flow,
        resourceOwnerId
      );

      window.location.href = portalSessionUrl;
    },
    [getState]
  );

  const checkIsPaymentRequired = useCallback(async () => {
    const { resourceOwnerId } = getState().resourceOwner;
    try {
      const isPaymentRequired = await apiClient.isPaymentRequired(
        resourceOwnerId
      );
      dispatch(IsPaymentRequiredUpdated(isPaymentRequired));
      return isPaymentRequired;
    } catch (e) {
      return false;
    }
  }, [dispatch, getState]);

  const { pushSwitchedTeamEvent } = useGtm();

  const selectTeam = useCallback(
    async (resourceOwnerId: string) => {
      const currentUser = getState().user.currentUser;
      if (!currentUser) {
        return;
      }

      const currentResourceOwnerId = getState().resourceOwner.resourceOwnerId;
      if (resourceOwnerId === currentResourceOwnerId) {
        return;
      }

      const briefTeam = currentUser.teams.find(
        team => team.id === resourceOwnerId
      );

      if (briefTeam) {
        setParam(URLParamsKey.team, briefTeam.lookupId);
        const { region } = AppConfig;
        pushSwitchedTeamEvent(region, briefTeam.lookupId);
      }

      dispatch(SelectTeam(resourceOwnerId, currentUser));

      checkIsPaymentRequired();
    },
    [
      dispatch,
      getState,
      checkIsPaymentRequired,
      setParam,
      pushSwitchedTeamEvent,
    ]
  );

  const getWorkerTokens = useCallback(async () => {
    const { resourceOwnerId } = getState().resourceOwner;
    const currentWorkerTokens = getState().resourceOwner.workerTokens;

    if (currentWorkerTokens || !resourceOwnerId) {
      return currentWorkerTokens;
    }

    const { tokens } = await apiClient.listWorkerTokens(resourceOwnerId, false);
    dispatch(GotWorkerTokens(tokens));
    return tokens;
  }, [dispatch, getState]);

  const getAllWorkerTokens = useCallback(
    async (teamId?: string, region?: string): Promise<WorkerTokenList> => {
      if (!teamId) {
        teamId = await getCurrentTeamId(getState);
      }
      return await apiClient.listWorkerTokens(teamId, true, region);
    },
    [getState]
  );

  const createWorkerToken = useCallback(
    async (name: string, teamId?: string): Promise<WorkerToken | undefined> => {
      const tokens = getState().resourceOwner.workerTokens;
      if (!tokens) {
        return;
      }

      if (!teamId) {
        teamId = await getCurrentTeamId(getState);
      }

      const { token } = await apiClient.createWorkerToken(name, teamId);

      dispatch(GotWorkerTokens([...tokens, token]));
      return token;
    },
    [getState, dispatch]
  );

  const updateWorkerToken = useCallback(
    async (name: string, tokenId: string): Promise<WorkerToken | undefined> => {
      const tokens = getState().resourceOwner.workerTokens;
      if (!tokens) {
        return;
      }
      const { token } = await apiClient.updateWorkerToken(name, tokenId);

      dispatch(
        GotWorkerTokens(tokens.map(t => (t.id === token.id ? token : t)))
      );

      return token;
    },
    [getState, dispatch]
  );

  const revokeWorkerToken = useCallback(
    async (tokenId: string): Promise<WorkerToken | undefined> => {
      const tokens = getState().resourceOwner.workerTokens;
      if (!tokens) {
        return;
      }

      const { token } = await apiClient.revokeWorkerToken(tokenId);
      dispatch(GotWorkerTokens(tokens.filter(t => t.id !== token.id)));

      return token;
    },
    [getState, dispatch]
  );

  const selectWorkerToken = useCallback(
    async (token: string): Promise<void> => {
      dispatch(SelectWorkerToken(token));
    },
    [dispatch]
  );

  const getSetting = useCallback(async (): Promise<ResourceOwnerSetting> => {
    const { resourceOwnerId } = getState().resourceOwner;
    const setting = await apiClient.getSetting(resourceOwnerId);

    dispatch(GotSetting(setting));
    return setting;
  }, [dispatch, getState]);

  const getReadmeIOAuthToken = useCallback(
    async (formId?: string, workspaceId?: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      if (!resourceOwnerId) {
        return;
      }

      const authToken = await apiClient.getReadmeIOAuthToken(
        resourceOwnerId,
        AppConfig.region,
        new URL(AppConfig.api.endpoint).host,
        new URL(AppConfig.worker.endpoint).host,
        formId,
        workspaceId
      );

      return authToken;
    },
    [getState]
  );

  return useMemo(
    () => ({
      updateSetting,
      subscribePlan,
      manageSubscription,
      checkIsPaymentRequired,
      selectTeam,
      getWorkerTokens,
      getAllWorkerTokens,
      createWorkerToken,
      updateWorkerToken,
      revokeWorkerToken,
      selectWorkerToken,
      getSetting,
      getReadmeIOAuthToken,
    }),
    [
      updateSetting,
      subscribePlan,
      manageSubscription,
      checkIsPaymentRequired,
      selectTeam,
      getWorkerTokens,
      getAllWorkerTokens,
      createWorkerToken,
      updateWorkerToken,
      revokeWorkerToken,
      selectWorkerToken,
      getSetting,
      getReadmeIOAuthToken,
    ]
  );
}
