import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { ListTeamOptions } from "../apiClient/mixin/team";
import { UserFeatureFlag } from "../constants";
import { FOCRError } from "../errors";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { BriefCustomModel, CustomModel } from "../types/customModel";
import { PaginatedCustomModelImage } from "../types/customModelImage";
import { BriefForm } from "../types/form";
import { BriefFormGroup } from "../types/formGroup";
import { PageInfoWithOffset } from "../types/pageInfo";
import { PlanQuota } from "../types/quota";
import {
  Permission,
  TeamDetail,
  TeamListItem,
  TeamMembersAndInvitations,
  TeamUsageItem,
} from "../types/team";
import {
  RequestLog,
  RequestLogApiName,
  Usage,
  UsageRange,
} from "../types/usage";
import { ensureFOCRError } from "../utils/errors";
import { useConfirmModalActionCreator } from "./confirmModal";
import { TeamUserRemoved, UserPermissionUpdated } from "./team";

export const AdminGettingTeamList = createAction(
  "admin/gettingTeamList",
  (
    region: string,
    options: ListTeamOptions | undefined,
    saveActiveRegion: boolean
  ) => ({ payload: { region, options, saveActiveRegion } })
);

export const AdminGotTeamList = createAction(
  "admin/gotTeamList",
  (
    region: string,
    teams: TeamListItem[],
    listTeamOptions: ListTeamOptions | undefined,
    pageInfo: PageInfoWithOffset
  ) => ({ payload: { region, teams, listTeamOptions, pageInfo } })
);

export const AdminGotTeamListFailed = createAction(
  "admin/gotTeamListFailed",
  (region: string, error: FOCRError) => ({
    payload: {
      region,
      error,
    },
  })
);

export const AdminGettingTeamDetail = createAction(
  "admin/gettingTeamDetail",
  (teamId: string, region: string) => ({ payload: { teamId, region } })
);

export const AdminGotTeamDetail = createAction<TeamDetail>(
  "admin/gotTeamDetail"
);

export const AdminGotTeamDetailFailed = createAction<FOCRError>(
  "admin/gotTeamDetailFailed"
);

export const AdminGettingTeamMembers = createAction(
  "admin/gettingTeamMembers",
  (teamId: string, region: string) => ({ payload: { teamId, region } })
);

export const AdminGotTeamMembers = createAction(
  "admin/gotTeamMembers",
  (
    teamId: string,
    region: string,
    membersAndInvitations: TeamMembersAndInvitations
  ) => ({
    payload: {
      teamId,
      region,
      membersAndInvitations,
    },
  })
);

export const AdminGotTeamMembersFailed = createAction<FOCRError>(
  "admin/gotTeamMembersFailed"
);

export const AdminGettingTeamUsage = createAction(
  "admin/gettingTeamUsage",
  (range: UsageRange, teamId: string, region: string) => ({
    payload: {
      range,
      teamId,
      region,
    },
  })
);

export const AdminGotTeamUsage = createAction(
  "admin/gotTeamUsage",
  (range: UsageRange, teamId: string, region: string, usages: Usage[]) => ({
    payload: {
      range,
      teamId,
      region,
      usages,
    },
  })
);

export const AdminGotTeamUsageFailed = createAction<FOCRError>(
  "admin/gotTeamUsageFailed"
);

export const AdminGettingRequestLogs = createAction("admin/gettingRequestLogs");

export const AdminGotRequestLogs = createAction(
  "admin/gotRequestLogs",
  (requestLogs: RequestLog[], pageInfo: PageInfoWithOffset) => ({
    payload: {
      requestLogs,
      pageInfo,
    },
  })
);

export const AdminGotRequestLogsFailed = createAction<FOCRError>(
  "admin/gotRequestLogsFailed"
);

export const AdminGettingTeamForms = createAction(
  "admin/gettingTeamForms",
  (teamId: string, region: string) => ({
    payload: {
      teamId,
      region,
    },
  })
);

export const AdminGotTeamForms = createAction(
  "admin/gotTeamForms",
  (forms: BriefForm[], pageInfo: PageInfoWithOffset) => ({
    payload: {
      forms,
      pageInfo,
    },
  })
);

export const AdminGotTeamFormsFailed = createAction<FOCRError>(
  "admin/gotTeamFormsFailed"
);

export const AdminGettingTeamFormGroups = createAction(
  "admin/gettingTeamFormGroups",
  (teamId: string, region: string) => ({
    payload: {
      teamId,
      region,
    },
  })
);

export const AdminGotTeamFormGroups = createAction(
  "admin/gotTeamFormGroups",
  (formGroups: BriefFormGroup[], pageInfo: PageInfoWithOffset) => ({
    payload: {
      formGroups,
      pageInfo,
    },
  })
);

export const AdminGotTeamFormGroupsFailed = createAction<FOCRError>(
  "admin/gotTeamFormGroupsFailed"
);

export const AdminGettingTeamCustomModels = createAction(
  "admin/gettingTeamCustomModels",
  (teamId: string, region: string) => ({
    payload: {
      teamId,
      region,
    },
  })
);

export const AdminGotTeamCustomModels = createAction(
  "admin/gotTeamCustomModels",
  (customModels: BriefCustomModel[], pageInfo: PageInfoWithOffset) => ({
    payload: {
      customModels,
      pageInfo,
    },
  })
);

export const AdminGotTeamCustomModelsFailed = createAction<FOCRError>(
  "admin/gotTeamCustomModelsFailed"
);

export const AdminGettingTeamCustomModel = createAction(
  "admin/gettingTeamCustomModel",
  (customModelId: string, region: string) => ({
    payload: {
      customModelId,
      region,
    },
  })
);

export const AdminGotTeamCustomModel = createAction<CustomModel>(
  "admin/gotTeamCustomModel"
);

export const AdminGotTeamCustomModelFailed = createAction<FOCRError>(
  "admin/gotTeamCustomModelFailed"
);

export const AdminGettingTeamCustomModelImages = createAction(
  "admin/gettingTeamCustomModelImages",
  (customModelId: string, region: string, offset: number, size: number) => ({
    payload: {
      customModelId,
      region,
      offset,
      size,
    },
  })
);

export const AdminGotTeamCustomModelImages =
  createAction<PaginatedCustomModelImage>("admin/gotTeamCustomModelImages");

export const AdminGotTeamCustomModelImagesFailed = createAction<FOCRError>(
  "admin/gotTeamCustomModelImagesFailed"
);

export const AdminGettingReportTeamsUsages = createAction(
  "admin/gettingReportTeamsUsages",
  (range: UsageRange, limit: number, region: string) => ({
    payload: {
      region,
      range,
      limit,
    },
  })
);

export const AdminGotReportTeamsUsages = createAction(
  "admin/gotReportTeamUsage",
  (
    range: UsageRange,
    limit: number,
    region: string,
    teamsUsages: TeamUsageItem[]
  ) => ({
    payload: {
      region,
      limit,
      teamsUsages,
      range,
    },
  })
);

export const AdminGotReportTeamsUsagesFailed = createAction(
  "admin/gotReportTeamsUsagesFailed",
  (region: string, error: FOCRError) => ({
    payload: {
      region,
      error,
    },
  })
);

export const AdminGettingReportNewTeamList = createAction(
  "admin/gettingReportNewTeamList",
  (region: string) => ({ payload: { region } })
);

export const AdminGotReportNewTeamList = createAction(
  "admin/gotReportNewTeamList",
  (region: string, teams: TeamListItem[], pageInfo: PageInfoWithOffset) => ({
    payload: { region, teams, pageInfo },
  })
);

export const AdminGotReportNewTeamListFailed = createAction(
  "admin/gotReportNewTeamListFailed",
  (region: string, error: FOCRError) => ({
    payload: {
      region,
      error,
    },
  })
);

export const AdminGettingReportCustomModels = createAction(
  "admin/gettingReportCustomModels",
  (region: string) => ({
    payload: {
      region,
    },
  })
);

export const AdminGotReportCustomModels = createAction(
  "admin/gotReportCustomModels",
  (
    region: string,
    customModels: BriefCustomModel[],
    pageInfo: PageInfoWithOffset
  ) => ({
    payload: {
      region,
      customModels,
      pageInfo,
    },
  })
);

export const AdminGotReportCustomModelsFailed = createAction(
  "admin/gotReportCustomModelsFailed",
  (region: string, error: FOCRError) => ({
    payload: {
      region,
      error,
    },
  })
);

export const AdminTeamUserInvited = createAction(
  "admin/teamUserInvited",
  (id: string, email: string, permission: Permission) => ({
    payload: {
      id,
      email,
      permission,
    },
  })
);

export const AdminTeamInvitationRemoved = createAction(
  "admin/teamInvitationRemoved ",
  (invitationId: string) => ({
    payload: { invitationId },
  })
);

export const AdminUserPermissionUpdated = createAction(
  "admin/userPermissionUpdated",
  (permission: Permission, userId: string, updatedAt: string) => ({
    payload: {
      permission,
      userId,
      updatedAt,
    },
  })
);

export const AdminTeamUserRemoved = createAction(
  "admin/teamUserRemoved",
  (userId: string) => ({
    payload: {
      userId,
    },
  })
);

export type AdminAction =
  | ReturnType<typeof AdminGettingTeamList>
  | ReturnType<typeof AdminGotTeamList>
  | ReturnType<typeof AdminGotTeamListFailed>
  | ReturnType<typeof AdminGettingTeamDetail>
  | ReturnType<typeof AdminGotTeamDetail>
  | ReturnType<typeof AdminGotTeamDetailFailed>
  | ReturnType<typeof AdminGettingTeamMembers>
  | ReturnType<typeof AdminGotTeamMembers>
  | ReturnType<typeof AdminGotTeamMembersFailed>
  | ReturnType<typeof AdminGettingTeamUsage>
  | ReturnType<typeof AdminGotTeamUsage>
  | ReturnType<typeof AdminGotTeamUsageFailed>
  | ReturnType<typeof AdminGettingTeamForms>
  | ReturnType<typeof AdminGotTeamForms>
  | ReturnType<typeof AdminGotTeamFormsFailed>
  | ReturnType<typeof AdminGettingTeamFormGroups>
  | ReturnType<typeof AdminGotTeamFormGroups>
  | ReturnType<typeof AdminGotTeamFormGroupsFailed>
  | ReturnType<typeof AdminGettingTeamCustomModels>
  | ReturnType<typeof AdminGotTeamCustomModels>
  | ReturnType<typeof AdminGotTeamCustomModelsFailed>
  | ReturnType<typeof AdminGettingRequestLogs>
  | ReturnType<typeof AdminGotRequestLogs>
  | ReturnType<typeof AdminGotRequestLogsFailed>
  | ReturnType<typeof AdminGettingTeamCustomModel>
  | ReturnType<typeof AdminGotTeamCustomModel>
  | ReturnType<typeof AdminGotTeamCustomModelFailed>
  | ReturnType<typeof AdminGettingTeamCustomModelImages>
  | ReturnType<typeof AdminGotTeamCustomModelImages>
  | ReturnType<typeof AdminGotTeamCustomModelImagesFailed>
  | ReturnType<typeof AdminGettingReportTeamsUsages>
  | ReturnType<typeof AdminGotReportTeamsUsages>
  | ReturnType<typeof AdminGotReportTeamsUsagesFailed>
  | ReturnType<typeof AdminGettingReportNewTeamList>
  | ReturnType<typeof AdminGotReportNewTeamList>
  | ReturnType<typeof AdminGotReportNewTeamListFailed>
  | ReturnType<typeof AdminGettingReportCustomModels>
  | ReturnType<typeof AdminGotReportCustomModels>
  | ReturnType<typeof AdminGotReportCustomModelsFailed>
  | ReturnType<typeof AdminTeamUserInvited>
  | ReturnType<typeof AdminTeamInvitationRemoved>
  | ReturnType<typeof AdminUserPermissionUpdated>
  | ReturnType<typeof AdminTeamUserRemoved>;

export function useAdminActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const listTeams = useCallback(
    async (
      region: string,
      options?: ListTeamOptions,
      offset: number = 0,
      size: number = 20,
      saveRegion: boolean = false
    ) => {
      dispatch(AdminGettingTeamList(region, options, saveRegion));

      try {
        const listResult = await apiClient.listTeam(
          region,
          size,
          offset,
          options
        );

        dispatch(
          AdminGotTeamList(
            region,
            listResult.teams,
            options,
            listResult.pageInfo
          )
        );
      } catch (error) {
        dispatch(AdminGotTeamListFailed(region, ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const getTeam = useCallback(
    async (teamId: string, region: string) => {
      const team = getState().admin.teamDetail.team;
      if (team !== undefined && teamId === team.id) {
        return;
      }
      dispatch(AdminGettingTeamDetail(teamId, region));
      try {
        const { team } = await apiClient.getTeam(teamId, region);
        dispatch(AdminGotTeamDetail(team));
      } catch (error) {
        dispatch(AdminGotTeamDetailFailed(ensureFOCRError(error)));
      }
    },
    [dispatch, getState]
  );

  const getTeamMembers = useCallback(
    async (teamId: string, region: string) => {
      dispatch(AdminGettingTeamMembers(teamId, region));
      try {
        const result = await apiClient.listTeamMembers(teamId, region);
        dispatch(AdminGotTeamMembers(teamId, region, result));
      } catch (error) {
        dispatch(AdminGotTeamMembersFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const getTeamUsage = useCallback(
    async (
      range: UsageRange,
      teamId: string,
      region: string,
      tokenIds: string[]
    ) => {
      dispatch(AdminGettingTeamUsage(range, teamId, region));
      try {
        const usages = await apiClient.getUsage(
          range,
          tokenIds,
          teamId,
          region
        );
        dispatch(AdminGotTeamUsage(range, teamId, region, usages));
      } catch (error) {
        dispatch(AdminGotTeamUsageFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const getRequestLogs = useCallback(
    async (
      size: number,
      offset: number,
      startFrom: Date,
      range: UsageRange,
      teamId: string,
      region: string,
      apiNames?: RequestLogApiName[],
      tokenIds?: (string | null)[]
    ) => {
      dispatch(AdminGettingRequestLogs());

      try {
        const { requestLogs, pageInfo } = await apiClient.listRequestLogs(
          size,
          offset,
          startFrom,
          range,
          teamId,
          apiNames,
          region,
          tokenIds
        );

        dispatch(AdminGotRequestLogs(requestLogs, pageInfo));
      } catch (error) {
        dispatch(AdminGotRequestLogsFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const listTeamForms = useCallback(
    async (
      teamId: string,
      region: string,
      offset: number = 0,
      size: number = 20
    ) => {
      dispatch(AdminGettingTeamForms(teamId, region));

      try {
        const listResult = await apiClient.listFormWithOffset(
          size,
          offset.toString(),
          undefined,
          teamId,
          region
        );

        dispatch(AdminGotTeamForms(listResult.forms, listResult.pageInfo));
      } catch (error) {
        dispatch(AdminGotTeamFormsFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const listTeamFormGroups = useCallback(
    async (
      teamId: string,
      region: string,
      offset: number = 0,
      size: number = 20
    ) => {
      dispatch(AdminGettingTeamFormGroups(teamId, region));

      try {
        const listResult = await apiClient.listFormGroupsWithOffset(
          size,
          offset.toString(),
          teamId,
          region
        );

        dispatch(
          AdminGotTeamFormGroups(listResult.formGroups, listResult.pageInfo)
        );
      } catch (error) {
        dispatch(AdminGotTeamFormGroupsFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const listTeamCustomModels = useCallback(
    async (
      teamId: string,
      region: string,
      offset: number = 0,
      size: number = 20
    ) => {
      dispatch(AdminGettingTeamCustomModels(teamId, region));

      try {
        const listResult = await apiClient.listCustomModelsWithOffset(
          size,
          offset.toString(),
          teamId,
          region
        );

        dispatch(
          AdminGotTeamCustomModels(listResult.customModels, listResult.pageInfo)
        );
      } catch (error) {
        dispatch(AdminGotTeamCustomModelsFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const setTrial = useCallback(
    async (
      teamId: string,
      region: string,
      quota?: number,
      trialEndAt?: Date
    ) => {
      const team = getState().admin.teamDetail.team;

      await apiClient.setPlan("trial", quota, trialEndAt, teamId, region);

      if (team !== undefined) {
        const planQuota: PlanQuota | null =
          quota !== undefined
            ? {
                aggregate: {
                  hardLimit: quota,
                  softLimit: quota,
                },
                annually: null,
                daily: null,
                monthly: null,
              }
            : null;

        dispatch(
          AdminGotTeamDetail({
            ...team,
            planName: "trial",
            quota: planQuota,
            trialEndAt,
          })
        );
      }
    },
    [dispatch, getState]
  );

  const clearTrial = useCallback(
    async (teamId: string, region: string) => {
      const team = getState().admin.teamDetail.team;

      await apiClient.clearPlan(teamId, region);

      if (team !== undefined) {
        dispatch(
          AdminGotTeamDetail({
            ...team,
            planName: "free",
            quota: null,
            trialEndAt: null,
          })
        );
      }
    },
    [dispatch, getState]
  );

  const setEnterprise = useCallback(
    async (teamId: string, region: string, quota?: number, endAt?: Date) => {
      const team = getState().admin.teamDetail.team;

      await apiClient.setPlan("enterprise", quota, endAt, teamId, region);

      if (team !== undefined) {
        const planQuota: PlanQuota | null =
          quota !== undefined
            ? {
                aggregate: {
                  hardLimit: quota,
                  softLimit: quota,
                },
                annually: null,
                daily: null,
                monthly: null,
              }
            : null;

        dispatch(
          AdminGotTeamDetail({
            ...team,
            planName: "enterprise",
            quota: planQuota,
            enterpriseEndAt: endAt,
          })
        );
      }
    },
    [dispatch, getState]
  );

  const downgradeEnterprise = useCallback(
    async (teamId: string, region: string) => {
      const team = getState().admin.teamDetail.team;

      await apiClient.clearPlan(teamId, region);

      if (team !== undefined) {
        dispatch(
          AdminGotTeamDetail({
            ...team,
            planName: "free",
            quota: null,
            enterpriseEndAt: null,
          })
        );
      }
    },
    [dispatch, getState]
  );

  const getTeamCustomModel = useCallback(
    async (customModelId: string, region: string) => {
      const customModel =
        getState().admin.teamDetail.resourceState.customModelsState
          .customModelDetailState?.customModel;
      if (customModel !== undefined && customModelId === customModel.id) {
        return;
      }
      dispatch(AdminGettingTeamCustomModel(customModelId, region));
      try {
        const customModel = await apiClient.getCustomModel(
          customModelId,
          undefined,
          region
        );
        dispatch(AdminGotTeamCustomModel(customModel));
      } catch (error) {
        dispatch(AdminGotTeamCustomModelFailed(ensureFOCRError(error)));
      }
    },
    [dispatch, getState]
  );

  const listTeamCustomModelImages = useCallback(
    async (
      customModelId: string,
      region: string,
      offset: number = 0,
      size: number = 20
    ) => {
      dispatch(
        AdminGettingTeamCustomModelImages(customModelId, region, offset, size)
      );

      try {
        const paginatedCustomModelImage = await apiClient.listCustomModelImages(
          customModelId,
          size,
          offset,
          undefined,
          region
        );

        dispatch(AdminGotTeamCustomModelImages(paginatedCustomModelImage));
      } catch (error) {
        dispatch(AdminGotTeamCustomModelImagesFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const setTeamEnabledFeatures = useCallback(
    async (
      teamId: string,
      region: string,
      features: UserFeatureFlag[],
      enabledAuditLog: boolean
    ) => {
      const team = getState().admin.teamDetail.team;

      await apiClient.setTeamEnabledFeatures(
        features,
        enabledAuditLog,
        teamId,
        region
      );

      if (team !== undefined) {
        dispatch(
          AdminGotTeamDetail({
            ...team,
            enabledFeatures: features,
            enabledAuditLog,
          })
        );
      }
    },
    [dispatch, getState]
  );

  const listTeamsUsages = useCallback(
    async (range: UsageRange, limit: number, region: string) => {
      dispatch(AdminGettingReportTeamsUsages(range, limit, region));

      try {
        const { teamsUsages } = await apiClient.listTeamsUsages(
          range,
          limit,
          region
        );

        dispatch(AdminGotReportTeamsUsages(range, limit, region, teamsUsages));
      } catch (error) {
        dispatch(
          AdminGotReportTeamsUsagesFailed(region, ensureFOCRError(error))
        );
      }
    },
    [dispatch]
  );

  const listNewTeams = useCallback(
    async (
      region: string,
      minCreatedAt: string,
      offset: number = 0,
      size: number = 20
    ) => {
      dispatch(AdminGettingReportNewTeamList(region));

      try {
        const listResult = await apiClient.listTeam(region, size, offset, {
          minCreatedAt,
          includeTotalUsage: true,
        });

        dispatch(
          AdminGotReportNewTeamList(
            region,
            listResult.teams,
            listResult.pageInfo
          )
        );
      } catch (error) {
        dispatch(
          AdminGotReportNewTeamListFailed(region, ensureFOCRError(error))
        );
      }
    },
    [dispatch]
  );

  const listReportCustomModels = useCallback(
    async (region: string, offset: number = 0, size: number = 20) => {
      dispatch(AdminGettingReportCustomModels(region));

      try {
        const listResult = await apiClient.listAllCustomModels(
          size,
          offset.toString(),
          region
        );

        dispatch(
          AdminGotReportCustomModels(
            region,
            listResult.customModels,
            listResult.pageInfo
          )
        );
      } catch (error) {
        dispatch(
          AdminGotReportCustomModelsFailed(region, ensureFOCRError(error))
        );
      }
    },
    [dispatch]
  );

  const inviteTeamUser = useCallback(
    async (
      email: string,
      permission: Permission,
      teamId: string,
      region: string
    ) => {
      const invitationId = await apiClient.inviteTeamUser(
        teamId,
        email,
        permission,
        region
      );

      dispatch(AdminTeamUserInvited(invitationId, email, permission));
    },
    [dispatch]
  );

  const removeTeamInvitation = useCallback(
    async (invitationCode: string, region: string) => {
      await apiClient.removeInvitation(invitationCode, region);
      dispatch(AdminTeamInvitationRemoved(invitationCode));
    },
    [dispatch]
  );

  const { handleConflict, requestUserConfirmation } =
    useConfirmModalActionCreator();

  const setTeamUserPermission = useCallback(
    async (
      userId: string,
      permission: Permission,
      retrievedAt: string,
      teamId: string,
      region: string
    ) => {
      const currentUserId = getState().user.currentUser?.id;

      if (currentUserId === userId) {
        await requestUserConfirmation({
          titleId: "team.change.permission.title",
          actionId: "common.confirm",
          messageId: "team.change.admin_permission.message",
          type: ConfirmModalType.Destory,
        });
      }

      const { updatedAt } = await handleConflict(
        async () => {
          return apiClient.setTeamUserPermission(
            teamId,
            userId,
            permission,
            retrievedAt,
            region
          );
        },
        async () => {
          return apiClient.setTeamUserPermission(
            teamId,
            userId,
            permission,
            undefined,
            region
          );
        },
        {
          titleId: "team.modified_prompt.title",
          messageId: "team.modified_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );

      dispatch(AdminUserPermissionUpdated(permission, userId, updatedAt));
      dispatch(
        UserPermissionUpdated(permission, teamId, currentUserId === userId)
      );
    },
    [dispatch, getState, handleConflict, requestUserConfirmation]
  );

  const removeTeamUser = useCallback(
    async (userId: string, teamId: string, region: string) => {
      await apiClient.removeTeamUser(teamId, userId, region);

      const currentUser = getState().user.currentUser;

      if (!currentUser) {
        return Promise.reject();
      }

      dispatch(TeamUserRemoved(teamId, userId, currentUser));
      dispatch(AdminTeamUserRemoved(userId));

      return Promise.resolve();
    },
    [dispatch, getState]
  );

  return useMemo(
    () => ({
      listTeams,
      getTeam,
      getTeamMembers,
      getTeamUsage,
      getRequestLogs,
      listTeamForms,
      listTeamFormGroups,
      listTeamCustomModels,
      setTrial,
      clearTrial,
      setEnterprise,
      downgradeEnterprise,
      getTeamCustomModel,
      listTeamCustomModelImages,
      setTeamEnabledFeatures,
      listTeamsUsages,
      listNewTeams,
      listReportCustomModels,
      inviteTeamUser,
      removeTeamInvitation,
      setTeamUserPermission,
      removeTeamUser,
    }),
    [
      listTeams,
      getTeam,
      getTeamMembers,
      getTeamUsage,
      getRequestLogs,
      listTeamForms,
      listTeamFormGroups,
      listTeamCustomModels,
      setTrial,
      clearTrial,
      setEnterprise,
      downgradeEnterprise,
      getTeamCustomModel,
      listTeamCustomModelImages,
      setTeamEnabledFeatures,
      listTeamsUsages,
      listNewTeams,
      listReportCustomModels,
      inviteTeamUser,
      removeTeamInvitation,
      setTeamUserPermission,
      removeTeamUser,
    ]
  );
}
