@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.container {
  background-color: $neutral-lighter-alt;
  display: flex;
  height: 100%;

  .content {
    flex: 1 0 0%;
    padding: 20px;

    .section {
      &:not(:last-child) {
        margin-bottom: 28px;
      }

      .section-header {
        display: block;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .section-empty-state-container {
        background-color: #f3f2f1;
        border: solid 1px #edebe9;
        border-radius: 4px;
        padding: 24px;

        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #605e5c;
        text-align: center;
      }
    }
  }
}

.right-sidebar {
  background-color: $white;
  padding: 16px 12px;
  max-width: 425px;
  min-width: 425px;

  .option-list-section {
    margin-bottom: 20px;

    .heading {
      display: block;
      color: #605e5c;
      font-weight: 600;
      font-size: 12px;
      line-height: 17.4px;
      margin-bottom: 8px;
    }

    .option-list {
      list-style-type: none;
    }
  }
}
